import { ErrorBoundary } from '../ErrorBoundary';
import { LexicalEditor } from '../LexicalEditor';

import { Wrapper } from './styled';

interface ILexicalEditorDiff {
  htmlBefore: string;
  htmlAfter: string;
  isHorizontal: boolean;
}

export const LexicalEditorDiff = (props: ILexicalEditorDiff) => {
  const { htmlBefore, htmlAfter, isHorizontal } = props;

  return (
    <ErrorBoundary tagKey={'LexicalEditorDiff'} tagValue={'lexical-editor-diff'}>
      <Wrapper $isHorizontal={isHorizontal}>
        <div className="editor-before">
          <LexicalEditor value={htmlBefore} isDisabled={false} />
        </div>
        <div className="editor-after">
          <LexicalEditor value={htmlAfter} isDisabled={false} />
        </div>
      </Wrapper>
    </ErrorBoundary>
  );
};
