import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb';

import { AppInfo, Paths } from '@/consts';
import { pageTitle } from '@/client/cache';

import { IBreadcrumbNameMapItem, INewCrumb } from './types';
import { getMatchedBreadcrumb } from './helpers';
import { useBreadcrumbNameMap } from './hooks';

import { BreadcrumbContainer, BreadcrumbLink, BreadcrumbStyled, HomeIcon } from './styled';

const maxLength = 80;

export const Breadcrumb = () => {
  const location = useLocation();
  const { NAME, COMPANY } = AppInfo;
  const title = useReactiveVar(pageTitle);

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbMap = useBreadcrumbNameMap();

  const matchedBreadcrumbs: (INewCrumb | null)[] = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    const matchedCrumb: IBreadcrumbNameMapItem | undefined = getMatchedBreadcrumb(
      url,
      breadcrumbMap,
    );

    if (!matchedCrumb) return null;

    /**
     * Create a new crumb on each iteration
     */
    const newCrumb: INewCrumb = {
      name: '',
      url: '',
    };

    if (!!matchedCrumb.hasId) {
      const newTitle = title || matchedCrumb.name;
      const trimmedTitle =
        newTitle.length > maxLength ? newTitle.substring(0, maxLength - 3) + '...' : newTitle;

      if (!!matchedCrumb.suffix) {
        newCrumb.name = `${trimmedTitle} | ${matchedCrumb.suffix}`;
      } else {
        newCrumb.name = `${trimmedTitle}`;
      }
    } else if (!!matchedCrumb?.name) {
      newCrumb.name = matchedCrumb.name;
    }

    if (!!matchedCrumb) {
      newCrumb.url = url;
    }

    return newCrumb;
  });

  const clearedBreadcrumbs = matchedBreadcrumbs.filter(i => i);

  const setDocumentTitle = () => {
    const oneBeforeLastBreadcrumb = clearedBreadcrumbs[clearedBreadcrumbs.length - 2] ?? null;
    const lastBreadcrumb = clearedBreadcrumbs[clearedBreadcrumbs.length - 1] ?? null;

    if (!!oneBeforeLastBreadcrumb?.name && !!lastBreadcrumb?.name) {
      document.title = `${oneBeforeLastBreadcrumb.name} / ${lastBreadcrumb.name} - ${NAME}`;
    } else if (!!lastBreadcrumb?.name) {
      document.title = `${lastBreadcrumb.name} - ${NAME}`;
    } else if (!lastBreadcrumb) {
      document.title = `Dashboard - ${NAME}`;
    } else {
      document.title = `${NAME} - ${COMPANY}`;
    }
  };

  useEffect(() => {
    setDocumentTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearedBreadcrumbs]);

  const items: BreadcrumbItemType[] = [
    {
      key: '0',
      title: (
        <BreadcrumbLink exact to={Paths.DASHBOARD}>
          {!!pathSnippets.length ? <HomeIcon /> : <>Dashboard</>}
        </BreadcrumbLink>
      ),
    },
    ...(!!clearedBreadcrumbs?.length
      ? clearedBreadcrumbs.map(({ url, name }: any, idx) => ({
          key: idx + 1,
          title: (
            <BreadcrumbLink exact to={url}>
              {name}
            </BreadcrumbLink>
          ),
        }))
      : []),
  ];

  return (
    <BreadcrumbContainer data-testid="breadcrumbs">
      <BreadcrumbStyled items={items} />
    </BreadcrumbContainer>
  );
};
