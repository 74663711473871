import { gql } from '@apollo/client';

import { DEAL_FRAGMENT } from '../fragments';

export const DEAL = gql`
  query deal($id: ID!) {
    deal(id: $id) {
      ...deal
    }
  }
  ${DEAL_FRAGMENT}
`;
