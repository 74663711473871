import { gql } from '@apollo/client';

import { TICKET_PRIORITY_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_PRIORITY = gql`
  mutation updateTicketPriority(
    $id: ID!
    $value: String
    $archived: Boolean
    $color: ColorPaletteEnum
    $priority: Float
  ) {
    updateTicketPriority(
      id: $id
      value: $value
      archived: $archived
      color: $color
      priority: $priority
    ) {
      ...priority
    }
  }
  ${TICKET_PRIORITY_FRAGMENT}
`;
