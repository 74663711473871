import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Input, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { PillsWrapper } from './styled';

interface IPillsField {
  pills: string[];
  handleUpdate: (newPills: string[]) => void;
}

export const PillsField = (props: IPillsField) => {
  const { pills = [], handleUpdate } = props;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newPills = pills.filter(tag => tag !== removedTag);
    handleUpdate(newPills);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      const newPills = inputValue.split('\n').filter(text => text.trim());

      const pillsToAdd = [];
      for (const pill of newPills) {
        if (pills.indexOf(pill) === -1 && pillsToAdd.indexOf(pill) === -1) {
          pillsToAdd.push(pill);
        }
      }
      handleUpdate([...pills, ...pillsToAdd]);
    }

    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    if (pills[editInputIndex] === editInputValue) {
      setEditInputIndex(-1);
      setEditInputValue('');
      return;
    }

    const newPills = [...pills];
    newPills[editInputIndex] = editInputValue;
    handleUpdate(newPills);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const handleClickEdit = (index: number, tag: string) => {
    return (e: React.ClickEvent) => {
      setEditInputIndex(index);
      setEditInputValue(tag);
      e.preventDefault();
    };
  };

  return (
    <PillsWrapper>
      {pills.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={index}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        return (
          <Tag
            className="edit-tag"
            key={index}
            closable={true}
            style={{ cursor: 'pointer' }}
            onClose={() => handleClose(tag)}
          >
            <span
              role="button"
              tabIndex="0"
              onKeyDown={handleClickEdit(index, tag)}
              onClick={handleClickEdit(index, tag)}
            >
              {tag}
            </span>
          </Tag>
        );
      })}
      {inputVisible && (
        <Input.TextArea
          rows={3}
          ref={inputRef}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          className="site-tag-plus"
          role="button"
          tabIndex="0"
          onClick={showInput}
          onKeyDown={showInput}
        >
          <PlusOutlined />
        </Tag>
      )}
    </PillsWrapper>
  );
};
