import {
  DocumentNode,
  NoInfer,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
  QueryHookOptions as ApolloQueryHookOptions,
  useQuery as ApolloUseQuery,
} from '@apollo/client';
import { get } from 'lodash';

export interface QueryHookOptions {
  skip?: boolean;
  [key: string]: any;
}

type BasicGqlResult<TData, TVariables extends OperationVariables, TResult> = Omit<
  QueryResult<TData, TVariables>,
  'data'
> & {
  data: TResult;
};

export const useQueryAndExtract = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TResult = any,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  keyToExtractDataFrom: string,
  options?: ApolloQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
  defaultData: any = [],
): BasicGqlResult<TData, TVariables, TResult> => {
  const result = ApolloUseQuery<TData, TVariables>(query, options);

  return {
    ...result,
    data: get(result.data, keyToExtractDataFrom, defaultData) as TResult,
  };
};
