import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const USER_IN_THE_LIST_FRAGMENT = gql`
  fragment userInTheList on User {
    ...userBasic
    email
    phone
    roleName
    role
  }
  ${USER_BASIC_FRAGMENT}
`;
