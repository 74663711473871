import { gql } from '@apollo/client';

export const CREATE_PHRASES = gql`
  mutation createPhrases($phrases: [PhraseDTOInput!]!, $domainId: String!, $groupId: String) {
    createPhrases(phrases: $phrases, domainId: $domainId, groupId: $groupId) {
      phrases {
        id
      }
      errors
    }
  }
`;
