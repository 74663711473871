import { gql } from '@apollo/client';

export const TEAM_BASIC_FRAGMENT = gql`
  fragment teamBasic on Team {
    id
    name
    leader {
      id
    }
    members {
      id
    }
  }
`;
