import { useEffect, useState } from 'react';

import { IconButton } from '../Buttons';

import { Dot } from './styled';

interface INotificationDot {
  handleMarkNotification: (notificationId: string, markUnread?: boolean) => void;
  notificationId: string;
  read: boolean;
}

export const NotificationDot = ({
  handleMarkNotification,
  notificationId,
  read,
}: INotificationDot) => {
  const [isRead, setIsRead] = useState(read);

  useEffect(() => {
    setIsRead(read);
  }, [read]);

  const handleClick = () => {
    setIsRead(!isRead);
    handleMarkNotification(notificationId, isRead);
  };

  return (
    <IconButton
      icon={
        <Dot read={isRead} data-testid={isRead ? 'notification-read' : 'notification-unread'} />
      }
      onClick={handleClick}
      data-testid="notification-dot"
      round
    />
  );
};
