import { defineMessages } from 'react-intl';

/**
 * i18n Messages
 */
export const i18nMessages = defineMessages({
  resetPassword: {
    id: 'components.Administration.notification.success.resetPassword',
    defaultMessage: 'Zresetowano hasło',
  },
  resetPasswordButton: {
    id: 'components.Administration.buttonText.resetPassword',
    defaultMessage: 'Zresetuj hasło',
  },
  activate: {
    id: 'components.Administration.buttonText.activate',
    defaultMessage: 'Aktywuj',
  },
  deactivate: {
    id: 'components.Administration.buttonText.deactivate',
    defaultMessage: 'Dezaktywuj',
  },
  userText: {
    id: 'components.Administration.buttonText.user',
    defaultMessage: 'użytkownika',
  },
  header: {
    id: 'components.Administration.header',
    defaultMessage: 'Administracja',
  },
  userDeactivated: {
    id: 'components.Administration.notification.success.userDeactivated',
    defaultMessage: 'Dezaktywowano użytkownika',
  },
  userActivated: {
    id: 'components.Administration.notification.success.userActivated',
    defaultMessage: 'Aktywowano użytkownika',
  },
});
