import { FC, PropsWithChildren } from 'react';
import { ApolloError, ServerError } from '@apollo/client';
import { useIntl } from 'react-intl';

import { hasProperty } from '@/utils';

import { Text, Wrapper } from './styled';

interface IErrorMessage {
  error?: ApolloError | (ApolloError | undefined)[];
}

const isErrorArray = (err: unknown): err is ServerError => {
  return (
    hasProperty(err, 'networkError') &&
    hasProperty(err.networkError, 'result') &&
    hasProperty(err.networkError.result, 'errors') &&
    Array.isArray(err.networkError.result.errors)
  );
};

export const ErrorMessage: FC<PropsWithChildren<IErrorMessage>> = ({
  children = null,
  error = null,
}) => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    id: 'components.ErrorMessage.dataFetchingError',
    defaultMessage: 'Nastąpił błąd podczas pobierania danych.',
  });
  const errorTitle = intl.formatMessage({
    defaultMessage: 'Wiadomość błędu',
  });

  const getErrorMessages = (err: ApolloError | undefined) => {
    if (!isErrorArray(err)) return null;

    const errors = err.networkError?.result?.errors;

    return errors.map(({ message }: { message: string }) => <Text key={message}>{message}</Text>);
  };

  const getServerErrors = (errorPack: ApolloError | (ApolloError | undefined)[]) => {
    if (Array.isArray(errorPack)) {
      return errorPack.filter(i => i).map(err => getErrorMessages(err));
    }

    return getErrorMessages(errorPack);
  };

  return (
    <Wrapper data-testid="error-message" aria-label={errorTitle}>
      <Text>{children ?? (!error ? defaultMessage : null)}</Text>
      {!!error && getServerErrors(error)}
    </Wrapper>
  );
};
