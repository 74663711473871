import { defineMessages } from 'react-intl';

/**
 * i18n Messages
 */
export const i18nMessages = defineMessages({
  open: {
    defaultMessage: 'Otwarte',
  },
  closed: {
    defaultMessage: 'Zamknięte',
  },
  overdue: {
    defaultMessage: 'Przeterminowane',
  },
  today: {
    defaultMessage: 'Na dzisiaj',
  },
  thisWeek: {
    defaultMessage: 'Najbliższe 7 dni',
  },
  domains: {
    defaultMessage: 'Domeny',
  },
  title: {
    defaultMessage: 'Podsumowanie ticketów',
  },
});
