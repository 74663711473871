import { lazy, Suspense, useEffect } from 'react';
import './fonts.css';

import { useUser } from '@/context/UserProvider';
import { Loader } from '@/loaders';
import { initializeTagManager } from './utils/gtm';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  useEffect(() => {
    initializeTagManager();
  }, []);
  const user = useUser();

  return (
    <Suspense fallback={<Loader wrapperHeight="100vh" />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

export default App;
