import { FC } from 'react';

import { IconButton } from '../../../Buttons';

import { NotificationsContainer, BellIcon, Badge } from './styled';

interface INotificationBell {
  onClick: () => void;
  unreadTotal: number;
}

export const NotificationBell: FC<INotificationBell> = ({ onClick, unreadTotal }) => (
  <NotificationsContainer onClick={onClick} data-testid="notification-bell">
    <IconButton icon={<BellIcon />} />
    {!!unreadTotal && <Badge count={unreadTotal} />}
  </NotificationsContainer>
);
