import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';
import { TICKET_COMMENT_FILE_FRAGMENT } from './ticketCommentFile';

export const TICKET_COMMENT_FRAGMENT = gql`
  fragment ticketComment on TicketComment {
    id
    user {
      ...userBasic
    }
    content_html
    created_at
    updated_at
    file {
      ...ticketCommentFile
    }
    ticket {
      id
    }
    pwl_id
  }
  ${USER_BASIC_FRAGMENT}
  ${TICKET_COMMENT_FILE_FRAGMENT}
`;
