import { ApolloError, ServerError } from '@apollo/client';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

import { hasNumberProperty } from '@/utils';

import { ErrorMessage } from '../ErrorMessage';

const isServerError = (prop: unknown): prop is ServerError => hasNumberProperty(prop, 'statusCode');

interface IFallbackView {
  error: ApolloError | undefined;
}

enum StatusCodes {
  STATUS_403 = 403,
  STATUS_404 = 404,
  STATUS_500 = 500,
}

export const FallbackView = (props: IFallbackView) => {
  const { error } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  if (isServerError(error?.networkError)) {
    if (
      error?.networkError?.statusCode === StatusCodes.STATUS_403 ||
      error?.networkError?.statusCode === StatusCodes.STATUS_404 ||
      error?.networkError?.statusCode === StatusCodes.STATUS_500
    ) {
      const description = error.networkError.result?.errors?.[0]?.message ?? '';
      const code = error.networkError.statusCode;

      return (
        <Result
          status={code}
          title={code}
          subTitle={description}
          extra={
            <Button type="primary" onClick={handleClick}>
              Dashboard
            </Button>
          }
        />
      );
    }
  }

  return <ErrorMessage error={error} />;
};
