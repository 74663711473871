import { gql } from '@apollo/client';

import { DOMAIN_ADS_BUDGET_FRAGMENT } from '../fragments';

export const CREATE_DOMAIN_ADS_BUDGET = gql`
  mutation createDomainAdsBudget($input: DomainAdsBudgetInput!) {
    createDomainAdsBudget(input: $input) {
      ...domainAdsBudget
    }
  }

  ${DOMAIN_ADS_BUDGET_FRAGMENT}
`;
