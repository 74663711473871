import { ApolloLink, createHttpLink, FetchResult, Observable } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';

import {
  hasNumberProperty,
  onUnauthenticatedErrorResponse,
  serverNotifications,
  updateAppVersion,
} from '@/utils';
import { X_CLIENT_VERSION } from '@/consts';

/**
 |--------------------------------------------------
 | httpLink
 |--------------------------------------------------
 */
export const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin',
  fetch,
});

/**
 |--------------------------------------------------
 | appUpdater
 |--------------------------------------------------
 */
export const appUpdater = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const authHeader = context.response.headers.get(X_CLIENT_VERSION);

    if (authHeader) {
      updateAppVersion(authHeader);
    }

    return response;
  });
});

/**
 |--------------------------------------------------
 | setTokenLink
 |--------------------------------------------------
 */
export const setTokenLink = setContext((_, s) => {
  return {
    headers: {
      ...s.headers,
    },
  };
});

/**
 |--------------------------------------------------
 | errorLink
 |--------------------------------------------------
 */
export const errorLink = onError(
  (
    errorResponse: ErrorResponse,
  ): void | Observable<
    FetchResult<{ [key: string]: unknown }, Record<string, unknown>, Record<string, unknown>>
  > => {
    serverNotifications(errorResponse);

    if (
      hasNumberProperty(errorResponse.networkError, 'statusCode') &&
      errorResponse.networkError.statusCode === 401
    ) {
      onUnauthenticatedErrorResponse(errorResponse);
    }
  },
);
