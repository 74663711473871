import { gql } from '@apollo/client';

export const LOCALES = gql`
  query locales {
    locales {
      id
      key
      value
    }
  }
`;
