export const logRocketSettings = {
  release: APP_VERSION,
  network: {
    requestSanitizer: (request: { headers: Record<string, string> }) => {
      if (request.headers['x-auth-token']) {
        request.headers['x-auth-token'] = '';
      }

      return request;
    },
  },
};
