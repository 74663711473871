import { gql } from '@apollo/client';

import { CREDENTIAL_FILES_FRAGMENT } from './credentialFiles';

export const CREDENTIAL_LOGIN_FRAGMENT = gql`
  fragment credentialLogin on Credential {
    id
    name
    login
    password
    host
    comment

    ...credentialFiles
  }

  ${CREDENTIAL_FILES_FRAGMENT}
`;
