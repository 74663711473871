import { gql } from '@apollo/client';

export const COMMUNICATION_LANGUAGES = gql`
  query communicationLanguages {
    communicationLanguages {
      id
      key
      value
    }
  }
`;
