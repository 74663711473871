import { gql } from '@apollo/client';

import { CMS_FRAGMENT } from '../fragments';

export const UPDATE_CMS_ITEM = gql`
  mutation updateCMSItem($id: ID!, $value: String!) {
    updateCMSItem(id: $id, value: $value) {
      ...cms
    }
  }
  ${CMS_FRAGMENT}
`;
