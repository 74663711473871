import styled, { css } from 'styled-components';

const fileStringCss = css`
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
  text-overflow: ellipsis;
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  max-height: 50px;
`;

export const FileName = styled.span`
  ${fileStringCss};

  color: ${({ theme: { colors } }) => colors.darkBlue};
`;

export const FileNameLink = styled.span`
  ${fileStringCss};

  color: ${({ theme: { colors } }) => colors.lightBlue};
  cursor: pointer;
`;

export const FileSize = styled.div`
  color: ${({ theme: { colors } }) => colors.textGrey};
`;
