import { gql } from '@apollo/client';

export const SUBPAGE_FRAGMENT = gql`
  fragment subpage on Subpage {
    id
    url
    type
    status
    priority
    title
    heading
    description
    character_count
    target_title
    target_heading
    target_description
    target_character_count
    keywords
  }
`;
