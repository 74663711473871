import { gql } from '@apollo/client';

import { PHRASE_GROUP_FRAGMENT } from '../fragments';

export const PHRASE_GROUPS = gql`
  query phraseGroups($domainId: String!) {
    phraseGroups(domainId: $domainId) {
      ...phraseGroup
    }
  }
  ${PHRASE_GROUP_FRAGMENT}
`;
