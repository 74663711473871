import { useState, useEffect, useRef } from 'react';
import { escapeRegExp } from 'lodash';

import { IHit, CursorState, SearchState } from '../../../../consts';

import {
  HitLinkWrapper,
  Hit,
  HitTitle,
  HitContent,
  EnterOutlinedIcon,
  HitAction,
  HitItem,
} from './styled';

const Highlighted = ({ text = '', highlight = '' }) => {
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>,
        )}
    </>
  );
};

interface ISearchHit extends IHit {
  setCursor: (props: CursorState) => void;
  setState: (props: SearchState) => void;
  lastSearchedValue: string;
  cursor: CursorState;
}

export const SearchHit = (props: ISearchHit) => {
  const { title, link, itemKey, setState, setCursor, lastSearchedValue, cursor } = props;

  const [isActive, setIsActive] = useState(() => itemKey === cursor.key);

  const listItemRef = useRef<HTMLLIElement | undefined>();

  useEffect(() => {
    setIsActive(itemKey === cursor.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor.key]);

  useEffect(() => {
    if (isActive && cursor.isArrowed && listItemRef.current) {
      listItemRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const onMouseOver = () => {
    setCursor({ key: itemKey, isArrowed: false });
  };

  const onLinkClick = () => {
    setState({ type: 'closed' });
  };

  return (
    <HitItem key={itemKey} role="option" aria-selected={isActive} ref={listItemRef}>
      <HitLinkWrapper
        onMouseOver={onMouseOver}
        to={link}
        $isActive={isActive}
        onClick={onLinkClick}
      >
        <Hit>
          <HitContent>
            <HitTitle $isActive={isActive}>
              <Highlighted text={title} highlight={lastSearchedValue} />
            </HitTitle>
          </HitContent>
          <HitAction>
            <EnterOutlinedIcon $isActive={isActive} />
          </HitAction>
        </Hit>
      </HitLinkWrapper>
    </HitItem>
  );
};
