import { gql } from '@apollo/client';

export const GROUPED_PERMISSIONS = gql`
  query groupedPermissions {
    groupedPermissions {
      id
      value
      options {
        id
        key
        value
      }
    }
  }
`;
