import { gql } from '@apollo/client';

import { TEXT_ORDER_FRAGMENT } from '../fragments';

export const TEXT_ORDERS = gql`
  query textOrders($grid: TextOrderGridInput) {
    textOrders(grid: $grid) {
      total
      rows {
        ...textOrder
        __typename
      }
      __typename
    }
  }

  ${TEXT_ORDER_FRAGMENT}
`;
