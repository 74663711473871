import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $createParagraphNode, $createTextNode, $getRoot, $insertNodes } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Prompt } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { $createQuoteNode } from '@lexical/rich-text';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

import { saveToEditorsIDB } from '@/utils';
import { EMPTY_EDITOR_HTML, RESET_EDITOR_KEYWORD } from '@/consts';
import { isEditorSaved } from '@/client/cache';

import { useSettings } from './context/SettingsContext';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import TableCellNodes from './nodes/TableCellNodes';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import ContextMenuPlugin from './plugins/ContextMenuPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import { CAN_USE_DOM } from './utils/canUseDOM';
import { ILexicalEditor } from './';

import { EditorContainer, EditorScroller, Wrapper } from './styled';

export default function Editor(props: ILexicalEditor): JSX.Element {
  const {
    value,
    onChange,
    isEditing = false,
    isDisabled = false,
    autoFocus,
    uploadImages = true,
    autoSaveKey,
    dataTestId,
    initialValue,
    quoteContent,
  } = props;
  const intl = useIntl();

  const { historyState } = useSharedHistoryContext();
  const {
    settings: {
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();
  const isEditable = useLexicalEditable();
  const text = intl.formatMessage({ defaultMessage: 'Dodaj treść...' });
  const placeholder = <Placeholder>{text}</Placeholder>;
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const cellEditorConfig = {
    namespace: 'Playground',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const [editor] = useLexicalComposerContext();

  const shouldDisplayPrompt = !useReactiveVar(isEditorSaved);

  const currentContent = useRef(value);
  const lastAutoSavedContent = useRef('');

  const debouncedAutoSave = useMemo(
    () =>
      debounce((contentHtml: string) => {
        if (
          !contentHtml ||
          contentHtml === EMPTY_EDITOR_HTML ||
          contentHtml === lastAutoSavedContent.current
        )
          return;

        lastAutoSavedContent.current = contentHtml;

        saveToEditorsIDB({ contentHtml, key: autoSaveKey });
      }, 2500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChange = () => {
    isEditorSaved(false);

    editor.update(() => {
      const editorState = editor.getEditorState();
      const contentHtml = $generateHtmlFromNodes(editor);

      currentContent.current = contentHtml;

      if (autoSaveKey !== undefined) {
        debouncedAutoSave(contentHtml);
      }

      if (onChange) onChange(editor, editorState);
    });
  };

  // Older editor was saving images with incorrect html code
  const fixImagesFromOlderEditor = (dom: Document) => {
    dom.querySelectorAll('figure').forEach(figure => {
      // Remove everything that isn't <img> inside figure
      for (const node of figure.childNodes) {
        if (node.nodeName !== 'IMG') figure.removeChild(node);
      }
      // Wrap img inside <p> instead of <figure> - otherwise it will display in the wrong place
      const p = document.createElement('p');
      p.innerHTML = figure.innerHTML;
      figure.replaceWith(p);
    });
    return dom;
  };

  const updateEditor = (textValue: string, focus = false) => {
    editor.update(() => {
      const parser = new DOMParser();
      const dom = fixImagesFromOlderEditor(parser.parseFromString(textValue, 'text/html'));
      if (
        dom.body.firstElementChild?.nodeName !== 'DIV' ||
        !dom.body.firstElementChild?.hasAttribute('lexical-div-wrapper')
      ) {
        const wrapperDiv = document.createElement('div');
        wrapperDiv.setAttribute('lexical-div-wrapper', '');
        wrapperDiv.append(...dom.body.childNodes);
        dom.body.appendChild(wrapperDiv);
      }
      const nodes = $generateNodesFromDOM(editor, dom);

      const root = $getRoot();
      root.clear();

      if (focus) root.select();

      $insertNodes(nodes);

      if (!focus) setTimeout(() => editor.blur(), 0);
    });
  };

  useEffect(() => {
    if (!value) return;

    updateEditor(value, autoFocus);
  }, []);

  useEffect(() => {
    if (!autoFocus || !isEditing) return;

    editor.focus(
      () => {
        const activeElement = document.activeElement;
        const rootElement = editor.getRootElement() as HTMLDivElement;
        if (
          rootElement !== null &&
          (activeElement === null || !rootElement.contains(activeElement))
        ) {
          rootElement.focus({ preventScroll: true });
        }
      },
      { defaultSelection: 'rootEnd' },
    );
  }, [isEditing]);

  useEffect(() => {
    if (value !== RESET_EDITOR_KEYWORD) return;

    updateEditor(initialValue, false);
  }, [value]);

  useEffect(() => {
    if (!quoteContent) return;

    editor.update(() => {
      const root = $getRoot();
      root.clear();

      const authorTextNode = $createTextNode(
        `${quoteContent.author} ${intl.formatMessage({ defaultMessage: 'napisał/a' })}:`,
      ).setFormat(3);

      const parser = new DOMParser();
      const dom = parser.parseFromString(quoteContent.contentHtml, 'text/html');

      const quoteNode = $createQuoteNode();
      quoteNode.append(
        $createParagraphNode().append(authorTextNode),
        ...$generateNodesFromDOM(editor, dom),
      );

      $insertNodes([quoteNode]);

      root.append($createParagraphNode().append($createTextNode()));

      root.select();
    });
  }, [quoteContent]);

  useEffect(() => {
    editor.setEditable(isEditing);
  }, [isEditing]);

  const checkForUnsavedData = () => {
    return (
      historyState?.undoStack !== undefined &&
      historyState?.undoStack.length > 0 &&
      currentContent.current !== EMPTY_EDITOR_HTML &&
      shouldDisplayPrompt &&
      isEditing
    );
  };

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      if (checkForUnsavedData()) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [value, shouldDisplayPrompt]);

  return (
    <Wrapper>
      <Prompt
        when={checkForUnsavedData()}
        message={intl.formatMessage({
          defaultMessage: 'Czy na pewno chcesz opuścić stronę?',
        })}
      />
      <div
        className={`editor-container ${showTreeView ? 'tree-view' : ''} ${
          !isRichText ? 'plain-text' : ''
        }`}
      >
        {isMaxLength && <MaxLengthPlugin maxLength={30} />}
        <DragDropPaste uploadImages={uploadImages} />
        <ClearEditorPlugin />
        <ComponentPickerPlugin />
        <MentionsPlugin />
        <HashtagPlugin />
        <AutoLinkPlugin />
        <OnChangePlugin onChange={handleChange} ignoreSelectionChange={true} />
        {isRichText ? (
          <>
            <HistoryPlugin externalHistoryState={historyState} />
            <RichTextPlugin
              contentEditable={
                <EditorScroller isEditing={isEditing} isDisabled={isDisabled}>
                  <EditorContainer
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    data-testid={dataTestId}
                    ref={onRef}
                  >
                    <ContentEditable isEditing={isEditing} />
                  </EditorContainer>
                </EditorScroller>
              }
              placeholder={isDisabled ? null : placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <TablePlugin
              hasCellMerge={tableCellMerge}
              hasCellBackgroundColor={tableCellBackgroundColor}
            />
            <ListMaxIndentLevelPlugin maxDepth={3} />
            <NewTablePlugin cellEditorConfig={cellEditorConfig}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable isEditing={isEditing} className="TableNode__contentEditable" />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <MentionsPlugin />
              <HistoryPlugin />
              <ImagesPlugin uploadImages={uploadImages} />
              <LinkPlugin />
              <LexicalClickableLinkPlugin />
            </NewTablePlugin>
            <ImagesPlugin uploadImages={uploadImages} />
            {/* <InlineImagePlugin uploadImages={uploadImages} /> */}
            <LinkPlugin />
            {!isEditable && <LexicalClickableLinkPlugin />}
            <TabFocusPlugin />
            <TabIndentationPlugin />
            <CollapsiblePlugin />
            {floatingAnchorElem && !isSmallWidthViewport && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
              </>
            )}
          </>
        ) : (
          <>
            <PlainTextPlugin
              contentEditable={<ContentEditable editable={isEditing} />}
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin externalHistoryState={historyState} />
          </>
        )}
        {(isCharLimit || isCharLimitUtf8) && (
          <CharacterLimitPlugin charset={isCharLimit ? 'UTF-16' : 'UTF-8'} maxLength={5} />
        )}
        <div>{showTableOfContents && <TableOfContentsPlugin />}</div>
        {shouldUseLexicalContextMenu && <ContextMenuPlugin />}
      </div>
      {isRichText && isEditing && <ToolbarPlugin />}
      {showTreeView && <TreeViewPlugin />}
    </Wrapper>
  );
}
