import { gql } from '@apollo/client';

import { DOMAIN_REPORT_FRAGMENT } from '../fragments';

export const CREATE_DOMAIN_REPORT = gql`
  mutation createDomainReport(
    $domainId: ID!
    $fields: [DomainReportFieldInput!]!
    # $language: LocaleEnum = LocaleEnum["pl_PL"]
    $language: LocaleEnum
    $reportType: DomainReportTypeEnum!
  ) {
    createDomainReport(
      domainId: $domainId
      fields: $fields
      language: $language
      reportType: $reportType
    ) {
      ...domainReport
    }
  }
  ${DOMAIN_REPORT_FRAGMENT}
`;
