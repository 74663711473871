import { gql } from '@apollo/client';

export const CREATE_TICKET_NO_DATA = gql`
  mutation createTicketNoData(
    $title: String!
    $contentHtml: String
    $priorityId: ID!
    $typeId: ID!
    $statusId: ID!
    $projectId: ID!
    $assignedUserId: ID
    $deadline: DateTime
    $domainId: ID
    $start: DateTime!
    $watchers: [ID!]
    $todos: [TodoInput!]
  ) {
    createTicket(
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      typeId: $typeId
      statusId: $statusId
      projectId: $projectId
      assignedUserId: $assignedUserId
      deadline: $deadline
      domainId: $domainId
      start: $start
      watchers: $watchers
      todos: $todos
    ) {
      id
      title
      domain {
        id
        name
      }
    }
  }
`;
