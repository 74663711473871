import { gql } from '@apollo/client';

import { TIME_LOG_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_TIME = gql`
  mutation updateTicketTime($id: ID!, $minutes: Int!) {
    updateTicketTime(id: $id, minutes: $minutes) {
      ...timeLog
    }
  }
  ${TIME_LOG_FRAGMENT}
`;
