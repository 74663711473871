import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const TICKET = gql`
  query ticket($id: ID!) {
    ticket(id: $id) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
