import { gql } from '@apollo/client';

export const DISCOUNT_FRAGMENT = gql`
  fragment discount on DealDiscount {
    id
    unit
    value
    start_date
    end_date
  }
`;
