import { queries } from '@/services';
import { UserBasicFragment, UsersQuery, UsersQueryVariables } from '@/__generated__';

import { useQueryAndExtract, QueryHookOptions } from './useQuery';

export const useUsers = (options?: QueryHookOptions) =>
  useQueryAndExtract<UsersQuery, UsersQueryVariables, UserBasicFragment[]>(
    queries.USERS,
    'users.rows',
    options,
  );
