import { gql } from '@apollo/client';

import { QUARTER_FRAGMENT, QUARTER_OVERRIDE_FRAGMENT } from '../fragments';

export const QUARTERS = gql`
  query quarters($id: ID!) {
    domain(id: $id) {
      id

      quarters {
        ...quarter
      }

      quarterOverrides {
        ...quarterOverride
      }
    }
  }

  ${QUARTER_FRAGMENT}
  ${QUARTER_OVERRIDE_FRAGMENT}
`;
