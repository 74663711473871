import { gql } from '@apollo/client';

import { TEAM_FRAGMENT } from '../fragments';

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: ID!, $name: String, $leaderId: ID, $memberIds: [ID!]!) {
    updateTeam(id: $id, name: $name, leaderId: $leaderId, memberIds: $memberIds) {
      ...team
    }
  }
  ${TEAM_FRAGMENT}
`;
