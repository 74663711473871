import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';

import { ITimeStatsFilters } from '../../consts';

import { TableHeader } from './styled';

export const RestrictedTimeStatsFilters = (props: ITimeStatsFilters) => {
  const { monthRange } = props;

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const placeholder: string = intl.formatMessage({ defaultMessage: 'Wybierz miesiąc' });

  const onTimeRangeChange = (date: Dayjs) => {
    const search = `?${date.format('YYYY-MM')}`;

    history.push({
      pathname: location.pathname,
      search,
    });
  };

  return (
    <TableHeader data-testid={'time-stats-filters'}>
      <DatePicker.MonthPicker
        placeholder={placeholder}
        onChange={onTimeRangeChange}
        value={monthRange[1]}
        format={'MM-YYYY'}
        picker="month"
        allowClear={false}
        inputReadOnly
      />
    </TableHeader>
  );
};
