import { gql } from '@apollo/client';

export const QUARTER_OVERRIDE_FRAGMENT = gql`
  fragment quarterOverride on QuarterOverride {
    id
    number
    start_date
    end_date
    type
    type_name
    characters_to_create
  }
`;
