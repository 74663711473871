import { gql } from '@apollo/client';

export const RELATED_TICKET_FRAGMENT = gql`
  fragment relatedTicket on Ticket {
    relation_type
    is_children
    sequence
    id
    title
    created_at
    updated_at
    priority {
      id
      value
      color
    }
    type {
      id
      value
      color
    }
    status {
      id
      value
      color
    }
    project {
      id
      name
    }
  }
`;
