import { gql } from '@apollo/client';

import { USER_IN_THE_LIST_FRAGMENT } from './userInTheList';

export const TEAM_FRAGMENT = gql`
  fragment team on Team {
    totalMembers
    id
    name
    leader {
      ...userInTheList
    }
    members {
      ...userInTheList
    }
  }
  ${USER_IN_THE_LIST_FRAGMENT}
`;
