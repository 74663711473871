import { gql } from '@apollo/client';

export const LINK_STATUSES = gql`
  query linkStatuses {
    linkStatuses {
      key
      id
      value
    }
  }
`;
