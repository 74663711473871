import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const UPDATE_TICKET = gql`
  mutation updateTicket(
    $id: ID!
    $title: String
    $contentHtml: String
    $priorityId: ID
    $projectId: ID
    $typeId: ID
    $statusId: ID
    $domainId: ID
    $ownerId: ID
    $deadline: DateTime
    $start: DateTime
    $isDraft: Boolean
  ) {
    updateTicket(
      id: $id
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      projectId: $projectId
      typeId: $typeId
      statusId: $statusId
      domainId: $domainId
      ownerId: $ownerId
      deadline: $deadline
      start: $start
      isDraft: $isDraft
    ) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
