import { FC, MouseEvent, ReactNode } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useIntl } from 'react-intl';

import { DropdownMenuKeys } from '@/scenes/GAManager/scenes/GAMInternalAccountsList/components/AccountsList/components/AccountsTable/components/Table/consts';

import {
  EditIcon,
  ItemOptions,
  Menu,
  MenuItem,
  OptionsIcon,
  RefreshIcon,
  TrashIcon,
} from './styled';

interface IOptionsDropdown {
  onMenuClick: (menuInfo: DropdownMenuKeys) => void;
  onDropdownClick?: (e: MouseEvent) => void;
  showDelete?: boolean;
  showEdit?: boolean;
  showRefreshToken?: boolean;
  showRegenerateToken?: boolean;
}

export const OptionsDropdown: FC<IOptionsDropdown> = ({
  onMenuClick,
  onDropdownClick,
  showDelete = true,
  showEdit = true,
  showRefreshToken = false,
  showRegenerateToken = false,
}) => {
  const intl = useIntl();
  const { EDIT, REMOVE, REFRESH_TOKEN, REGENERATE_TOKEN } = DropdownMenuKeys;

  const menuItems = [
    ...(showEdit
      ? [
          {
            key: EDIT,
            text: intl.formatMessage({
              id: 'components.OptionsDropdown.menuItem.edit',
              defaultMessage: 'Edytuj',
            }),
            icon: EditIcon,
            'data-testid': 'dropdown-edit',
          },
        ]
      : []),
    ...(showDelete
      ? [
          {
            key: REMOVE,
            text: intl.formatMessage({
              id: 'components.OptionsDropdown.menuItem.remove',
              defaultMessage: 'Usuń',
            }),
            icon: TrashIcon,
            'data-testid': 'dropdown-remove',
          },
        ]
      : []),
    ...(showRefreshToken
      ? [
          {
            key: REFRESH_TOKEN,
            text: intl.formatMessage({
              id: 'components.OptionsDropdown.menuItem.refreshToken',
              defaultMessage: 'Odśwież token',
            }),
            icon: RefreshIcon,
            'data-testid': 'dropdown-refresh-token',
          },
        ]
      : []),
    ...(showRegenerateToken
      ? [
          {
            key: REGENERATE_TOKEN,
            text: intl.formatMessage({
              id: 'components.OptionsDropdown.menuItem.refreshToken',
              defaultMessage: 'Wygeneruj token na nowo',
            }),
            icon: RefreshIcon,
            'data-testid': 'dropdown-regenate-token',
          },
        ]
      : []),
  ];

  const items: MenuProps['items'] = menuItems.map(({ key, text, icon: Icon, ...props }) => ({
    key,
    label: (
      <MenuItem onClick={() => onMenuClick(key)} {...props}>
        <Icon />
        {text}
      </MenuItem>
    ),
  }));

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={['click']}
      dropdownRender={(menus: ReactNode) => <Menu>{menus}</Menu>}
    >
      <ItemOptions onClick={onDropdownClick} data-testid="dropdown-trigger">
        <OptionsIcon />
      </ItemOptions>
    </Dropdown>
  );
};
