import { gql } from '@apollo/client';

export const TEXT_ORDER_PRIORITIES = gql`
  query textOrderPriorities {
    textOrderPriorities {
      id
      key
      value
    }
  }
`;
