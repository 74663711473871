import { gql } from '@apollo/client';

import { TICKET_COMMENT_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_COMMENT = gql`
  mutation updateTicketComment($id: ID!, $contentHtml: String!) {
    updateTicketComment(id: $id, contentHtml: $contentHtml) {
      ...ticketComment
    }
  }
  ${TICKET_COMMENT_FRAGMENT}
`;
