import { useEffect, useRef, useState } from 'react';

import { useHorizontalScrollPosition } from './useHorizontalScrollPosition';

export const useHorizontalScroll = () => {
  const innerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState({ isStart: true, isEnd: true });

  useEffect(() => {
    if (
      scrollRef.current &&
      innerRef.current &&
      scrollRef.current.offsetWidth > innerRef.current.offsetWidth
    ) {
      setPosition({ isStart: true, isEnd: false });
    }
  }, []);

  useHorizontalScrollPosition({
    effect: ({ isStart, isEnd }: { isStart: boolean; isEnd: boolean }) => {
      if (isStart !== position.isStart || isEnd !== position.isEnd) setPosition({ isStart, isEnd });
    },
    deps: [position],
    innerRef,
  });

  return { position, innerRef, scrollRef };
};
