import { gql } from '@apollo/client';

export const GLOBAL_SEARCH = gql`
  query globalSearch(
    $domainsGrid: DomainGridInput
    $usersGrid: UserGridInput
    $ticketsGrid: TicketGridInput
    $domainContactsGrid: DomainContactsGridInput
  ) {
    domains(grid: $domainsGrid) {
      rows {
        id
        name
      }
    }
    users(grid: $usersGrid) {
      rows {
        id
        first_name
        last_name
      }
    }
    tickets(grid: $ticketsGrid) {
      rows {
        id
        title
      }
    }
    domainsContacts(grid: $domainContactsGrid) {
      rows {
        id
        domain {
          id
          name
        }
      }
    }
  }
`;
