import { Paths } from '@/consts';

import { Name, NameWrapper } from './styled';

interface IDomainCellProps {
  id: string;
  name: string;
  to?: string;
}

export const DomainCell = (props: IDomainCellProps) => {
  const { id, name, to = `${Paths.DOMAINS}/${id}/info` } = props;

  return (
    <NameWrapper>
      <Name to={to} data-testid="domain-name-link">
        {name}
      </Name>
    </NameWrapper>
  );
};
