import { gql } from '@apollo/client';

import { GAM_INTERNAL_ACCOUNT_FRAGMENT } from '../fragments';

export const CREATE_GAM_INTERNAL_ACCOUNT = gql`
  mutation createGAMInternalAccount($name: String!) {
    createGAMInternalAccount(name: $name) {
      ...gamInternalAccount
    }
  }

  ${GAM_INTERNAL_ACCOUNT_FRAGMENT}
`;
