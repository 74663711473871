import { gql } from '@apollo/client';

import { PHRASE_POSITION_ORDERED_FRAGMENT } from './phrasePositionOrdered';

/**
 * This fragment can only be used in a GROUPED_PHRASES query
 * as it contains 'positionsOrdered' field
 */
export const GROUPED_PHRASE_FRAGMENT = gql`
  fragment groupedPhrase on Phrase {
    id
    value
    start_position
    user_assigned_url
    location
    location_key
    deleted_at
    is_paused
    import_id
    last_position_url
    exclude_from_linking
    positionsOrdered {
      ...phrasePositionOrdered
    }
    search {
      id
      count
    }
    group {
      id
      name
    }
    domain {
      id
    }
  }
  ${PHRASE_POSITION_ORDERED_FRAGMENT}
`;
