import { useMutation } from '@apollo/client';
import { noop } from 'lodash';

import { mutations } from '@/services';
import {
  MarkReadNotificationMutation,
  MarkReadNotificationMutationVariables,
  MarkUnreadNotificationMutation,
  MarkUnreadNotificationMutationVariables,
} from '@/__generated__';

interface IHandleMarkNotification {
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
  notificationId: string;
  markUnread?: boolean;
}

export const useMarkNotification = () => {
  const [markReadNotification] = useMutation<
    MarkReadNotificationMutation,
    MarkReadNotificationMutationVariables
  >(mutations.MARK_READ_NOTIFICATION);

  const [markUnreadNotification] = useMutation<
    MarkUnreadNotificationMutation,
    MarkUnreadNotificationMutationVariables
  >(mutations.MARK_UNREAD_NOTIFICATION);

  const markNotification = (markUnread: boolean) =>
    markUnread ? markUnreadNotification : markReadNotification;

  const handleMarkNotification = ({
    notificationId,
    markUnread = false,
    onSuccess = noop,
    onError = noop,
  }: IHandleMarkNotification) => {
    return markNotification(markUnread)({
      variables: { id: notificationId },
    })
      .then(response => {
        onSuccess(response);
      })
      .catch(error => {
        onError(error);
      });
  };

  return [handleMarkNotification];
};
