import { Tooltip } from 'antd';

import { theme } from '@/theme';
import { UserBasicFragment } from '@/__generated__';

import { UserItem } from '../UserItem';

import { Wrapper } from './styled';

interface IAvatarWithTooltip {
  user: UserBasicFragment;
}

export const AvatarWithTooltip = (props: IAvatarWithTooltip) => {
  const { user } = props;
  const { first_name, last_name, id } = user;
  const { font, border, colors } = theme;

  const name = `${first_name} ${last_name}`;

  return (
    <Wrapper>
      <Tooltip
        title={name}
        placement="topRight"
        trigger={['hover', 'click']}
        overlayStyle={{
          fontWeight: font.weight.bold,
          fontSize: font.size.semiMedium,
          borderRadius: border.radius.big,
        }}
        key={id}
        destroyTooltipOnHide
      >
        <div aria-label="avatar with tooltip" aria-details={name} role="button">
          <UserItem user={user} avatarOnly />
        </div>
      </Tooltip>
    </Wrapper>
  );
};
