import { useIntl } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IRow, ITimeStatsTable } from '../../consts';
import { Loader } from '../../../../Loader';

import { monthColumns, renderTime } from './helpers';

export const TimeStatsTable = (props: ITimeStatsTable) => {
  const intl = useIntl();

  const { rows, totalsRow, months, loading } = props;

  const columns: ColumnsType<IRow> = [
    {
      title: intl.formatMessage({ defaultMessage: 'Projekt' }),
      dataIndex: 'projectName',
    },
    ...monthColumns(months),
    {
      title: intl.formatMessage({ defaultMessage: 'Suma' }),
      dataIndex: 'total',
      align: 'center',
      render: renderTime,
    },
  ];

  const lastRow: IRow = {
    ...totalsRow,
    projectName: intl.formatMessage({ defaultMessage: 'Suma' }),
  };

  const data: IRow[] = [...rows, ...(!!lastRow.total ? [lastRow] : [])];

  return (
    <Table<IRow>
      columns={columns}
      dataSource={data}
      size={'small'}
      loading={loading ? { indicator: <Loader /> } : false}
      pagination={false}
      scroll={{ x: 800 }}
      bordered
      data-testid={'time-stats-table'}
    />
  );
};
