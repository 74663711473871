import styled from 'styled-components';
import { InputNumber } from 'antd';

export const LogTimeFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NumberInputField = styled(InputNumber)`
  width: 60px;
  border: 1px solid ${({ theme: { colors } }) => colors.grey2};
  border-radius: ${({ theme: { border } }) => border.radius.big};
`;

export const FieldsWrapper = styled.div<{ label: 'top' | 'right' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${props => (props.label === 'right' ? 'flex-wrap: wrap;' : '')}
`;

export const FieldWrapper = styled.div<{ label: 'top' | 'right' }>`
  display: flex;
  margin-right: 10px;

  ${props =>
    props.label === 'top'
      ? `
    flex-direction: column-reverse;
    align-items: end;
    margin-top: -13px;
  `
      : `
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: 10px;
`;

export const FormLabel = styled.div`
  width: 100%;
  margin-top: 25px;
  color: ${({ theme: { colors } }) => colors.darkBlue};
`;

export const FieldLabel = styled.div`
  margin: 0 10px 0 5px;
`;

export const Alert = styled.div`
  color: ${({ theme: { colors } }) => colors.notifRed};
`;
