import { FC } from 'react';

import { AnyFileIcon, PdfFileIcon, ImageFileIcon, TextFileIcon } from './styled';

interface IFileIcon {
  name?: string;
  height: number;
}

export const FileIcon: FC<IFileIcon> = ({ name = '', height = 16 }) => {
  const pdfRegex = /(\.pdf)$/i;
  const imageRegex = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
  const wordRegex = /(\.doc|\.docx|\.text|\.dot|\.dotx)$/i;

  if (pdfRegex.exec(name)) {
    return <PdfFileIcon height={height} />;
  }

  if (imageRegex.exec(name)) {
    return <ImageFileIcon height={height} />;
  }

  if (wordRegex.exec(name)) {
    return <TextFileIcon height={height} />;
  }

  return <AnyFileIcon height={height} />;
};
