import { FC } from 'react';
import { useIntl } from 'react-intl';

import { DownloadTrigger } from '../DownloadTrigger';

import { FileWrapper, FileNameLink, FileName, FileSize } from './styled';

interface IAttachment {
  fileId: string;
  name: string;
  url?: string;
  canBeDownloaded?: boolean;
  sizeInKb?: string | number;
  type?: 'ticket' | 'ticket-comment' | 'credential';
}

export const Attachment: FC<IAttachment> = ({
  fileId,
  name,
  canBeDownloaded = true,
  sizeInKb = null,
  url = null,
  type,
}) => {
  const intl = useIntl();

  const getFileSizeInMb = (kbSize: number | string) => {
    const sizeInMb =
      typeof kbSize === 'string' ? parseInt(kbSize, 10) * 0.00095367432 : kbSize * 0.00095367432;

    return sizeInMb < 0.01 ? '0.01' : sizeInMb.toFixed(2);
  };

  const getUrl = (id: string) => `/${type}/file/${id}`;

  const getFileName = (fileName: string) => {
    /* Max number of characters for min width */
    if (fileName.length < 28) return fileName;

    const lastDotIndex = fileName.lastIndexOf('.');
    const fileTitle = fileName.substring(0, lastDotIndex);
    const fileExt = fileName.substring(lastDotIndex + 1);

    /* Substring indices below are calculated to fit the current filename box min-width */
    return fileTitle.substr(0, 15) + '...' + fileTitle.substr(-8 + fileExt.length) + '.' + fileExt;
  };

  const fileName = getFileName(name);

  return (
    <FileWrapper key={fileId}>
      {canBeDownloaded ? (
        <DownloadTrigger url={url ?? getUrl(fileId)}>
          <FileNameLink data-testid="file-link">{fileName}</FileNameLink>
        </DownloadTrigger>
      ) : (
        <FileName data-testid="file-not-a-link">{fileName}</FileName>
      )}
      {!!sizeInKb && (
        <FileSize>
          {intl.formatMessage({
            id: 'containers.Attachments.fileDescription.fileSize',
            defaultMessage: 'Rozmiar',
          })}{' '}
          {getFileSizeInMb(sizeInKb)} MB
        </FileSize>
      )}
    </FileWrapper>
  );
};
