import { gql } from '@apollo/client';

import { ROLE_FRAGMENT } from '../fragments';

export const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $title: String!) {
    updateRole(id: $id, title: $title) {
      ...role
    }
  }
  ${ROLE_FRAGMENT}
`;
