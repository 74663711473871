import { SortOrder } from 'antd/lib/table/interface';

export enum FilterOperators {
  RANGE = 'RANGE',
  LT = 'LT',
  GT = 'GT',
  LTE = 'LTE',
  GTE = 'GTE',
  EQ = 'EQ',
  NEQ = 'NEQ',
  LIKE = 'LIKE',
  IN = 'IN',
}

export enum SortDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ValueOperators {
  FALSE = 'false',
  TRUE = 'true',
}

/* Used for removing values */
export const EMPTY_STRING = '';

export enum Search {
  FULLTEXT = 'fulltext',
}

export const sortDirections: SortOrder[] = ['descend', 'ascend'];
