import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const CHANGE_TICKET_ASSIGNEE = gql`
  mutation changeTicketAssignee($ticketId: ID!, $assignedUserId: ID!) {
    changeTicketAssignee(ticketId: $ticketId, assignedUserId: $assignedUserId) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
