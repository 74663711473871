import { gql } from '@apollo/client';

import { CREDENTIAL_FRAGMENT } from '../fragments';

export const CREDENTIALS = gql`
  query credentials($domainId: String!) {
    credentials(domainId: $domainId) {
      ...credential
    }
  }
  ${CREDENTIAL_FRAGMENT}
`;
