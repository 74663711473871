import { DatePickerStyled } from './styled';

const SuffixIcon = () => null;

export const Date = (props: any) => {
  return (
    <DatePickerStyled
      {...props}
      placeholder={props.placeholder || ''}
      suffixIcon={<SuffixIcon />}
    />
  );
};
