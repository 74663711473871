import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';

import { Paths } from '@/consts';
import { mutations } from '@/services';
import { useDeviceLayout, useMarkNotification, useNotify } from '@/hooks';
import {
  MarkAllNotificationReadMutation,
  MarkReadNotificationMutationVariables,
  Notification,
} from '@/__generated__';

import { Skeleton } from '../Skeleton';
import { ErrorMessage } from '../ErrorMessage';

import { useGlobalNotifications } from './useGlobalNotifications';
import {
  NotificationBell,
  NotificationItem,
  NotificationsFooter,
  NotificationsHeader,
} from './components';

import { NoNewNotifications, Popup } from './styled';

export const Notifications = () => {
  const intl = useIntl();
  const { notifyError, notifySuccess } = useNotify();
  const { isMobileLayout } = useDeviceLayout();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: userNotificationsData,
    loading: loadingUserNotifications,
    error: userNotificationsError,
    refetch: refetchUserNotifications,
  } = useGlobalNotifications();

  const [markNotification] = useMarkNotification();

  const [markAllNotificationRead, { loading: loadingMarkAllNotificationRead }] = useMutation<
    MarkAllNotificationReadMutation,
    MarkReadNotificationMutationVariables
  >(mutations.MARK_ALL_NOTIFICATION_READ);

  const handleMarkNotification = (notificationId: string, markUnread = false, isLink = false) => {
    const onError = () => {
      notifyError(
        intl.formatMessage({
          id: 'components.Notifications.markReadNotification.notifyError',
          defaultMessage: 'Błąd przy zmianie statusu powiadomienia',
        }),
      );
    };

    isLink && setIsOpen(false);
    markNotification({ notificationId, markUnread, onError });
  };

  const onRefreshClick = () => {
    refetchUserNotifications();
  };

  const handleReadAllClick = () => {
    markAllNotificationRead().then(() => {
      refetchUserNotifications();

      notifySuccess(
        intl.formatMessage({
          defaultMessage: 'Oznaczono wszystkie powiadomienia jako przeczytane',
        }),
      );
    });
  };

  const handleShowAllClick = () => {
    setIsOpen(false);
    history.push(Paths.NOTIFICATIONS);
  };

  const getItemsList = () => {
    if (loadingUserNotifications) return <Skeleton padding={'20px'} rows={2} hideTitle />;
    if (userNotificationsError) return <ErrorMessage error={userNotificationsError} />;

    const userNotificationsList: Notification[] = userNotificationsData?.rows ?? [];

    return !!userNotificationsList?.length ? (
      userNotificationsList.map(notification => (
        <NotificationItem
          key={notification.id}
          handleMarkNotification={handleMarkNotification}
          notification={notification}
        />
      ))
    ) : (
      <NoNewNotifications>
        {intl.formatMessage({
          id: `components.Notifications.noNewNotifications`,
          defaultMessage: 'Brak nowych powiadomień',
        })}
      </NoNewNotifications>
    );
  };

  const content = (
    <Popup isMobileLayout={isMobileLayout}>
      <NotificationsHeader onRefreshClick={onRefreshClick} loading={loadingUserNotifications} />
      {getItemsList()}
      <NotificationsFooter
        handleReadAllClick={handleReadAllClick}
        loadingMarkAllNotificationRead={loadingMarkAllNotificationRead}
        handleShowAllClick={handleShowAllClick}
      />
    </Popup>
  );

  const handleOpenChange = (isVisible: boolean) => {
    setIsOpen(isVisible);
  };

  const unreadTotal = userNotificationsData?.total_unread ?? 0;

  const handleBellClick = () => {
    refetchUserNotifications();
  };

  return (
    <Popover
      content={content}
      open={isOpen}
      onOpenChange={handleOpenChange}
      trigger="click"
      placement="bottomRight"
    >
      <NotificationBell onClick={handleBellClick} unreadTotal={unreadTotal} />
    </Popover>
  );
};
