import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

export const ButtonStyled = styled<{ $colors: any; $width: string; $height: string } | any>(
  ButtonAntd,
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  min-width: 30px;
  padding: 0 25px;
  background-color: ${({ $colors }) => $colors.bg};
  color: ${({ $colors }) => $colors.text};
  border-radius: 4px;

  :active {
    background-color: ${({ $colors }) => $colors.bg};
    color: ${({ $colors }) => $colors.text};
  }

  :hover,
  :focus {
    background-color: ${({ $colors }) => $colors.bg};
    color: ${({ $colors }) => $colors.text} !important;
    border-color: unset !important;
    filter: brightness(115%);
  }
`;
