import { gql } from '@apollo/client';

export const SUBPAGE_STATUSES = gql`
  query subpageStatuses {
    subpageStatuses {
      key
      id
    }
  }
`;
