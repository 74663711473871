import { queries } from '@/services';
import { UserQuery, UserQueryVariables } from '@/__generated__';

import { useQueryAndExtract } from './useQuery';

export const useUser = (id: string | undefined, path?: string, defaultData?: unknown) => {
  const propertyPath = path ? `.${path}` : '';
  return useQueryAndExtract<UserQuery, UserQueryVariables>(
    queries.USER,
    `user${propertyPath}`,
    {
      variables: { id: id as string },
      skip: !id,
    },
    defaultData,
  );
};
