import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const TEXT_ORDER_FRAGMENT = gql`
  fragment textOrder on TextOrder {
    id
    title
    text_type
    text_type_name
    schema {
      meta_description
      bold_keywords
      short_text
      long_text
      base_on_url
      headers
    }
    status
    priority
    order_type
    order_type_name
    url
    text_sum
    text_url
    text_to_be_written_by
    domain {
      id
      name
      brief_copy
      country_name
    }
    verifier {
      ...userBasic
    }
    orderer {
      ...userBasic
    }
    keywords
    tags
    verification_time
    text_score
    final_text_count
    created_at
    verification_date
    planned_publication_date
    text_added_at
    error_message
    sendingToClientTicket {
      id
    }
    implementationTicket {
      id
    }
    comments
  }

  ${USER_BASIC_FRAGMENT}
`;
