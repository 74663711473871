import { gql } from '@apollo/client';

import { DOMAIN_BASIC_FRAGMENT } from '../fragments';

export const DOMAINS = gql`
  query domains($grid: DomainGridInput) {
    domains(grid: $grid) {
      rows {
        ...domainBasic
      }
    }
  }
  ${DOMAIN_BASIC_FRAGMENT}
`;
