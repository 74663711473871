import { gql } from '@apollo/client';

import { CREDENTIAL_FRAGMENT } from '../fragments';

export const UPDATE_CREDENTIALS = gql`
  mutation updateCredentials(
    $id: String!
    $domainId: String
    $host: String
    $login: String
    $name: String
    $password: String
    $comment: String
  ) {
    updateCredentials(
      id: $id
      domainId: $domainId
      host: $host
      login: $login
      name: $name
      password: $password
      comment: $comment
    ) {
      ...credential
    }
  }
  ${CREDENTIAL_FRAGMENT}
`;
