import { useContext } from 'react';

import { CopywritingContext } from './contexts';

export const useCopywritingContext = () => {
  const context = useContext(CopywritingContext);

  if (!context) {
    throw new Error('useCopywritingContext must be used within a CopywritingContextProvider');
  }

  return context;
};
