import { FC, ChangeEvent } from 'react';

import { Input, SearchGlassIcon } from './styled';

interface ISearchInput {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  placeholder?: string;
}

export const SearchInput: FC<ISearchInput> = ({ value, onChange, onEnter, placeholder = '' }) => (
  <Input
    placeholder={placeholder}
    type="text"
    value={value}
    onChange={onChange}
    onPressEnter={onEnter}
    prefix={<SearchGlassIcon />}
    data-testid="search-input"
  />
);
