import { openDB, DBSchema, StoreNames, IDBPDatabase } from 'idb';
import dayjs from 'dayjs';

import { log } from './logService';

enum IDBs {
  TICKETS_IDB = 'tickets-idb',
  TICKETS_PILLS_IDB = 'tickets-pills-idb',
  DOMAINS_IDB = 'tickets-idb',
  DOMAINS_PILLS_IDB = 'domains-pills-idb',
  COPYWRITING_PILLS_IDB = 'copywriting-pills-idb',
}

enum IDBStores {
  EDITORS = 'editors',
  TICKETS_PILLS = 'ticketsPills',
  DOMAINS_PILLS = 'domainsPills',
  COPYWRITING = 'copywritingPills',
}

interface EditorsIDB extends DBSchema {
  editors: {
    value: { contentHtml: string; valueKey: number; createdAt: string };
    key: number;
  };
}

interface TicketsPillsIDB extends DBSchema {
  ticketsPills: {
    value: {
      url: string;
      name: string;
      id: string;
      createdAt: string;
      color: string;
      showInAllTickets: boolean;
      showInDomainTickets: boolean;
    };
    key: string;
  };
}

interface DomainsPillsIDB extends DBSchema {
  domainsPills: {
    value: {
      url: string;
      name: string;
      id: string;
      createdAt: string;
      color: string;
    };
    key: string;
  };
}

interface CopywritingPillsIDB extends DBSchema {
  copywritingPills: {
    value: {
      url: string;
      name: string;
      id: string;
      createdAt: string;
      color: string;
    };
    key: string;
  };
}

const openIDB = async <TDatabase>(
  database: IDBs,
  table: IDBStores,
): Promise<IDBPDatabase<TDatabase>> => {
  const db = await openDB<TDatabase>(database, 1, {
    upgrade(dbObj) {
      dbObj.createObjectStore(table as StoreNames<TDatabase>);
    },
  });

  if (!db) throw new Error('There was an error opening a database');

  return db;
};

const savePills = async <TDatabase>(
  _openIDB: () => Promise<IDBPDatabase<TDatabase> | undefined>,
  table: IDBStores,
  pills: any[],
) => {
  try {
    const db = await _openIDB();

    if (!db) throw new Error('There was an error opening a database');

    await db.clear(table);

    const transactions = [];
    const tx = db.transaction(table, 'readwrite');

    pills.forEach(pill => {
      const date = new Date();

      transactions.push(
        tx.store.add({ ...pill, createdAt: dayjs(date).format('DD MM YYYY HH:mm') }, pill.id),
      );
    });

    return Promise.all([...transactions, tx.done]);
  } catch (e: unknown) {
    log(e);
  }
};

const getAllPills = async <TDatabase>(
  _openIDB: () => Promise<IDBPDatabase<TDatabase> | undefined>,
  table: IDBStores,
) => {
  try {
    const db = await _openIDB();

    if (!db) throw new Error('There was an error opening a database');

    const all = await db.getAll(table);

    return all;
  } catch (e: unknown) {
    log(e);
  }
};

export const openEditorsIDB = async () => {
  return openIDB<EditorsIDB>(IDBs.TICKETS_IDB, IDBStores.EDITORS);
};

export const openTicketsPillsIDB = async () => {
  return openIDB<TicketsPillsIDB>(IDBs.TICKETS_PILLS_IDB, IDBStores.TICKETS_PILLS);
};

export const openDomainsPillsIDB = async () => {
  return openIDB<DomainsPillsIDB>(IDBs.DOMAINS_PILLS_IDB, IDBStores.DOMAINS_PILLS);
};

export const openCopywritingPillsIDB = async () => {
  return openIDB<CopywritingPillsIDB>(IDBs.COPYWRITING_PILLS_IDB, IDBStores.COPYWRITING);
};

export const saveToTicketsPillsIDB = async (pills: any[]) => {
  return await savePills<TicketsPillsIDB>(openTicketsPillsIDB, IDBStores.TICKETS_PILLS, pills);
};

export const saveToDomainPillsIDB = async (pills: any[]) => {
  return await savePills<DomainsPillsIDB>(openDomainsPillsIDB, IDBStores.DOMAINS_PILLS, pills);
};

export const saveToCopywritingPillsIDB = async (pills: any[]) => {
  return await savePills<CopywritingPillsIDB>(
    openCopywritingPillsIDB,
    IDBStores.COPYWRITING,
    pills,
  );
};

export const getAllFromTicketsPillsIDB = async () => {
  return await getAllPills<TicketsPillsIDB>(openTicketsPillsIDB, IDBStores.TICKETS_PILLS);
};

export const getAllFromDomainsPillsIDB = async () => {
  return await getAllPills<DomainsPillsIDB>(openDomainsPillsIDB, IDBStores.DOMAINS_PILLS);
};

export const getAllFromCopywritingPillsIDB = async () => {
  return await getAllPills<CopywritingPillsIDB>(openCopywritingPillsIDB, IDBStores.COPYWRITING);
};

export const saveToEditorsIDB = async ({
  contentHtml,
  key,
}: {
  contentHtml: string;
  key?: string;
}) => {
  try {
    const db = await openEditorsIDB();

    const date = new Date();
    key ??= String(date.getTime());

    return await db.put(
      IDBStores.EDITORS,
      {
        contentHtml,
        valueKey: key,
        createdAt: dayjs(date).format('MM-DD-YYYY HH:mm'),
      },
      key,
    );
  } catch (e: unknown) {
    log(e);
  }
};

export const getItemFromEditorsIDB = async (key: string) => {
  const db = await openEditorsIDB();

  const item = await db.get(IDBStores.EDITORS, key);

  return item;
};

export const getAllFromEditorsIDB = async () => {
  try {
    const db = await openEditorsIDB();

    const all = await db.getAll(IDBStores.EDITORS);

    return all;
  } catch (e: unknown) {
    log(e);
  }
};

export const deleteFromEditorsIDB = async (key: string) => {
  try {
    const db = await openEditorsIDB();

    return await db.delete(IDBStores.EDITORS, key);
  } catch (e: unknown) {
    log(e);
  }
};

export const deleteOutdatedAutosaves = async () => {
  const db = await openEditorsIDB();

  const allItems = await db.getAll(IDBStores.EDITORS);

  for (const item of allItems) {
    if (dayjs(item.createdAt).add(7, 'day') < dayjs()) {
      await db.delete(IDBStores.EDITORS, item.valueKey);
    }
  }
};
