import { gql } from '@apollo/client';

export const DOMAIN_SERVICE_TYPES = gql`
  query domainServiceTypes {
    domainServiceTypes {
      key
      id
      value
    }
  }
`;
