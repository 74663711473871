import { gql } from '@apollo/client';

import { FULL_DOMAIN_VIEW_FRAGMENT } from '../fragments';

export const FULL_DOMAIN_VIEW = gql`
  query fullDomainView($id: ID!) {
    domain(id: $id) {
      ...fullDomainView
    }
  }
  ${FULL_DOMAIN_VIEW_FRAGMENT}
`;
