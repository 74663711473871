import Lightbox from 'react-image-lightbox';

import { Loader } from '../Loader';

interface IImageView {
  closeImageView: () => void;
  image: string;
}

export const ImageView = (props: IImageView) => {
  const { closeImageView, image } = props;

  // @ts-ignore
  return <Lightbox mainSrc={image} onCloseRequest={closeImageView} loader={<Loader light />} />;
};
