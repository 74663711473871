import { gql } from '@apollo/client';

import { TIME_STATS_FRAGMENT } from '../fragments';

export const TIME_LOGS = gql`
  query timeLogs($grid: TimeLogGridInput) {
    timeLogs(grid: $grid) {
      total
      rows {
        ...timeStats
      }
    }
  }
  ${TIME_STATS_FRAGMENT}
`;
