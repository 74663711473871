import { gql } from '@apollo/client';

export const DEAL_TERMINATION_FRAGMENT = gql`
  fragment dealTermination on DealTermination {
    id
    saved
    valid
    termination_date
  }
`;
