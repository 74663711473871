import { FC, ReactNode } from 'react';

interface IDownloadTrigger {
  url: string;
  children: ReactNode;
}

export const DownloadTrigger: FC<IDownloadTrigger> = ({ url, children }) => {
  const triggerHref = `${API_URL}${url}`;

  return (
    <a key={url} href={triggerHref} download>
      {children}
    </a>
  );
};
