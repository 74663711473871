export interface IPill {
  url: string;
  name: string;
  id: string;
  showInAllTickets: boolean;
  showInDomainTickets: boolean;
  color?: string;
  createdAt?: string;
}

export enum FilterType {
  DOMAIN_TICKETS = 'domainTickets',
  DOMAINS = 'domains',
  TICKETS = 'tickets',
  COPYWRITING = 'copywriting',
}
