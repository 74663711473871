import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const DELETE_RELATED_TICKET = gql`
  mutation deleteRelatedTicket($ticketId: String!, $relatedTicketId: String!) {
    deleteRelatedTicket(ticketId: $ticketId, relatedTicketId: $relatedTicketId) {
      ...ticket
    }
  }

  ${TICKET_FRAGMENT}
`;
