import { gql } from '@apollo/client';

export const TEMPLATES_EXTERNAL_REPORT = gql`
  query templatesExternalReport {
    templatesExternalReport {
      id
      key
    }
  }
`;
