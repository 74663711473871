import { FC } from 'react';

import { Skeleton, ISkeleton } from '../Skeleton';

import { Wrapper } from './styled';

interface ISkeletonList extends ISkeleton {
  skeletons?: number;
  direction?: 'row' | 'column';
}

export const SkeletonList: FC<ISkeletonList> = ({
  skeletons = 3,
  direction = 'column',
  ...props
}) => (
  <Wrapper direction={direction}>
    {[...Array(skeletons)].map((_, index) => (
      <Skeleton key={index} height="auto" {...props} />
    ))}
  </Wrapper>
);
