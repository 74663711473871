import styled from 'styled-components';

import { PlusInCircle } from '../../Icon';

export const PlusIcon = styled(PlusInCircle)`
  fill: ${({ theme: { colors } }) => colors.white};
  height: 15px;
  min-width: 15px;
  margin-right: 10px;
`;
