import { isNewVersionAvailable } from '@/client/cache';

import { log } from './logService';

/**
 * This ain't an actual app version,
 * but a random hash generated by BE
 */
export const updateAppVersion = (authHeader: string) => {
  try {
    setTimeout(() => {
      if (!APP_VERSION || !authHeader) {
        return;
      }

      if (APP_VERSION === authHeader) {
        return;
      }

      isNewVersionAvailable(true);
    }, 0);
  } catch (e: unknown) {
    log(e);
  }
};
