import { useMutation, FetchResult } from '@apollo/client';

import { mutations } from '@/services';
import {
  ImportPhrasesFromGoogleSheetsMutation,
  ImportPhrasesFromGoogleSheetsMutationVariables,
} from '@/__generated__';
import { useMonitoringContext } from '@/scenes/Domains/scenes/DomainView/scenes/Monitoring/hooks';

interface IHandleImportPhrasesFromGoogleSheets {
  variables: ImportPhrasesFromGoogleSheetsMutationVariables;
}

type IUseImportPhrasesFromGoogleSheets<T> = [
  (props: IHandleImportPhrasesFromGoogleSheets, refetch?: boolean) => Promise<T>,
  { loading: boolean },
];
export const useImportPhrasesFromGoogleSheets = (): IUseImportPhrasesFromGoogleSheets<
  FetchResult<ImportPhrasesFromGoogleSheetsMutation>
> => {
  const [importPhrasesFromGoogleSheets, { loading }] = useMutation<
    ImportPhrasesFromGoogleSheetsMutation,
    ImportPhrasesFromGoogleSheetsMutationVariables
  >(mutations.IMPORT_PHRASES_FROM_GOOGLE_SHEETS);

  const handleImportPhrasesFromGoogleSheets = ({
    variables,
  }: IHandleImportPhrasesFromGoogleSheets) => {
    return importPhrasesFromGoogleSheets({
      variables,
    });
  };

  return [handleImportPhrasesFromGoogleSheets, { loading }];
};
