import { FC, ReactNode } from 'react';
import { ButtonProps } from 'antd';

import { ButtonWithoutStyle } from './styled';

interface IIconButton extends ButtonProps {
  icon?: ReactNode;
  round?: boolean;
  noHover?: boolean;
}

export const IconButton: FC<IIconButton> = props => {
  const { icon, children, round = false, noHover = false, ...rest } = props;

  return (
    <ButtonWithoutStyle round={round ? '50%' : '5px'} brightness={noHover ? 100 : 80} {...rest}>
      {icon}
      {children}
    </ButtonWithoutStyle>
  );
};
