import { gql } from '@apollo/client';

import { TEXT_ORDER_GUIDELINE_FRAGMENT } from '../fragments';

export const CREATE_TEXT_ORDER_GUIDELINE = gql`
  mutation createTextOrderGuideline($input: CreateTextOrderGuidelineInput!) {
    createTextOrderGuideline(input: $input) {
      ...textOrderGuideline
    }
  }

  ${TEXT_ORDER_GUIDELINE_FRAGMENT}
`;
