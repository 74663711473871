import { gql } from '@apollo/client';

export const GAM_INTERNAL_ACCOUNT_FRAGMENT = gql`
  fragment gamInternalAccount on GAMInternalAccount {
    id
    name
    count_ga
    count_gsc
  }
`;
