import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import { mutations, queries } from '@/services';
import { useNotify } from '@/hooks';
import {
  CreateQuarterOverrideMutation,
  CreateQuarterOverrideMutationVariables,
} from '@/__generated__';
import { useDomainContext } from '@/scenes/Domains/scenes/DomainView/hooks';

type IUseCreateQuarterOverride = [(props: any) => Promise<void>];

export const useCreateQuarterOverride = (): IUseCreateQuarterOverride => {
  const intl = useIntl();
  const { notifyError, notifySuccess } = useNotify();

  const { domain } = useDomainContext();

  const [createQuarterOverride] = useMutation<
    CreateQuarterOverrideMutation,
    CreateQuarterOverrideMutationVariables
  >(mutations.CREATE_QUARTER_OVERRIDE);

  const handleCreateQuarterOverride = ({ variables }: any) => {
    return createQuarterOverride({
      variables,
      refetchQueries: [{ query: queries.QUARTERS, variables: { id: domain.id } }],
    })
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: 'scenes.QuarterOverrideForm.notification.success',
            defaultMessage: `Nadpisano kwartał`,
          }),
        );
      })

      .catch(() => {
        notifyError(
          intl.formatMessage({
            id: 'scenes.QuarterOverrideForm.notification.error',
            defaultMessage: 'Nastąpił błąd podczas nadpisywaniu kwartału',
          }),
        );
      });
  };

  return [handleCreateQuarterOverride];
};
