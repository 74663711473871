import { gql } from '@apollo/client';

import { DOMAIN_DETAILS_FRAGMENT } from '../fragments';

export const DOMAIN_DETAILS = gql`
  query domainDetails($id: ID!) {
    domain(id: $id) {
      ...domainDetails
    }
  }
  ${DOMAIN_DETAILS_FRAGMENT}
`;
