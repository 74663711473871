import { gql } from '@apollo/client';

export const TEXT_ORDER_STATUSES = gql`
  query textOrderStatuses {
    textOrderStatuses {
      id
      key
      value
    }
  }
`;
