import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { QuarterSummaryDTO, QuarterTypeEnum } from '@/__generated__';

import {
  Quarter as StyledQuarter,
  QuarterBackground,
  QuarterNumber,
  QuarterDates,
  QuarterCharacters,
  QuarterChangedText,
  EditQuarterButton,
  PencilIcon,
  QuarterSummary,
  QuarterDivider,
  QuarterWrapper,
  FutureQuarterText,
} from '../styled';
import { SliderRangeTypes } from '../consts';

interface IQuarter {
  quarter: {
    number: number;
    displayed_number: number;
    type: QuarterTypeEnum;
    start_date: Date;
    end_date: Date | null;
    created_characters: number;
    characters_to_create: number;
    summary: QuarterSummaryDTO | null;
  };
  canOverrideQuarter: boolean;
  isOverridden: boolean;

  updateSliderRange(quarter: number, type: SliderRangeTypes): void;

  handleEdit(evt: MouseEvent): void;
  refProp: any;
}

export const Quarter = ({
  quarter,
  canOverrideQuarter = false,
  isOverridden,
  updateSliderRange,
  handleEdit,
  refProp = null,
}: IQuarter) => {
  const intl = useIntl();

  return (
    <StyledQuarter
      data-quarter-number={quarter.number}
      className={quarter.type}
      aria-hidden="true"
      ref={refProp}
    >
      <QuarterBackground
        onClick={() => updateSliderRange(quarter.number, SliderRangeTypes.SINGLE)}
      />
      <QuarterNumber>
        {quarter.type === QuarterTypeEnum.ZERO
          ? intl.formatMessage({ defaultMessage: 'Przed startem' })
          : quarter.type === QuarterTypeEnum.ANALYTICAL
          ? intl.formatMessage({ defaultMessage: 'Analityczny' })
          : `${intl.formatMessage({ defaultMessage: 'Kwartał' })} ${quarter.displayed_number}`}
      </QuarterNumber>
      <QuarterWrapper>
        <QuarterDates>
          {quarter.type !== QuarterTypeEnum.ZERO && (
            <>{dayjs(quarter.start_date).format('DD.MM.YYYY')}</>
          )}
          <br />
          {quarter.end_date ? dayjs(quarter.end_date).format('DD.MM.YYYY') : <br />}
        </QuarterDates>
        <QuarterCharacters>
          {intl.formatMessage({ defaultMessage: 'Tekst' })}
          <br />
          {quarter.created_characters.toLocaleString()} /{' '}
          {quarter.characters_to_create.toLocaleString()}
        </QuarterCharacters>
      </QuarterWrapper>
      <QuarterDivider></QuarterDivider>
      <QuarterSummary>
        <p>Zamówione: {quarter.summary?.ordered.toLocaleString()}</p>
        <p>Gotowe: {quarter.summary?.done.toLocaleString()}</p>
      </QuarterSummary>
      <QuarterSummary>
        <p>Zaakceptowane: {quarter.summary?.accepted.toLocaleString()}</p>
        <p>Wdrożone: {quarter.summary?.implemented.toLocaleString()}</p>
      </QuarterSummary>
      <QuarterSummary>
        <p>Błędne zlecenie: {quarter.summary?.wrong.toLocaleString()}</p>
        <p>Do poprawy: {quarter.summary?.to_be_corrected.toLocaleString()}</p>
      </QuarterSummary>
      <QuarterDivider></QuarterDivider>
      <QuarterSummary>
        <p>Opis kategorii: {quarter.summary?.category_description.toLocaleString()}</p>
        <p>Artykuł: {quarter.summary?.article.toLocaleString()}</p>
      </QuarterSummary>
      <QuarterSummary>
        <p>Opis produktu: {quarter.summary?.product_description.toLocaleString()}</p>
        <p>FAQ: {quarter.summary?.faq.toLocaleString()}</p>
      </QuarterSummary>
      <QuarterSummary>
        <p>Opis meta: {quarter.summary?.meta_description.toLocaleString()}</p>
      </QuarterSummary>
      {isOverridden && (
        <QuarterChangedText>
          {intl.formatMessage({ defaultMessage: 'Zmieniony' })}
        </QuarterChangedText>
      )}
      {quarter.type === QuarterTypeEnum.FUTURE && (
        <FutureQuarterText>{intl.formatMessage({ defaultMessage: 'Przyszły' })}</FutureQuarterText>
      )}
      {quarter.type === QuarterTypeEnum.TERMINATION_COMPLETION && (
        <FutureQuarterText>
          {intl.formatMessage({ defaultMessage: 'Wyrównanie' })}
        </FutureQuarterText>
      )}
      {canOverrideQuarter && (
        <EditQuarterButton onClick={handleEdit}>
          <PencilIcon />
        </EditQuarterButton>
      )}
    </StyledQuarter>
  );
};
