import { ApolloError } from '@apollo/client';

import { DomainBasicFragment, TicketFragment, UserBasicFragment } from '@/__generated__';

type ResultUser = Pick<UserBasicFragment, 'id' | 'first_name' | 'last_name'>;
type ResultDomain = Pick<DomainBasicFragment, 'id' | 'name'>;
type ResultTicket = Pick<TicketFragment, 'id' | 'title'>;

export enum SearchCategories {
  DOMAINS = 'domains',
  USERS = 'users',
  ACTIONS = 'actions',
  TICKETS = 'tickets',
}

export interface IResult {
  [SearchCategories.DOMAINS]: ResultDomain[];
  [SearchCategories.USERS]: ResultUser[];
  [SearchCategories.TICKETS]: ResultTicket[];
}

export interface IAction {
  id: string;
  name: string;
  link: string;
}

export const limit = 5;
export const searchBounce = 300;

export interface IHit {
  id: string;
  title: string;
  link: string;
  itemKey: number;
}

export interface IHitsGroup {
  key: string;
  groupTitle: string;
  hits: IHit[];
}

export type SearchState =
  | { type: 'loading' }
  | { type: 'found'; results: IHitsGroup[]; lastSearchedValue: string }
  | { type: 'not-found' }
  | { type: 'closed' }
  | { type: 'error'; error: ApolloError }
  | { type: 'mounted' };

export type CursorState = { key: number; isArrowed: boolean };
