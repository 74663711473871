import { lazy, FC, Suspense, PropsWithChildren } from 'react';

import { Locale } from '@/consts';

import { Loader } from '../components';

/**
 * Interfaces & Types
 */
interface I18nProvider {
  locale: Locale;
  defaultLocale: Locale;
  messages: Record<string, unknown>;
}

/**
 * Component
 */
const Provider: FC<PropsWithChildren<I18nProvider>> = ({
  locale,
  children,
  defaultLocale,
  messages,
}) => {
  const lang = locale.split('-')[0];
  const IntlProvider = lazy(() => import(`./providers/${lang}.ts`));

  return (
    <Suspense fallback={<Loader wrapperHeight="100vh" />}>
      <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={messages}>
        {children}
      </IntlProvider>
    </Suspense>
  );
};

export default Provider;
