import { gql } from '@apollo/client';

import { LSP_TYPE_FRAGMENT } from '../fragments';

export const CREATE_LSP_TYPE = gql`
  mutation createLspType($value: String!) {
    createLspType(value: $value) {
      ...lspType
    }
  }
  ${LSP_TYPE_FRAGMENT}
`;
