import { FC, forwardRef } from 'react';
import { ErrorMessage, FieldProps } from 'formik';

import { ErrorWrapper, FieldWrapper, InputNumber, Label } from '../styled';

interface INumberInputField extends FieldProps {
  label?: string;
}

export const NumberInputField: FC<INumberInputField> = forwardRef(
  ({ field, form, label = null, ...props }, ref) => {
    return (
      <FieldWrapper>
        {label && <Label>{label}</Label>}
        <InputNumber
          data-testid={`${field.name ?? 'number'}-input`}
          // @ts-ignore
          ref={ref}
          {...field}
          {...props}
          /**
           * InputNumber is giving back the value instead of a SyntheticEvent object
           * as Antd Input does.
           * Formik does not know what value should change when calling handleChange
           * with just a number.
           */
          onChange={(value: number | string) => {
            form.setFieldValue(field.name, value);
          }}
        />
        <ErrorMessage
          {...field}
          data-testid={`${field.name ?? 'number'}-error`}
          component={ErrorWrapper}
        />
      </FieldWrapper>
    );
  },
);

NumberInputField.displayName = 'NumberInputField';
