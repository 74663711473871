import styled, { css } from 'styled-components';
import { Select as SelectAntd } from 'antd';

const selectStyles = css`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    border: dashed 2px ${({ theme: { colors } }) => colors.grey4};
    background-color: ${({ theme: { colors } }) => colors.lightGrey4};
    line-height: normal;
    cursor: pointer;

    .ant-select-selection-overflow {
      cursor: pointer;
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;

      .ant-select-selection-search-input {
        cursor: pointer !important;
      }
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
    }
  }

  .ant-select-item-option-content {
    white-space: normal;
  }
`;

export const RectangleSelect = styled<{ $height: string; $width: string } | any>(SelectAntd)`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${selectStyles}
`;

export const RoundSelect = styled<{ $height: string; $width: string } | any>(SelectAntd)`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${selectStyles}

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 50%;
  }
`;

export const Text = styled.div`
  padding-left: 13px;
  color: ${({ theme: { colors } }) => colors.lightGrey5};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
`;
