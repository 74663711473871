import { CSSProperties, FC, PropsWithChildren } from 'react';

import { Tab, Text } from './styled';

interface ISimpleTab {
  isSelected: boolean;
  hasRibbons?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
}

export const SimpleTab: FC<PropsWithChildren<ISimpleTab>> = props => {
  const { children, isSelected, hasRibbons = false, disabled = false, ...rest } = props;

  return (
    <Tab
      $isSelected={isSelected}
      $hasRibbons={hasRibbons}
      $disabled={disabled}
      aria-selected={isSelected}
      role="tab"
      {...rest}
    >
      <Text>{children}</Text>
    </Tab>
  );
};
