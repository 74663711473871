import { gql } from '@apollo/client';

import { TEAM_FRAGMENT } from '../fragments';

export const CREATE_TEAM = gql`
  mutation createTeam($name: String!, $leaderId: ID!, $memberIds: [ID!]!) {
    createTeam(name: $name, leaderId: $leaderId, memberIds: $memberIds) {
      ...team
    }
  }
  ${TEAM_FRAGMENT}
`;
