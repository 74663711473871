import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const ARCHIVE_PROJECT = gql`
  mutation archiveProject($id: ID!) {
    archiveProject(id: $id) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
