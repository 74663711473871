import { useEffect, useState } from 'react';

import { Equals } from '@/components/Icon';
import { useWindowDimensions } from '@/hooks';

import { SliderHandle as StyledSliderHandle } from '../styled';
import { SliderRangeTypes } from '../consts';

interface ISliderHandle {
  zoom: number;
  type: SliderRangeTypes.LEFT | SliderRangeTypes.RIGHT;
  sliderStartNum: number;
  sliderEndNum: number;
  updateSliderOffsets(
    position: number,
    handle: SliderRangeTypes.LEFT | SliderRangeTypes.RIGHT,
  ): void;
  updateSliderRange(quarter: number, handle: SliderRangeTypes.LEFT | SliderRangeTypes.RIGHT): void;
}

const $$ = document.querySelector.bind(document);

export const SliderHandle = ({
  type,
  sliderStartNum,
  sliderEndNum,
  updateSliderOffsets,
  updateSliderRange,
  zoom,
}: ISliderHandle) => {
  const [handlePosition, setHandlePosition] = useState<number | null>(null);
  const [isDragged, setIsDragged] = useState(false);

  const { width } = useWindowDimensions();

  const fixSlider = () => {
    const position = (() => {
      if (type === SliderRangeTypes.LEFT) {
        return $$(`[data-quarter-number="${sliderStartNum}"]`).offsetLeft;
      } else if (type === SliderRangeTypes.RIGHT) {
        return (
          $$(`[data-quarter-number="${sliderEndNum}"]`).offsetLeft +
          $$(`[data-quarter-number="${sliderEndNum}"]`).offsetWidth -
          2
        );
      }
    })();

    updateSliderOffsets(position, type);
    setHandlePosition(position);
  };

  useEffect(fixSlider, [zoom, width, sliderStartNum, sliderEndNum]);

  useEffect(() => {
    setTimeout(fixSlider, 250);
  }, []);

  useEffect(() => {
    updateSliderOffsets(handlePosition, type);
  }, [handlePosition]);

  const handleMoveMouse = (e: MouseEvent) => {
    if (!isDragged) return;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;

    const newOffset =
      e.pageX -
      $$('[data-quarters-wrapper]').getBoundingClientRect().left +
      $$('[data-quarters-wrapper]').scrollLeft;

    if (type === SliderRangeTypes.LEFT) {
      const quartersBorderOffset = $$(`[data-quarter-number="${0}"]`).offsetLeft;
      const sliderBorderOffset = $$(`[data-quarter-number="${sliderEndNum}"]`).offsetLeft;

      if (newOffset < quartersBorderOffset) setHandlePosition(quartersBorderOffset);
      else if (newOffset > sliderBorderOffset) setHandlePosition(sliderBorderOffset);
      else setHandlePosition(newOffset);
    } else if (type === SliderRangeTypes.RIGHT) {
      const sliderBorderOffset =
        $$(`[data-quarter-number="${sliderStartNum}"]`).offsetLeft +
        $$(`[data-quarter-number="${sliderStartNum}"]`).offsetWidth;
      const quartersBorderOffset =
        $$(`[data-quarter-number]:last-of-type`).offsetLeft +
        $$(`[data-quarter-number]:last-of-type`).offsetWidth;

      if (newOffset < sliderBorderOffset) setHandlePosition(sliderBorderOffset);
      else if (newOffset > quartersBorderOffset) setHandlePosition(quartersBorderOffset);
      else setHandlePosition(newOffset);
    }
  };

  const getClosestQuarterNumber = (position: number) => {
    const quartersNodes = [...document.querySelectorAll('[data-quarters-wrapper] > .quarter')];
    let quartersBorders = [];

    if (type === SliderRangeTypes.LEFT) {
      quartersBorders = quartersNodes
        .filter(el => Number(el.dataset.quarterNumber) <= sliderEndNum)
        .map(el => [el.offsetLeft, Number(el.dataset.quarterNumber)]);
    } else {
      quartersBorders = quartersNodes
        .filter(el => Number(el.dataset.quarterNumber) >= sliderStartNum)
        .map(el => [el.offsetLeft + el.offsetWidth, Number(el.dataset.quarterNumber)]);
    }

    return quartersBorders.sort(
      (a, b) => Math.abs(a[0] - position) - Math.abs(b[0] - position),
    )[0][1];
  };

  useEffect(() => {
    if (isDragged) {
      window.addEventListener('mousemove', handleMoveMouse);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMoveMouse);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMoveMouse);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragged]);

  const handleMouseDown = (e: MouseEvent) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;

    setIsDragged(true);
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;

    setIsDragged(false);
    const currentHandlePosition =
      e.pageX -
      $$('[data-quarters-wrapper]').getBoundingClientRect().left +
      $$('[data-quarters-wrapper]').scrollLeft;
    const quarterNumber = getClosestQuarterNumber(currentHandlePosition);

    if (
      (type === SliderRangeTypes.LEFT && quarterNumber === sliderStartNum) ||
      (type === SliderRangeTypes.RIGHT && quarterNumber === sliderEndNum)
    )
      return fixSlider();

    updateSliderRange(quarterNumber, type);

    if (
      (type === SliderRangeTypes.LEFT && quarterNumber === sliderStartNum) ||
      (type === SliderRangeTypes.RIGHT && quarterNumber === sliderEndNum)
    )
      fixSlider();
  };

  if (handlePosition === null) return null;

  return (
    <StyledSliderHandle
      className={isDragged ? 'active' : ''}
      onMouseDown={handleMouseDown}
      data-slider-handle={type}
      style={{ left: `${handlePosition - 6}px` }}
    >
      <Equals />
    </StyledSliderHandle>
  );
};
