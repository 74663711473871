import { gql } from '@apollo/client';

import { GROUPED_PHRASE_FRAGMENT } from './groupedPhrase';

/**
 * This fragment can only be used in a GROUPED_PHRASES query
 * as it contains 'positionsOrdered' field in the 'groupedPhrase' field
 */
export const GROUPED_PHRASES_FRAGMENT = gql`
  fragment groupedPhrases on GroupedPhrases {
    id
    name
    phrases {
      ...groupedPhrase
    }
  }
  ${GROUPED_PHRASE_FRAGMENT}
`;
