import { gql } from '@apollo/client';

export const UPDATE_PHRASE_URL = gql`
  mutation updatePhraseUrl($id: String!, $userAssignedUrl: String) {
    updatePhrase(id: $id, userAssignedUrl: $userAssignedUrl) {
      id
      user_assigned_url
    }
  }
`;
