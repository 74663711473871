import { hasProperty, hasStringProperty, log, uploadImage } from '@/utils';
import { IMAGE_THUMBNAIL } from '@/consts';

export const allowedImageFileTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  'image/svg',
  'image/webp',
];

export default async function addFile(file: File, onError: (err: unknown) => void) {
  try {
    if (allowedImageFileTypes.includes(file.type) === false) {
      throw new Error('Image is of the wrong type');
    }
    const result = await uploadImage(file);

    const data = result.data;

    if (!hasProperty(data, 'file') && hasStringProperty(data.file, 'id'))
      throw new Error('Uploaded image has no image id');

    const id = result.data.file.id;
    const src = `${API_URL}${IMAGE_THUMBNAIL}/${id}`;

    return { id, src };
  } catch (err: unknown) {
    log(err);
    if (onError) onError(err);
  }
}
