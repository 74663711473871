import { gql } from '@apollo/client';

import { GAM_INTERNAL_ACCOUNT_FRAGMENT } from '../fragments';

export const UPDATE_GAM_INTERNAL_ACCOUNT = gql`
  mutation updateGAMInternalAccount($id: ID!, $name: String!) {
    updateGAMInternalAccount(id: $id, name: $name) {
      ...gamInternalAccount
    }
  }

  ${GAM_INTERNAL_ACCOUNT_FRAGMENT}
`;
