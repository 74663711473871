import { FC, PropsWithChildren } from 'react';

import { Link } from './styled';

interface IHyperlink {
  email?: string;
  phone?: string;
  link?: string;
}

export const Hyperlink: FC<PropsWithChildren<IHyperlink>> = ({
  email = null,
  phone = null,
  children = null,
  link = null,
  ...props
}) => (
  <Link href={link ?? !!email ? `mailto:${email}` : `tel:${phone}`} {...props}>
    {children ?? email ?? phone ?? link}
  </Link>
);
