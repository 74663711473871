import { FC, PropsWithChildren, ReactNode } from 'react';

import { Wrapper, Error, Label } from '../styled';

interface IFieldWrapper {
  label: ReactNode;
  error: string | null;
}

export const FieldWrapper: FC<PropsWithChildren<IFieldWrapper>> = ({
  label = null,
  error = null,
  children,
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      {children}
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
