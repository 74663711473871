import styled from 'styled-components';

export const Wrapper = styled.div<{ $isHorizontal: boolean }>`
  display: flex;
  gap: 1rem;
  flex-direction: ${({ $isHorizontal }) => ($isHorizontal ? 'row' : 'column')};

  & > div {
    width: ${({ $isHorizontal }) => ($isHorizontal ? '50%' : '100%')};
  }

  .editor-before {
    border: 3px solid ${({ theme: { colors } }) => colors.lightRed};
  }

  .editor-after {
    border: 3px solid ${({ theme: { colors } }) => colors.lightGreen};
  }
`;

export const DiffBefore = styled.div`
  display: flex;
  margin: 5px;
  border: 3px solid ${({ theme: { colors } }) => colors.lightRed2};
  border-radius: ${({ theme: { border } }) => border.radius.medium};
`;

export const DiffAfter = styled.div`
  display: flex;
  margin: 5px;
  border: 3px solid ${({ theme: { colors } }) => colors.lightGreen2};
  border-radius: ${({ theme: { border } }) => border.radius.medium};
`;
