import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from './ticket';
import { RELATED_TICKET_FRAGMENT } from './relatedTicket';

export const TICKET_WITH_RELATED_TICKETS_FRAGMENT = gql`
  fragment ticketWithRelatedTickets on Ticket {
    ...ticket
    is_subtask

    relatedTickets {
      ...relatedTicket
    }
  }

  ${TICKET_FRAGMENT}
  ${RELATED_TICKET_FRAGMENT}
`;
