import { FC } from 'react';
import { ButtonProps } from 'antd';

import { Button } from '../Button';

import { PlusIcon } from './styled';

export const AddButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button {...props}>
    <PlusIcon />
    {children}
  </Button>
);
