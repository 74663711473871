import { UserOutlined } from '@ant-design/icons';

import { UserBasicFragment } from '@/__generated__';

import { AvatarStyled } from './styled';

interface IUserAvatar {
  user: Omit<UserBasicFragment, '__typename' | 'positionName' | 'blocked'>;
  size?: number | null;
}

/**
 * There must be both user.id and user.avatar for an avatar to show up.
 * Otherwise, there will be difficult to find bugs with avatars
 * not showing correct images.
 */
const srcOrIcon = (user: IUserAvatar['user']) =>
  !!user?.id && !!user?.avatar
    ? { src: `${API_URL}/user/${user?.id}/avatar` }
    : { icon: <UserOutlined /> };

export const UserAvatar = (props: IUserAvatar) => {
  const { user, size = 40 } = props;

  return (
    <AvatarStyled
      size={size ?? 'large'}
      alt={`${user?.first_name} ${user?.last_name}'s avatar`}
      {...srcOrIcon(user)}
    />
  );
};
