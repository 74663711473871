import styled from 'styled-components';
import { Pagination as PaginationAntd } from 'antd';

export const PaginationWrapper = styled.div<{ withInfo: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ withInfo }) => (withInfo ? 'space-between' : 'flex-end')};
  margin-top: 26px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ListInfo = styled.div`
  color: ${({ theme: { colors } }) => colors.textGrey};
`;

export const Pagination = styled(PaginationAntd)`
  li {
    border-color: ${({ theme: { colors } }) => colors.borderGrey};

    .ant-pagination-item-link {
      border-color: ${({ theme: { colors } }) => colors.borderGrey};
    }

    a {
      color: ${({ theme: { colors } }) => colors.textGrey};
    }

    .ant-pagination-options-size-changer {
      color: ${({ theme: { colors } }) => colors.textGrey};

      .ant-select-selection {
        border-color: ${({ theme: { colors } }) => colors.borderGrey};
      }
    }
  }

  .ant-pagination-item-active {
    background-color: ${({ theme: { colors } }) => colors.lightBlue};
    border-color: ${({ theme: { colors } }) => colors.lightBlue};

    a {
      color: ${({ theme: { colors } }) => colors.white};
    }
  }
`;
