import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { FilterType } from '@/components/QuickFilters/consts';

import { Pill } from '../../../../../Pill';
import { usePillLoader } from '../../../../usePillLoader';
import { Skeleton } from '../../../../../Skeleton';
import { QuickFiltersModalState } from '../../consts';

interface ILocalQuickFilters {
  modalState: QuickFiltersModalState;
  filtersType: FilterType;
}

export const LocalQuickFilters = (props: ILocalQuickFilters) => {
  const history = useHistory();
  const location = useLocation();
  const { modalState, filtersType } = props;

  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const [pills] = usePillLoader({
    onLoad,
    onError,
    deps: [modalState.type],
    type: filtersType,
  });

  const onClick = (url: string) => {
    history.push(`${location.pathname}${url}`);
  };

  if (loading) return <Skeleton rows={1} noTitle />;

  const filteredPills = (() => {
    if (filtersType === FilterType.TICKETS) pills.filter(pill => pill['showInAllTickets']);
    else if (filtersType === FilterType.DOMAIN_TICKETS)
      pills.filter(pill => pill['showInDomainTickets']);
    return pills;
  })();

  return (
    <>
      {filteredPills.map(pill => (
        <Pill key={pill.id} onClick={() => onClick(pill.url)} data-testid={`quickfilter-custom`}>
          {pill.name}
        </Pill>
      ))}
    </>
  );
};
