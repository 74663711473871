import { gql } from '@apollo/client';

import { TEXT_ORDER_FRAGMENT } from '../fragments';

export const CREATE_TEXT_ORDERS = gql`
  mutation createTextOrders($inputs: [CreateTextOrdersDTOInput!]!) {
    createTextOrders(inputs: $inputs) {
      ...textOrder
    }
  }

  ${TEXT_ORDER_FRAGMENT}
`;
