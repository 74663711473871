import { gql } from '@apollo/client';

export const DOMAIN_NAMES = gql`
  query domainNames($grid: DomainNamesGridInput) {
    domainNames(grid: $grid) {
      rows {
        id
        name
      }
    }
  }
`;
