import { gql } from '@apollo/client';

import { TEXT_ORDER_FRAGMENT } from '../fragments';

export const UPDATE_TEXT_ORDERS = gql`
  mutation updateTextOrders($inputs: [UpdateTextOrdersDTOInput!]!) {
    updateTextOrders(inputs: $inputs) {
      ...textOrder
    }
  }

  ${TEXT_ORDER_FRAGMENT}
`;
