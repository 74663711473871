import { gql } from '@apollo/client';

export const DOMAIN_CATEGORIES = gql`
  query domainCategories {
    domainCategories {
      id
      key
      value
    }
  }
`;
