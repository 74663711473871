import styled from 'styled-components';

import { DatePicker } from '../../DatePicker';
import { inputStyles } from '../styled';

export const DatePickerStyled = styled(DatePicker)`
  & .ant-picker {
    ${inputStyles};
    height: 30px;
    line-height: 16px;
  }
`;
