import { ComponentProps, FC } from 'react';

import { IconContainer, TextWrapper } from './styled';

interface ILabelWithIcon {
  icon: React.FunctionComponent<ComponentProps<'svg'>>;
  text: string;
}

export const LabelWithIcon: FC<ILabelWithIcon> = props => {
  const { text, icon: Icon } = props;

  return (
    <TextWrapper>
      {!!Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      {text}
    </TextWrapper>
  );
};
