import styled from 'styled-components';

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const LogTimeWrapper = styled.div`
  display: flex;

  & > div:nth-child(2) {
    width: unset;
  }
`;

export const LogTimeLabel = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 10px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: end;
`;
