import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: ID!, $name: String, $content: String, $priority: Float) {
    updateProject(id: $id, name: $name, content: $content, priority: $priority) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
