export enum AppEnvironment {
  Local = 'local_dev',
  Test = 'test',
  Dev = 'dev',
  Stage = 'stage',
  Prod = 'prod',
}

export const getBaseUrl = (appEnv: AppEnvironment) => {
  const baseUrls = {
    [AppEnvironment.Local]: 'http://localhost:3000',
    [AppEnvironment.Test]: 'https://lsp-test.grupa-icea.pl',
    [AppEnvironment.Dev]: 'https://lsp-dev.grupa-icea.pl',
    [AppEnvironment.Stage]: 'https://lsp-stage.grupa-icea.pl',
    [AppEnvironment.Prod]: 'https://lsp.grupa-icea.pl',
  };

  return baseUrls[appEnv];
};
