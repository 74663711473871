import { gql } from '@apollo/client';

export const DEAL_STATUSES = gql`
  query dealStatuses {
    dealStatuses {
      id
      key
      value
      description
    }
  }
`;
