import { forwardRef, ReactNode } from 'react';
import { InputProps } from 'antd/lib/input';

import { TextInput } from '../TextInput';
import { FieldWrapper } from '../FieldWrapper';

interface ITextInputField extends InputProps {
  label?: ReactNode;
  error?: string;
  type?: 'text' | 'password' | 'number';
}

export const TextInputField = forwardRef<HTMLInputElement, ITextInputField>(
  ({ label = null, error = null, ...props }, ref) => {
    return (
      <FieldWrapper label={label} error={error}>
        <TextInput ref={ref} {...props} />
      </FieldWrapper>
    );
  },
);

TextInputField.displayName = 'TextInputField';
