import { gql } from '@apollo/client';

import { TEXT_ORDERS_STATISTIC_FRAGMENT } from '../fragments';

export const TEXT_ORDERS_STATISTICS = gql`
  query textOrdersStatistics($grid: TextOrderStatisticsGridInput) {
    textOrdersStatistics(grid: $grid) {
      total
      statistics {
        ...textOrdersStatistics
      }
    }
  }

  ${TEXT_ORDERS_STATISTIC_FRAGMENT}
`;
