import axios from 'axios';
import { noop } from 'lodash';

import { log } from './logService';

export const uploadAttachment = <T extends { file: File | null }>(
  fileObject: T,
  url: string,
  onSuccess: (response: unknown) => void = noop,
  onError: (error: unknown) => void = noop,
) => {
  const data = new FormData();

  /**
   * fileObject.file can be null for deleting (eg. avatar)
   */
  if (!!fileObject?.file) {
    data.append('file', fileObject.file);
  }

  const promise = axios({
    url: `${API_URL}${url}`,
    method: 'POST',
    withCredentials: true,
    data,
  })
    .then(response => {
      onSuccess(response);
    })
    .catch(error => {
      log(error);
      onError(error);
    });

  return promise;
};
