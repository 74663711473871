import { useMutation } from '@apollo/client';

import {
  ImpersonateMutation,
  ImpersonateMutationVariables,
  LeaveImpersonationMutation,
  LeaveImpersonationMutationVariables,
} from '@/__generated__';
import { mutations } from '@/services';

interface IHandleImpersonateUser {
  userId: string;
}

export const useImpersonateUser = () => {
  const [impersonateUser] = useMutation<ImpersonateMutation, ImpersonateMutationVariables>(
    mutations.IMPERSONATE,
  );

  const handleImpersonateUser = ({ userId }: IHandleImpersonateUser) => {
    return impersonateUser({
      variables: {
        userId,
      },
    }).then(() => {
      window.location.reload();
    });
  };

  return [handleImpersonateUser];
};

export const useLeaveImpersonation = () => {
  const [leaveImpersonation] = useMutation<
    LeaveImpersonationMutation,
    LeaveImpersonationMutationVariables
  >(mutations.LEAVE_IMPERSONATION);

  const handleLeaveImpersonation = () => {
    return leaveImpersonation().then(() => {
      window.location.reload();
    });
  };

  return [handleLeaveImpersonation];
};
