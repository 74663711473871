import { FC, useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import { useNotify } from '@/hooks';
import { ProjectMetaObject } from '@/services/types';

import { AddButton } from '../Buttons';

import { AvailableItems, ProjectMetaItem } from './components';
import { IEditMenu, IMetaObjectsCard } from './consts';

import { DropBox, ItemCard, ListHeader, ListTitle } from './styled';

export const MetaObjectsCard: FC<IMetaObjectsCard> = ({
  editMenu,
  onAddClick,
  updatePriority,
  syncMetaObjectsPriority,
  metaObject,
  canEdit = true,
}) => {
  const intl = useIntl();
  const { notifyWarning } = useNotify();
  const projectSettingsHeight = '400px';
  const globalSettingsHeight = '1000px';
  const [hightlight, setHighlight] = useState(false);

  const {
    metaKey,
    metaName,
    items = [],
    projectId = null,
    isSync = false,
    availableItems = [],
  } = metaObject;

  const itemsList = useMemo(() => {
    return isSync
      ? items
      : items
          .map(metaItem => (!!metaItem.priority ? metaItem : { ...metaItem, priority: 0 }))
          .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  }, [items, isSync]);

  const itemsIdList = items.map((item: { id: string }) => item.id);

  const onDragEnd = (result: DropResult) => {
    setHighlight(false);
    const { destination, source, draggableId } = result;

    /* Dropped outside of the list */
    if (!destination) return;

    /* Dropped back in place */
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newItemsIds = [...itemsIdList];
    newItemsIds.splice(source.index, 1);
    newItemsIds.splice(destination.index, 0, draggableId);

    /* Update BE with new priority */
    const higherPriorityPointer = destination.index < source.index ? 1 : 0;
    const higherPriority = itemsList[destination.index - higherPriorityPointer]?.priority;
    const lowerPriorityPointer = destination.index > source.index ? 1 : 0;
    const lowerPriority = itemsList[destination.index + lowerPriorityPointer]?.priority;

    const getNewPriority = () => {
      if (!lowerPriority && !higherPriority) return 2 ** 16 - destination.index * 1000;
      if (!higherPriority && lowerPriority) return lowerPriority + lowerPriority / 2;
      if (!lowerPriority && higherPriority) return higherPriority - higherPriority / 2;

      if (lowerPriority === higherPriority) {
        notifyWarning(
          intl.formatMessage({
            id: 'components.MetaObjectsCard.getNewPriority.warning',
            defaultMessage:
              'Nastąpił problem z pozycjonowaniem elementów. Co najmniej dwa elementy mają taką samą ważnosć.',
          }),
        );

        return;
      }

      if (!higherPriority || !lowerPriority) {
        notifyWarning(
          intl.formatMessage({
            defaultMessage: 'Nastąpił problem z pozycjonowaniem elementów.',
          }),
        );

        return;
      }

      return (higherPriority - lowerPriority) / 2 + lowerPriority;
    };

    updatePriority({
      metaKey,
      metaIds: isSync ? newItemsIds : draggableId,
      newPriority: getNewPriority(),
      projectId,
      isSync,
    });
  };

  const lastItemPriority =
    (!!itemsList?.length && itemsList[itemsList.length - 1]?.priority) || 2 ** 16;

  const addToTheEndPriority = lastItemPriority - lastItemPriority / 2;

  const handleSyncMetaObjects = (itemId: string, isChecked: boolean) => {
    const currentIds = [...itemsIdList];

    const ids = isChecked
      ? [...currentIds, itemId]
      : currentIds.filter((id: string) => id !== itemId);

    if (!!syncMetaObjectsPriority && projectId) syncMetaObjectsPriority(metaKey, ids, projectId);
  };

  const handleEditMenu = (editedItem: ProjectMetaObject) =>
    editMenu({
      item: editedItem,
      metaKey,
      newPriority: addToTheEndPriority,
      ...(projectId ? { projectId } : {}),
    } as IEditMenu);

  const handleAddClick = () => {
    onAddClick({ metaKey, newPriority: addToTheEndPriority, ...(projectId ? { projectId } : {}) });
  };

  const onDragStart = () => {
    setHighlight(true);
  };

  return (
    <ItemCard
      title={
        <ListHeader>
          <ListTitle>{metaName}</ListTitle>
          {!isSync && canEdit && (
            <AddButton onClick={handleAddClick} data-testid={`${metaKey}-add-button`}>
              {intl.formatMessage({
                id: 'components.MetaObjectsCard.button.addMetaObject',
                defaultMessage: 'Dodaj',
              })}
            </AddButton>
          )}
        </ListHeader>
      }
      $maxheight={!!projectId ? projectSettingsHeight : globalSettingsHeight}
      data-testid={metaKey}
    >
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable key={'column'} droppableId={'metaItems'}>
          {provided => (
            <DropBox ref={provided.innerRef} $highlight={hightlight} {...provided.droppableProps}>
              <ProjectMetaItem
                handleEditMenu={handleEditMenu}
                handleSyncMetaObjects={handleSyncMetaObjects}
                itemsList={itemsList}
                isSync={isSync}
                canEdit={canEdit}
              />
              {provided.placeholder}
            </DropBox>
          )}
        </Droppable>
      </DragDropContext>
      {!!availableItems?.length && (
        <AvailableItems
          handleEditMenu={handleEditMenu}
          handleSyncMetaObjects={handleSyncMetaObjects}
          canEdit={canEdit}
          availableItems={availableItems}
          isSync={isSync}
        />
      )}
    </ItemCard>
  );
};
