import { noop } from 'lodash';
import { FC, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

import { ErrorComponent } from '../ErrorComponent';
import { Button } from '../Buttons';

interface ISentryErrorBoundary {
  tagKey: string;
  tagValue: string;
  onError?: () => void;
}

/**
 * When resetError() is called it will remove the Fallback component
 * and render the Sentry ErrorBoundary's children in their initial state
 */
export const SentryErrorBoundary: FC<PropsWithChildren<ISentryErrorBoundary>> = ({
  tagKey,
  tagValue,
  onError = noop,
  children,
}) => {
  const fallback: Sentry.FallbackRender = ({ resetError }) => {
    const handleClick = () => {
      resetError();
    };

    return (
      <ErrorComponent>
        <Button onClick={handleClick}>Reset</Button>
      </ErrorComponent>
    );
  };

  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      beforeCapture={(scope: Sentry.Scope) => {
        onError();
        scope.setTag(tagKey, tagValue);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
