export const hasProperty = <T, Key extends string>(
  obj: any,
  key: Key,
): obj is T & Record<Key, unknown> => {
  if (!(obj && typeof obj === 'object')) return false;

  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(key);
};

export const hasStringProperty = <T, Key extends string>(
  obj: any,
  key: Key,
): obj is T & Record<Key, string> => {
  if (!(obj && typeof obj === 'object')) return false;

  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(key) && typeof obj[key] === 'string';
};

export const hasNumberProperty = <T, Key extends string>(
  obj: any,
  key: Key,
): obj is T & Record<Key, number> => {
  if (!(obj && typeof obj === 'object')) return false;

  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(key) && typeof obj[key] === 'number';
};

export const isObject = (prop: any): prop is Record<string, unknown> =>
  prop && typeof prop === 'object';
export const isArray = (prop: any) => Array.isArray(prop);

export const hasInheritedProperty = <T, Key extends string>(
  obj: any,
  key: Key,
): obj is T & Record<Key, unknown> => {
  if (!(obj && typeof obj === 'object')) return false;

  return key in obj;
};

export const hasInheritedStringProperty = <T, Key extends string>(
  obj: any,
  key: Key,
): obj is T & Record<Key, string> => {
  if (!(obj && typeof obj === 'object')) return false;

  // eslint-disable-next-line no-prototype-builtins
  return key in obj && typeof obj[key] === 'string';
};
