import { gql } from '@apollo/client';

import { CLIENT_SETTINGS_FRAGMENT } from '../fragments';

export const UPDATE_CLIENT_SETTINGS = gql`
  mutation updateClientSettings($id: ID!, $feSettings: String) {
    updateUser(id: $id, userInput: { feSettings: $feSettings }) {
      ...clientSettings
    }
  }
  ${CLIENT_SETTINGS_FRAGMENT}
`;
