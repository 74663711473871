import { gql } from '@apollo/client';

import { LINK_FRAGMENT } from '../fragments';

export const UPDATE_LINK = gql`
  mutation updateLink(
    $id: ID!
    $group: LinkGroupEnum
    $status: LinkStatusEnum
    $portal: String
    $articleUrl: String
    $characterCount: Int
    $price: Float
    $ownerId: ID
    $plannedPublicationDate: DateTime
    $realPublicationDate: DateTime
    $orderDate: DateTime
    $textDeliveryDate: DateTime
    $note: String
    $textUrl: String
    $copywriterId: ID
    $domainId: ID
    $anchors: [LinkAnchorDTOInput!]
  ) {
    updateLink(
      id: $id
      group: $group
      status: $status
      portal: $portal
      articleUrl: $articleUrl
      characterCount: $characterCount
      price: $price
      ownerId: $ownerId
      plannedPublicationDate: $plannedPublicationDate
      realPublicationDate: $realPublicationDate
      orderDate: $orderDate
      textDeliveryDate: $textDeliveryDate
      note: $note
      textUrl: $textUrl
      copywriterId: $copywriterId
      domainId: $domainId
      anchors: $anchors
    ) {
      ...link
    }
  }
  ${LINK_FRAGMENT}
`;
