import styled from 'styled-components';
import { Table as TableAntd } from 'antd';

export const TableComponent = styled<{ header: string; $evenrowcolor: string } | any>(TableAntd)`
  min-width: 100%;

  .ant-table-column-sorters {
    justify-content: center;
  }

  .ant-table-column-title {
    flex: unset;
  }

  .ant-table-content {
    min-height: 300px;
  }

  .ant-table-thead > tr > th {
    text-align: ${({ header }) => header ?? 'left'};
    background-color: ${({ theme: { colors } }) => colors.white};
    font-weight: ${({ theme: { font } }) => font.weight.bold};
    border: none;
    padding: 0 4px;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    font-weight: ${({ theme: { font } }) => font.weight.bold};
    font-size: ${({ theme: { font } }) => font.size.medium};

    :last-child {
      text-align: center;
    }

    .ant-table-column-sorter {
      margin: 0 4px;
    }
  }

  .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }

  .ant-table-column-sorters,
  .ant-table-cell {
    height: 1px;
    padding: 14px 4px !important;
  }

  .ant-table-cell:first-of-type {
    padding-left: 16px !important;
  }

  .ant-table-cell:last-of-type {
    padding-right: 16px !important;
  }

  .ant-table-tbody > .ant-table-row {
    max-height: 70px;

    :nth-child(even) {
      background-color: ${({ $evenrowcolor }) => $evenrowcolor};
    }

    td {
      border-bottom: none;
    }
  }

  .ant-table-tbody > .ant-table-row.termination-alert {
    background-color: #ffdede;

    td {
      background-color: unset;
    }
  }

  .ant-table-tbody > .ant-table-row.new-text-order {
    background-color: #90ee90;

    td {
      background-color: unset;
    }
  }

  .ant-table-tbody > .ant-table-row.text-order-edit-row {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    box-shadow: inset 0px 0px 15px 0px rgba(42, 43, 76, 1);
    color: white;

    td:first-child * {
      visibility: hidden;
    }

    td {
      background-color: unset;
    }
  }
`;
