import { gql } from '@apollo/client';

export const DOMAIN_REPORT_VALUES = gql`
  query domainReportValues($id: ID!) {
    domainReport(id: $id) {
      id
      language
      data {
        field {
          id
        }
        value
      }
    }
  }
`;
