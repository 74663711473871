import { gql } from '@apollo/client';

export const DEAL_TYPES = gql`
  query dealTypes {
    dealTypes {
      id
      key
      value
    }
  }
`;
