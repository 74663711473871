import { forwardRef } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';

import { PasswordInput, TextInputStyled } from '../styled';

interface ITextInput extends InputProps {
  type?: 'text' | 'password' | 'number';
}

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  ({ type = 'text', ...props }, ref) => {
    return (
      <>
        {type === 'password' ? (
          <PasswordInput
            ref={ref}
            type={type}
            iconRender={(visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            {...props}
          />
        ) : (
          <TextInputStyled ref={ref} type={type} {...props} />
        )}
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
