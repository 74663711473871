import { gql } from '@apollo/client';

import { NOTIFICATION_FRAGMENT } from '../fragments';

export const USER_NOTIFICATIONS = gql`
  query userNotifications($grid: NotificationGridInput) {
    userNotifications(grid: $grid) {
      total
      total_unread
      rows {
        ...notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
