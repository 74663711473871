import styled, { keyframes } from 'styled-components';

import { Delete } from '../Icon';

export const DeleteIcon = styled(Delete)`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  height: 14px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(1px, 0, 0);
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  width: min-content;

  &:hover ${DeleteIcon} {
    display: block;
    fill: ${({ theme: { colors } }) => colors.notifRed};
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
  }
`;
