import { gql } from '@apollo/client';

export const DELETE_CREDENTIALS = gql`
  mutation deleteCredentials($id: String!) {
    deleteCredentials(id: $id) {
      id
      name
    }
  }
`;
