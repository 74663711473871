import { CSSProperties } from 'react';

import { Box } from './styled';

interface ITicketSequenceTag {
  sequence: string | number;
  style: CSSProperties;
}

export const TicketSequenceTag = (props: ITicketSequenceTag) => {
  const { sequence, style } = props;

  return <Box style={style}>#{sequence}</Box>;
};
