import { defineMessages } from 'react-intl';

export const i18nMessages = defineMessages({
  dealActive: {
    id: 'components.Ribbon.dealActive',
    defaultMessage: 'Umowa aktywna',
  },
  dealInactive: {
    id: 'components.Ribbon.dealInactive',
    defaultMessage: 'Umowa nieaktywna',
  },
  whitelabel: {
    id: 'components.Ribbon.whitelabel',
    defaultMessage: 'Whitelabel',
  },
});
