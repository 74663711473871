import { gql } from '@apollo/client';

import { EXTERNAL_REPORT_SETTINGS_FRAGMENT } from '../fragments';

export const EXTERNAL_REPORT_SETTINGS = gql`
  query externalReportSettings($domainId: ID!) {
    externalReportSettings(domainId: $domainId) {
      ...externalReportSettings
    }
  }

  ${EXTERNAL_REPORT_SETTINGS_FRAGMENT}
`;
