import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const PROJECT_USERS_FRAGMENT = gql`
  fragment projectUsers on Project {
    id
    users {
      ...userBasic
    }
  }

  ${USER_BASIC_FRAGMENT}
`;
