import styled, { css } from 'styled-components';
import { EditFilled } from '@ant-design/icons';

const iconStyles = css`
  opacity: 0;
  font-size: 20px;
  transition: all 0.4s ease-in-out;
  color: white;
  margin: 10px;
  cursor: pointer;
`;

export const EditFilledIcon = styled(EditFilled)`
  ${iconStyles}
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  :hover {
    background: rgba(0, 0, 0, 0.4);

    ${EditFilledIcon} {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;
