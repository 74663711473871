import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';

import { SettingsContext } from './context/SettingsContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import Editor from './Editor';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { TableContext } from './plugins/TablePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';

import './index.css';
import { EditorState, TextNode, LexicalEditor as TLexicalEditor } from 'lexical';

import { ExtendedTextNode } from './nodes/ExtendedTextNode';
import { DivWrapperNode } from './nodes/DivWrapperNode';

import { log } from '@/utils';

export interface ILexicalEditor {
  value?: string;
  onChange?: (lexicalEditor: TLexicalEditor, editorState: EditorState) => void;
  isEditing?: boolean;
  uploadImages?: boolean;
  autoSaveKey?: string | null | undefined;
  dataTestId?: string;
  initialValue?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  quoteContent?: {
    contentHtml: string;
    author: string;
    type: string;
  };
}

export function LexicalEditor(props: ILexicalEditor): JSX.Element {
  const {
    value = '',
    onChange,
    isEditing = false,
    uploadImages = true,
    autoSaveKey = undefined,
    dataTestId,
    initialValue = '',
    quoteContent = undefined,
    isDisabled = false,
    autoFocus = false,
  } = props;

  const initialConfig: InitialConfigType = {
    editorState: undefined,
    namespace: 'Playground',
    nodes: [
      ...PlaygroundNodes,
      ExtendedTextNode,
      DivWrapperNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
      },
    ],
    onError: (error: Error) => {
      log(error);
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <SettingsContext>
      <LexicalComposer initialConfig={initialConfig}>
        <SharedHistoryContext>
          <TableContext>
            <div className="editor-shell">
              <Editor
                value={value}
                onChange={onChange}
                isEditing={isEditing}
                autoSaveKey={autoSaveKey}
                uploadImages={uploadImages}
                dataTestId={dataTestId}
                initialValue={initialValue}
                quoteContent={quoteContent}
                isDisabled={isDisabled}
                autoFocus={autoFocus}
              />
            </div>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    </SettingsContext>
  );
}
