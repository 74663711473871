import { gql } from '@apollo/client';

import { DOMAIN_DETAILS_FRAGMENT } from '../fragments';

export const UPDATE_DOMAIN_DETAILS = gql`
  mutation updateDomainDetails(
    $id: ID!
    $maxPhrases: Int
    $name: String
    $s4sInstanceId: String
    $briefCopy: String
    $commentCopy: String
    $seoComment: String
    $csComment: String
    $adsComment: String
    $strategyLink: String
    $linkbuildingStrategy: LinkbuildingStrategyEnum
    $diversificationLinkbuildingStrategy: LinkbuildingStrategyEnum
    $contentMarketingSpreadsheet: String
    $linkBuildingDiversificationSpreadsheet: String
    $relatedDomainsIds: [ID!]
    $cms: String
    $country: CountryEnum
    $language: LanguageCodeEnum
    $categories: [CategoryEnum!]
    $countableTexts: [LinkGroupEnum!]
    $positionedDomain: String
    $fullUrl: String
    $fullUrlLocked: Boolean
    $serviceType: ServiceTypeEnum
    $textToBeWrittenBy: TextWriterEnum
    $contentImportance: ContentImportanceEnum
  ) {
    updateDomain(
      id: $id
      maxPhrases: $maxPhrases
      name: $name
      s4sInstanceId: $s4sInstanceId
      briefCopy: $briefCopy
      commentCopy: $commentCopy
      seoComment: $seoComment
      csComment: $csComment
      adsComment: $adsComment
      strategyLink: $strategyLink
      linkbuildingStrategy: $linkbuildingStrategy
      diversificationLinkbuildingStrategy: $diversificationLinkbuildingStrategy
      categories: $categories
      countableTexts: $countableTexts
      language: $language
      cms: $cms
      contentMarketingSpreadsheet: $contentMarketingSpreadsheet
      linkBuildingDiversificationSpreadsheet: $linkBuildingDiversificationSpreadsheet
      country: $country
      relatedDomainsIds: $relatedDomainsIds
      positionedDomain: $positionedDomain
      fullUrl: $fullUrl
      fullUrlLocked: $fullUrlLocked
      serviceType: $serviceType
      textToBeWrittenBy: $textToBeWrittenBy
      contentImportance: $contentImportance
    ) {
      ...domainDetails
    }
  }
  ${DOMAIN_DETAILS_FRAGMENT}
`;
