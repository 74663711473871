import { RadioChangeEvent } from 'antd';

import { theme } from '@/theme';
import { TodoStatusEnum } from '@/__generated__';

import { CheckedIcon, CloseIcon, Line, Switch, SwitchButton } from './styled';

interface ITaskStatusSwitch {
  onChange?: (e: RadioChangeEvent) => void;
  statusValue: TodoStatusEnum;
  disabled: boolean;
}

export const TaskStatusSwitch = (props: ITaskStatusSwitch) => {
  const { onChange, statusValue, disabled } = props;
  const { INIT, NOT_DONE, DONE } = TodoStatusEnum;
  const { green, notifRed, white, darkGrey } = theme.colors;

  const notDoneBgColor = statusValue === NOT_DONE ? notifRed : white;
  const notDoneIconColor = statusValue === NOT_DONE ? white : notifRed;

  const doneBgColor = statusValue === DONE ? green : white;
  const doneIconColor = statusValue === DONE ? white : green;

  const initBgColor = statusValue === INIT ? darkGrey : white;
  const initIconColor = statusValue === INIT ? white : darkGrey;

  return (
    <Switch onChange={onChange} value={statusValue} buttonStyle="solid" disabled={disabled}>
      <SwitchButton
        style={{ backgroundColor: notDoneBgColor, borderColor: notDoneBgColor }}
        value={NOT_DONE}
        data-testid={`criterion-not-done`}
      >
        <CloseIcon color={notDoneIconColor} />
      </SwitchButton>
      <SwitchButton
        style={{ backgroundColor: initBgColor, borderColor: initBgColor }}
        value={INIT}
        data-testid={`criterion-init`}
      >
        <Line color={initIconColor} />
      </SwitchButton>
      <SwitchButton
        style={{ backgroundColor: doneBgColor, borderColor: doneBgColor }}
        value={DONE}
        data-testid={`criterion-done`}
      >
        <CheckedIcon color={doneIconColor} />
      </SwitchButton>
    </Switch>
  );
};
