import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { useNotify } from './notification';

const monthThreeMonthsAgo = dayjs().subtract(2, 'month');
const currentMonth = dayjs();
const defaultValues: [Dayjs, Dayjs] = [monthThreeMonthsAgo, currentMonth];
const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2]))/;
const searchValues = (search: string, onError: (err: unknown) => void): [Dayjs, Dayjs] => {
  try {
    if (!search) return defaultValues;

    const values = search.substring(1).split('_');

    if (values.length === 1) {
      values.unshift(dayjs(values[0]).subtract(2, 'month').format('YYYY-MM'));
    }

    if (typeof values?.[0] !== 'string' || typeof values?.[1] !== 'string')
      throw new Error('Parsed search value is not a string');
    if (!dateRegex.test(values[0]) || !dateRegex.test(values[1]))
      throw new Error('Parsed string is not a valid date');

    return [dayjs(values[0]), dayjs(values[1])];
  } catch (err: unknown) {
    onError(err);

    return defaultValues;
  }
};

const parseParameters = (search: string, onError: (err: unknown) => void) => {
  const [start, end] = searchValues(search, onError);

  const startDate = start.format('YYYY-MM-DD');
  const endDate = end.endOf('month').format('YYYY-MM-DD');

  return {
    monthRange: [start, end] as [Dayjs, Dayjs],
    dateRange: [startDate, endDate] as [string, string],
  };
};

export const useParseMonthParams = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { notifyError } = useNotify();
  const intl = useIntl();

  const onError = (err: unknown) => {
    history.push(pathname);

    notifyError(
      intl.formatMessage({
        defaultMessage: 'Nastąpił błąd przy przetwarzaniu parametrów wyszukiwania',
      }),
      err,
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { monthRange, dateRange } = useMemo(() => parseParameters(search, onError), [search]);

  return { monthRange, dateRange };
};
