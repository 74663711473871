import axios from 'axios';

export const axiosGet = (url: string) =>
  axios({
    url: `${API_URL}${url}`,
    method: 'GET',
    withCredentials: true,
  });

export const axiosPost = (
  url: string,
  data: {
    [key: string | number]: any;
  },
) =>
  axios({
    url: `${API_URL}${url}`,
    method: 'POST',
    withCredentials: true,
    data: data,
  });
