import {
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalNode,
  SerializedElementNode,
} from 'lexical';

type SerializedDivWrapperNode = SerializedElementNode;

export class DivWrapperNode extends ElementNode {
  static getType(): string {
    return 'div-wrapper';
  }

  static clone(node: DivWrapperNode): DivWrapperNode {
    return new DivWrapperNode(node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('div');
    return dom;
  }

  updateDOM(prevNode: DivWrapperNode, dom: HTMLElement): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('lexical-div-wrapper')) {
          return null;
        }
        return {
          conversion: () => {
            return {
              node: $createDivWrapperNode(),
            };
          },
          priority: 2,
        };
      },
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    element.setAttribute('lexical-div-wrapper', 'true');
    return { element };
  }

  static importJSON(serializedNode: SerializedDivWrapperNode): DivWrapperNode {
    return $createDivWrapperNode();
  }

  isShadowRoot(): boolean {
    return true;
  }

  exportJSON(): SerializedDivWrapperNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      version: 1,
    };
  }
}

export function $createDivWrapperNode(): DivWrapperNode {
  return new DivWrapperNode();
}

export function $isDivWrapperNode(node: LexicalNode | null | undefined): node is DivWrapperNode {
  return node instanceof DivWrapperNode;
}
