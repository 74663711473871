export enum GridSearchParamsTypes {
  UPDATE_PAGINATION = 'updatePagination',
  UPDATE_SORT = 'updateSort',
  UPDATE_FILTER = 'updateFilter',
  REMOVE_FILTER = 'removeFilter',
  REMOVE_PARAM = 'removeParam',
}

export interface IUpdatePagination {
  type: GridSearchParamsTypes.UPDATE_PAGINATION;
  payload: { limit: string; offset: string };
}

export interface IUpdateSort {
  type: GridSearchParamsTypes.UPDATE_SORT;
  payload: string;
}

export interface IUpdateFilter {
  type: GridSearchParamsTypes.UPDATE_FILTER;
  // T
  payload: Record<string, unknown>;
}
export interface IRemoveFilter {
  type: GridSearchParamsTypes.REMOVE_FILTER;
  // keyof T(filter)
  payload: string;
}
export interface IRemoveParam {
  type: GridSearchParamsTypes.REMOVE_PARAM;
  payload: 'sort' | 'filter' | 'offset';
}

export type SearchParamsProps =
  | IUpdatePagination
  | IUpdateSort
  | IUpdateFilter
  | IRemoveFilter
  | IRemoveParam;
