import { ComponentType, CSSProperties } from 'react';
import { ErrorMessage, FieldProps, useField } from 'formik';

import { ErrorWrapper, FieldWrapper } from '../styled';

interface IFormikField extends FieldProps {
  component: ComponentType;
  style?: CSSProperties;
}

export const FormikField = (props: IFormikField) => {
  const { component, style, ...rest } = props;
  const [field, meta, helpers] = useField(rest);

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  const Component = component;

  return (
    <FieldWrapper data-testid={`${field.name}-wrapper`} style={style}>
      <Component
        name={field.name}
        onChange={handleChange}
        value={field.value}
        dataTestId={`${field.name}-select`}
        {...rest}
      />
      <ErrorMessage data-testid={`${field.name}-error`} component={ErrorWrapper} name={field.name}>
        {meta?.error}
      </ErrorMessage>
    </FieldWrapper>
  );
};
