import { gql } from '@apollo/client';

import { MONITOR_FRAGMENT } from '../fragments';

export const UPDATE_MONITOR = gql`
  mutation updateMonitor($id: String!, $newUrl: String!) {
    updateMonitor(id: $id, newUrl: $newUrl) {
      ...monitor
    }
  }
  ${MONITOR_FRAGMENT}
`;
