import { gql } from '@apollo/client';

import { SUBPAGE_FRAGMENT } from '../fragments';

export const CREATE_SUBPAGE = gql`
  mutation createSubpage(
    $domainId: String!
    $url: String!
    $type: SubpageTypeEnum!
    $status: SubpageStatusEnum!
    $priority: SubpagePriorityEnum!
    $title: String!
    $heading: String
    $description: String
    $characterCount: Int
    $targetTitle: String
    $targetHeading: String
    $targetDescription: String
    $targetCharacterCount: Int
    $keywords: [String!]!
  ) {
    createSubpage(
      input: {
        domainId: $domainId
        url: $url
        type: $type
        status: $status
        priority: $priority
        title: $title
        heading: $heading
        description: $description
        characterCount: $characterCount
        targetTitle: $targetTitle
        targetHeading: $targetHeading
        targetDescription: $targetDescription
        targetCharacterCount: $targetCharacterCount
        keywords: $keywords
      }
    ) {
      ...subpage
    }
  }

  ${SUBPAGE_FRAGMENT}
`;
