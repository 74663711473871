import { FC, PropsWithChildren } from 'react';
import { Route as RouterRoute, RouteProps, useLocation } from 'react-router-dom';

import { ErrorBoundary } from '../ErrorBoundary';

/**
 * Error boundary wrapped in a React Router Route.
 */
export const Route: FC<PropsWithChildren<RouteProps>> = ({ children, ...props }) => {
  const location = useLocation();

  return (
    <RouterRoute {...props}>
      <ErrorBoundary tagKey={'route-level'} tagValue={location.pathname}>
        {children}
      </ErrorBoundary>
    </RouterRoute>
  );
};
