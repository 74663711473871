import { gql } from '@apollo/client';

import { TICKET_TYPE_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_TYPE = gql`
  mutation updateTicketType(
    $id: ID!
    $projectId: ID
    $value: String
    $archived: Boolean
    $color: ColorPaletteEnum
    $priority: Float
  ) {
    updateTicketType(
      id: $id
      projectId: $projectId
      value: $value
      archived: $archived
      color: $color
      priority: $priority
    ) {
      ...type
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;
