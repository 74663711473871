import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const FlexWrapper = styled.div`
  display: flex;

  & > span {
    min-width: 33%;
    margin-right: 5px;
    text-wrap: nowrap;
    line-height: 25px;
  }

  & > div:not(:last-of-type) {
    width: min-content;
  }
`;
