import { gql } from '@apollo/client';

import { GAM_INTERNAL_ACCOUNT_FRAGMENT } from '../fragments';

export const REFRESH_GAM_ACCESS_TOKEN = gql`
  mutation refreshGAMAccessToken($id: ID!) {
    refreshGAMAccessToken(id: $id) {
      ...gamInternalAccount
    }
  }

  ${GAM_INTERNAL_ACCOUNT_FRAGMENT}
`;
