import UAParser from 'ua-parser-js';

import { DeviceTypes } from '@/consts';

export const useDeviceLayout = () => {
  const UA = new UAParser();
  const device = UA.getDevice();

  const isMobileLayout = [DeviceTypes.Mobile, DeviceTypes.Tablet].includes(device.type);

  return { isMobileLayout };
};
