import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { ButtonCustomTypes, Permissions } from '@/consts';
import { useCurrentUser, useIsMe, useNotify, useUser } from '@/hooks';
import { mutations, queries } from '@/services';
import {
  BlockUserMutation,
  BlockUserMutationVariables,
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables,
  UnblockUserMutation,
  UnblockUserMutationVariables,
} from '@/__generated__';

import { Button } from '../Buttons';
import { ErrorMessage } from '../ErrorMessage';

import { i18nMessages } from './consts';

import { ButtonWrapper, Container, Header } from './styled';

export const Administration = () => {
  const intl = useIntl();
  const { notifySuccess } = useNotify();
  const { SAVE, REMOVE } = ButtonCustomTypes;
  const { id: userId }: { id: string } = useParams();
  const { data: userData, loading: loadingUser, error: userDataError } = useUser(userId);
  const { allowedPermissions } = useCurrentUser();
  const isBlocked = loadingUser ? false : userData.blocked;

  const [blockUser, { loading: loadingBlockUser }] = useMutation<
    BlockUserMutation,
    BlockUserMutationVariables
  >(mutations.BLOCK_USER);
  const [unblockUser, { loading: loadingUnblockUser }] = useMutation<
    UnblockUserMutation,
    UnblockUserMutationVariables
  >(mutations.UNBLOCK_USER);
  const [sendResetPassword, { loading: loadingSendResetPassword }] = useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(mutations.SEND_RESET_PASSWORD);

  const toggle = () => (isBlocked ? unblockUser : blockUser);

  const getErrorMessage = () => <ErrorMessage error={userDataError} />;

  const canManageAdmin = allowedPermissions.includes(Permissions.MANAGE_ADMINS);
  const isManageAdminsRequired = userData.allowedPermissions.includes(Permissions.MANAGE_ADMINS);

  const isAuthorisedToManageUser = isManageAdminsRequired ? canManageAdmin : true;

  const canResetPassword =
    isAuthorisedToManageUser && allowedPermissions.includes(Permissions.RESET_PASSWORD);
  const canBlockUser =
    isAuthorisedToManageUser && allowedPermissions.includes(Permissions.BLOCK_USERS);

  const isMe = useIsMe(userId);

  if (loadingUser) return null;
  if (!canResetPassword && !canBlockUser) return null;

  const notificationText = () =>
    isBlocked
      ? intl.formatMessage(i18nMessages.userActivated)
      : intl.formatMessage(i18nMessages.userDeactivated);

  const toggleUser = () => {
    const variables = { id: userId };

    toggle()({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: queries.USERS }],
    }).then(() => {
      notifySuccess(notificationText());
    });
  };

  const resetPassword = () => {
    sendResetPassword({ variables: { email: userData.email } }).then(() => {
      notifySuccess(intl.formatMessage(i18nMessages.resetPassword));
    });
  };

  const getButtons = () => (
    <>
      {canResetPassword && (
        <ButtonWrapper>
          <Button
            loading={loadingSendResetPassword}
            onClick={resetPassword}
            data-testid="reset-password-button"
          >
            {intl.formatMessage(i18nMessages.resetPasswordButton)}
          </Button>
        </ButtonWrapper>
      )}
      {canBlockUser && (
        <ButtonWrapper>
          <Button
            loading={loadingBlockUser || loadingUnblockUser}
            onClick={toggleUser}
            colorType={isBlocked ? SAVE : REMOVE}
            disabled={isMe}
            data-testid={isBlocked ? 'unblock-user-button' : 'block-user-button'}
          >
            {isBlocked
              ? intl.formatMessage(i18nMessages.activate)
              : intl.formatMessage(i18nMessages.deactivate)}{' '}
            {intl.formatMessage(i18nMessages.userText)}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );

  return (
    <Container>
      <Header>{intl.formatMessage(i18nMessages.header)}</Header>

      {!!userDataError ? getErrorMessage() : getButtons()}
    </Container>
  );
};
