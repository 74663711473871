import { gql } from '@apollo/client';

export const COUNTRIES = gql`
  query countries {
    countries {
      id
      key
      value
    }
  }
`;
