import { gql } from '@apollo/client';

import { SUBPAGE_FRAGMENT } from '../fragments';

export const UPDATE_SUBPAGE = gql`
  mutation updateSubpage(
    $id: ID!
    $url: String
    $type: SubpageTypeEnum
    $status: SubpageStatusEnum
    $priority: SubpagePriorityEnum
    $title: String
    $heading: String
    $description: String
    $characterCount: Int
    $targetTitle: String
    $targetHeading: String
    $targetDescription: String
    $targetCharacterCount: Int
    $domainId: String
    $keywords: [String!]
  ) {
    updateSubpage(
      id: $id
      input: {
        url: $url
        type: $type
        status: $status
        priority: $priority
        title: $title
        heading: $heading
        description: $description
        characterCount: $characterCount
        targetTitle: $targetTitle
        targetHeading: $targetHeading
        targetDescription: $targetDescription
        targetCharacterCount: $targetCharacterCount
        domainId: $domainId
        keywords: $keywords
      }
    ) {
      ...subpage
    }
  }

  ${SUBPAGE_FRAGMENT}
`;
