import { TableProps } from 'antd';

import { theme } from '@/theme';

import { Loader } from '../../Loader';

import { TableComponent } from './styled';

interface ITable<T> extends TableProps<T> {
  scrollWidth?: number;
  disableScroll?: boolean;
}

export const Table = <T,>(props: ITable<T>) => {
  const { loading = false, disableScroll = false, scrollWidth, bordered = false, ...rest } = props;
  const { azure, white } = theme.colors;
  const evenRowColor = bordered ? white : azure;

  return (
    <TableComponent
      pagination={false}
      loading={loading ? { indicator: <Loader /> } : false}
      $evenrowcolor={evenRowColor}
      bordered={bordered}
      {...(disableScroll ? {} : { scroll: { x: scrollWidth ?? 1000 } })}
      {...rest}
    />
  );
};
