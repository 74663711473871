import { CSSProperties, FC, useEffect } from 'react';
import { ErrorMessage, FieldProps } from 'formik';

import { Select } from '../../Selects';
import { ErrorWrapper, FieldWrapper } from '../styled';

interface IOption {
  id: string;
  value: string;
  color?: string | null;
}

interface ISelectInputField extends FieldProps {
  options: IOption[];
  style?: CSSProperties;
}

export const SelectInputField: FC<ISelectInputField> = ({
  field,
  form: { values, setFieldValue },
  options,
  style,
  ...props
}) => {
  /**
   * Functions below serve ticket draft. If value is already present
   * and is included in a currently selected project, leave it as it
   * comes from a saved draft.
   */
  const isInProject = () => options?.some(({ id }) => id === field.value);

  const getOption = () => {
    if (!!field.value && isInProject()) {
      return field.value;
    }

    return options?.[0]?.id ?? '';
  };

  useEffect(() => {
    if (field.name === 'statusId') {
      setFieldValue('statusId', getOption());
    }

    if (field.name === 'priorityId') {
      setFieldValue('priorityId', getOption());
    }

    if (field.name === 'typeId') {
      setFieldValue('typeId', getOption());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.projectId]);

  const handleChange = (value: string) => {
    setFieldValue(field.name, value);
  };

  return (
    <FieldWrapper data-testid={`${field.name}-wrapper`} style={style}>
      <Select
        onChange={handleChange}
        value={field.value}
        fieldTestId={`${field.name}-select`}
        options={options}
        {...props}
      />
      <ErrorMessage data-testid={`${field.name}-error`} component={ErrorWrapper} {...field} />
    </FieldWrapper>
  );
};
