import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { isArray } from 'lodash';
import { ApolloError } from '@apollo/client';

import { NotificationType } from '@/consts';
import { isObject, log } from '@/utils';
import { theme } from '@/theme';
import IconSuccess from '@/static/notification-success.svg';
import IconDanger from '@/static/notification-danger.svg';
import IconWarning from '@/static/notification-warning.svg';
import IconInfo from '@/static/notification-info.svg';

notification.config({
  duration: 5,
});

export const useNotification = () => {
  return {
    openNotification:
      ({ type }: { type: string }) =>
      ({ message, extra = null }: { message: string; extra?: string[] | null }) => {
        let config = {};
        const key = `open${Date.now()}`;
        const onClick = () => notification.destroy(key);

        const getNotificationList = () => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {type === NotificationType.WARNING_INDEFINITE && (
              <button
                className={'ant-btn'}
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={onClick}
              >
                X
              </button>
            )}
            <b>{message}</b>
            {!!extra?.length &&
              extra?.map((error: string, index: number) => (
                <div key={index}>- {typeof error === 'string' ? error : 'unknown error'}</div>
              ))}
          </div>
        );

        const messageToDisplay = !extra ? message : getNotificationList();

        if (type === NotificationType.SUCCESS) {
          config = {
            icon: <IconSuccess fill={theme.colors.black} />,
            className: 'success',
          };
        }

        if (type === NotificationType.DANGER) {
          config = {
            icon: <IconDanger fill={theme.colors.black} />,
            className: 'danger',
          };
        }

        if (type === NotificationType.WARNING) {
          config = {
            icon: <IconWarning fill={theme.colors.black} />,
            className: 'warning',
          };
        }

        if (type === NotificationType.WARNING_INDEFINITE) {
          config = {
            icon: <IconWarning fill={theme.colors.black} />,
            className: 'warning',
            duration: 0,
          };
        }

        if (type === NotificationType.INFO) {
          config = {
            icon: <IconInfo fill={theme.colors.black} />,
            className: 'info',
          };
        }

        notification.open({
          message: messageToDisplay,
          onClick: type !== NotificationType.WARNING_INDEFINITE && onClick,
          key,
          ...config,
        });
      },
  };
};

export const useNotify = () => {
  const { openNotification } = useNotification();
  const intl = useIntl();

  const notifySuccess = (text?: string, extra?: string[] | null) => {
    const defaultMessage = intl.formatMessage({ defaultMessage: 'Zapisano zmiany' });

    return openNotification({ type: NotificationType.SUCCESS })({
      message: text || defaultMessage,
      extra,
    });
  };

  const notifyError = (text?: string, err?: unknown) => {
    const defaultMessage = intl.formatMessage({
      defaultMessage: 'Nastąpił błąd przy zapisywaniu zmian',
    });

    if (err) {
      log({ type: 'error', text, error: err });
    }

    const extra = isArray(err) ? err : null;

    return openNotification({ type: NotificationType.DANGER })({
      message: text || defaultMessage,
      extra,
    });
  };

  const notifyWarning = (text: string) => {
    return openNotification({ type: NotificationType.WARNING })({
      message: text,
    });
  };

  const notifyWarningIndefinite = (text: string, extra?: string[] | null) => {
    return openNotification({ type: NotificationType.WARNING_INDEFINITE })({
      message: text,
      extra: extra,
    });
  };

  const notifyInfo = (text: string) => {
    return openNotification({ type: NotificationType.INFO })({
      message: text,
    });
  };

  const getExtraErrors = (object: unknown): string[] => {
    if (isObject(object)) {
      const arr = Object.values(object);

      return arr?.flat();
    }

    return [];
  };

  const notifyRestErrors = (error: unknown) => {
    log(error);

    const errors = error?.response?.data?.errors;
    const extraPath = error?.response?.data?.extra;
    const extra = !!extraPath && getExtraErrors(extraPath);

    if (!errors?.length) {
      return;
    }

    errors?.map(({ message }, index) => {
      if (index === 0 && !!extra?.length) notifyError(message, extra);
      else notifyError(message);
    });
  };

  const notifyServerErrors = (error: ApolloError | AxiosError | unknown, isRest = false) => {
    const errors: string[] | never[] =
      (isRest ? error?.response?.data?.errors : error?.graphQLErrors) ?? [];
    const extraPath: string[] | null =
      (isRest ? error?.response?.data?.extra : error?.networkError?.result?.extra) ?? null;
    const extra: string[] | null = !!extraPath ? getExtraErrors(extraPath) : null;

    if (!errors?.length) {
      return;
    }

    errors?.map(({ message }, index) => {
      if (index === 0 && !!extra?.length) notifyError(message, extra);
      else notifyError(message);
    });
  };

  return {
    notifySuccess,
    notifyError,
    notifyWarning,
    notifyServerErrors,
    notifyInfo,
    notifyRestErrors,
    notifyWarningIndefinite,
  };
};
