import { gql } from '@apollo/client';

import { TICKET_PRIORITY_FRAGMENT } from './ticketPriority';
import { TICKET_STATUS_FRAGMENT } from './ticketStatus';
import { TICKET_TYPE_FRAGMENT } from './ticketType';

export const PROJECT_FRAGMENT = gql`
  fragment project on Project {
    id
    name
    content
    archived
    priority
    types {
      ...type
    }
    statuses {
      ...status
    }
    priorities {
      ...priority
    }
  }
  ${TICKET_PRIORITY_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_TYPE_FRAGMENT}
`;
