import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from '@/services/fragments/userBasic';

import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';
import { DOMAIN_VIEW_FRAGMENT } from './domainView';
import { DEAL_IN_THE_DOMAIN_LIST_FRAGMENT } from './dealInTheDomainList';

export const DOMAIN_IN_THE_CONTROL_LIST_FRAGMENT = gql`
  fragment domainInTheControlList on Domain {
    id
    name
    positioned_domain
    fulfillmentUsers {
      ...userBasic
      __typename
    }
    days_to_end_quarter
    newestGridDeal {
      amount
      content_quarterly
      basket
      currency
      start_date
      name
      product
      diversification_links_count
      sponsored_articles_count
      __typename
    }
    __typename
  }
  ${USER_BASIC_FRAGMENT}
`;
