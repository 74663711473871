import { FC } from 'react';

import { Container, Figure, LoaderIcon1, LoaderIcon2, Wrapper } from './styled';

interface ILoader {
  height?: number;
  wrapperHeight?: string;
  light?: boolean;
}

export const Loader: FC<ILoader> = ({ height = 100, wrapperHeight = '100%', light = false }) => (
  <Container wrapperHeight={wrapperHeight}>
    <Wrapper height={height} light={light} data-testid="loader">
      <Figure>
        <LoaderIcon1 />
      </Figure>
      <Figure>
        <LoaderIcon2 />
      </Figure>
    </Wrapper>
  </Container>
);
