import { gql } from '@apollo/client';

import { SUBPAGE_FRAGMENT } from '../fragments';

export const SUBPAGES = gql`
  query subpages($grid: SubpageGridInput) {
    subpages(grid: $grid) {
      total
      rows {
        ...subpage
      }
    }
  }

  ${SUBPAGE_FRAGMENT}
`;
