import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  border: solid 2px ${({ theme: { colors } }) => colors.lightGrey};
  border-radius: ${({ theme: { border } }) => border.radius.big};
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0 2px 4px 0 rgba(215, 215, 215, 0.5);
`;
