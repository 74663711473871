import styled from 'styled-components';
import { Radio } from 'antd';

import { Checked, Close } from '../../../Icon';

const switchWidth = 105;
const switchHeight = 33;
const switchButtonWidth = switchWidth / 3;
const iconHeight = 10;

export const Switch = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme: { colors } }) => colors.grey2};
  border-radius: ${({ theme: { border } }) => border.radius.medium};
`;

export const SwitchButton = styled(Radio.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${switchButtonWidth}px;
  height: ${switchHeight}px;

  .ant-radio-button-input {
    pointer-events: unset !important;
  }

  &.ant-radio-button-wrapper:last-child {
    border-radius: 0 3px 3px 0;
  }

  &.ant-radio-button-wrapper:first-child {
    border-radius: 3px 0 0 3px;
  }

  &.ant-radio-button-wrapper:not(:first-child):before {
    background-color: ${({ theme: { colors } }) => colors.grey2};
  }

  &.ant-radio-button-wrapper-disabled {
    cursor: auto;
  }
`;

export const CheckedIcon = styled<{ color: string } | any>(Checked)`
  height: ${iconHeight}px;
  fill: ${({ color }) => color};
  transition: all 0.3s ease-out;
`;

export const CloseIcon = styled<{ color: string } | any>(Close)`
  height: ${iconHeight}px;
  fill: ${({ color }) => color};
  transition: all 0.3s ease-out;
`;

export const Line = styled.div<{ color: string }>`
  height: 12px;
  width: 2px;
  background-color: ${({ color }) => color};
  transform: rotate(45deg);
`;
