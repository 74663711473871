import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const TEAM_IN_THE_LIST_FRAGMENT = gql`
  fragment teamInTheList on Team {
    totalMembers
    id
    name
    leader {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
