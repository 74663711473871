import { Key, createContext } from 'react';

import { DomainGridInput, TextOrderSchemaDTOInput } from '@/__generated__';
import { TableKeys } from '@/consts';

import { IMassEditTextOrderDTO, ITextOrdersMap, SchemaEditModalState } from './types';

interface ICopywritingContext {
  grid: DomainGridInput;
  tableKey: TableKeys;
  selectedRows: Key[];
  initialTextOrdersRef: React.MutableRefObject<ITextOrdersMap | null>;
  currentTextOrders: ITextOrdersMap | null;
  massEditData: IMassEditTextOrderDTO;
  massEditToggle: Record<keyof IMassEditTextOrderDTO, boolean>;
  showSaveDataButton: boolean;
  domainId: string | null;
  schemaModal: SchemaEditModalState;
  setSchemaModal: React.Dispatch<React.SetStateAction<SchemaEditModalState>>;
  setCurrentTextOrders: React.Dispatch<React.SetStateAction<ITextOrdersMap | null>>;
  setSelectedRows: (rowKeys: Key[]) => void;
  handleToggleMassEditField: (field: keyof IMassEditTextOrderDTO) => void;
  handleSendTextOrdersToClient: () => void;
  handleCreateImplementationTicket: () => void;
  handleDeleteTextOrders: () => void;
  handleClearBanner: () => void;
  handleUpdateTextOrders: () => void;
  handleChangeTextOrders: (props: { textOrderId: string; field: string; value: string }) => void;
  handleUpdateTextOrder: (props: {
    textOrderId: string | null;
    field: string;
    value: string | string[] | number | TextOrderSchemaDTOInput | null;
  }) => Promise<void>;
}

export const CopywritingContext = createContext<ICopywritingContext | undefined>(undefined);
