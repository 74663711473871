import { gql } from '@apollo/client';

import { PHRASE_GROUP_FRAGMENT } from '../fragments';

/**
 * Do not use positionsOrdered here as it can be used in groupedPhrases only
 */
export const GROUP_PHRASES = gql`
  mutation groupPhrases($groupId: String!, $ids: [String!]!) {
    groupPhrases(groupId: $groupId, ids: $ids) {
      ...phraseGroup
    }
  }
  ${PHRASE_GROUP_FRAGMENT}
`;
