import { gql } from '@apollo/client';

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      id
      name
    }
  }
`;
