import { Draggable } from 'react-beautiful-dnd';

import { Priority, Status, Type } from '@/__generated__';
import { ProjectMetaObject } from '@/services/types';

import { MetaItem } from '../MetaItem';

import { ShiftIcon, DragWrapper } from './styled';

type MetaSyncItem = Status | Priority;
type MetaItem = MetaSyncItem | Type;

interface IProjectMetaItem {
  handleEditMenu: (item: MetaItem) => void;
  handleSyncMetaObjects: (itemId: string, isChecked: boolean) => void;
  itemsList: MetaItem[];
  isSync: boolean;
  canEdit: boolean;
}

export const ProjectMetaItem = ({
  handleEditMenu,
  handleSyncMetaObjects,
  itemsList,
  isSync,
  canEdit,
}: IProjectMetaItem) => (
  <>
    {itemsList.map((item: ProjectMetaObject, index: number) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <div key={item.id} ref={provided.innerRef} {...provided.draggableProps}>
            <MetaItem
              handleSyncMetaObjects={handleSyncMetaObjects}
              handleEditMenu={handleEditMenu}
              item={item}
              canEdit={canEdit}
              isSync={isSync}
              isDragging={snapshot.isDragging}
              isChecked
            >
              {canEdit ? (
                <DragWrapper {...provided.dragHandleProps}>
                  <ShiftIcon data-testid="meta-item-drag" />
                </DragWrapper>
              ) : null}
            </MetaItem>
          </div>
        )}
      </Draggable>
    ))}
  </>
);
