import { gql } from '@apollo/client';

import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';
import { DEAL_FRAGMENT } from './deal';

export const DOMAIN_DETAILS_FRAGMENT = gql`
  fragment domainDetails on Domain {
    id
    name
    max_phrases

    phraseCount
    strategy_link
    linkbuilding_strategy
    diversification_linkbuilding_strategy
    content_importance
    brief_copy
    comment_copy
    seo_comment
    cs_comment
    ads_comment
    content_marketing_spreadsheet
    linkbuilding_diversification_spreadsheet
    cms {
      id
      value
    }
    language
    categories
    countable_texts
    country
    first_deal_start_date
    positioned_domain
    service_type
    full_url
    full_url_locked
    percentage_of_positioning
    text_to_be_written_by
    google_ads_currency
    google_ads_last_fetch_date

    related {
      ...domainBasic
    }

    newestDeals {
      key
      name
      deal {
        ...deal
      }
    }

    textOrderGuidelines {
      id
      guidelines
      text_type
      text_type_name
    }
  }

  ${DOMAIN_BASIC_FRAGMENT}
  ${DEAL_FRAGMENT}
`;
