import { useIntl } from 'react-intl';
import { Alert, Switch } from 'antd';

import { Select } from '../Selects';

import { SwitchWrapper, Table } from './styled';

interface ICsvPreviewTable {
  requestData: any;
  columnsForm: any;
  handleChangeColumn: (idx: string) => (value: string) => void;
  headersInCSV: boolean;
  setHeadersInCSV: (value: boolean) => void;
}

export const CsvPreviewTable = ({
  requestData,
  columnsForm,
  handleChangeColumn,
  headersInCSV,
  setHeadersInCSV,
}: ICsvPreviewTable) => {
  const intl = useIntl();

  const possibleFieldsEntries = Object.entries(requestData.possible_fields);

  const selectOptions = [
    { id: 'ignore', value: intl.formatMessage({ defaultMessage: 'Ignoruj' }) },
    ...possibleFieldsEntries.map(([key, value]) => ({ id: key, value: value })),
  ];

  const dataSource = requestData.preview_rows.map((row: any[]) =>
    row.reduce((acc, curr, idx) => ({ ...acc, [idx]: curr }), {}),
  );

  const columns = requestData.preview_rows[0].map((_, idx) => ({
    title: (
      <Select options={selectOptions} value={columnsForm[idx]} onChange={handleChangeColumn(idx)}>
        {idx}
      </Select>
    ),
    dataIndex: idx,
  }));

  return (
    <>
      <Alert
        message={intl.formatMessage({
          defaultMessage:
            'Wszystkie kolumny muszą zostać uzupełnione, kolumny nie mogą się powtarzać, ignorowane kolumny nie będą brane pod uwagę podczas importu danych',
        })}
        type="warning"
        style={{ marginBottom: '1rem' }}
        showIcon
      />
      <SwitchWrapper>
        <Switch checked={headersInCSV} onClick={() => setHeadersInCSV(!headersInCSV)} />{' '}
        {intl.formatMessage({
          defaultMessage: 'CSV Posiada nagłówek',
        })}
      </SwitchWrapper>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
};
