import { useState } from 'react';
import { useIntl } from 'react-intl';
import { LexicalCommand, LexicalEditor, createCommand } from 'lexical';

import { saveToEditorsIDB } from '@/utils';
import { useNotify } from '@/hooks';
import { EMPTY_EDITOR_HTML } from '@/consts';

import { LoadFromIDBModal } from './LoadFromIDBModal';

export const SAVE_CONTENT_COMMAND: LexicalCommand<null> = createCommand();
export const LOAD_CONTENT_COMMAND: LexicalCommand<{ contentHtml: string }> = createCommand();

export default function IDBPlugin() {
  const intl = useIntl();
  const { notifyError, notifyWarning, notifySuccess } = useNotify();

  const [modalOpen, setModalOpen] = useState(false);

  const handleSave = (contentHtml: string) => {
    if (contentHtml === EMPTY_EDITOR_HTML)
      return notifyWarning(
        intl.formatMessage({ defaultMessage: 'Nie mozna zapisać pustego edytora' }),
      );

    saveToEditorsIDB({ contentHtml })
      .then(() => {
        notifySuccess(intl.formatMessage({ defaultMessage: 'Zapisano treść edytora lokalnie' }));
      })
      .catch((e: unknown) => {
        notifyError(
          intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy zapisywaniu treści edytora' }),
          e,
        );
      });
  };

  const handleShowForm = () => {
    setModalOpen(true);
  };

  const handleCloseForm = () => {
    setModalOpen(false);
  };

  const handleLoad = (contentHtml: string, editor: LexicalEditor) => {
    editor.dispatchCommand(LOAD_CONTENT_COMMAND, { contentHtml });
  };

  return {
    handleSave,
    handleShowForm,
    loadFromIDBModalOpen: modalOpen,
    LoadFromIDBModal: (props: { editor: LexicalEditor }) => (
      <LoadFromIDBModal
        editor={props.editor}
        handleLoad={handleLoad}
        handleClose={handleCloseForm}
      />
    ),
  };
}
