import { gql } from '@apollo/client';

import { PHRASE_GROUP_FRAGMENT } from '../fragments';

export const CREATE_PHRASE_GROUP = gql`
  mutation createPhraseGroup($name: String!, $domainId: String!, $phraseIds: [String!]) {
    createPhraseGroup(name: $name, domainId: $domainId, phraseIds: $phraseIds) {
      ...phraseGroup
    }
  }
  ${PHRASE_GROUP_FRAGMENT}
`;
