import { mutations, queries } from '@/services';
import {
  ImportTextOrderFromGoogleSheetsMutation,
  ImportTextOrderFromGoogleSheetsMutationVariables, TextOrdersQuery, TextOrdersQueryVariables,
} from '@/__generated__';
import { useMutation, FetchResult } from '@apollo/client';

interface IHandleImportTextOrderFromGoogleSheets {
  variables: ImportTextOrderFromGoogleSheetsMutationVariables;
  textOrdersVariables: TextOrdersQueryVariables;
}

type UseImportTextOrderFromGoogleSheets<T> = [
  (props: IHandleImportTextOrderFromGoogleSheets, refetch?: boolean) => Promise<T>,
  { loading: boolean },
];
export const useImportTextOrderFromGoogleSheets = (): UseImportTextOrderFromGoogleSheets<
    FetchResult<ImportTextOrderFromGoogleSheetsMutation>
> => {
  const [useImportTextOrderFromGoogleSheets, {loading}] = useMutation<
      ImportTextOrderFromGoogleSheetsMutation,
      ImportTextOrderFromGoogleSheetsMutationVariables
  >(mutations.IMPORT_TEXT_ORDER_FROM_GOOGLE_SHEETS);

  const handleImportTextOrderFromGoogleSheets = ({ variables, textOrdersVariables  }: IHandleImportTextOrderFromGoogleSheets) => {
    return useImportTextOrderFromGoogleSheets({
      variables,
      update: (proxy, { data }) => {
        const cacheData: TextOrdersQuery | null = proxy.readQuery({
          query: queries.TEXT_ORDERS,
          variables: textOrdersVariables,
        });

        if (!cacheData || !data) return;

        const newTextOrders = [...cacheData.textOrders.rows, ...data.importTextOrderFromGoogleSheets.textOrders];

        proxy.writeQuery({
          query: queries.TEXT_ORDERS,
          variables: textOrdersVariables,
          data: {
            ...data,
            textOrders: { ...cacheData.textOrders, rows: newTextOrders },
          },
        });
      },
    });
  };


  return [handleImportTextOrderFromGoogleSheets, {loading}];
};