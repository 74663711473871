import { gql } from '@apollo/client';

export const TEXT_WRITERS = gql`
  query textWriters {
    textWriters {
      id
      key
      value
    }
  }
`;
