export const parseJson = (json: string) => {
  try {
    const parsed = JSON.parse(json);

    return parsed;
  } catch (err: unknown) {
    return null;
  }
};

export const stringifyObject = (obj: unknown) => JSON.stringify(obj, null, 2);
