import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme: { colors } }) => colors.red};
`;

export const Text = styled.p`
  margin: 0;
`;
