import { gql } from '@apollo/client';

export const QUARTER_FRAGMENT = gql`
  fragment quarter on Quarter {
    number
    start_date
    end_date
    type
    created_characters
    characters_to_create
    displayed_number
    summary {
      ordered
      done
      accepted
      implemented
      wrong
      to_be_corrected
      category_description
      product_description
      article
      faq
      meta_description
    }
  }
`;
