import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Slider } from 'antd';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AvatarEditor from 'react-avatar-editor';

import { uploadAttachment } from '@/utils';
import { useNotify, useUser, useCurrentUser } from '@/hooks';

import { Button } from '../../../Buttons';
import { Loader } from '../../../Loader';

import { useFetchAvatar } from './hooks';

import { ButtonWrapper, ModalBody, UploadOutlinedIcon, Wrapper } from './styled';

interface IUseAvatarEditor {
  closeModal: () => void;
  setIsSubmitting: (bool: boolean) => void;
  isSubmitting: boolean;
}

const acceptFiles = '.png,.jpg,.jpeg,.gif,.svg';
const twentyMb = 20971520;
const initialEditionState = {
  position: { x: 0.5, y: 0.5 },
  scale: 1,
  rotate: 0,
};

export const UserAvatarEditor = ({
  isSubmitting,
  closeModal,
  setIsSubmitting,
}: IUseAvatarEditor) => {
  const intl = useIntl();
  const { id: userIdFromParams }: { id: string | undefined } = useParams();
  const { notifySuccess, notifyError, notifyWarning } = useNotify();

  const { id: activeUserId, avatar: activeUserAvatar } = useCurrentUser();

  const userId = userIdFromParams ?? activeUserId;

  const { data: userData, refetch: refetchUser } = useUser(userId);

  const avatarHash = userIdFromParams ? userData.avatar : activeUserAvatar;
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  let editor = useRef<AvatarEditor | undefined>(undefined);

  const [fetchAvatar] = useFetchAvatar();

  const [edition, setEdition] = useState<typeof initialEditionState>(initialEditionState);
  const [image, setImage] = useState<string | File>();

  const fetchImage = async () => {
    if (typeof avatarHash !== 'string') return;

    await fetchAvatar({ setImage, userId, avatarHash });
  };

  useEffect(() => {
    fetchImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadClick = () => {
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  const showErrorNotification = (e: unknown) => {
    notifyError(
      intl.formatMessage({
        defaultMessage: 'Nastąpił błąd przy zmienianiu avatara',
      }),
      e,
    );
  };

  const handleSubmit = async () => {
    try {
      const onSuccess = () => {
        notifySuccess(
          intl.formatMessage({
            defaultMessage: 'Avatar został zmieniony',
          }),
        );

        refetchUser({ id: userId });
        closeModal();
      };

      const onError = (err: unknown) => {
        showErrorNotification(err);
      };

      if (editor) {
        // @ts-ignore
        const canvas = editor?.getImage();
        const blobFromCanvas: BlobPart = await new Promise(resolve => canvas?.toBlob(resolve));
        const newFile = new File([blobFromCanvas], 'newFile.png', { type: 'image/png' });

        await uploadAttachment({ file: newFile }, `/user/${userId}/upload`, onSuccess, onError);
      }
    } catch (err: unknown) {
      showErrorNotification(err);
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isSubmitting) handleSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const handleNewImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;

    const file = e.target.files[0];

    if (file.size > twentyMb) {
      const message = intl.formatMessage(
        {
          defaultMessage: 'Plik {name} jest za duży. Maksymalny rozmiar awatara to 20Mb',
        },
        { name: file.name },
      );

      notifyWarning(message);
      fetchImage();

      return;
    }

    setImage(file);
  };

  const handleScale = (scale: number) => {
    setEdition(prev => ({ ...prev, scale }));
  };

  const rotateScale = (rotate: number) => {
    setEdition(prev => ({ ...prev, rotate }));
  };

  const handlePositionChange = (position: { x: number; y: number }) => {
    setEdition(prev => ({ ...prev, position }));
  };

  const assignEditorRef = (editorRef: unknown) => {
    if (editor) {
      // @ts-ignore
      editor = editorRef;
    }
  };

  return (
    <ModalBody>
      {!!image ? (
        <AvatarEditor
          ref={assignEditorRef}
          onPositionChange={handlePositionChange}
          image={image}
          width={370}
          height={370}
          border={50}
          borderRadius={250}
          scale={edition.scale}
          position={edition.position}
          rotate={edition.rotate}
        />
      ) : (
        <Wrapper>
          <Loader />
        </Wrapper>
      )}
      <br />
      <ButtonWrapper>
        <Button onClick={handleLoadClick} icon={<UploadOutlinedIcon />} fullWidth>
          {intl.formatMessage({
            defaultMessage: 'Wczytaj',
          })}
        </Button>
      </ButtonWrapper>
      <input
        onChange={handleNewImage}
        ref={hiddenFileInput}
        name="avatar"
        type="file"
        accept={acceptFiles}
        style={{ display: 'none' }}
      />
      <br />
      {intl.formatMessage({
        defaultMessage: 'Przybliżenie:',
      })}
      <Slider
        onChange={handleScale}
        tooltipVisible={false}
        defaultValue={1}
        min={1}
        max={2}
        step={0.01}
      />
      <br />
      {intl.formatMessage({
        defaultMessage: 'Rotacja:',
      })}
      <Slider onChange={rotateScale} tooltipVisible={false} min={0} max={360} step={1} />
    </ModalBody>
  );
};
