import { gql } from '@apollo/client';

import { USER_IN_THE_LIST_FRAGMENT } from './userInTheList';
import { TEAM_BASIC_FRAGMENT } from './teamBasic';

export const USER_FRAGMENT = gql`
  fragment user on User {
    ...userInTheList
    allowedPermissions
    crm_import_id
    app_locale
    is_impersonated
    teams {
      ...teamBasic
    }
  }
  ${TEAM_BASIC_FRAGMENT}
  ${USER_IN_THE_LIST_FRAGMENT}
`;
