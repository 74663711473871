import { useContext } from 'react';

import { DomainContext } from './contexts';

export const useDomainContext = () => {
  const context = useContext(DomainContext);

  if (!context) {
    throw new Error('useDomainContext must be used within a DomainContextProvider');
  }

  return context;
};
