import { theme } from '@/theme';
import { ButtonCustomTypes } from '@/consts';

const { white, lightBlue, green, notifRed, textGrey, borderGrey } = theme.colors;
const { BACK, SAVE, REMOVE, DISABLED } = ButtonCustomTypes;

export const buttonColors = (type: ButtonCustomTypes) => {
  switch (type) {
    case BACK:
      return {
        bg: white,
        border: lightBlue,
        text: lightBlue,
      };
    case SAVE:
      return {
        bg: green,
        border: green,
        text: white,
      };
    case REMOVE:
      return {
        bg: notifRed,
        border: notifRed,
        text: white,
      };
    case DISABLED:
      return {
        bg: borderGrey,
        border: textGrey,
        text: textGrey,
      };
    default:
      return {
        bg: lightBlue,
        border: lightBlue,
        text: white,
      };
  }
};
