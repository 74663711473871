import { useIntl } from 'react-intl';
import { Checkbox, Tooltip } from 'antd';
import { useMemo, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { i18nMessages } from '@/containers/TextOrderList/components/TextOrdersTable/components/Table/components/Table/consts';
import { useCopywritingContext } from '@/containers/TextOrderList/hooks';

import { TextInputField } from '../Form';
import { PillsField } from '../PillsField';
import { Button } from '../Buttons';

import { FlexWrapper, Wrapper } from './styled';

interface ISchemaModal {
  textOrderId?: string;
  values?: any;
}

interface SchemaForm {
  shortText: number | null;
  longText: number | null;
  metaDescription: boolean;
  boldKeywords: boolean;
  baseOnUrl: string | null;
  headers: string[] | null;
}

export const SchemaModal = ({ textOrderId, values }: ISchemaModal) => {
  const intl = useIntl();
  const { handleUpdateTextOrder, setSchemaModal } = useCopywritingContext();

  const initialValues = useMemo(
    () => ({
      shortText: values?.shortText ?? null,
      longText: values?.longText ?? null,
      metaDescription: values?.metaDescription ?? false,
      boldKeywords: values?.boldKeywords ?? false,
      baseOnUrl: values?.baseOnUrl,
      headers: values?.headers ?? null,
    }),
    [values],
  );

  const [form, setForm] = useState<SchemaForm>(initialValues);

  const handleSubmit = async () => {
    const variables = {
      baseOnUrl: form.baseOnUrl,
      shortText: form.shortText !== null ? Number(form.shortText) : null,
      longText: form.longText !== null ? Number(form.longText) : null,
      metaDescription: form?.metaDescription ?? null,
      boldKeywords: form?.boldKeywords ?? null,
      headers: !form.headers || form.headers.length === 0 ? null : form.headers,
    };

    await handleUpdateTextOrder({
      textOrderId: textOrderId ?? null,
      field: 'schema',
      value: variables,
    });

    setSchemaModal({ type: 'closed' });
  };

  const handleUpdate = (evt: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
    const { name, value, type, checked } = evt.target;
    setForm(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const tooltip = (
    <>
      Bazuj na URL używamy gdy:
      <ol style={{ marginLeft: 0, paddingLeft: 20 }}>
        <li>
          Tworzymy nową kategorię (wskazujemy w pierwszej kolejności URL ze strony klienta który
          jest najlepszym odpowiednikiem / bazą pod tworzenie tekstu){' '}
        </li>
        <li>Tworzymy nowy produkt (możemy wskazać stronę konkurencji)</li>
        <li>
          [!!!] W wyjątkowych sytuacjach inny artykuł blogowy jako inspiracja pod tworzenie artykułu
          na stronę klienta
        </li>
      </ol>
    </>
  );

  return (
    <Wrapper>
      <FlexWrapper>
        <span>{intl.formatMessage(i18nMessages.shortText)}:</span>
        <TextInputField
          style={{ width: 200, height: 25 }}
          type="number"
          min={1}
          max={50_000}
          name="shortText"
          onChange={handleUpdate}
          value={form.shortText}
        />
      </FlexWrapper>
      <FlexWrapper>
        <span>{intl.formatMessage(i18nMessages.longText)}:</span>
        <TextInputField
          type="number"
          min={1}
          max={50_000}
          style={{ width: 200, height: 25 }}
          name="longText"
          onChange={handleUpdate}
          value={form.longText}
        />
      </FlexWrapper>
      <FlexWrapper>
        <span>{intl.formatMessage(i18nMessages.metaDescription)}:</span>
        <Checkbox name="metaDescription" onChange={handleUpdate} checked={form.metaDescription} />
      </FlexWrapper>
      <FlexWrapper>
        <span>{intl.formatMessage(i18nMessages.boldKeywords)}:</span>
        <Checkbox name="boldKeywords" onChange={handleUpdate} checked={form.boldKeywords} />
      </FlexWrapper>
      <FlexWrapper>
        <span>
          {intl.formatMessage(i18nMessages.baseOnUrl)}:
          <Tooltip placement="top" title={tooltip} overlayStyle={{ maxWidth: '500px' }}>
            <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
          </Tooltip>
        </span>
        <TextInputField
          type="text"
          style={{ width: 200, height: 25 }}
          name="baseOnUrl"
          onChange={handleUpdate}
          value={form.baseOnUrl}
        />
      </FlexWrapper>
      <FlexWrapper>
        <span>{intl.formatMessage(i18nMessages.headers)}:</span>
        <PillsField
          pills={form.headers ?? []}
          handleUpdate={pills => setForm(prev => ({ ...prev, headers: pills }))}
        />
      </FlexWrapper>
      <Button style={{ marginTop: 10 }} onClick={handleSubmit}>
        {intl.formatMessage({ defaultMessage: 'Zapisz' })}
      </Button>
    </Wrapper>
  );
};
