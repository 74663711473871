import { Button, Modal, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import { LexicalEditor } from 'lexical';

import { useNotify } from '@/hooks';
import { deleteFromEditorsIDB, getAllFromEditorsIDB } from '@/utils';
import { Skeleton } from '@/components/Skeleton';

import { ModalState } from '../types';

import { HTMLBox, PaginationWrapper } from './styled';

interface ILoadFromIDBModal {
  editor: LexicalEditor;
  handleLoad: (contentHtml: string, editor: LexicalEditor) => void;
  handleClose: () => void;
}

export const LoadFromIDBModal = ({ editor, handleLoad, handleClose }: ILoadFromIDBModal) => {
  const intl = useIntl();
  const [modalState, setModalState] = useState<ModalState>({ type: 'loading' });
  const { notifyError, notifySuccess } = useNotify();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAllFromEditorsIDB()
      .then(res => {
        if (!res) throw new Error('There was an error reading from a database');

        setModalState({ type: 'loaded', localCopies: res.reverse() });
      })
      .catch(e => {
        handleClose();

        notifyError(
          intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy wczytywaniu kopii edytora' }),
          e,
        );
      });
  }, []);

  const createMarkup = (contentHtml: string) => {
    const sanitizedHtml = sanitizeHtml(contentHtml, {
      transformTags: {
        img: () => {
          return {
            tagName: 'i',
            text: intl.formatMessage({ defaultMessage: 'Obrazy nie są wyświetlane w podglądzie' }),
          };
        },
      },
    });

    return { __html: sanitizedHtml };
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const loadedContent = () => {
    if (modalState.type !== 'loaded') return null;

    const copies = modalState.localCopies;

    if (!copies.length) {
      setModalState({
        type: 'error',
        message: intl.formatMessage({
          defaultMessage: 'Brak zapisanych kopii edytora',
        }),
      });

      return;
    }

    const selected = copies[currentPage - 1];

    if (!selected) {
      notifyError(
        intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy wczytywaniu treści edytora' }),
      );

      handleClose();

      return;
    }

    return (
      <section>
        <PaginationWrapper>
          <Pagination
            size="small"
            current={currentPage}
            onChange={onPageChange}
            pageSize={1}
            total={copies.length}
          />
        </PaginationWrapper>
        <HTMLBox dangerouslySetInnerHTML={createMarkup(selected.contentHtml)} />
      </section>
    );
  };

  const content = () => {
    if (modalState.type === 'loading') return <Skeleton />;

    if (modalState.type === 'loaded') return loadedContent();

    if (modalState.type === 'error') return modalState.message;
  };

  const handleOk = () => {
    if (modalState.type !== 'loaded') return null;

    const selected = modalState.localCopies[currentPage - 1];

    if (!selected) return;

    handleLoad(selected.contentHtml, editor);

    handleClose();
  };

  const handleDelete = () => {
    if (modalState.type !== 'loaded') return null;

    const selected = modalState.localCopies[currentPage - 1];

    if (!selected) return;

    setModalState({ type: 'loading' });

    deleteFromEditorsIDB(selected.valueKey)
      .then(() => {
        notifySuccess(intl.formatMessage({ defaultMessage: 'Usunięto kopię' }));

        const filtered = modalState.localCopies.filter(
          localCopy => localCopy.valueKey !== selected.valueKey,
        );

        setModalState({ type: 'loaded', localCopies: filtered });
      })
      .catch(e => {
        const message = intl.formatMessage({
          defaultMessage: 'Nastąpił błąd przy usuwaniu lokalnej kopii edytora',
        });

        notifyError(message, e);

        setModalState({
          type: 'closed',
        });
      });
  };

  const isButtonDisabled = () => modalState.type !== 'loaded';

  const modalFooter = () => [
    <Button key="delete" type="primary" danger disabled={isButtonDisabled()} onClick={handleDelete}>
      {intl.formatMessage({
        defaultMessage: 'Usuń kopię',
      })}
    </Button>,
    <Button key="cancel" onClick={handleClose}>
      {intl.formatMessage({
        defaultMessage: 'Wróć',
      })}
    </Button>,
    <Button key="load" type="primary" onClick={handleOk} disabled={isButtonDisabled()}>
      {intl.formatMessage({
        defaultMessage: 'Wczytaj kopię',
      })}
    </Button>,
  ];

  return (
    <Modal
      onCancel={handleClose}
      title={intl.formatMessage({
        defaultMessage: 'Wybierz kopię',
      })}
      visible={true}
      width={700}
      footer={modalFooter()}
      destroyOnClose
    >
      {content()}
    </Modal>
  );
};
