import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 20px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
`;
