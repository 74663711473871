import { useLazyQuery } from '@apollo/client';
import { ComponentProps } from 'react';

import { queries } from '@/services';
import { DomainNamesQuery, DomainNamesQueryVariables } from '@/__generated__';
import { log } from '@/utils';

import { SearchSelect } from '../Selects';

export const DomainsSearch = (
  props: Omit<ComponentProps<typeof SearchSelect>, 'fetchData' | 'error' | 'result' | 'loading'>,
) => {
  const handleError = (err: unknown) => {
    log(err);
  };

  const [fetchDomains, { loading, data: domainsData, error }] = useLazyQuery<
    DomainNamesQuery,
    DomainNamesQueryVariables
  >(queries.DOMAIN_NAMES, {
    fetchPolicy: 'network-only',
    onError: handleError,
  });

  const fetchData = (searchValue: string) =>
    fetchDomains({
      variables: {
        grid: {
          filter: { domainName: { value: [searchValue] } },
          limit: 100000,
        },
      },
    });

  const result = domainsData?.domainNames?.rows?.map(item => ({
    id: item.id,
    value: item.name,
  }));

  return (
    <SearchSelect
      fetchData={fetchData}
      loading={loading}
      result={result}
      error={error}
      {...props}
    />
  );
};
