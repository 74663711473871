import { gql } from '@apollo/client';

export const TEXT_ORDER_TEXT_TYPES = gql`
  query textOrderTextTypes {
    textOrderTextTypes {
      id
      key
      value
    }
  }
`;
