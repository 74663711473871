import { gql } from '@apollo/client';

import { TIME_LOG_FRAGMENT } from '../fragments';

export const TICKET_TIME_LOGS = gql`
  query ticketTimeLogs($grid: TimeLogGridInput) {
    timeLogs(grid: $grid) {
      total
      rows {
        ...timeLog
      }
    }
  }
  ${TIME_LOG_FRAGMENT}
`;
