export enum Categories {
  TEXTS = 'texts',
  LINKS = 'links',
  WEBDEV = 'webdev',
  UX = 'ux',
  GRAPHICS = 'graphics',
  INTERNAL = 'internal',
  OPTIMALISATION = 'optimalisation',
  START = 'start',
  START_SEM = 'start_sem',
}

/**
 * These are hardcoded project ids from prod
 */
const projectIds = {
  copywriting: 'b2bfbbb1-3fc1-4a1c-9607-84d7c0a9943c',
  contentMarketing: 'd6472fd9-6cfb-4163-8c2f-6e603cf2fce1',
  linkbuildingBWP: '1f652e04-922e-44bd-b659-66c30d64dc18',
  linkbuildingICEAPress: 'ec8b83f3-80b6-4a88-8781-525f405a8432',
  webDev: 'df3d32e1-1fa3-4950-b0ee-13e65d829a9f',
  webDevIndia: 'f0645208-9f4f-4779-8175-0d67b74ba180',
  uxPoweredByEbrains: 'f1b92bc9-c048-492f-9593-7eec31d04a40',
  analiticsPoweredByEbrains: 'e47a9c8f-8fb8-401d-9936-a0976da07dfa',
  graphics: '70a74ec3-a437-4824-8154-13d4530cac28',
  seo: '7bfc74e3-8663-4f8e-aac6-38fba13a9dc3',
  copywritingIndia: '77f146de-a4c9-41a4-87ab-f5f038c525da',
  copywritingInternational: 'fdddd84e-52be-4016-81ce-193f23290def',
  linkbuildingIndia: '543c6b06-9365-4fcb-8c0b-d517865fd7c2',
  sem: 'd36ca787-00fb-4e9e-b612-1668291d39a3',
};

/**
 * These are hardcoded type ids from prod
 */
const typeIds = {
  internalArrangements: ['638e03e0-4c27-4e46-8067-27f02edc835c'],
  analysis: ['ef8be190-0454-4392-a8d3-aab63e6c7963'],
  optimalisation: ['6b336862-64ff-4e9e-b52c-7917517c5aa5'],
  analysisOptimalisation: ['d3129247-4de0-4bb4-904f-4643343a1761'],
  start: ['d1384f63-c5b7-4081-9524-e163b3b1d213'],
  semStart: ['ce08bb28-7d6d-472c-a377-7a3906ad187b'],
  semNewStart: ['fd87d0d5-ab6d-42b1-8d2d-48b4ab728b6f'],
};

/**
 * typedId must be .join('|') array of typeIds
 * that share the same name
 */
export const prodIds = {
  [Categories.TEXTS]: {
    projectId: [
      projectIds.copywriting,
      projectIds.copywritingIndia,
      projectIds.copywritingInternational,
    ],
  },
  [Categories.LINKS]: {
    projectId: [
      projectIds.contentMarketing,
      projectIds.linkbuildingBWP,
      projectIds.linkbuildingICEAPress,
      projectIds.linkbuildingIndia,
    ],
  },
  [Categories.WEBDEV]: { projectId: [projectIds.webDev, projectIds.webDevIndia] },
  [Categories.UX]: {
    projectId: [projectIds.uxPoweredByEbrains, projectIds.analiticsPoweredByEbrains],
  },
  [Categories.GRAPHICS]: { projectId: [projectIds.graphics] },
  [Categories.INTERNAL]: {
    projectId: [projectIds.seo],
    typeId: [typeIds.internalArrangements],
  },
  [Categories.OPTIMALISATION]: {
    projectId: [projectIds.seo],
    typeId: [typeIds.analysis, typeIds.optimalisation, typeIds.analysisOptimalisation],
  },
  [Categories.START]: {
    projectId: [projectIds.seo],
    typeId: [typeIds.start],
  },
  [Categories.START_SEM]: {
    projectId: [projectIds.sem],
    typeId: [typeIds.semStart, typeIds.semNewStart],
  },
};
