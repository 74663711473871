import { queries } from '@/services';
import { DomainNamesQuery, DomainNamesQueryVariables } from '@/__generated__';

import { useQueryAndExtract } from './useQuery';

export const useDomainNames = (variables: DomainNamesQueryVariables = {}, skip = false) =>
  useQueryAndExtract<DomainNamesQuery, DomainNamesQueryVariables>(
    queries.DOMAIN_NAMES,
    'domainNames.rows',
    {
      variables,
      skip,
    },
  );
