import styled from 'styled-components';

import {
  CalendarInDisk,
  CursorInDisk,
  ExclMarkInDisk,
  StarInDisk,
  BoxInDisk,
  Domains,
} from '../Icon';

export const SummaryContainer = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => padding};
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export const Header = styled.h1`
  align-self: flex-start;
  margin: 0 0 20px;
  letter-spacing: 0.33px;
  font-size: ${({ theme: { font } }) => font.size.semiBig};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
`;

export const CardsWrapper = styled.div<{ smallCards: boolean }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ smallCards }) => (smallCards ? '170px' : '210px')}, 1fr)
  );
  grid-gap: ${({ smallCards }) => (smallCards ? '5px' : '1vw')};
  width: 100%;
`;

export const BoxInDiskIcon = styled(BoxInDisk)`
  fill: ${({ theme: { colors } }) => colors.iconGrey};
`;

export const CalendarInDiskIcon = styled(CalendarInDisk)`
  fill: ${({ theme: { colors } }) => colors.purple};
`;

export const CursorInDiskIcon = styled(CursorInDisk)`
  fill: ${({ theme: { colors } }) => colors.lightBlue};
`;

export const ExclMarkInDiskIcon = styled(ExclMarkInDisk)`
  fill: ${({ theme: { colors } }) => colors.notifRed};
`;

export const StarInDiskIcon = styled(StarInDisk)`
  fill: ${({ theme: { colors } }) => colors.iconYellow};
`;

export const DomainsIcon = styled(Domains)`
  height: 40px;
  fill: ${({ theme: { colors } }) => colors.green};
`;
