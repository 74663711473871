import { gql } from '@apollo/client';

export const SAVE_SEO_NOTE = gql`
  mutation saveSeoNote($domainId: String!, $language: String!, $note: String) {
    saveSeoNote(domainId: $domainId, language: $language, note: $note) {
      note
      success
    }
  }
`;
