import { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Bar } from './styled';

interface IHeaderBar {
  title?: string | null;
  children?: ReactNode;
  tooltip?: string | null;
}

export const HeaderBar = ({ title, children, tooltip }: IHeaderBar) => {
  if (tooltip !== null) {
    return (
      <Bar>
        <Tooltip title={tooltip}>
          {title && (
            <h2>
              {title}
              <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
            </h2>
          )}
        </Tooltip>
        {children}
      </Bar>
    );
  }
  return (
    <Bar>
      {title && <h2>{title}</h2>}
      {children}
    </Bar>
  );
};
