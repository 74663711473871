import { gql } from '@apollo/client';

import { GAM_INTERNAL_ACCOUNT_FRAGMENT } from '../fragments';

export const GAM_INTERNAL_ACCOUNTS = gql`
  query gamInternalAccounts {
    gamInternalAccounts {
      rows {
        ...gamInternalAccount
      }
      total
    }
  }

  ${GAM_INTERNAL_ACCOUNT_FRAGMENT}
`;
