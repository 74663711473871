import { gql } from '@apollo/client';

import { DEAL_FRAGMENT } from '../fragments';

export const CREATE_DEAL = gql`
  mutation createDeal(
    $name: String!
    $startDate: DateTime!
    $realStartDate: DateTime
    $origStartDate: DateTime
    $origRealStartDate: DateTime
    $endDate: DateTime
    $analyticsMonthEndDate: DateTime
    $amount: Float!
    $maxBudget: Float!
    $active: Boolean!
    $url: String!
    $indefinite: Boolean!
    $activationAmount: Float!
    $currency: CurrencyEnum!
    $isLatest: Boolean!
    $type: DealTypeEnum!
    $product: DealCrmProductEnum
    $nofCreatedText: Int!
    $textReductionPercent: Int
    $previousDealId: ID
    $domainId: ID
    $ipressMonthly: Int
    $linkBuildingBudget: Float
    $lspTypeId: String
  ) {
    createDeal(
      name: $name
      startDate: $startDate
      realStartDate: $realStartDate
      origStartDate: $origStartDate
      origRealStartDate: $origRealStartDate
      endDate: $endDate
      analyticsMonthEndDate: $analyticsMonthEndDate
      amount: $amount
      maxBudget: $maxBudget
      active: $active
      url: $url
      indefinite: $indefinite
      activationAmount: $activationAmount
      currency: $currency
      isLatest: $isLatest
      type: $type
      product: $product
      nofCreatedText: $nofCreatedText
      textReductionPercent: $textReductionPercent
      previousDealId: $previousDealId
      domainId: $domainId
      ipressMonthly: $ipressMonthly
      linkBuildingBudget: $linkBuildingBudget
      lspTypeId: $lspTypeId
    ) {
      ...deal
    }
  }
  ${DEAL_FRAGMENT}
`;
