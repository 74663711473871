import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from '../fragments';

export const TICKET_WATCHERS = gql`
  query ticketWatchers($ticketId: ID!) {
    ticketWatchers(id: $ticketId) {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
