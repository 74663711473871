import styled from 'styled-components';

export const Tab = styled.div<{ $isSelected: boolean; $hasRibbons: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding-inline: ${({ $hasRibbons }) => ($hasRibbons ? '40px 60px' : '50px')};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
  background-color: ${({ theme: { colors }, $isSelected }) =>
    $isSelected ? colors.blue4 : colors.grey8};
  border-radius: 5px 5px 0 0;
  transition: all 0.25s ease-in-out;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  position: relative;

  :hover {
    filter: ${({ $disabled }) => ($disabled ? 'none' : 'brightness(110%)')};
  }
`;

export const Text = styled.h2`
  margin: 0;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: ${({ theme: { font } }) => font.size.mediumPlus};
`;
