import { gql } from '@apollo/client';

import { DOMAIN_REPORT_FRAGMENT } from '../fragments';

export const SINGLE_DOMAIN_REPORTS = gql`
  query singleDomainReports($domainId: ID!, $grid: DomainReportGridInput) {
    singleDomainReports(domainId: $domainId, grid: $grid) {
      total
      rows {
        ...domainReport
      }
    }
  }
  ${DOMAIN_REPORT_FRAGMENT}
`;
