import { FC, ReactNode } from 'react';
import { CollapseProps } from 'antd';

import { Collapse } from '../Collapse';
import { Card } from '../Card';

interface ICollapseCard {
  header?: ReactNode | string;
  cardPadding?: string;
  loading?: boolean;
  items: CollapseProps['items'];
}

export const CollapseCard: FC<ICollapseCard> = ({
  items,
  header,
  cardPadding = '0',
  loading = false,
}) => (
  <Card loading={loading} cardpadding={cardPadding}>
    <Collapse header={header} cardMode items={items} />
  </Card>
);
