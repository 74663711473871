import { gql } from '@apollo/client';

import { LINK_BASIC_FRAGMENT } from '../fragments';

export const LINKS = gql`
  query links($grid: LinkGridInput) {
    links(grid: $grid) {
      rows {
        ...linkBasic
      }
    }
  }
  ${LINK_BASIC_FRAGMENT}
`;
