import { ErrorResponse } from '@apollo/client/link/error';

import { shouldKickMeOut } from '@/client/cache';

export const onUnauthenticatedErrorResponse = (errorResponse: ErrorResponse) => {
  const obj = {
    operationVars: JSON.stringify(errorResponse?.operation?.variables),
    operationName: errorResponse?.operation?.operationName,
    statusCode: errorResponse?.networkError?.statusCode,
    result: JSON.stringify(errorResponse?.networkError?.result),
  };

  shouldKickMeOut(obj);
};
