import { gql } from '@apollo/client';

import { QUARTER_OVERRIDE_FRAGMENT } from '../fragments';

export const CREATE_QUARTER_OVERRIDE = gql`
  mutation createQuarterOverride(
    $domainId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $type: QuarterTypeEnum!
    $charactersToCreate: Int!
    $number: Int!
  ) {
    createQuarterOverride(
      domainId: $domainId
      startDate: $startDate
      endDate: $endDate
      type: $type
      charactersToCreate: $charactersToCreate
      number: $number
    ) {
      ...quarterOverride
    }
  }
  ${QUARTER_OVERRIDE_FRAGMENT}
`;
