import { forwardRef } from 'react';
import { TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea';

import { TextAreaInputStyled } from '../styled';

export const TextAreaInput = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => (
  <TextAreaInputStyled {...props} ref={ref} />
));

TextAreaInput.displayName = 'TextAreaInput';
