import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';

import { ITimeStatsFilters } from '../../consts';

import { TableHeader } from './styled';

export const TimeStatsFilters = (props: ITimeStatsFilters) => {
  const { monthRange } = props;

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const placeholder: [string, string] = [
    intl.formatMessage({ defaultMessage: 'Od' }),
    intl.formatMessage({ defaultMessage: 'Do' }),
  ];

  const onTimeRangeChange = (dates: [Dayjs, Dayjs]) => {
    const search = `?${dates[0].format('YYYY-MM')}_${dates[1].format('YYYY-MM')}`;

    history.push({
      pathname: location.pathname,
      search,
    });
  };

  return (
    <TableHeader data-testid={'time-stats-filters'}>
      <DatePicker.RangePicker
        placeholder={placeholder}
        onChange={onTimeRangeChange}
        value={monthRange}
        format={'MM-YYYY'}
        picker="month"
        allowClear={false}
        inputReadOnly
      />
    </TableHeader>
  );
};
