import styled from 'styled-components';

export const Wrapper = styled.div<{
  $padding: string;
  $width: string;
  $position: string;
  $height: string;
}>`
  display: flex;
  align-items: ${({ $position }) => $position};
  justify-content: center;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
`;
