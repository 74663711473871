import { gql } from '@apollo/client';

import { TICKET_TYPE_FRAGMENT } from '../fragments';

export const TICKET_TYPES = gql`
  query ticketTypes($projectId: ID, $includeArchived: Boolean) {
    ticketTypes(projectId: $projectId, includeArchived: $includeArchived) {
      ...type
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;
