import { FC } from 'react';

import { timeFrom, parseJson } from '@/utils';
import { Notification } from '@/__generated__';

import { NotificationDot } from '../../../NotificationDot';

import { Item, DetailsWrapper, Event, TextLink } from './styled';

interface INotificationItem {
  handleMarkNotification: (notificationId: string, markUnread?: boolean, isLink?: boolean) => void;
  notification: Notification;
}

export const NotificationItem: FC<INotificationItem> = ({
  notification,
  handleMarkNotification,
}) => {
  const { created_at: createdAt, data, id: notificationId, read_at: readAt } = notification;

  const { subject: eventText, url } = !!data ? parseJson(data) : { subject: '', url: null };
  const { host, pathname } = !!url ? new URL(url) : { host: window.location.host, pathname: '' };

  return (
    <Item key={notificationId}>
      <Event>
        {pathname.startsWith('/api') || host !== window.location.host ? (
          <a
            href={url}
            target="_blank"
            data-testid="notification-popup-external-link"
            rel="noreferrer"
          >
            {eventText}
          </a>
        ) : (
          <TextLink
            to={pathname}
            onClick={() => handleMarkNotification(notificationId, false, true)}
            data-testid="notification-popup-link"
          >
            {eventText}
          </TextLink>
        )}
      </Event>

      <DetailsWrapper>
        {timeFrom(createdAt)}
        <NotificationDot
          handleMarkNotification={handleMarkNotification}
          notificationId={notificationId}
          read={!!readAt}
        />
      </DetailsWrapper>
    </Item>
  );
};
