import { gql } from '@apollo/client';
export const IMPORT_PHRASES_FROM_GOOGLE_SHEETS = gql`
  mutation importPhrasesFromGoogleSheets($sheetId: String!, $domainId: String!) {
    importPhrasesFromGoogleSheets(sheetId: $sheetId, domainId: $domainId) {
      phrases {
        id
      }
      errors
    }
  }
`;
