import { isObject } from 'lodash';
import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';

import { isDev, isLocal } from './featureFlags';
import { reportToSentry } from './sentrySettings';

interface IError {
  type: 'error' | 'warning' | 'info';
  message?: string;
  error?: unknown;
  text?: string;
  extra?: string;
}

type Log = unknown | string | AxiosError | ApolloError | IError;

const handleLog = (toReport: string | Record<string, unknown> | null, toLog: string) => {
  if (toReport) {
    /**
     * Lets check if it allows for more content to fit in the message
     */
    const str = JSON.stringify(toReport);

    reportToSentry(str);
  }

  // eslint-disable-next-line no-console
  if (toLog && (isLocal() || isDev())) console.log(toLog);
};

export const log = (prop: Log) => {
  if (!prop) {
    handleLog(null, `UNDEFINED LOG: ${prop}`);

    return;
  }

  if (typeof prop === 'string') {
    handleLog(prop, `LOG: ${prop}`);

    return;
  }

  if (prop === ApolloError) {
    const message = { type: 'apollo error', error: JSON.stringify(prop) };

    handleLog(message, `APOLLO ERROR: ${JSON.stringify(prop)}`);

    return;
  }

  if (isObject(prop)) {
    if (prop.type) {
      const payload = {
        ...(!!prop.type ? { type: prop.type } : {}),
        ...(!!prop.message ? { message: prop.message } : {}),
        ...(!!prop.text ? { text: prop.text } : {}),
        ...(!!prop.extra ? { extra: prop.extra } : {}),
        ...(!!prop.error ? { error: JSON.stringify(prop.error) } : {}),
      };

      handleLog(payload, `LOG ${prop.type}: ${JSON.stringify(prop)}`);

      return;
    }

    handleLog(null, `TYPE NOT MATCHED: ${prop}`);
  }

  handleLog(JSON.stringify(prop), `OTHER: ${JSON.stringify(prop)}`);
};
