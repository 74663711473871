import { gql } from '@apollo/client';

export const SUBPAGE_TYPES = gql`
  query subpageTypes {
    subpageTypes {
      key
      id
    }
  }
`;
