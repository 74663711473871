import { gql } from '@apollo/client';

import { S4S_INSTANCE_FRAGMENT } from '../fragments';

export const S4S_INSTANCE = gql`
  query s4sInstance($id: ID!) {
    s4sInstance(id: $id) {
      ...s4sInstance
    }
  }
  ${S4S_INSTANCE_FRAGMENT}
`;
