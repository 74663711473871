import styled from 'styled-components';
import { Checkbox as CheckboxAntd } from 'antd';

export const Checkbox = styled(CheckboxAntd)`
  .ant-checkbox {
    margin: 0 10px;

    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border: 2px solid ${({ theme: { colors } }) => colors.lightBlue};
      border-radius: ${({ theme: { border } }) => border.radius.big};
    }
  }

  .ant-checkbox-checked {
    :after {
      border-radius: ${({ theme: { border } }) => border.radius.big};
      border: none;
    }

    .ant-checkbox-inner {
      background-color: ${({ theme: { colors } }) => colors.lightBlue};

      :after {
        width: 7px;
        height: 12px;
        border: 3px solid ${({ theme: { colors } }) => colors.white};
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-inner::after {
    background-color: ${({ theme: { colors } }) => colors.lightBlue};
  }

  .ant-checkbox-indeterminate > .ant-checkbox-inner::after {
    top: 48%;
    width: 12px;
    height: 12px;
    border-radius: ${({ theme: { border } }) => border.radius.small};
  }
`;
