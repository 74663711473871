import { gql } from '@apollo/client';

export const TICKET_STATUS_FRAGMENT = gql`
  fragment status on Status {
    id
    value
    treat_as_closed
    color
    color_value
    default
    visible_in_kanban
    priority
    archived
  }
`;
