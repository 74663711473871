import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from '../fragments';

export const USERS_BASIC = gql`
  query usersBasic($grid: UserGridInput) {
    users(grid: $grid) {
      total
      rows {
        ...userBasic
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
