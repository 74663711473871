import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { isObject } from 'lodash';
import { useCallback } from 'react';

import { GridSearchParamsTypes, SearchParamsProps } from '@/consts';

import { useNotify } from './notification';

type UseUpdateGridSearchParams = [(props: SearchParamsProps, newLocation?: string) => void];

const { UPDATE_PAGINATION, UPDATE_SORT, UPDATE_FILTER, REMOVE_FILTER, REMOVE_PARAM } =
  GridSearchParamsTypes;
const currentSearchParams = () => parse(window.location.search, { ignoreQueryPrefix: true });
const previousFilters = (obj: unknown) => (isObject(obj) ? obj : {});

export const useUpdateGridSearchParams = (): UseUpdateGridSearchParams => {
  const history = useHistory();
  const intl = useIntl();
  const { notifyError } = useNotify();

  const updateSearchParams = useCallback((props: SearchParamsProps, newLocation?: string) => {
    try {
      const newSearchParams = newLocation ? {} : currentSearchParams();

      newSearchParams.limit && delete newSearchParams.limit;
      newSearchParams.offset && delete newSearchParams.offset;

      switch (props.type) {
        case UPDATE_PAGINATION:
          newSearchParams.limit = props.payload.limit;
          newSearchParams.offset = props.payload.offset;

          break;

        case UPDATE_SORT:
          newSearchParams.sort = props.payload;

          break;

        case UPDATE_FILTER:
          // @ts-ignore

          if (props.payload.dealProducts) {
            const dealProducts = props.payload.dealProducts?.value;
            const dealsOrder = newSearchParams.filter?.dealsOrder?.value;

            if (dealsOrder) {
              dealProducts.forEach(deal => {
                if (!dealsOrder.includes(deal)) dealsOrder.push(deal);
              });

              dealsOrder.forEach(deal => {
                if (!dealProducts.includes(deal)) dealsOrder.splice(dealsOrder.indexOf(deal), 1);
              });

              props.payload.dealsOrder = { value: dealsOrder };
            } else {
              props.payload.dealsOrder = { value: dealProducts };
            }
          }

          newSearchParams.filter = { ...previousFilters(newSearchParams.filter), ...props.payload };
          break;

        case REMOVE_FILTER:
          if (!isObject(newSearchParams?.filter)) {
            throw new Error('Filter to remove is not an object in updateSearchParams switch!');
          }

          if (typeof props.payload !== 'string') {
            throw new Error('Remove filter payload is not a string in updateSearchParams switch!');
          }

          // @ts-ignore
          delete newSearchParams.filter[props.payload];

          if (props.payload === 'dealProducts') delete newSearchParams.filter?.dealsOrder;

          break;

        case REMOVE_PARAM:
          delete newSearchParams[props.payload];

          break;

        default:
          throw new Error('Missed case in updateSearchParams switch!');
      }

      const stringifiedParams = stringify(newSearchParams, {
        encode: false,
      });

      if (newLocation) {
        history.push({
          pathname: newLocation,
          search: '?' + stringifiedParams,
        });

        return;
      }

      history.push({
        pathname: window.location.pathname,
        search: '?' + stringifiedParams,
      });
    } catch (err: unknown) {
      notifyError(
        intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy przetwarzaniu parametrów' }),
        err,
      );
    }
  }, []);

  return [updateSearchParams];
};
