import { useIntl } from 'react-intl';

import { IconButton } from '../../../Buttons';

import { Header, ProcessIcon, Title } from './styled';

interface INotificationHeader {
  onRefreshClick: () => void;
  loading: boolean;
}

export const NotificationsHeader = (props: INotificationHeader) => {
  const intl = useIntl();
  const { onRefreshClick, loading } = props;

  return (
    <Header data-testid="notification-popup">
      <Title>
        {intl.formatMessage({
          id: `components.Notifications.header`,
          defaultMessage: 'Ostatnie nieprzeczytane powiadomienia',
        })}
      </Title>

      <IconButton onClick={onRefreshClick} icon={<ProcessIcon $loading={loading} />} />
    </Header>
  );
};
