import { gql } from '@apollo/client';

import { CREDENTIAL_FRAGMENT } from '../fragments';

export const CREATE_CREDENTIALS = gql`
  mutation createCredentials(
    $domainId: String!
    $host: String!
    $login: String!
    $name: String!
    $password: String!
    $comment: String
  ) {
    createCredentials(
      domainId: $domainId
      host: $host
      login: $login
      name: $name
      password: $password
      comment: $comment
    ) {
      ...credential
    }
  }
  ${CREDENTIAL_FRAGMENT}
`;
