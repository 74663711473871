import { Modal } from 'antd';
import { useIntl } from 'react-intl';

import { saveToDomainPillsIDB, saveToTicketsPillsIDB } from '@/utils';
import { useNotify } from '@/hooks';
import { ButtonCustomTypes } from '@/consts';
import { FilterType } from '@/components/QuickFilters/consts';
import { saveToCopywritingPillsIDB } from '@/utils/idbHelpers';

import { Button } from '../../../../../Buttons';
import { Skeleton } from '../../../../../Skeleton';
import { usePillLoader } from '../../../../usePillLoader';
import { QuickFiltersModalState, StateKeys } from '../../consts';

import { ModalContent } from './components';

import { ButtonsWrapper } from './styled';

interface IQuickFiltersModal {
  filtersType: FilterType;
  modalState: QuickFiltersModalState;
  setModalState: (prop: QuickFiltersModalState) => void;
}

const width = window.innerWidth > 900 ? 700 : '90vw';

export const QuickFiltersModal = (props: IQuickFiltersModal) => {
  const intl = useIntl();
  const { notifyError, notifySuccess } = useNotify();
  const { modalState, setModalState, filtersType } = props;

  const afterClose = () => {
    setModalState({ type: StateKeys.DISMOUNTED });
  };

  const onLoad = () => {
    setModalState({
      type: StateKeys.OPEN,
      loading: false,
      isSaving: false,
    });
  };

  const onError = () => {
    handleCancel();
  };

  const [pills, setPills] = usePillLoader({
    onLoad,
    onError,
    type: filtersType,
  });

  const handleCancel = () => {
    setModalState({ type: StateKeys.CLOSED });
  };

  const onSubmit = () => {
    setModalState({ type: StateKeys.OPEN, loading: false, isSaving: true });

    const savePillsIDB =
      filtersType === FilterType.DOMAINS
        ? saveToDomainPillsIDB
        : filtersType === FilterType.COPYWRITING
        ? saveToCopywritingPillsIDB
        : saveToTicketsPillsIDB;

    savePillsIDB(pills)
      .then(() => {
        notifySuccess(intl.formatMessage({ defaultMessage: 'Zapisano filtry' }));

        handleCancel();
      })
      .catch((e: unknown) => {
        notifyError(
          intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy zapisywaniu filtrów' }),
          e,
        );

        setModalState({ type: StateKeys.OPEN, loading: false, isSaving: false });
      });
  };

  const isOpen = modalState.type === StateKeys.OPEN;
  const isSubmitting = isOpen && modalState.isSaving;

  const footer = () => (
    <ButtonsWrapper>
      <Button
        key="back"
        colorType={ButtonCustomTypes.BACK}
        disabled={isSubmitting}
        onClick={handleCancel}
      >
        {intl.formatMessage({ defaultMessage: 'Anuluj zmiany' })}
      </Button>
      <Button
        key="submit"
        colorType={ButtonCustomTypes.SAVE}
        type="primary"
        loading={isSubmitting}
        onClick={onSubmit}
      >
        {intl.formatMessage({ defaultMessage: 'Zapisz zmiany' })}
      </Button>
    </ButtonsWrapper>
  );

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Edytuj szybkie filtry' })}
      open={isOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
      width={width}
      footer={footer()}
    >
      {isOpen && modalState.loading ? (
        <Skeleton />
      ) : (
        <ModalContent
          onSubmit={onSubmit}
          pills={pills}
          setPills={setPills}
          filtersType={filtersType}
        />
      )}
    </Modal>
  );
};
