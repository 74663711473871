import { gql } from '@apollo/client';

export const DOMAIN_ADS_DEPOSIT_FRAGMENT = gql`
  fragment domainAdsDeposit on DomainAdsDeposit {
    id
    domain {
      id
      name
    }
    deposited_budget
    is_infinite
    date
    created_at
    updated_at
    description
  }
`;
