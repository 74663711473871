import styled from 'styled-components';

export const Link = styled.a`
  &,
  :hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
`;
