import styled from 'styled-components';

import { Shift } from '@/components/Icon';

export const DragWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ShiftIcon = styled(Shift)`
  height: 22px;
`;
