import { FC } from 'react';

import { Tag } from './styled';

interface ITicketTypeTag {
  ticketType: string;
  color: string;
}

export const TicketTypeTag: FC<ITicketTypeTag> = ({ ticketType, color }) => (
  <Tag backgroundColor={color}>{ticketType}</Tag>
);
