import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 370px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const UploadOutlinedIcon = styled(UploadOutlined)`
  font-size: 16px;
`;
