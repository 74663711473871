import { createContext, ReactNode, useContext, useState } from 'react';

import { ImportDataModal } from '@/components';

/**
 * Context
 */
interface IImportDataContext {
  openModal: (data: { uploadRoute: any; data: any }) => void;
  closeModal: () => void;
}

const ImportDataContext = createContext<IImportDataContext | undefined>(undefined);

interface IImportDataProvider {
  children: ReactNode;
}

/**
 * Provider
 */

interface IModalOptions {
  isVisible: boolean;
  uploadRoute?: string;
  data?: any;
  mutation?: (data: {
    variables: any;
    onSuccess: () => void;
    onError: () => void;
  }) => Promise<void>;
  domainRequired?: boolean;
}

const ImportDataProvider = ({ children }: IImportDataProvider) => {
  const [modalOptions, setModalOptions] = useState<IModalOptions>({
    isVisible: false,
  });
  const openModal = ({
    uploadRoute,
    data,
    mutation,
    domainRequired,
  }: Omit<IModalOptions, 'isVisible'>) => {
    setModalOptions({
      isVisible: true,
      uploadRoute,
      data,
      mutation,
      domainRequired,
    });
  };
  const closeModal = () =>
    setModalOptions({
      isVisible: false,
    });

  return (
    <ImportDataContext.Provider value={{ openModal, closeModal }}>
      {modalOptions.isVisible && (
        <ImportDataModal
          visible={modalOptions.isVisible}
          closeModal={closeModal}
          uploadRoute={modalOptions.uploadRoute}
          mutation={modalOptions.mutation}
          data={modalOptions.data}
          domainRequired={Boolean(modalOptions.domainRequired)}
        />
      )}
      {children}
    </ImportDataContext.Provider>
  );
};

const useImportData = () => {
  const context = useContext(ImportDataContext);

  if (context === undefined) {
    throw new Error('useImportData must be used within a ImportDataProvider');
  }

  return context;
};

export { ImportDataProvider, useImportData };
