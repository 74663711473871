import { gql } from '@apollo/client';

import { PROJECT_USERS_FRAGMENT } from '../fragments';

export const PROJECTS_USERS = gql`
  query projectsUsers {
    projects {
      ...projectUsers
    }
  }
  ${PROJECT_USERS_FRAGMENT}
`;
