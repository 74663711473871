import { gql } from '@apollo/client';

import { TICKET_STATUS_FRAGMENT } from '../fragments';

export const ADD_TICKET_STATUS = gql`
  mutation addTicketStatus(
    $value: String!
    $color: ColorPaletteEnum!
    $treatAsClosed: Boolean
    $visibleInKanban: Boolean
  ) {
    addTicketStatus(
      value: $value
      color: $color
      treatAsClosed: $treatAsClosed
      visibleInKanban: $visibleInKanban
    ) {
      ...status
    }
  }
  ${TICKET_STATUS_FRAGMENT}
`;
