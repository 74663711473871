import { gql } from '@apollo/client';

import {
  DOMAIN_CONTROL_REPORT_SETTINGS_FRAGMENT,
  DOMAIN_IN_THE_CONTROL_LIST_FRAGMENT,
  USER_BASIC_FRAGMENT,
} from '@/services/fragments';

export const DOMAINS_CONTROL = gql`
  query domainsControl($grid: DomainControlGridInput) {
    domainsControl(grid: $grid) {
      total
      rows {
        ...domainInTheControlList
        reportSettings {
          ...domainControlReportSettings
        }
      }
      controls {
        ...domainControl
        __typename
      }
      __typename
    }
  }
  fragment domainControl on ControlDomainDTO {
    diversificationLinksCount
    contentMarketingLinksCount
    aoCommentsCount
    textSum
    quarter {
      id
      start_date
      end_date
      characters_to_create
    }
    fulfillmentUsers {
      ...userBasic
    }
    domain {
      ...domainInTheControlList
      reportSettings {
        ...domainControlReportSettings
      }
    }
  }
  ${DOMAIN_CONTROL_REPORT_SETTINGS_FRAGMENT}
  ${DOMAIN_IN_THE_CONTROL_LIST_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;
