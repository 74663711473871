import 'antd/dist/reset.css';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import 'react-image-lightbox/style.css';

import theme from './theme';

export default createGlobalStyle`
  ${normalize}

  ;

  body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: ${theme.font.size.regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.colors.darkBlue};
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .prevent-scrolling {
    overflow: hidden;
  }

  ::placeholder {
    color: ${theme.colors.black};
  }

  * {
    box-sizing: border-box;
  }

  /* DatePicker */
  .ant-calendar-range .ant-calendar-input-wrap {
    height: 36px;
  }

  .ant-btn-primary {
    background-color: ${theme.colors.lightBlue};
  }

  .ant-tag {
    max-width: 200px;
    text-wrap: balance;
  }


  /**
  * Notifications
  */
  .ant-notification {
    .ant-notification-notice {
      padding: 0;

      .ant-notification-notice-close {
        display: none;
      }

      .ant-notification-notice-with-icon {
        display: flex;

        .ant-notification-notice-icon {
          min-height: 50px;
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: static;
          margin-left: 0;

          svg {
            fill: ${theme.colors.white};
          }
        }

        .ant-notification-notice-message {
          margin: 0;
          padding: 13px 20px;
          display: flex;
          flex: 1;
          font-size: ${theme.font.size.regular};
        }

        .ant-notification-notice-description {
          margin-left: 0;
        }
      }

      &.success {
        border: 1px solid ${theme.colors.greenSuccessDark};

        .ant-notification-notice-with-icon {
          .ant-notification-notice-icon {
            background-color: ${theme.colors.greenSuccessDark};
          }

          .ant-notification-notice-message {
            background-color: ${theme.colors.greenSuccessLight};
            color: ${theme.colors.greenSuccessText};
          }
        }
      }

      &.danger {
        border: 1px solid ${theme.colors.redDangerDark};

        .ant-notification-notice-with-icon {
          .ant-notification-notice-icon {
            background-color: ${theme.colors.redDangerDark};
          }

          .ant-notification-notice-message {
            background-color: ${theme.colors.redDangerLight};
            color: ${theme.colors.redDangerText};
          }
        }
      }

      &.warning {
        border: 1px solid ${theme.colors.yellowWarningDark};

        .ant-notification-notice-with-icon {
          .ant-notification-notice-icon {
            background-color: ${theme.colors.yellowWarningDark};
          }

          .ant-notification-notice-message {
            background-color: ${theme.colors.yellowWarningLight};
            color: ${theme.colors.yellowWarningText};
          }
        }
      }

      &.info {
        border: 1px solid ${theme.colors.blueInfoDark};

        .ant-notification-notice-with-icon {
          .ant-notification-notice-icon {
            background-color: ${theme.colors.blueInfoDark};
          }

          .ant-notification-notice-message {
            background-color: ${theme.colors.blueInfoLight};
            color: ${theme.colors.blueInfoText};
          }
        }
      }
    }
  }

  .ant-table-column-title {
    font-size: 15px !important;
    font-weight: 700 !important;
  }

  .ant-btn-dashed {
    border-color: #d9d9d9 !important;
  }

  .ant-rate-star span {
    font-size: 20px !important;
  }

  .ant-menu .ant-menu-item {
    border-radius: 0 !important;
  }

  .ant-menu-inline .ant-menu-submenu-title, .ant-menu-inline .ant-menu-item {
    margin: 0 !important;
    width: 100% !important;
  }

  .ant-menu .ant-menu-submenu-title {
    border-radius: unset !important;
  }

  .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: inherit !important;
  }

  .ant-select-item-empty {
    padding: 5px 12px;
  }

  /**
  * Menu collapsed popup
  */
  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }

  .ant-menu-submenu-popup {
    .ant-menu {
      background-color: ${theme.colors.shadyBlue} !important;

      .ant-menu-item {
        border-radius: 5px !important;
      }

      .ant-menu-item:hover {
        background-color: ${theme.colors.navyBlue} !important;
      }

      .ant-menu-item-selected {
        background-color: ${theme.colors.navyBlue};
      }
    }

    .ant-menu-title-content {
      color: ${theme.colors.white};
    }
  }

  /**
  * AntD tooltip 
  */
  .ant-tooltip {
    pointer-events: none;
    max-width: 350px;
    
    .ant-tooltip-inner {
      color: rgba(0, 0, 0, 0.88);
      background-color: #f9f9f9;
    }

    .ant-tooltip-arrow::before {
      background: #f9f9f9;
    }

  }


  .ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: fixed;
  }

  .ant-modal {
    max-width: 90vw !important;
    min-width: 600px !important;
    width: min-content !important;

    .editor {
      max-height: 300px;
    }
  }

  .ant-menu-light.ant-menu-submenu > .ant-menu {

  }
`;
