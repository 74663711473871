import { useParams } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';

import { useUser, useCurrentUser } from '@/hooks';

import { Skeleton } from '../../../Skeleton';
import { ErrorMessage } from '../../../ErrorMessage';
import { Hyperlink } from '../../../Hyperlink';

import { Item, TextWrapper, Title, Text, PhoneInCircleIcon, MailInCircleIcon } from './styled';

/**
 * i18n Messages
 */
const i18nMessages = defineMessages({
  phone: {
    id: 'cards.UserContactDetails.title.phone',
    defaultMessage: 'Telefon',
  },
  email: {
    id: 'cards.UserContactDetails.title.email',
    defaultMessage: 'E-mail',
  },
  noData: {
    id: 'cards.UserContactDetails.title.noData',
    defaultMessage: 'Brak',
  },
});

export const UserContactDetails = () => {
  const intl = useIntl();
  const { id: userIdFromParams }: { id: string | undefined } = useParams();

  const activeUser = useCurrentUser();
  const { data: userData, loading, error } = useUser(userIdFromParams);

  const user = userIdFromParams ? userData : activeUser;

  if (loading) return <Skeleton rows={6} />;
  if (error) return <ErrorMessage error={error} />;

  const { phone, email } = user;

  const rows = [
    ...(!!phone
      ? [
          {
            title: intl.formatMessage(i18nMessages.phone),
            text: <Hyperlink phone={phone}>{phone}</Hyperlink>,
            icon: PhoneInCircleIcon,
          },
        ]
      : []),
    {
      title: intl.formatMessage(i18nMessages.email),
      text: <Hyperlink email={email}>{email}</Hyperlink>,
      icon: MailInCircleIcon,
    },
  ];

  return (
    <>
      {rows.map(({ title, text, icon: Icon }) => (
        <Item key={title}>
          <Icon />
          <TextWrapper>
            <Title>{title}</Title>
            <Text>{text || intl.formatMessage(i18nMessages.noData)}</Text>
          </TextWrapper>
        </Item>
      ))}
    </>
  );
};
