import { gql } from '@apollo/client';

export const DEAL_IN_THE_DOMAIN_LIST_FRAGMENT = gql`
  fragment dealInTheDomainList on Deal {
    id
    max_budget
    content_quarterly
    status
    status_name
    type
    type_name
    lspType {
      id
      value
    }
    basket
    currency
    link_building_budget
    diversification_links_count
    sponsored_articles_count
    start_date
    name
    import_id
    text_reduction_percent
    amount
    product
    actions_quarterly
    content_guidelines
    content_creation
    content_implementation
    terminations {
      id
      termination_date
    }
  }
`;
