import { FC, forwardRef } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input as InputAntd } from 'antd';

interface ITransparentTextInput {
  type?: 'text' | 'password';
  [key: string]: any;
}

export const TransparentTextInput: FC<ITransparentTextInput> = forwardRef(
  ({ type = 'text', ...props }, ref) => {
    return (
      <>
        {type === 'password' ? (
          <InputAntd.Password
            ref={ref}
            type={type}
            iconRender={(visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            {...props}
          />
        ) : (
          <InputAntd ref={ref} type={type} {...props} />
        )}
      </>
    );
  },
);

TransparentTextInput.displayName = 'TransparentTextInput';
