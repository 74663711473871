export const defaultState = { minutes: 0, hours: 0 };

export interface ITimeFormState {
  minutes: number;
  hours: number;
}

export interface ISubmitProps {
  minutes: number;
}

export interface ILogTimeForm {
  onSubmit?: (props: ISubmitProps) => void;
  currentState?: ITimeFormState;
  labels?: 'top' | 'right';
}

export interface ITimeField {
  key: keyof ITimeFormState;
  max: number;
  label: string;
  autoFocus?: boolean;
}

export const oneMinuteInSeconds = 60;
