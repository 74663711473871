import { ErrorResponse } from '@apollo/client/link/error';
import { notification } from 'antd';
import { isObject } from 'lodash';

import { theme } from '@/theme';
import IconDanger from '@/static/notification-danger.svg';

import { log } from './logService';

export const serverNotifications = ({ graphQLErrors, networkError }: ErrorResponse) => {
  const getMessage = (message = '', extra?: unknown[]) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <b>{message}</b>
      {!!extra?.length &&
        extra?.map((error: unknown, index: number) => (
          <div key={index}>- {JSON.stringify(error)}</div>
        ))}
    </div>
  );

  const notify = (message: string, extra?: unknown[]) => {
    const key = `open${Date.now()}`;
    const onClick = () => notification.close(key);

    notification.open({
      message: getMessage(message, extra),
      onClick,
      key,
      duration: 5,
      icon: <IconDanger fill={theme.colors.black} />,
      className: 'danger',
    });
  };

  if (!!networkError?.statusCode && networkError?.statusCode === 404) {
    log({ type: 'error', extra: 'statusCode 404', error: networkError });
  }

  const getExtraErrors = (object: unknown) =>
    isObject(object) ? Object.values(object)?.flat() : [];

  const errors = graphQLErrors ?? [];

  const extraPath = networkError?.result?.extra;
  const extra = !!extraPath ? getExtraErrors(extraPath) : null;

  if (!errors?.length) return;

  errors?.map(({ message }, index) => {
    if (index === 0 && !!extra?.length) notify(message, extra ?? []);
    else notify(message);
  });
};
