import { gql } from '@apollo/client';

export const DOMAIN_REPORT_POSSIBLE_VALUES = gql`
  query domainReportPossibleValues {
    domainReportPossibleValues {
      id
      key
      value
    }
  }
`;
