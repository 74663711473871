import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const TIME_LOG_FRAGMENT = gql`
  fragment timeLog on TimeLog {
    id
    minutes
    created_at
    user {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
