import { gql } from '@apollo/client';

export const DEAL_BASKETS = gql`
  query dealBaskets {
    dealBaskets {
      id
      key
    }
  }
`;
