import { Modal } from 'antd';
import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';

import { useNotify } from '@/hooks';

import { DomainsSearch } from '../DomainsSearch';

import { DnDUpload } from './DnDUpload';
import { CsvPreviewTable } from './CsvPreviewTable';

import { ContentWrapper } from './styled';

interface IImportDataModal {
  visible: boolean;
  closeModal: () => void;
  uploadRoute: string;
  data?: any;
  mutation: (data: { variables: any; onSuccess: () => void; onError: () => void }) => Promise<void>;
  domainRequired: boolean;
}

export const ImportDataModal = ({
  visible,
  closeModal,
  uploadRoute,
  data,
  mutation,
  domainRequired,
}: IImportDataModal) => {
  const intl = useIntl();
  const { notifyWarning, notifyError } = useNotify();

  const [file, setFile] = useState<File | null>(null);
  const [requestData, setRequestData] = useState<any>(null);
  const [headersInCSV, setHeadersInCSV] = useState(false);
  const [columnsForm, setColumnsForm] = useState<Record<string, string>>({});
  const [domain, setDomain] = useState(null);

  const handleChangeColumn = (idx: number) => (value: string) => {
    setColumnsForm(prev => ({ ...prev, [idx]: value }));
  };

  const handleSubmit = () => {
    if (!requestData) {
      fetchData();
    } else {
      importData();
    }
  };

  const fetchData = useCallback(async () => {
    if (file === null) return;

    var formData = new FormData();
    formData.append('file', file);

    if (data) {
      Object.keys(data).forEach(key => formData.append(key, data[key]));
    }

    if (domain) formData.append('domainId', domain);

    const request = await axios({
      url: `${API_URL}${uploadRoute}`,
      method: 'POST',
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    const possibleHeadersReversedMap = Object.fromEntries(
      Object.entries(request.data.possible_fields).map(([key, value]) => [value, key]),
    );
    const possibleHeadersValues = Object.values(request.data.possible_fields);

    setRequestData(request.data);
    setColumnsForm(
      request.data.preview_rows[0].reduce(
        (acc, curr, idx) => ({
          ...acc,
          [idx]: possibleHeadersValues.includes(curr) ? possibleHeadersReversedMap[curr] : '',
        }),
        {},
      ),
    );
  }, [file, domain]);

  const importData = useCallback(async () => {
    const onError = () => {
      notifyError(
        intl.formatMessage({ defaultMessage: 'Nastąpił błąd podczas importowania danych' }),
      );
    };

    const filteredColumns = Object.fromEntries(
      Object.entries(columnsForm).filter(([key, value]) => value !== 'ignore'),
    );

    mutation({
      variables: {
        id: requestData.file.id,
        headers: JSON.stringify(filteredColumns),
        headerRow: headersInCSV,
      },
      onSuccess: () => {
        notifyWarning(
          intl.formatMessage({ defaultMessage: 'Wynik importu otrzymasz w powiadomieniach' }),
        );
        closeModal();
      },
      onError,
    });
  }, [requestData, columnsForm, headersInCSV]);

  const isFormDisabled = (() => {
    const rules = [];

    if (requestData) {
      const formValues = Object.values(columnsForm);

      // No empty values
      rules.push(formValues.every(value => value !== ''));

      // At least one is not ignored
      rules.push(formValues.some(value => value !== 'ignore'));

      // No column is repeated
      rules.push(
        (() => {
          const filteredValues = formValues.filter(value => value !== 'ignore');
          return filteredValues.length === new Set(filteredValues).size;
        })(),
      );
    } else {
      rules.push(file !== null);

      if (domainRequired) rules.push(Boolean(data.domainId || domain));
    }

    return rules.some(rule => !rule);
  })();

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Import' })}
      open={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      okButtonProps={{ disabled: isFormDisabled }}
    >
      <ContentWrapper>
        {!requestData ? (
          <>
            <DnDUpload file={file} setFile={setFile} />
            {domainRequired && !data.domainId && (
              <DomainsSearch
                value={domain}
                onChange={setDomain}
                style={{ marginTop: '1rem' }}
                placeholder={intl.formatMessage({ defaultMessage: 'Domena' })}
              />
            )}
          </>
        ) : (
          <CsvPreviewTable
            requestData={requestData}
            columnsForm={columnsForm}
            handleChangeColumn={handleChangeColumn}
            headersInCSV={headersInCSV}
            setHeadersInCSV={setHeadersInCSV}
          />
        )}
      </ContentWrapper>
    </Modal>
  );
};
