import { useIntl } from 'react-intl';

import { isStage } from '@/utils';
import { ClosedInput, OperatorEnum, TicketGridFilterInput } from '@/__generated__';

import { Categories, prodIds } from './consts';

export const useQuickFilterCards = () => {
  const intl = useIntl();

  const getIds = (key: keyof typeof prodIds) => {
    if (!isStage()) {
      const entries = Object.entries(prodIds[key]);

      const filter: TicketGridFilterInput = {};

      entries.forEach(([entryKey, ids]) => {
        filter[entryKey] = { value: ids };
      });

      return filter;
    }

    const closed: ClosedInput = { operator: OperatorEnum.EQ, value: ['false'] };

    return { closed: closed };
  };

  const cards = [
    {
      label: intl.formatMessage({ defaultMessage: 'Teksty' }),
      ids: getIds(Categories.TEXTS),
      category: Categories.TEXTS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Linkowanie' }),
      ids: getIds(Categories.LINKS),
      category: Categories.LINKS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'WebDev' }),
      ids: getIds(Categories.WEBDEV),
      category: Categories.WEBDEV,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'UX & Analityka' }),
      ids: getIds(Categories.UX),
      category: Categories.UX,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Grafika' }),
      ids: getIds(Categories.GRAPHICS),
      category: Categories.GRAPHICS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Ustalenia wewnętrzne' }),
      ids: getIds(Categories.INTERNAL),
      category: Categories.INTERNAL,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Analiza/Optymalizacja' }),
      ids: getIds(Categories.OPTIMALISATION),
      category: Categories.OPTIMALISATION,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Start' }),
      ids: getIds(Categories.START),
      category: Categories.START,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Start SEM' }),
      ids: getIds(Categories.START_SEM),
      category: Categories.START_SEM,
    },
  ];

  return cards;
};
