import { gql } from '@apollo/client';

export const UPDATE_PHRASE = gql`
  mutation updatePhrase($id: String!, $userAssignedUrl: String, $excludeFromLinking: Boolean) {
    updatePhrase(
      id: $id
      userAssignedUrl: $userAssignedUrl
      excludeFromLinking: $excludeFromLinking
    ) {
      id
      user_assigned_url
      exclude_from_linking
    }
  }
`;
