import { gql } from '@apollo/client';

export const IMPERSONATE = gql`
  mutation impersonate($userId: ID!) {
    impersonate(userId: $userId) {
      email
      first_name
      last_name
    }
  }
`;
