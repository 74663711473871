import { FC, ReactNode } from 'react';
import { Field, FieldArray } from 'formik';

import { UserBasicFragment } from '@/__generated__';

import { AlwaysEmptyUserSelect } from '../../Selects';
import { UserItemField } from '../UserItemField';
import { FieldWrapper, Label, SelectWrapper } from '../styled';

interface ISelectUserInputFieldArray {
  name: string;
  label?: string | ReactNode;
  users: UserBasicFragment[];
  selectedUsersIds: string[];
  notAvailableUserIds?: string[];
}

export const SelectUserInputFieldArray: FC<ISelectUserInputFieldArray> = ({
  name,
  label,
  users,
  selectedUsersIds,
  notAvailableUserIds = null,
}) => {
  const userIdsToFilter = notAvailableUserIds ?? selectedUsersIds;

  return (
    <FieldWrapper>
      {label && <Label>{label}</Label>}
      <FieldArray
        name={name}
        render={arrayHelpers => {
          const addUser = (value: any) => {
            arrayHelpers.push(value);
          };

          return (
            <>
              {!!selectedUsersIds.length &&
                selectedUsersIds.map((userId: string, index: number) => (
                  <Field
                    key={index}
                    name={`${name}.${index}`}
                    userId={userId}
                    users={users}
                    component={UserItemField}
                    onClick={() => arrayHelpers.remove(index)}
                  />
                ))}
              <SelectWrapper>
                <AlwaysEmptyUserSelect
                  users={users}
                  selectedUsersIds={userIdsToFilter}
                  onChange={addUser}
                  dataTestId="user-select-in-field-array"
                />
              </SelectWrapper>
            </>
          );
        }}
      />
    </FieldWrapper>
  );
};
