import { gql } from '@apollo/client';

export const TEXT_ORDERS_STATISTIC_FRAGMENT = gql`
  fragment textOrdersStatistics on TextOrdersStatistics {
    new
    ready_to_write
    to_be_verified
    ready_to_send
    sent_to_customer
    accepted_by_client
    to_be_corrected
    incorrect_order
    implemented
    cancelled
    domain {
      id
      name
    }
  }
`;
