import { FC, PropsWithChildren } from 'react';
import { RadioChangeEvent } from 'antd';

import { ProjectMetaObject } from '@/services/types';

import { Checkbox } from '../../../Checkbox';

import { Wrapper, ItemName, Text, Color, KeyIcon } from './styled';

interface IMetaItem {
  handleEditMenu: (item: ProjectMetaObject) => void;
  handleSyncMetaObjects: (itemId: string, isChecked: boolean) => void;
  item: ProjectMetaObject;
  isSync: boolean;
  canEdit: boolean;
  isChecked?: boolean;
  isDragging?: boolean;
}

export const MetaItem: FC<PropsWithChildren<IMetaItem>> = ({
  handleSyncMetaObjects,
  handleEditMenu,
  item,
  canEdit,
  isSync,
  isDragging = false,
  isChecked = false,
  children = null,
}) => {
  const {
    id: itemId,
    archived,
    treat_as_closed: treatAsClosed = false,
    color,
    color_value,
    value,
  } = item;

  const handleCheck = (e: RadioChangeEvent) => {
    handleSyncMetaObjects(itemId, e.target.checked);
  };

  return (
    <Wrapper disabled={archived || !isChecked} $isDragging={isDragging} data-testid={`meta-item`}>
      <ItemName disabled={archived || !isChecked}>
        {children}
        <Color
          disabled={archived || !isChecked}
          $color={color_value ?? color ?? ''}
          data-testid="meta-item-color"
        />
        <Text>{value}</Text>
        {treatAsClosed && <KeyIcon data-testid="meta-item-closed" />}
      </ItemName>
      {canEdit &&
        (isSync ? <Checkbox onChange={handleCheck} checked={isChecked} /> : handleEditMenu(item))}
    </Wrapper>
  );
};
