import { gql } from '@apollo/client';

import { TEAM_FRAGMENT } from '../fragments';

export const TEAMS = gql`
  query teams($grid: TeamGridInput) {
    teams(grid: $grid) {
      total
      rows {
        ...team
      }
    }
  }
  ${TEAM_FRAGMENT}
`;
