import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const USER_WITH_PERM_INFO_FRAGMENT = gql`
  fragment userWithPermInfo on User {
    ...userBasic
    allowedPermissions
    teams {
      id
      leader {
        id
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
