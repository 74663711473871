import { gql } from '@apollo/client';

export const SUBPAGE_PRIORITIES = gql`
  query subpagePriorities {
    subpagePriorities {
      key
      id
    }
  }
`;
