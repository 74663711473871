import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const CREATE_TICKET_DRAFT = gql`
  mutation createTicketDraft {
    createTicketDraft {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
