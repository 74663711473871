import { gql } from '@apollo/client';


import { TEXT_ORDER_FRAGMENT } from '../fragments';
export const IMPORT_TEXT_ORDER_FROM_GOOGLE_SHEETS = gql`
  mutation importTextOrderFromGoogleSheets($sheetId: String!, $domainId: String!) {
    importTextOrderFromGoogleSheets (sheetId: $sheetId, domainId: $domainId) {
      textOrders {
       ...textOrder
       }
       errors
    }
  }

  ${TEXT_ORDER_FRAGMENT}
`;
