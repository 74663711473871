import { ApolloError } from '@apollo/client';

import { TimeStatsFragment } from '@/__generated__';

import { ErrorMessage } from '../../ErrorMessage';

import { RestrictedTimeStatsFilters, TimeStatsFilters, TimeStatsTable } from './components';
import { ITimeStatsFilters } from './consts';
import { timeStatsData, timeStatsMonths } from './helpers';

import { TableWrapper } from './styled';

interface ITimeStats extends ITimeStatsFilters {
  showFilters?: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  timeLogs: TimeStatsFragment[];
  isRestricted?: boolean;
}

export const TimeStats = (props: ITimeStats) => {
  const { showFilters = true, timeLogs, error, loading, monthRange, isRestricted } = props;
  const months = timeStatsMonths(monthRange);
  const data = timeStatsData(timeLogs);

  return (
    <TableWrapper>
      {showFilters && !isRestricted && <TimeStatsFilters monthRange={monthRange} />}
      {showFilters && isRestricted && <RestrictedTimeStatsFilters monthRange={monthRange} />}
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <TimeStatsTable loading={loading} months={months} {...data} />
      )}
    </TableWrapper>
  );
};
