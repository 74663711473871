import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!, $content: String, $priority: Float, $archived: Boolean) {
    createProject(name: $name, content: $content, priority: $priority, archived: $archived) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
