import { gql } from '@apollo/client';

export const DOMAIN_CONTROL_REPORT_SETTINGS_FRAGMENT = gql`
  fragment domainControlReportSettings on DomainExternalReportSettings {
    company
    country
    language
    report_languages
    send_email
    template
    GAMGAService {
      id
      name
    }
    GAMSearchConsoleSite {
      id
      website_url
    }
  }
`;
