import { gql } from '@apollo/client';

/**
 * This fragment can only be used in a GROUPED_PHRASES query
 */
export const PHRASE_POSITION_ORDERED_FRAGMENT = gql`
  fragment phrasePositionOrdered on PhrasePositionOrdered {
    date
    position
    url
    positionDifference
  }
`;
