import { gql } from '@apollo/client';

import { S4S_INSTANCE_FRAGMENT } from '../fragments';

export const S4S_INSTANCES = gql`
  query s4sInstances($grid: S4sGridInput) {
    s4sInstances(grid: $grid) {
      total
      rows {
        ...s4sInstance
      }
    }
  }
  ${S4S_INSTANCE_FRAGMENT}
`;
