import { gql } from '@apollo/client';

export const NOTIFICATION_GROUPS = gql`
  query notificationGroups {
    notificationGroups {
      id
      key
      value
    }
  }
`;
