import { createContext, ReactNode, useContext, useMemo } from 'react';

import { UserFragment } from '@/__generated__';

import { useAuth } from './AuthProvider';

interface IUserProviderProps {
  children: ReactNode;
}

/**
 * Context
 */
const UserContext = createContext<UserFragment | null | undefined>(undefined);

/**
 * Provider
 */
const UserProvider = (props: IUserProviderProps) => {
  const { data } = useAuth();

  const user = useMemo(() => data.user, [data]);

  return <UserContext.Provider value={user} {...props} />;
};

/**
 * Hook
 */
const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
