import { domainInfoUrlFromId, ticketUrlFromId, userInfoUrlFromId } from '@/utils';

import { limit, SearchCategories } from './consts';

export const address = (category: SearchCategories, value: string) => {
  switch (category) {
    case SearchCategories.USERS:
      return userInfoUrlFromId(value);

    case SearchCategories.DOMAINS:
      return domainInfoUrlFromId(value);

    case SearchCategories.TICKETS:
      return ticketUrlFromId(value);

    case SearchCategories.ACTIONS:
      return value;

    default:
      return value;
  }
};

export const variables = (searchText: string) => ({
  domainsGrid: { filter: { domainName: { value: [searchText] } }, limit },
  usersGrid: { filter: { fulltext: { value: [searchText] } }, limit },
  ticketsGrid: { filter: { title: { value: [searchText] } }, limit },
  domainContactsGrid: { filter: { fulltext: { operator: 'LIKE', value: [searchText] } }, limit },
});
