import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { useParams } from 'react-router-dom';

import { uploadAttachment } from '@/utils';
import { useNotify, useUser, useCurrentUser } from '@/hooks';
import { ButtonCustomTypes } from '@/consts';

import { Button } from '../Buttons';

import { UserAvatarEditor } from './components';

import { Overlay, EditFilledIcon, ButtonWrapper, Footer } from './styled';

export const AvatarOverlay = () => {
  const intl = useIntl();
  const { notifySuccess, notifyError, notifyRestErrors } = useNotify();
  const { id: activeUserId, avatar: avatarHash } = useCurrentUser();
  const { id: userIdFromParams }: { id: string | undefined } = useParams();

  const userId = userIdFromParams ?? activeUserId;

  const { data: userData, refetch: refetchUser } = useUser(userId);
  const [modalVisible, setModalVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { BACK, REMOVE, SAVE } = ButtonCustomTypes;

  const hash = userIdFromParams ? userData.avatar : avatarHash;
  const isDefaultAvatar = !hash?.includes('/');

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleEditClick = () => {
    setModalVisible(true);
  };

  const showErrorNotification = (err: unknown) => {
    notifyError(
      intl.formatMessage({
        defaultMessage: 'Nastąpił błąd przy usuwaniu avatara',
      }),
    );
    notifyRestErrors(err);
  };

  const handleRemove = async () => {
    try {
      const onSuccess = () => {
        notifySuccess(
          intl.formatMessage({
            defaultMessage: 'Usunięto avatar',
          }),
        );

        refetchUser({ id: userId });
        closeModal();
      };

      const onError = (err: unknown) => {
        showErrorNotification(err);

        setIsRemoving(false);
      };

      setIsRemoving(true);

      await uploadAttachment({ file: null }, `/user/${userId}/upload`, onSuccess, onError);
    } catch (err: unknown) {
      showErrorNotification(err);
    }

    setIsRemoving(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
  };

  const footer = () => (
    <Footer>
      <Button
        colorType={REMOVE}
        loading={isRemoving}
        onClick={handleRemove}
        disabled={isDefaultAvatar || isSubmitting}
        data-testid="remove-avatar"
      >
        {intl.formatMessage({
          defaultMessage: 'Usuń',
        })}
      </Button>
      <ButtonWrapper>
        <Button
          colorType={BACK}
          onClick={handleCancel}
          disabled={isRemoving || isSubmitting}
          style={{ marginRight: '20px' }}
        >
          {intl.formatMessage({
            defaultMessage: 'Anuluj',
          })}
        </Button>
        <Button
          colorType={SAVE}
          loading={isSubmitting}
          disabled={isRemoving}
          onClick={handleSubmit}
          data-testid="save-avatar"
        >
          {intl.formatMessage({
            defaultMessage: 'Zapisz',
          })}
        </Button>
      </ButtonWrapper>
    </Footer>
  );

  return (
    <>
      <Overlay>
        <EditFilledIcon onClick={handleEditClick} data-testid="edit-avatar" />
      </Overlay>
      <Modal
        onCancel={handleCancel}
        footer={footer()}
        open={modalVisible}
        title={intl.formatMessage({
          defaultMessage: 'Edytuj avatar',
        })}
        centered
        destroyOnClose
      >
        {modalVisible && (
          <UserAvatarEditor
            closeModal={closeModal}
            setIsSubmitting={setIsSubmitting}
            isSubmitting={isSubmitting}
          />
        )}
      </Modal>
    </>
  );
};
