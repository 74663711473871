import { gql } from '@apollo/client';

export const TICKET_SUBTASK_FRAGMENT = gql`
  fragment ticketSubtask on Ticket {
    id
    title
    priority {
      id
      value
      color
    }
    type {
      id
      value
      color
    }
    status {
      id
      value
      color
      treat_as_closed
    }
    project {
      id
      name
    }
  }
`;
