import styled from 'styled-components';

export const PillBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.h3`
  margin: 0 0 10px;
  font-size: ${({ theme: { font } }) => font.size.medium};

  span {
    font-size: ${({ theme: { font } }) => font.size.semiMedium};
    color: ${({ theme: { colors } }) => colors.textGrey};
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  margin: 20px 0 10px;
  display: flex;
  justify-content: flex-end;
`;

export const PillContainer = styled.div`
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
`;

export const OptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Checkboxes = styled.div`
  display: flex;
  align-items: center;
`;

export const PillWrapper = styled.div<{ $highlight: boolean }>`
  margin: 5px;
  border-radius: 6px;
  border: ${({ theme: { colors }, $highlight }) =>
    $highlight ? `2px solid ${colors.scaleOrange}` : 'none'};
  transition: all 0.2s ease-in-out;
`;
