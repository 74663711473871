import { gql } from '@apollo/client';

export const TICKET_TYPE_FRAGMENT = gql`
  fragment type on Type {
    id
    value
    color
    color_value
    default
    priority
    archived
  }
`;
