import { FC, useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { Search, EMPTY_STRING } from '@/consts';

import { SearchInput } from '../SearchInput';

interface ISearchField {
  handleSearch: (fieldKey: string, value: string) => void;
  searchFieldKey?: string;
  placeholder?: string;
}

export const SearchField: FC<ISearchField> = ({
  handleSearch,
  placeholder = '',
  searchFieldKey,
}) => {
  const initialSearchDelay = 0;
  const { FULLTEXT } = Search;
  const SEARCH_KEY = searchFieldKey ?? FULLTEXT;

  let filterTimeout: NodeJS.Timeout;
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(EMPTY_STRING);

  const [searchDelay, setSearchDelay] = useState(initialSearchDelay);

  const isInSearchParams = () =>
    location.search.includes(`filter[${searchFieldKey ?? 'fulltext'}]`);

  useEffect(() => {
    if (searchValue && !isInSearchParams()) {
      setSearchValue(EMPTY_STRING);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const onNewSearch = (value: string, delay: number) => {
    if (value.length > 0) {
      setSearchDelay(1000);
      filterTimeout = setTimeout(() => {
        handleSearch(SEARCH_KEY, value);
      }, delay);
    } else if (delay) {
      handleSearch(SEARCH_KEY, EMPTY_STRING);
    }
  };

  useEffect(() => {
    /**
     * Has already been removed
     */
    if (!searchValue && !isInSearchParams()) return;

    onNewSearch(searchValue, searchDelay);

    return () => {
      clearTimeout(filterTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchDelay]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEnter = () => {
    !!searchValue && handleSearch(SEARCH_KEY, searchValue);
  };

  return (
    <SearchInput
      placeholder={placeholder}
      value={searchValue}
      onChange={onChange}
      onEnter={onEnter}
    />
  );
};
