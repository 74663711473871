import styled from 'styled-components';

export const IconContainer = styled.span`
  margin-right: 20px;
`;

export const TextWrapper = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
