import { gql } from '@apollo/client';

import { TICKET_STATUS_FRAGMENT } from '../fragments';

export const TICKET_STATUSES = gql`
  query ticketStatuses($projectId: ID, $includeArchived: Boolean) {
    ticketStatuses(projectId: $projectId, includeArchived: $includeArchived) {
      ...status
    }
  }
  ${TICKET_STATUS_FRAGMENT}
`;
