import { gql } from '@apollo/client';

import { LINK_FRAGMENT } from '../fragments';

export const CREATE_LINK = gql`
  mutation createLink(
    $group: LinkGroupEnum!
    $status: LinkStatusEnum!
    $portal: String
    $price: Float!
    $articleUrl: String
    $characterCount: Int
    $ownerId: ID!
    $plannedPublicationDate: DateTime
    $realPublicationDate: DateTime
    $orderDate: DateTime
    $textDeliveryDate: DateTime
    $note: String
    $textUrl: String
    $copywriterId: ID
    $domainId: ID
    $anchors: [LinkAnchorDTOInput!]
  ) {
    createLink(
      group: $group
      status: $status
      portal: $portal
      price: $price
      articleUrl: $articleUrl
      characterCount: $characterCount
      ownerId: $ownerId
      plannedPublicationDate: $plannedPublicationDate
      realPublicationDate: $realPublicationDate
      orderDate: $orderDate
      textDeliveryDate: $textDeliveryDate
      note: $note
      textUrl: $textUrl
      copywriterId: $copywriterId
      domainId: $domainId
      anchors: $anchors
    ) {
      ...link
    }
  }
  ${LINK_FRAGMENT}
`;
