import { NotificationFragment } from '@/__generated__';
import { log, parseJson } from '@/utils';
import { Paths } from '@/consts';

interface IProps {
  rows: NotificationFragment[];
  markNotificationRead: (id: string) => void;
}

const showNotifications = (
  notificationsToShow: NotificationFragment[],
  markNotificationRead: (id: string) => void,
) => {
  let i = 0;

  const interval = setInterval(() => {
    try {
      const notification = notificationsToShow[i];

      if (!notification) {
        throw new Error('Not valid notification');
      }

      if (document.visibilityState === 'visible') {
        clearInterval(interval);

        return;
      }

      const { data, id } = notification;

      const { subject: eventText, url } = !!data ? parseJson(data) : { subject: '', url: null };
      const { pathname } = !!url ? new URL(url) : { pathname: '' };

      const n = new Notification(eventText);

      n.addEventListener('click', function () {
        const notificationPathname = pathname || Paths.NOTIFICATIONS;

        n.close();

        markNotificationRead(id);

        window.open(`${window.location.origin}${notificationPathname}`);
      });

      if (i++ === notificationsToShow.length - 1) {
        clearInterval(interval);
      }
    } catch (e: unknown) {
      log(e);
    }
  }, 5000);
};

const syncShowedNotificationsWithLS = (
  notificationsToShow: NotificationFragment[],
  showedNotificationsArr: string[],
  todayKey: string,
) => {
  setTimeout(() => {
    try {
      let showed: string[] = [];

      if (showedNotificationsArr) {
        showed = showedNotificationsArr;
      }

      const notificationIds = notificationsToShow.map(({ id }: { id: string }) => id);

      showed.push(...notificationIds);

      const stringifiedShowed = JSON.stringify(showed);

      localStorage.setItem(todayKey, stringifiedShowed);
    } catch (e: unknown) {
      log(e);
    }
  }, 0);
};

const removePreviousShowedNotifications = (notTodayKey: string) => {
  setTimeout(() => {
    try {
      localStorage.removeItem(notTodayKey);
    } catch (e: unknown) {
      log(e);
    }
  }, 0);
};

const notificationsService = (props: IProps) => {
  const remDay: number = new Date().getDay() % 2;
  const todayKey = `notificationsShowed${remDay}`;
  const notTodayKey = `notificationsShowed${remDay ? 0 : 1}`;

  removePreviousShowedNotifications(notTodayKey);

  const showedNotifications = localStorage.getItem(todayKey);

  const showedNotificationsArr = showedNotifications ? parseJson(showedNotifications) : null;

  const notificationsToShow = showedNotificationsArr
    ? props.rows.filter(row => !showedNotificationsArr.includes(row.id))
    : props.rows;

  if (!notificationsToShow.length) return;

  if (document.visibilityState !== 'visible') {
    showNotifications(notificationsToShow, props.markNotificationRead);
  }

  syncShowedNotificationsWithLS(notificationsToShow, showedNotificationsArr, todayKey);
};

export const runNotification = (props: IProps) => {
  if (window.Notification && Notification.permission === 'granted') {
    notificationsService(props);
  }

  // If the user hasn't told if they want to be notified or not
  // Note: because of Chrome, we are not sure the permission property
  // is set, therefore it's unsafe to check for the "default" value.
  else if (window.Notification && Notification.permission !== 'denied') {
    Notification.requestPermission(function (status) {
      // If the user said okay
      if (status === 'granted') {
        notificationsService(props);
      }
    });
  }
};

export const handleFetchedNotifications = (props: IProps) => {
  try {
    if (Notification.permission === 'denied' || !props.rows.length) return;

    const isAllowed = localStorage.getItem('desktopNotifications');

    if (isAllowed !== 'true') return;

    runNotification(props);
  } catch (e: unknown) {
    log(e);
  }
};
