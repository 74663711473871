import {
  UserStatisticsQuery,
  UserStatisticsQueryVariables,
  UserStatisticsResponse,
} from '@/__generated__';
import { queries } from '@/services';

import { QueryHookOptions, useQueryAndExtract } from './useQuery';

export const useUserStatistics = ({ id }: QueryHookOptions) =>
  useQueryAndExtract<UserStatisticsQuery, UserStatisticsQueryVariables, UserStatisticsResponse>(
    queries.USER_STATISTICS,
    'userStatistics',
    { variables: { id }, fetchPolicy: 'cache-and-network' },
  );
