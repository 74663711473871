import { InMemoryCache, makeVar } from '@apollo/client';

import { CreateTicketMutationVariables } from '@/__generated__';
import { TypedTypePolicies } from '@/__generated__/apolloHelpers';

const typePolicies: TypedTypePolicies = {
  DomainExternalReportSettings: {
    keyFields: ['domain', ['id']],
  },
  Query: {
    fields: {
      ticketComments: {
        keyArgs: ['grid', ['filter', 'sort']],
        merge(existing = { rows: [] }, incoming, options) {
          if (incoming.total === null) return incoming;
          if (options.variables?.grid.offset === 0) return incoming;

          return { total: incoming.total, rows: [...existing.rows, ...incoming.rows] };
        },
      },
      ticketActivities: {
        keyArgs: ['grid', ['filter', 'sort']],
        merge(existing = { rows: [] }, incoming, options) {
          if (options.variables?.grid.offset === 0) return incoming;

          return { total: incoming.total, rows: [...existing.rows, ...incoming.rows] };
        },
      },
      pageTitle() {
        return pageTitle();
      },
      newEditorContent() {
        return newEditorContent();
      },
      shouldRefetchTicket() {
        return shouldRefetchTicket();
      },
      newCommentUploadingFilesId() {
        return newCommentUploadingFilesId();
      },
      initialCreateTicketData() {
        return initialCreateTicketData();
      },

      isAddingNewComment() {
        return isAddingNewComment();
      },
      showMonitorDrawer() {
        return showMonitorDrawer();
      },
      showGAMInternalAccountDrawer() {
        return showGAMInternalAccountDrawer();
      },
      credentialsTableCellIdToRefetch() {
        return credentialsTableCellIdToRefetch();
      },
      contentHtmlToLoad() {
        return contentHtmlToLoad();
      },
      shouldKickMeOut() {
        return shouldKickMeOut();
      },
      tableDataToCopy() {
        return tableDataToCopy();
      },
      isNewVersionAvailable() {
        return isNewVersionAvailable();
      },
      isEditorSaved() {
        return isEditorSaved();
      },
      team: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Team',
            id: args?.id,
          });
        },
      },
      user: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'User',
            id: args?.id,
          });
        },
      },
      project: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Project',
            id: args?.id,
          });
        },
      },
      s4sInstance: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'S4sInstance',
            id: args?.id,
          });
        },
      },
      domain: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Domain',
            id: args?.id,
          });
        },
      },
    },
  },
};

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies,
});

export const pageTitle = makeVar<string | null>(null);
export const newEditorContent = makeVar<Record<string, unknown> | null>(null);
export const shouldRefetchTicket = makeVar<boolean>(false);
export const newCommentUploadingFilesId = makeVar<string | null>(null);
export const initialCreateTicketData = makeVar<Partial<CreateTicketMutationVariables> | null>(null);
export const isAddingNewComment = makeVar<boolean>(false);
export const showMonitorDrawer = makeVar<boolean>(false);
export const showGAMInternalAccountDrawer = makeVar<boolean>(false);
export const credentialsTableCellIdToRefetch = makeVar<string | null>(null);
export const contentHtmlToLoad = makeVar<string | null>(null);
export const shouldKickMeOut = makeVar<Record<string, unknown> | null>(null);
export const tableDataToCopy = makeVar<string | boolean>(false);
export const isNewVersionAvailable = makeVar<boolean>(false);
export const isEditorSaved = makeVar<boolean>(false);
