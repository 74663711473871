import styled from 'styled-components';

import { Colors } from '@/theme/styled';

const getBackgroundByStatus = (colors: Colors, status?: 'active' | 'inactive' | 'wl') => {
  if (status === 'active') return colors.lightGreen;
  else if (status === 'inactive') return colors.warningRed;
  else if (status === 'wl') return colors.darkOrange;
  else return colors.darkGrey;
};

const getClipByStatus = (status?: 'active' | 'inactive' | 'wl') => {
  if (status === 'active')
    return 'polygon(0 0, 100% 0, 100% calc(100% - 12px), 40% 100%, 0 calc(100% - 9px))';
  else if (status === 'inactive')
    return 'polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 100%);';
  else if (status === 'wl')
    return 'polygon(0 0, 100% 0, 100% calc(100% - 6px), 50% 100%, 0 calc(100% - 6px))';
  else return 'polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%)';
};

export const RibbonWrapper = styled.div`
  width: 20px;
  height: 30px;
`;

export const RibbonContent = styled.div<{ type?: 'active' | 'inactive' | 'wl' }>`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 3px;
  background: ${({ type, theme: { colors } }) => getBackgroundByStatus(colors, type)};
  clip-path: ${({ type }) => getClipByStatus(type)};
  filter: drop-shadow(2px 3px 2px rgba(black, 0.5));
`;

export const Text = styled.span`
  font-size: 10px;
  font-weight: bold;
  font-family: cursive;
`;
