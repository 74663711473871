import { gql } from '@apollo/client';

import { TEAM_IN_THE_LIST_FRAGMENT } from '../fragments';

export const TEAM_LIST = gql`
  query teamList($grid: TeamGridInput) {
    teams(grid: $grid) {
      total
      rows {
        ...teamInTheList
      }
    }
  }
  ${TEAM_IN_THE_LIST_FRAGMENT}
`;
