import { useQuery } from '@apollo/client';

import { ILinkStatus } from '@/consts';
import { theme } from '@/theme';
import { queries } from '@/services';
import { LinkStatusesQuery, LinkStatusEnum, LinkStatusesQueryVariables } from '@/__generated__';

const statusesColors: Record<LinkStatusEnum, string> = {
  ['LINK_NEW']: theme.colors.green,
  ['LINK_IN_PROGRESS']: theme.colors.fadedBlue,
  ['LINK_DONE']: theme.colors.madisonBlue,
  ['LINK_INVALID']: theme.colors.rejectionRed,
};

export const useLinkStatuses = () => {
  const { data, loading, error } = useQuery<LinkStatusesQuery, LinkStatusesQueryVariables>(
    queries.LINK_STATUSES,
  );

  const linkStatusesWithColors = data?.linkStatuses?.map(linkStatus => ({
    ...linkStatus,
    color: statusesColors[linkStatus.key] as string,
  }));

  return {
    data: linkStatusesWithColors as ILinkStatus[],
    loading,
    error,
  };
};
