import { gql } from '@apollo/client';

import { LINK_IN_THE_LIST_FRAGMENT } from '../fragments';

export const LINK_LIST = gql`
  query linkList($grid: LinkGridInput) {
    links(grid: $grid) {
      total
      rows {
        ...linkInTheList
      }
    }
  }
  ${LINK_IN_THE_LIST_FRAGMENT}
`;
