import { gql } from '@apollo/client';

import { LINK_FRAGMENT } from '../fragments';

export const LINK = gql`
  query link($id: ID!) {
    link(id: $id) {
      ...link
    }
  }
  ${LINK_FRAGMENT}
`;
