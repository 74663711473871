import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../fragments';

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $role: String
    $permissions: [PermissionEnum!]
    $password: String
    $crmImportId: Int
    $appLocale: LocaleEnum
  ) {
    updateUser(
      id: $id
      userInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        role: $role
        permissions: $permissions
        password: $password
        crmImportId: $crmImportId
        appLocale: $appLocale
      }
    ) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
