import { gql } from '@apollo/client';

import { TICKET_TYPE_FRAGMENT } from '../fragments';

export const ADD_TICKET_TYPE = gql`
  mutation addTicketType($value: String!, $projectId: ID!, $color: ColorPaletteEnum!) {
    addTicketType(value: $value, projectId: $projectId, color: $color) {
      ...type
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;
