import { EyeFilled, DeleteFilled, EditOutlined } from '@ant-design/icons';

import { theme } from '@/theme';

import { Button } from '../../Buttons';

import { Wrapper } from './styled';

interface IOptionsCell<T> {
  payload: T;
  onViewClick?: ((props: T) => void) | null;
  onEditClick?: ((props: T) => void) | null;
  onDeleteClick?: ((props: T) => void) | null;
}

export const OptionsCell = <T extends { id: string }>(props: IOptionsCell<T>) => {
  const { payload, onEditClick, onViewClick, onDeleteClick } = props;

  const handleViewClick = () => {
    !!onViewClick && onViewClick(payload);
  };

  const handleEditClick = () => {
    !!onEditClick && onEditClick(payload);
  };

  const handleDeleteClick = () => {
    !!onDeleteClick && onDeleteClick(payload);
  };

  return (
    <Wrapper>
      {!!onViewClick && (
        <Button
          onClick={handleViewClick}
          icon={<EyeFilled style={{ color: theme.colors.white }} />}
          type="primary"
          size="small"
          data-testid="btn-view-option"
          style={{ margin: '0 5px' }}
        />
      )}
      {!!onEditClick && (
        <Button
          onClick={handleEditClick}
          icon={<EditOutlined style={{ color: theme.colors.white }} />}
          type="primary"
          size="small"
          data-testid="btn-edit-option"
          style={{ margin: '0 5px' }}
        />
      )}
      {!!onDeleteClick && (
        <Button
          onClick={handleDeleteClick}
          icon={<DeleteFilled style={{ color: theme.colors.white }} />}
          type="primary"
          size="small"
          data-testid="btn-delete-option"
          style={{ margin: '0 5px' }}
          danger
        />
      )}
    </Wrapper>
  );
};
