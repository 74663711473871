import { gql } from '@apollo/client';

export const SYNC_USER_PERMISSIONS = gql`
  mutation syncUserPermissions(
    $userId: String!
    $role: String!
    $directPermissions: [PermissionEnum!]!
  ) {
    syncUserPermissions(userId: $userId, role: $role, directPermissions: $directPermissions) {
      permissions
      user {
        id
      }
      role
    }
  }
`;
