import { gql } from '@apollo/client';

export const LINKBUILDING_STRATEGIES = gql`
  query linkbuildingStrategies {
    linkbuildingStrategies {
      id
      key
      value
    }
  }
`;
