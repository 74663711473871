import { gql } from '@apollo/client';

export const CONTENT_IMPORTANCE_PRIORITIES = gql`
  query contentImportancePriorities {
    contentImportancePriorities {
      id
      key
      value
    }
  }
`;
