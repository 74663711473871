import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { EditorState, LexicalEditor as TLexicalEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import dayjs from 'dayjs';

import { useDomainNames, useNotify } from '@/hooks';
import { ILogTimeRef, LogTimeForm } from '@/scenes/Tickets/scenes/TicketView/shared/LogTimeForm';
import { DatePicker } from '@/components';

import { Select } from '../Selects';
import { LexicalEditor } from '../LexicalEditor';

import { LogTimeLabel, LogTimeWrapper, Wrapper } from './styled';

export interface IDomainNoteForm {
  domain: string | undefined;
  type:
    | 'seo'
    | 'google_ads'
    | 'fb_ads'
    | 'seoads'
    | 'linkedin_ads'
    | 'amazon_ads'
    | 'allegro_ads'
    | 'tiktok_ads';
  content: string | null;
  date?: string;
  totalMinutesLog: number;
}

export interface IDomainNoteRef {
  validateForm: () => boolean;
  getFormValues: () => IDomainNoteForm;
}

export const DomainNoteForm = forwardRef((props, ref) => {
  const intl = useIntl();
  const location = useLocation();
  const logTimeFormRef = useRef<ILogTimeRef>(null);
  const { notifyError } = useNotify();

  const defaultDomainId = useMemo(() => {
    const domainRE = new RegExp(/^\/domains\/?.*\//g);

    return domainRE.test(location.pathname) ? (location.pathname.split('/')[2] as string) : null;
  }, [location.pathname]);

  const [form, setForm] = useState<IDomainNoteForm>({
    domain: undefined,
    type: (localStorage.getItem('quickNoteType') as IDomainNoteForm['type']) || undefined,
    content: null,
    totalMinutesLog: 0,
  });

  const handleUpdateForm = (label: keyof IDomainNoteForm, value: string) => {
    setForm(prev => ({ ...prev, [label]: value }));
  };

  const { data: domainNames, loading: domainNamesLoading } = useDomainNames({
    grid: {
      limit: 100000,
      filter: {
        status: {
          value: ['active'],
        },
      },
    },
  });

  const formattedDomainNames = useMemo(() => {
    if (!domainNames) return [];

    if (defaultDomainId && domainNames.find(({ id }: { id: string }) => id === defaultDomainId))
      handleUpdateForm('domain', defaultDomainId);

    return domainNames.map(({ id, name }: { id: string; name: string }) => ({ id, value: name }));
  }, [domainNames]);

  useImperativeHandle(ref, () => ({
    validateForm() {
      const timeLog = logTimeFormRef?.current?.getLogTime();
      const totalMinutesLog = timeLog ? timeLog.minutes + timeLog.hours * 60 : 0;

      if (totalMinutesLog <= 0 || !form.domain || !form.type) {
        notifyError(intl.formatMessage({ defaultMessage: 'Uzupełnij wszystkie pola' }));
        return false;
      }

      return true;
    },
    getFormValues(): IDomainNoteForm {
      const timeLog = logTimeFormRef?.current?.getLogTime();
      const totalMinutesLog = timeLog ? timeLog.minutes + timeLog.hours * 60 : 0;

      return { ...form, totalMinutesLog };
    },
  }));

  const onChange = (lexicalEditor: TLexicalEditor, editorState: EditorState) => {
    const htmlString = $generateHtmlFromNodes(lexicalEditor);
    handleUpdateForm('content', htmlString);
  };

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    handleUpdateForm('date', dateString);
  };

  return (
    <Wrapper>
      <Select
        value={form.domain}
        onChange={domain => handleUpdateForm('domain', domain)}
        options={formattedDomainNames}
        loading={domainNamesLoading}
        placeholder={intl.formatMessage({ defaultMessage: 'Domena' })}
        autoFocus
      />
      <Select
        value={form.type}
        onChange={type => {
          localStorage.setItem('quickNoteType', type);
          handleUpdateForm('type', type);
        }}
        options={[
          { id: 'seo', value: 'SEO' },
          { id: 'google_ads', value: 'Google Ads' },
          { id: 'fb_ads', value: 'Facebook Ads' },
          { id: 'seoads', value: 'SEOAds' },
          { id: 'linkedin_ads', value: 'LinkedIn Ads' },
          { id: 'amazon_ads', value: 'Amazon Ads' },
          { id: 'allegro_ads', value: 'Allegro Ads' },
          { id: 'tiktok_ads', value: 'TikTok Ads' },
        ]}
        placeholder={intl.formatMessage({ defaultMessage: 'Typ notatki' })}
      />
      <DatePicker
        allowClear={false}
        onChange={onChangeDate}
        defaultValue={dayjs()}
        picker="month"
      />
      <LexicalEditor onChange={onChange} isEditing={true} />
      <LogTimeWrapper>
        <LogTimeLabel>{intl.formatMessage({ defaultMessage: 'Czas pracy' })}</LogTimeLabel>
        <LogTimeForm ref={logTimeFormRef} />
      </LogTimeWrapper>
    </Wrapper>
  );
});

DomainNoteForm.displayName = 'DomainNoteForm';
