import { FC } from 'react';
import { useIntl } from 'react-intl';

import { UserBasicFragment } from '@/__generated__';

import { UserItem } from '../UserItem';

import { Wrapper, Label } from './styled';

interface ITicketOwner {
  ticketOwner: UserBasicFragment;
}

export const TicketOwner: FC<ITicketOwner> = ({ ticketOwner }) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Label>
        {intl.formatMessage({
          id: 'scenes.Tickets.additionalTicketFeatures.owner',
          defaultMessage: 'Zlecone przez',
        })}
      </Label>
      <UserItem user={ticketOwner} style={{ padding: '10px' }} withStar isLink />
    </Wrapper>
  );
};
