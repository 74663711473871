import { gql } from '@apollo/client';

import { TICKET_PRIORITY_FRAGMENT } from '../fragments';

export const ADD_TICKET_PRIORITY = gql`
  mutation addTicketPriority($value: String!, $color: ColorPaletteEnum!) {
    addTicketPriority(value: $value, color: $color) {
      ...priority
    }
  }
  ${TICKET_PRIORITY_FRAGMENT}
`;
