import { gql } from '@apollo/client';

import { TICKET_WITH_RELATED_TICKETS_FRAGMENT } from '../fragments';

export const TICKET_WITH_RELATED_TICKETS = gql`
  query ticketWithRelatedTickets($id: ID!) {
    ticket(id: $id) {
      ...ticketWithRelatedTickets
    }
  }

  ${TICKET_WITH_RELATED_TICKETS_FRAGMENT}
`;
