import { gql } from '@apollo/client';

import { S4S_INSTANCE_FRAGMENT } from '../fragments';

export const CREATE_S4S_INSTANCE = gql`
  mutation createS4sInstance(
    $name: String!
    $host: String!
    $port: Int!
    $user: String!
    $password: String!
    $db: String!
    $prefix: String!
  ) {
    createS4sInstance(
      name: $name
      host: $host
      port: $port
      user: $user
      password: $password
      db: $db
      prefix: $prefix
    ) {
      ...s4sInstance
    }
  }
  ${S4S_INSTANCE_FRAGMENT}
`;
