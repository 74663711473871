import { gql } from '@apollo/client';

import { TEAM_FRAGMENT } from '../fragments';

export const TEAM = gql`
  query team($id: ID!) {
    team(id: $id) {
      ...team
    }
  }
  ${TEAM_FRAGMENT}
`;
