import { FC, ReactNode } from 'react';
import { ErrorMessage, FieldProps } from 'formik';
import dayjs, { Dayjs } from 'dayjs';

import { formatDateForBE } from '@/utils';

import { Date } from '../../Form';
import { ErrorWrapper, FieldWrapper, Label } from '../styled';

interface IDateSelectField extends FieldProps {
  label: ReactNode;
  isDateDisabled?: (current: Dayjs | null, name: string) => boolean;
  defaultPickerValue?: Dayjs;
}

export const DateSelectField: FC<IDateSelectField> = props => {
  const { field, form, label, isDateDisabled, defaultPickerValue, ...rest } = props;

  const dayjsValue = !!field.value ? dayjs(field.value) : null;

  const handleDateChange = (date: Dayjs) => {
    const formattedDate = date ? formatDateForBE(date) : null;

    form.setFieldValue(field.name, formattedDate);
  };

  /**
   * Must return
   */
  const disabledDate = (current: Dayjs | null): boolean | Dayjs =>
    !!isDateDisabled ? isDateDisabled(current, field.name) : false;

  return (
    <FieldWrapper>
      {label && <Label>{label}</Label>}
      <Date
        onChange={handleDateChange}
        disabledDate={disabledDate}
        value={dayjsValue}
        data-testid={`${field.name}-select`}
        format={'DD MMM YYYY'}
        defaultPickerValue={defaultPickerValue ? dayjs(defaultPickerValue) : undefined}
        {...rest}
      />
      <ErrorMessage {...field} component={ErrorWrapper} />
    </FieldWrapper>
  );
};
