import { gql } from '@apollo/client';

import { CMS_FRAGMENT } from '../fragments';

export const CREATE_CMS_ITEM = gql`
  mutation createCMSItem($value: String!) {
    createCMSItem(value: $value) {
      ...cms
    }
  }
  ${CMS_FRAGMENT}
`;
