import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  ApolloCache,
  ApolloError,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from '@apollo/client';

import { Loader } from '@/loaders';
import { mutations, queries } from '@/services';
import {
  ActiveUserQuery,
  ActiveUserQueryVariables,
  LoginUserMutation,
  LoginUserMutationVariables,
  LogoutUserMutation,
  LogoutUserMutationVariables,
  UserFragment,
} from '@/__generated__';

/**
 * Context
 */
interface IAuthContext {
  login: (
    options?:
      | MutationFunctionOptions<
          LoginUserMutation,
          LoginUserMutationVariables,
          DefaultContext,
          ApolloCache<unknown>
        >
      | undefined,
  ) => Promise<FetchResult<LoginUserMutation>>;
  logout: () => void;
  refetchActiveUser: () => Promise<ApolloQueryResult<ActiveUserQuery>>;
  data: {
    user: UserFragment | null;
  };
  loginIsLoading: boolean;
  loginError: ApolloError | undefined;
}

interface IAuthProviderProps {
  children: ReactNode;
}

const POLL_INTERVAL = 1000 * 60 * 5;
const AuthContext = createContext<IAuthContext | undefined>(undefined);

/**
 * Provider
 */
const AuthProvider = (props: IAuthProviderProps) => {
  const {
    data: activeUserData,
    loading: loadingActiveUser,
    refetch: refetchActiveUser,
  } = useQuery<ActiveUserQuery, ActiveUserQueryVariables>(queries.ACTIVE_USER, {
    pollInterval: POLL_INTERVAL,
  });

  const [login, { loading: loginIsLoading, error: loginError, client }] = useMutation<
    LoginUserMutation,
    LoginUserMutationVariables
  >(mutations.LOGIN_USER, {
    refetchQueries: [{ query: queries.ACTIVE_USER }],
  });

  const clearStore = async () => {
    try {
      return await client.clearStore();
    } catch (e: unknown) {
      return e;
    }
  };

  const handleLogout = () => {
    refetchActiveUser().finally(() => {
      location.assign(location.hostname);
      location.reload();
      clearStore();
    });
  };

  const [logout] = useMutation<LogoutUserMutation, LogoutUserMutationVariables>(
    mutations.LOGOUT_USER,
    {
      onCompleted: handleLogout,
      onError: handleLogout,
    },
  );

  const user = useMemo(() => activeUserData?.activeUser || null, [activeUserData]);

  const value = useMemo(
    () => ({
      data: { user },
      login,
      loginIsLoading,
      loginError,
      logout,
      refetchActiveUser,
    }),
    [user, loginIsLoading, loginError],
  );

  if (loadingActiveUser) return <Loader wrapperHeight="100vh" />;

  return <AuthContext.Provider value={value} {...props} />;
};

/**
 * Hook
 */
const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
