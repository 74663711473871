import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const SYNC_PROJECT_STATUSES = gql`
  mutation syncProjectStatuses($projectId: ID!, $statusIds: [ID!]!) {
    syncProjectStatuses(projectId: $projectId, statusIds: $statusIds) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
