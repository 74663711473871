import { Paths } from '@/consts';

export const ticketUrlFromId = (id: string) => `${Paths.TICKETS}/${id}/view`;
export const ticketCommentUrlFromIds = (ticketId: string, commentId: string) =>
  `${ticketUrlFromId(ticketId)}/comments#comment-${commentId}`;
export const teamUrlFromId = (id: string) => `${Paths.TEAMS}/${id}/view`;
export const userInfoUrlFromId = (id: string) => `${Paths.USERS}/${id}/overview/info`;
export const userSettingsUrlFromId = (id: string) => `${Paths.USERS}/${id}/overview/settings`;
export const userPermissionsUrlFromId = (id: string) => `${Paths.USERS}/${id}/overview/permissions`;
export const domainInfoUrlFromId = (id: string) => `${Paths.DOMAINS}/${id}/info`;
export const domainTicketsUrlFromId = (id: string) => `${Paths.DOMAINS}/${id}/tickets`;
export const domainLinkbuildingUrlFromId = (id: string) => `${Paths.DOMAINS}/${id}/linkbuilding`;
export const orderIpressUrlFromId = (domainId: string) =>
  `${domainLinkbuildingUrlFromId(domainId)}/order-ipress`;
export const domainMonitoringUrlFromId = (id: string) => `${Paths.DOMAINS}/${id}/monitoring`;
