import { FC } from 'react';

import { Container, Left, Right } from './styled';

interface ILeftRightItem {
  leftRenderer: () => any;
  rightRenderer: () => any;
  className?: string;
}

export const LeftRightItem: FC<ILeftRightItem> = ({ leftRenderer, rightRenderer, className }) => {
  return (
    <Container className={className}>
      <Left>{leftRenderer()}</Left>

      <Right>{rightRenderer()}</Right>
    </Container>
  );
};
