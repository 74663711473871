import { useIntl } from 'react-intl';

import { GlobalSearchQuery } from '@/__generated__';

import { address } from '../../helpers';
import { SearchCategories } from '../../consts';

export const useNormalizeSearchData = () => {
  const intl = useIntl();

  const normalizeSearchData = (data: GlobalSearchQuery) => {
    if (!data) return null;

    const results = [];

    if (!!data?.domains?.rows?.length) {
      const domains = data?.domains?.rows?.map(
        (item: { id: string; name: string }, index: number) => ({
          id: item.id,
          title: item.name,
          link: address(SearchCategories.DOMAINS, item.id),
          itemKey: index,
        }),
      );

      results.push({
        key: 'domains',
        groupTitle: intl.formatMessage({ defaultMessage: 'Domeny' }),
        hits: domains,
      });
    }

    if (!!data?.users?.rows?.length) {
      const initLength = results?.[0]?.hits?.length ?? 0;

      const users = data?.users?.rows?.map(
        (item: { id: string; first_name: string; last_name: string }, index: number) => ({
          id: item.id,
          title: `${item.first_name} ${item.last_name}`,
          link: address(SearchCategories.USERS, item.id),
          itemKey: initLength + index,
        }),
      );

      results.push({
        key: 'users',
        groupTitle: intl.formatMessage({ defaultMessage: 'Pracownicy' }),
        hits: users,
      });
    }

    if (!!data?.tickets?.rows?.length) {
      const initLength = (results?.[0]?.hits?.length ?? 0) + (results?.[1]?.hits?.length ?? 0);

      const tickets = data?.tickets?.rows?.map(
        (item: { id: string; title: string }, index: number) => ({
          id: item.id,
          title: item.title,
          link: address(SearchCategories.TICKETS, item.id),
          itemKey: initLength + index,
        }),
      );

      results.push({
        key: 'tickets',
        groupTitle: intl.formatMessage({ defaultMessage: 'Tickety' }),
        hits: tickets,
      });
    }

    if (!!data?.domainsContacts?.rows?.length) {
      const initLength =
        (results?.[0]?.hits?.length ?? 0) +
        (results?.[1]?.hits?.length ?? 0) +
        (results?.[2]?.hits?.length ?? 0);

      const domainHits = results.find(({ key }) => key === 'domains')?.hits;

      const domainsContacts = data?.domainsContacts?.rows?.reduce(
        (
          domainsContactsHits: any,
          item: { id: string; domain: { id: string; name: string } },
          index: number,
        ) => {
          if (domainHits?.find(({ title }) => title === item.domain.name))
            return domainsContactsHits;

          if (domainsContactsHits?.find(({ title }) => title === item.domain.name))
            return domainsContactsHits;

          return domainsContactsHits.concat({
            id: item.id,
            title: item.domain.name,
            link: address(SearchCategories.DOMAINS, item.domain.id),
            itemKey: initLength + index,
          });
        },
        [],
      );

      results.push({
        key: 'domainsContacts',
        groupTitle: intl.formatMessage({ defaultMessage: 'Kontakty domen' }),
        hits: domainsContacts,
      });
    }

    return results;
  };

  return normalizeSearchData;
};
