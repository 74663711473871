import { FetchResult, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import {
  LogTicketTimeMutation,
  LogTicketTimeMutationVariables,
  TicketQuery,
  TicketTimeLogsQuery,
} from '@/__generated__';
import { mutations, queries } from '@/services';
import { useCurrentUser } from '@/hooks';

interface IHandleLogTicketTime {
  variables: LogTicketTimeMutationVariables;
}

type IUseLogTicketTime = [
  (props: IHandleLogTicketTime) => Promise<FetchResult<LogTicketTimeMutation>>,
];

export const useLogTicketTime = (): IUseLogTicketTime => {
  const [logTicketTime] = useMutation<LogTicketTimeMutation, LogTicketTimeMutationVariables>(
    mutations.LOG_TICKET_TIME,
  );

  const currentUser = useCurrentUser();

  const handleLogTicketTime = ({ variables }: IHandleLogTicketTime) => {
    const createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

    return logTicketTime({
      variables,
      optimisticResponse: {
        __typename: 'Mutation',
        logTicketTime: {
          __typename: 'TimeLog',
          minutes: variables.minutes,
          user: currentUser,
          id: createdAt,
          created_at: createdAt,
        },
      },
      update: (proxy, { data }) => {
        if (!data) return;

        const ticketId = variables.ticketId;

        const ticketQuery = {
          query: queries.TICKET,
          variables: { id: ticketId },
        };

        const cacheTicket: TicketQuery | null = proxy.readQuery(ticketQuery);

        if (!cacheTicket) return;

        const totalTimeLogs = cacheTicket.ticket.total_time_logs ?? 0;

        const newTime = totalTimeLogs + variables.minutes;

        proxy.writeQuery({
          ...ticketQuery,
          data: {
            ...cacheTicket,
            ticket: { ...cacheTicket.ticket, total_time_logs: newTime },
          },
        });

        const timeLogsQuery = {
          query: queries.TICKET_TIME_LOGS,
          variables: { grid: { filter: { ticketId: { value: [ticketId] } } } },
        };

        const cacheTimeLogs: TicketTimeLogsQuery | null = proxy.readQuery(timeLogsQuery);

        if (!cacheTimeLogs) return;

        const newRows = [data.logTicketTime, ...cacheTimeLogs.timeLogs.rows];

        proxy.writeQuery({
          ...timeLogsQuery,
          data: {
            ...cacheTimeLogs,
            timeLogs: { ...cacheTimeLogs.timeLogs, rows: newRows },
          },
        });
      },
    });
  };

  return [handleLogTicketTime];
};
