import { gql } from '@apollo/client';

export const LANGUAGE_CODES = gql`
  query languageCodes {
    languageCodes {
      id
      key
      value
    }
  }
`;
