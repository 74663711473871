import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const CREDENTIAL_FRAGMENT = gql`
  fragment credential on Credential {
    id
    name
    updatedBy {
      ...userBasic
    }
    updated_at
  }
  ${USER_BASIC_FRAGMENT}
`;
