import axios from 'axios';

import { UPLOAD_IMAGE, UPLOAD_IMAGES } from '@/consts';

const upload = async (fileData: File | File[], url: string) => {
  const data = new FormData();

  if (Array.isArray(fileData)) {
    fileData.forEach((file: File) => {
      data.append('files[]', file);
    });
  } else {
    data.append('file', fileData);
  }

  return axios({
    url: `${API_URL}${url}`,
    method: 'POST',
    withCredentials: true,
    data,
  });
};

export const uploadImage = async (file: File) => {
  return upload(file, UPLOAD_IMAGE);
};

export const uploadImages = async (files: File[]) => {
  return upload(files, UPLOAD_IMAGES);
};
