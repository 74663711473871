import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { CardFilterOptions } from '@/consts';
import { UserStatisticsResponse } from '@/__generated__';

import { TicketCard } from '../TicketCard';

import { i18nMessages } from './consts';

import {
  BoxInDiskIcon,
  CalendarInDiskIcon,
  CardsWrapper,
  CursorInDiskIcon,
  DomainsIcon,
  ExclMarkInDiskIcon,
  Header,
  StarInDiskIcon,
  SummaryContainer,
} from './styled';

interface ITicketsSummary {
  handleCardClick?: (key: keyof UserStatisticsResponse | CardFilterOptions) => void;
  numberOfTickets: (key: keyof UserStatisticsResponse | CardFilterOptions) => number;
  title?: string;
  containerPadding?: string | null;
  smallCards?: boolean;
  showDomains?: boolean;
}

export const TicketsSummary: FC<ITicketsSummary> = ({
  handleCardClick,
  numberOfTickets,
  title = null,
  containerPadding = null,
  smallCards = false,
  showDomains = false,
}) => {
  const intl = useIntl();
  const { OPEN, OVERDUE, TODAY, NEXT_7_DAYS, CLOSED, DOMAINS } = CardFilterOptions;

  const ticketCards: {
    key: keyof UserStatisticsResponse | CardFilterOptions;
    title: string;
    icon: ReactNode;
  }[] = [
    {
      key: OPEN,
      title: intl.formatMessage(i18nMessages.open),
      icon: CursorInDiskIcon,
    },
    {
      key: CLOSED,
      title: intl.formatMessage(i18nMessages.closed),
      icon: BoxInDiskIcon,
    },
    {
      key: OVERDUE,
      title: intl.formatMessage(i18nMessages.overdue),
      icon: ExclMarkInDiskIcon,
    },
    {
      key: TODAY,
      title: intl.formatMessage(i18nMessages.today),
      icon: StarInDiskIcon,
    },
    {
      key: NEXT_7_DAYS,
      title: intl.formatMessage(i18nMessages.thisWeek),
      icon: CalendarInDiskIcon,
    },
    ...(showDomains
      ? [
          {
            key: DOMAINS,
            title: intl.formatMessage(i18nMessages.domains),
            icon: DomainsIcon,
          },
        ]
      : []),
  ];

  const padding = containerPadding ?? '30px 40px';

  return (
    <SummaryContainer padding={padding}>
      <Header>{title ?? intl.formatMessage(i18nMessages.title)}</Header>
      <CardsWrapper data-testid="tickets-sumary-cards-wrapper" smallCards={smallCards}>
        {ticketCards.map(({ key, ...props }) => (
          <TicketCard
            key={key}
            cardKey={key}
            handleCardClick={handleCardClick}
            number={numberOfTickets(key)}
            smallCards={smallCards}
            {...props}
          />
        ))}
      </CardsWrapper>
    </SummaryContainer>
  );
};
