import { CSSProperties, FC, ReactNode } from 'react';
import { ErrorMessage, FieldProps } from 'formik';

import { UserBasicFragment } from '@/__generated__';

import { AlwaysEmptyUserSelect } from '../../Selects';
import { ErrorWrapper, FieldWrapper, Label } from '../styled';

interface IAlwaysEmptyUserSelectField extends FieldProps {
  label?: ReactNode;
  users: UserBasicFragment[];
  style?: CSSProperties;
}

export const AlwaysEmptyUserSelectField: FC<IAlwaysEmptyUserSelectField> = ({
  field,
  form,
  label,
  users,
  style,
  ...props
}) => (
  <FieldWrapper style={style}>
    {!!label && <Label>{label}</Label>}
    <AlwaysEmptyUserSelect
      onChange={(value: string) => {
        form.setFieldValue(field.name, value);
      }}
      value={field.value || null}
      users={users}
      dataTestId={`${field.name}-select`}
      {...props}
    />
    <ErrorMessage {...field} component={ErrorWrapper} data-testid={`${field.name}-error`} />
  </FieldWrapper>
);
