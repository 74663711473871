import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  font-size: ${({ theme: { font } }) => font.size.medium};
`;
