import { gql } from '@apollo/client';

import { TICKET_SUBTASK_FRAGMENT } from '../fragments';

export const ADD_TICKET_SUBTASK = gql`
  mutation addTicketSubtask(
    $ticketId: ID!
    $title: String!
    $priority: String!
    $type: String!
    $status: String!
    $project: String!
  ) {
    addTicketSubtask(
      ticketId: $ticketId
      title: $title
      priority: $priority
      type: $type
      status: $status
      project: $project
    ) {
      ...ticketSubtask
    }
  }
  ${TICKET_SUBTASK_FRAGMENT}
`;
