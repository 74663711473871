import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

export const Container = styled.div`
  margin-bottom: 10px;
`;
