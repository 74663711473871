import { gql } from '@apollo/client';

/**
 * Do not use positionsOrdered here as it can be used in groupedPhrases only
 */
export const UNGROUP_PHRASES = gql`
  mutation ungroupPhrases($ids: [String!]!) {
    ungroupPhrases(ids: $ids) {
      id
    }
  }
`;
