import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useCurrentUser, useUser } from '@/hooks';
import { teamUrlFromId } from '@/utils';
import { TeamBasicFragment } from '@/__generated__';

import { CollapseCard } from '../CollapseCard';
import { ErrorMessage } from '../ErrorMessage';

import { Name, NameWrapper, NoTeams } from './styled';

export const UserTeamsCard = () => {
  const { id: userIdFromParams }: { id: string | undefined } = useParams();
  const intl = useIntl();
  const activeUser = useCurrentUser();
  const { data: userData, loading, error } = useUser(userIdFromParams);

  const userTeams = () => {
    const user = userIdFromParams ? userData : activeUser;

    return !!user?.teams?.length ? (
      user.teams.map((team: TeamBasicFragment) => (
        <NameWrapper key={team.id}>
          <Name to={teamUrlFromId(team.id)}>{team.name}</Name>
        </NameWrapper>
      ))
    ) : (
      <NoTeams>
        {intl.formatMessage({
          id: 'components.UserTeamsCard.NoTeams',
          defaultMessage: 'Brak przypisania do zespołów',
        })}
      </NoTeams>
    );
  };

  const getUserTeams = () => (!loading ? userTeams() : null);

  return (
    <CollapseCard
      loading={loading}
      items={[
        {
          key: '1',
          children: !!error ? <ErrorMessage error={error} /> : getUserTeams(),
          label: intl.formatMessage({
            id: 'components.UserTeamsCard.teamsList.header',
            defaultMessage: 'Lista zespołów',
          }),
        },
      ]}
    />
  );
};
