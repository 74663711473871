import { gql } from '@apollo/client';

export const TEXT_ORDER_ORDER_TYPES = gql`
  query textOrderOrderTypes {
    textOrderOrderTypes {
      id
      key
      value
    }
  }
`;
