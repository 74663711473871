import { gql } from '@apollo/client';

import { S4S_INSTANCE_FRAGMENT } from '../fragments';

export const UPDATE_S4S_INSTANCE = gql`
  mutation updateS4sInstance(
    $id: String!
    $name: String
    $host: String
    $port: Int
    $user: String
    $password: String
    $db: String
    $prefix: String
  ) {
    updateS4sInstance(
      id: $id
      name: $name
      host: $host
      port: $port
      user: $user
      password: $password
      db: $db
      prefix: $prefix
    ) {
      ...s4sInstance
    }
  }
  ${S4S_INSTANCE_FRAGMENT}
`;
