import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../fragments';

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $role: String
    $permissions: [PermissionEnum!]
  ) {
    createUser(
      userInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        role: $role
        permissions: $permissions
      }
    ) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
