import { gql } from '@apollo/client';

export const S4S_INSTANCE_FRAGMENT = gql`
  fragment s4sInstance on S4sInstance {
    id
    name
    host
    port
    prefix
    db
    phrases
    domains {
      id
      name
    }
  }
`;
