import { gql } from '@apollo/client';

import { QUARTER_OVERRIDE_FRAGMENT } from '../fragments';

export const UPDATE_QUARTER_OVERRIDE = gql`
  mutation updateQuarterOverride(
    $id: ID!
    $startDate: DateTime
    $endDate: DateTime
    $type: QuarterTypeEnum
    $charactersToCreate: Int
  ) {
    updateQuarterOverride(
      id: $id
      startDate: $startDate
      endDate: $endDate
      type: $type
      charactersToCreate: $charactersToCreate
    ) {
      ...quarterOverride
    }
  }
  ${QUARTER_OVERRIDE_FRAGMENT}
`;
