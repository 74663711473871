import { ApolloProvider as Provider } from '@apollo/client';
import { ReactNode } from 'react';

import { client } from '@/client';

interface IApolloProvider {
  children: ReactNode;
}

export const ApolloProvider = ({ children, ...rest }: IApolloProvider) => (
  <Provider client={client} {...rest}>
    {children}
  </Provider>
);
