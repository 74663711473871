import { useEffect } from 'react';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import { useMarkNotification } from '@/hooks';
import { log } from '@/utils';
import { queries } from '@/services';
import { UserNotificationsQuery, UserNotificationsQueryVariables } from '@/__generated__';

import { handleFetchedNotifications } from './utils';
import { notificationsPopupFilters } from './consts';

export const useGlobalNotifications = () => {
  const [handleMarkNotification] = useMarkNotification();

  const markNotificationRead = (notificationId: string) => {
    handleMarkNotification({ notificationId });
  };

  const [userNotifications, { data, loading, error, refetch }] = useLazyQuery<
    UserNotificationsQuery,
    UserNotificationsQueryVariables
  >(queries.USER_NOTIFICATIONS, {
    variables: { grid: notificationsPopupFilters },
    notifyOnNetworkStatusChange: true,
  });

  const fetchNotifications = () => {
    userNotifications()
      .then(res => {
        try {
          const rows = res?.data?.userNotifications?.rows;

          if (!rows) {
            throw new Error('No userNotifications.rows field');
          }

          handleFetchedNotifications({ rows, markNotificationRead });
        } catch (e: unknown) {
          log(e);
        }
      })
      .catch(err => {
        log(err);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      userNotifications();
    }, 3000);

    const interval = setInterval(fetchNotifications, 300000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: get(data, `userNotifications`, null),
    loading,
    error,
    refetch,
  };
};
