import { gql } from '@apollo/client';

import { LINK_BASIC_FRAGMENT } from './linkBasic';
import { LINK_ANCHOR_BASIC_FRAGMENT } from './linkAnchorBasic';
import { DOMAIN_BASIC_FRAGMENT } from './domainBasic';

export const LINK_IN_THE_LIST_FRAGMENT = gql`
  fragment linkInTheList on Link {
    ...linkBasic
    group
    status
    price
    article_url
    real_publication_date
    planned_publication_date
    note
    anchor {
      ...linkAnchorBasic
    }
    domain {
      ...domainBasic
    }
  }
  ${LINK_BASIC_FRAGMENT}
  ${LINK_ANCHOR_BASIC_FRAGMENT}
  ${DOMAIN_BASIC_FRAGMENT}
`;
