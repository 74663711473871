import { gql } from '@apollo/client';

import { TICKET_COMMENT_FRAGMENT } from '../fragments';

export const TICKET_COMMENTS = gql`
  query ticketComments($grid: TicketCommentsGridInput) {
    ticketComments(grid: $grid) {
      total
      rows {
        ...ticketComment
      }
    }
  }
  ${TICKET_COMMENT_FRAGMENT}
`;
