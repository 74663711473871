import { SortDirections } from '@/consts';
import { NotificationGridInput } from '@/__generated__';

/**
 * It has a 'type' filter to not fetch 'ticketSummary'
 */
export const notificationsPopupFilters: NotificationGridInput = {
  limit: 5,
  sort: {
    field: 'created_at',
    dir: SortDirections.DESC,
  },
  filter: {
    read: {
      value: false,
    },
    type: {
      value: ['summary'],
      operator: 'NEQ',
    },
  },
};
