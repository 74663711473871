import styled from 'styled-components';

export const Control = styled.span`
  height: 30px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const HTMLBox = styled.div`
  padding-top: 10px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
  max-height: 60vh;
  min-height: 200px;
  overflow: auto;
  background:
    /* Shadow Cover TOP */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))
      center top,
    /* Shadow BOTTOM */
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size:
    100% 20px,
    100% 20px,
    100% 10px,
    100% 10px;
  background-attachment: local, local, scroll, scroll;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
