import { useUser } from '@/context/UserProvider';

/**
 * To be used only in AuthenticatedApp
 */
export const useCurrentUser = () => {
  const user = useUser();

  if (!user) throw new Error('useCurrentUser hook used outside AuthenticatedApp!');

  return user;
};
