import { ErrorInfo, FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { Container } from './styled';

interface ErrorComponent {
  error?: Error | null;
  errorInfo?: ErrorInfo;
  boundaryName?: string;
}

/**
 * This component serves as a fallback component for Sentry.ErrorBoundary
 * and ErrorBoundary.
 */
export const ErrorComponent: FC<PropsWithChildren<ErrorComponent>> = ({ children }) => {
  const intl = useIntl();

  return (
    <Container>
      <p>
        {intl.formatMessage({
          id: 'components.ErrorBoundary.title',
          defaultMessage: 'Nastąpił błąd. Przepraszamy.',
        })}
      </p>
      <p>
        {intl.formatMessage({
          id: 'components.ErrorBoundary.steps',
          defaultMessage: 'Raport o błędzie został wysłany.',
        })}
      </p>
      <p>
        {intl.formatMessage({
          id: 'components.ErrorBoundary.contact',
          defaultMessage: 'Jeśli błąd będzie się powtarzał, skontaktuj się z administratorem.',
        })}
      </p>
      {children}
    </Container>
  );
};
