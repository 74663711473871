import { gql } from '@apollo/client';

import { MONITOR_FRAGMENT } from '../fragments';

export const CREATE_MONITOR = gql`
  mutation createMonitor($domainId: String!, $url: String!) {
    createMonitor(domainId: $domainId, url: $url) {
      ...monitor
    }
  }
  ${MONITOR_FRAGMENT}
`;
