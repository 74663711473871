import styled from 'styled-components';

import { PdfFile, ImageFile, TextFile, File } from '../Icon';

export const PdfFileIcon = styled<{ height: number } | any>(PdfFile)`
  height: ${({ height }) => height}px;
  fill: ${({ theme: { colors } }) => colors.notifRed};
`;

export const ImageFileIcon = styled<{ height: number } | any>(ImageFile)`
  height: ${({ height }) => height}px;
  fill: ${({ theme: { colors } }) => colors.readyGreen};
`;

export const TextFileIcon = styled<{ height: number } | any>(TextFile)`
  height: ${({ height }) => height}px;
  fill: ${({ theme: { colors } }) => colors.lightBlue};
`;

export const AnyFileIcon = styled<{ height: number } | any>(File)`
  height: ${({ height }) => height}px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;
