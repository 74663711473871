import { ComponentProps, FC, PropsWithChildren } from 'react';

import { UserItem } from '../UserItem';

import { PersonContainer } from './styled';

export const PersonItemWithAction: FC<PropsWithChildren<ComponentProps<typeof UserItem>>> = ({
  children = null,
  ...props
}) => (
  <PersonContainer>
    <UserItem {...props} />
    {children}
  </PersonContainer>
);
