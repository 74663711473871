import { gql } from '@apollo/client';

export const TIME_STATS_FRAGMENT = gql`
  fragment timeStats on TimeLog {
    id
    minutes
    created_at
    user_id
    project_id
    project_name
  }
`;
