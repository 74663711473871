import { gql } from '@apollo/client';

export const LANGUAGES_EXTERNAL_REPORT = gql`
  query languagesExternalReport {
    languagesExternalReport {
      id
      key
      value
    }
  }
`;
