import { gql } from '@apollo/client';

export const SET_TEMPORARY_GA_ACCESS = gql`
  mutation setTemporaryGAAccess($domainId: String!) {
    setTemporaryGAAccess(domainId: $domainId) {
      user {
        id
        first_name
        last_name
      }
      __typename
    }
  }
`;
