import { useLazyQuery, QueryTuple, ApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';

import { queries } from '@/services';
import { GlobalSearchQuery, GlobalSearchQueryVariables } from '@/__generated__';
import { useNotify } from '@/hooks';

export const useLazyGlobalSearch = (): QueryTuple<
  GlobalSearchQuery,
  GlobalSearchQueryVariables
> => {
  const intl = useIntl();
  const { notifyError } = useNotify();

  const handleError = (err: ApolloError) => {
    notifyError(intl.formatMessage({ defaultMessage: 'Nastąpił błąd podczas wyszukiwania' }), err);
  };

  const [globalSearch, result] = useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    queries.GLOBAL_SEARCH,
    {
      fetchPolicy: 'network-only',
      onError: handleError,
    },
  );

  return [globalSearch, result];
};
