import { KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';

import { commandOrControl } from '@/utils';
import { useDeviceLayout } from '@/hooks';

import { SearchState } from '../../consts';

import { Container, Keybinding, Text, Key, SearchIcon } from './styled';

interface ISearchButton {
  setState: (props: SearchState) => void;
}

export const SearchButton = (props: ISearchButton) => {
  const intl = useIntl();
  const { isMobileLayout } = useDeviceLayout();

  const { setState } = props;

  const onClick = () => {
    setState({ type: 'mounted' });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();

      setState({ type: 'mounted' });
    }
  };

  return (
    <Container
      type="text"
      onClick={onClick}
      onKeyDown={onKeyDown}
      id="global-search"
      data-testid="global-search"
    >
      <SearchIcon />

      <Text>{intl.formatMessage({ defaultMessage: 'Szukaj' })}</Text>
      {!isMobileLayout && (
        <Keybinding>
          <Key>{commandOrControl()}</Key>
          <Key>K</Key>
        </Keybinding>
      )}
    </Container>
  );
};
