import { FC } from 'react';

import { ProjectMetaObject } from '@/services/types';

import { MetaItem } from '../MetaItem';

interface IAvailableItems {
  handleEditMenu: (item: ProjectMetaObject) => void;
  handleSyncMetaObjects: (itemId: string, isChecked: boolean) => void;
  availableItems: ProjectMetaObject[];
  isSync: boolean;
  canEdit: boolean;
}

export const AvailableItems: FC<IAvailableItems> = ({
  availableItems,
  isSync,
  canEdit,
  handleSyncMetaObjects,
  handleEditMenu,
}) => (
  <>
    {availableItems.map((item: ProjectMetaObject) => (
      <MetaItem
        handleEditMenu={handleEditMenu}
        handleSyncMetaObjects={handleSyncMetaObjects}
        key={item.id}
        item={item}
        isSync={isSync}
        canEdit={canEdit}
      />
    ))}
  </>
);
