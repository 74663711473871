import { Empty } from 'antd';
import { useIntl } from 'react-intl';

import { Skeleton } from '../../../Skeleton';
import { ErrorMessage } from '../../../ErrorMessage';
import { IHit, SearchState, IHitsGroup, CursorState } from '../../consts';

import { SearchHit } from './components';

import { ContentContainer, Wrapper, Title, Section, List } from './styled';

interface ISearchResults {
  setCursor: (props: CursorState) => void;
  setState: (props: SearchState) => void;
  state: SearchState;
  cursor: CursorState;
}

export const SearchResults = (props: ISearchResults) => {
  const intl = useIntl();

  const { state, cursor, setCursor, setState } = props;

  const renderHit = (hit: IHit) => {
    const lastSearchedValue = state.type === 'found' ? state.lastSearchedValue : '';

    return (
      <SearchHit
        key={hit.itemKey}
        setCursor={setCursor}
        setState={setState}
        lastSearchedValue={lastSearchedValue}
        cursor={cursor}
        {...hit}
      />
    );
  };

  const renderResults = (hitGroup: IHitsGroup) => {
    const { groupTitle, key, hits } = hitGroup;

    return (
      <Section key={key}>
        <Title>{groupTitle}</Title>
        <List role="listbox">{hits.map(renderHit)}</List>
      </Section>
    );
  };

  const renderEmpty = () => {
    return (
      <Empty
        description={intl.formatMessage({ defaultMessage: 'Brak wyników' })}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  };

  const content = () => {
    if (state.type === 'mounted')
      return (
        <Wrapper>
          <Title>
            {intl.formatMessage({ defaultMessage: 'Wpisz wyszukiwaną frazę w polu powyżej' })}
          </Title>
        </Wrapper>
      );

    if (state.type === 'loading') return <Skeleton />;

    if (state.type === 'not-found') return renderEmpty();

    if (state.type === 'error') return <ErrorMessage error={state.error} />;

    if (state.type === 'found') return state.results.map(renderResults);
  };

  if (state.type === 'closed') return null;

  return (
    <ContentContainer>
      <div>{content()}</div>
    </ContentContainer>
  );
};
