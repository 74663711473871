import { gql } from '@apollo/client';

import { TICKET_FRAGMENT } from '../fragments';

export const CREATE_TICKET = gql`
  mutation createTicket(
    $title: String!
    $contentHtml: String
    $priorityId: ID!
    $typeId: ID!
    $statusId: ID!
    $projectId: ID!
    $assignedUserId: ID
    $deadline: DateTime
    $domainId: ID
    $start: DateTime
    $watchers: [ID!]
    $todos: [TodoInput!]
    $ownerTicketId: ID
    $sendingToClientTextOrders: [ID!]
    $implementationTextOrders: [ID!]
  ) {
    createTicket(
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      typeId: $typeId
      statusId: $statusId
      projectId: $projectId
      assignedUserId: $assignedUserId
      deadline: $deadline
      domainId: $domainId
      start: $start
      watchers: $watchers
      todos: $todos
      ownerTicketId: $ownerTicketId
      sendingToClientTextOrders: $sendingToClientTextOrders
      implementationTextOrders: $implementationTextOrders
    ) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
