import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const SYNC_PROJECT_PRIORITIES = gql`
  mutation syncProjectPriorities($projectId: ID!, $priorityIds: [ID!]!) {
    syncProjectPriorities(projectId: $projectId, priorityIds: $priorityIds) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
