import { Dayjs } from 'dayjs';

import { TimeStatsFragment } from '@/__generated__';

interface IRow {
  key: string;
  projectName: string;
  total: number;

  [key: string]: number;
}

interface ITotalsRow {
  total: number;
  key: string;

  [key: string]: number;
}

export const timeStatsData = (timeLogs: TimeStatsFragment[]) => {
  if (!timeLogs) return { rows: [], totalRow: {} };

  const obj: Record<string, IRow[]> = {};

  const monthsTotals: ITotalsRow = { total: 0, key: 'totals' };

  timeLogs.forEach(timeLog => {
    const projectId = timeLog?.project_id;
    const userId = timeLog?.user_id;

    if (!projectId || !userId) return;

    const createdAtMonth = timeLog.created_at.slice(0, 7).split('-').reverse().join('-');

    const minutes = timeLog.minutes;

    const isMonthInTotals = createdAtMonth in monthsTotals;

    if (!isMonthInTotals) {
      monthsTotals[createdAtMonth] = 0;
    }

    monthsTotals[createdAtMonth] += minutes;
    monthsTotals.total += minutes;

    const isInObj = projectId in obj;

    if (!isInObj) {
      obj[projectId] = {
        key: projectId,
        projectName: timeLog.project_name,
        [createdAtMonth]: minutes,
        total: minutes,
      };

      return;
    }

    const currentObj = obj[projectId];

    if (!currentObj) return;

    const currentMonthTime = createdAtMonth in currentObj ? currentObj[createdAtMonth] : 0;
    const newMonthTime = currentMonthTime + minutes;
    const newProjectTotal = currentObj.total + minutes;

    obj[projectId] = {
      ...currentObj,
      [createdAtMonth]: newMonthTime,
      total: newProjectTotal,
    };
  });

  return { rows: Object.values(obj), totalsRow: monthsTotals };
};

export const timeStatsMonths = (rangeValues: [Dayjs, Dayjs]) => {
  const difference = rangeValues[1].diff(rangeValues[0], 'month');

  const months = [];

  for (let i = 0; i <= difference; i++) {
    const formatted = rangeValues[0].add(i, 'month').format('MM-YYYY');

    months.push(formatted);
  }

  return months;
};
