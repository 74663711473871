import { defineMessages } from 'react-intl';

/**
 * i18n Messages
 */
export const i18nMessages = defineMessages({
  accountName: {
    defaultMessage: 'Nazwa konta',
  },
  countGA: {
    defaultMessage: 'Ilość GA',
  },
  countGSC: {
    defaultMessage: 'Ilość GSC',
  },
  options: {
    defaultMessage: 'Opcje',
  },
});

export interface IHandleMenuClick {
  key: DropdownMenuKeys;
  id: string;
  name: string | null | undefined;
}

export enum DropdownMenuKeys {
  EDIT = 'edit',
  REMOVE = 'remove',
  REFRESH_TOKEN = 'refreshToken',
  REGENERATE_TOKEN = 'regenerateToken',
}
