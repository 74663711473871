import { createContext, Key } from 'react';

import { PhrasesDrawerState } from '@/consts';
import { GroupedPhrases, PhraseGroup, PhraseGridInput } from '@/__generated__';

import { UpdateGrid, ViewMode } from './consts';

interface IMonitoringContext {
  updateGrid: (key: UpdateGrid, value: any) => void;
  updatePhraseGroupAssignment: (props: {
    onSuccess: () => void;
    onError: () => void;
    groupId: string;
  }) => void;
  setSelectedRows: (props: Key[]) => void;
  handleUpdatePhraseGroup: (id: string, value: string) => void;
  changePhrasesGroup: () => void;
  deletePhraseGroup: (id: string) => void;
  refetchGroupedPhrases: () => void;
  setDrawerState: (props: PhrasesDrawerState) => void;
  drawerState: PhrasesDrawerState;
  phraseGroups: PhraseGroup[];
  grid: PhraseGridInput;
  selectedRows: Key[];
  loadingGroupedPhrases: boolean;
  refetchingGroupedPhrases: boolean;
  loadingMutation: boolean;
  groupedPhrases: GroupedPhrases[] | never[];
  phraseCount: { max: number; current: number };
  removedPhrasesVisibility: boolean;
  toggleRemovedPhrasesVisibility: () => void;
  isDeleteButtonDisabled: boolean;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
}

export const MonitoringContext = createContext<IMonitoringContext | undefined>(undefined);
