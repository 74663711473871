import { gql } from '@apollo/client';

import { PHRASE_GROUP_FRAGMENT } from '../fragments';

export const UPDATE_PHRASE_GROUP = gql`
  mutation updatePhraseGroup($groupId: String!, $name: String, $phraseIds: [String!]) {
    updatePhraseGroup(groupId: $groupId, name: $name, phraseIds: $phraseIds) {
      ...phraseGroup
    }
  }
  ${PHRASE_GROUP_FRAGMENT}
`;
