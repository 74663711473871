import styled from 'styled-components';
import { Card as CardAntd } from 'antd';

export const ItemCard = styled<{ $maxheight: string } | any>(CardAntd)`
  border-radius: ${({ theme: { border } }) => border.radius.big};
  border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

  .ant-card-body {
    max-height: ${({ $maxheight }) => $maxheight};
    padding: 0;
    overflow: auto;
  }

  .ant-card-head {
    margin-bottom: 0;
    border: 1px solid ${({ theme: { colors } }) => colors.borderGrey};

    .ant-card-head-wrapper {
      min-height: 72px;
    }
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListTitle = styled.div`
  color: ${({ theme: { colors } }) => colors.darkBlue};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
`;

export const DropBox = styled.div<{ $highlight: boolean }>`
  border: 1px solid
    ${({ theme: { colors }, $highlight }) => ($highlight ? colors.lightBlue : colors.borderGrey)};
  transition: all 0.2s ease;
`;
