import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

export const ButtonWithoutStyle = styled<{ round: string; brightness: number } | any>(ButtonAntd)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  border-radius: ${({ round }) => round};
  transition: all 0.2s ease-in-out;

  :active,
  :focus {
    border: none;
    background-color: transparent;
  }

  :hover {
    border: none;
    background-color: transparent;
    filter: brightness(${({ brightness }) => brightness}%);
  }

  svg {
    transition: all 0.2s ease-in-out;

    :hover {
      filter: brightness(${({ brightness }) => brightness}%);
    }
  }
`;
