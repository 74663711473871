import { Locale, Languages } from '@/consts';
import { LocaleEnum } from '@/__generated__';

import translations from './translations';
import { supportedLocales, supportedLanguages, antdLocales, defaultLanguage } from './config';

export default (userLocale: LocaleEnum | undefined, defaultLocale: Locale) => {
  const appLocale = () => {
    if (!!userLocale) {
      return userLocale.replace('_', '-');
    }

    if (!!localStorage.getItem('appLocale')) {
      const localeFromLS = localStorage.getItem('appLocale');

      if (supportedLocales.includes(localeFromLS)) {
        return localeFromLS;
      }
    }

    const navigatorLocale = window.navigator.language;

    if (supportedLocales.includes(navigatorLocale)) {
      return navigatorLocale;
    }
  };

  const locale = (appLocale() ?? defaultLocale) as Locale;

  const detectedLang = locale.split('-')[0] as Languages;
  const delectedLocale = locale.split('-')[1] as keyof typeof antdLocales;
  const antdLocale = antdLocales[delectedLocale];

  const lang = ((supportedLanguages.includes(detectedLang) && detectedLang) ||
    defaultLanguage) as string;
  const trs: { [index: string]: any } = translations;
  const messages = trs[lang];

  return {
    locale,
    lang,
    messages,
    antdLocale,
  };
};
