import { gql } from '@apollo/client';

import { EXTERNAL_REPORT_SETTINGS_FRAGMENT } from '../fragments';

export const UPDATE_EXTERNAL_REPORT_SETTINGS = gql`
  mutation updateExternalReportSettings(
    $id: ID!
    $sendEmail: Boolean
    $company: String
    $template: String
    $language: LanguageCodeEnum
    $country: CountryEnum
    $reportEmails: [String!]
    $reportLanguages: [String!]
    $brandedKeywords: [String!]
    $excludedKeywords: [String!]
    $ecommerce: Boolean
    $gamGaServiceId: String
    $gamSearchConsoleSiteId: String
    $gamGaServiceAudienceId: String
    $googleAdsAccountId: String
    $googleAdsReportLanguages: [String!]
    $googleAdsReportEmails: [String!]
    $googleAdsSendEmail: Boolean
    $startDate: String
  ) {
    updateExternalReportSettings(
      domainId: $id
      domainExternalReportSettingsInput: {
        sendEmail: $sendEmail
        company: $company
        template: $template
        language: $language
        country: $country
        reportEmails: $reportEmails
        reportLanguages: $reportLanguages
        brandedKeywords: $brandedKeywords
        excludedKeywords: $excludedKeywords
        ecommerce: $ecommerce
        gamGaServiceId: $gamGaServiceId
        gamSearchConsoleSiteId: $gamSearchConsoleSiteId
        gamGaServiceAudienceId: $gamGaServiceAudienceId
        googleAdsAccountId: $googleAdsAccountId
        googleAdsReportLanguages: $googleAdsReportLanguages
        googleAdsReportEmails: $googleAdsReportEmails
        googleAdsSendEmail: $googleAdsSendEmail
        startDate: $startDate
      }
    ) {
      ...externalReportSettings
    }
  }

  ${EXTERNAL_REPORT_SETTINGS_FRAGMENT}
`;
