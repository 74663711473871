import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const ACTIVITY_FRAGMENT = gql`
  fragment activity on Activity {
    id
    event_type
    payload
    created_at
    summary
    target
    user {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
