import { gql } from '@apollo/client';

export const GAMGA_SERVICE_FRAGMENT = gql`
  fragment gamgaService on GAMGAService {
    id
    name
    service_id
    permission_ok
    GAMGAServiceAudiences {
      id
      name
    }
    GAMAccount {
      id
      GAMInternalAccount {
        id
        name
      }
    }
  }
`;
