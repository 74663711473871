import { ThemeProvider as Provider } from 'styled-components';
import { ReactNode } from 'react';

import { theme } from '@/theme';

interface IThemeProvider {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProvider) => (
  <Provider theme={theme}>{children}</Provider>
);
