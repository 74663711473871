import { gql } from '@apollo/client';

import { TICKET_IN_THE_LIST_FRAGMENT } from '../fragments';

export const TICKET_LIST = gql`
  query tickets($grid: TicketGridInput) {
    tickets(grid: $grid) {
      total
      rows {
        ...ticketInTheList
      }
    }
  }
  ${TICKET_IN_THE_LIST_FRAGMENT}
`;
