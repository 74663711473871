import { gql } from '@apollo/client';

export const LSP_TYPES = gql`
  query lspTypes {
    lspTypes {
      id
      value
    }
  }
`;
