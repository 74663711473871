import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { StarFilled } from '../Icon';

export const Details = styled.div<{ $noMargin: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: ${({ $noMargin }) => ($noMargin ? '100%' : 'calc(100% - 55px)')};
  margin-left: ${({ $noMargin }) => ($noMargin ? 0 : '15px')};
`;

const nameStyles = css`
  display: flex;
  align-items: center;
  font-weight: ${({ theme: { font } }) => font.weight.black};
`;

export const Name = styled.p<{ $nameColor: string | null; $fontSize: any }>`
  ${nameStyles}

  margin:0;
  padding: 0;
  max-width: 100%;
  color: ${({ theme: { colors }, $nameColor }) => $nameColor ?? colors.darkBlue};
  font-size: ${({ theme: { font }, $fontSize }) => $fontSize ?? font.size.semiMedium};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`;

export const NameLink = styled(Link)<{ $fontSize: any }>`
  ${nameStyles}

  color: ${({ theme: { colors } }) => colors.lightBlue};
  font-size: ${({ theme: { font }, $fontSize }) => $fontSize ?? font.size.semiMedium};
  transition: all 0.2s ease-in-out;

  :hover {
    color: ${({ theme: { colors } }) => colors.lightBlueHover};
  }
`;

export const StarFilledIcon = styled(StarFilled)`
  height: 12px;
  min-width: 12px;
  margin-left: 8px;
  fill: ${({ theme: { colors } }) => colors.yellow2};
`;
