import styled from 'styled-components';

import { Options, Edit, Trash, Refresh } from '@/components/Icon';

export const ItemOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-color: ${({ theme: { colors } }) => colors.lightGrey};
  border-radius: ${({ theme: { border } }) => border.radius.big};
  cursor: pointer;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.textGrey};
  border-radius: ${({ theme: { border } }) => border.radius.big};
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.textGrey};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};

  svg {
    margin-right: 10px;
  }
`;

export const OptionsIcon = styled(Options)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;

export const EditIcon = styled(Edit)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;

export const TrashIcon = styled(Trash)`
  height: 17px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;

export const RefreshIcon = styled(Refresh)`
  height: 14px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;
