import { matchPath } from 'react-router-dom';

import { IBreadcrumbNameMapItem, TBreadcrumbNameMap } from './types';

export const getMatchedBreadcrumb = (
  url: string,
  breadcrumbNameMap: TBreadcrumbNameMap,
): IBreadcrumbNameMapItem | undefined => {
  return breadcrumbNameMap.find(breadcrumb => {
    const matchArray = breadcrumb.paths.map(path => {
      return !!matchPath(url, { path, exact: true, strict: true });
    });

    return matchArray.some(match => match);
  });
};
