import { gql } from '@apollo/client';

import { TIME_LOG_FRAGMENT } from '../fragments';

export const LOG_TICKET_TIME = gql`
  mutation logTicketTime($ticketId: ID!, $minutes: Int!) {
    logTicketTime(ticketId: $ticketId, minutes: $minutes) {
      ...timeLog
    }
  }
  ${TIME_LOG_FRAGMENT}
`;
