import { FC } from 'react';
import { Skeleton as SkeletonAntd } from 'antd';
import { SkeletonProps } from 'antd/lib/skeleton';

import { Wrapper } from './styled';

export interface ISkeleton extends SkeletonProps {
  showAvatar?: boolean;
  hideTitle?: boolean;
  active?: boolean;
  rows?: number;
  padding?: string;
  height?: string;
  width?: string;
  alignItems?: string;
}

export const Skeleton: FC<ISkeleton> = ({
  rows = 4,
  padding = '0',
  width = '100%',
  height = '100%',
  showAvatar = false,
  hideTitle = false,
  alignItems = 'center',
  active = true,
  ...props
}) => (
  <Wrapper $padding={padding} $width={width} $height={height} $position={alignItems}>
    <SkeletonAntd
      avatar={showAvatar ? true : false}
      title={hideTitle ? false : true}
      paragraph={{ rows: rows }}
      active={active}
      {...props}
    />
  </Wrapper>
);
