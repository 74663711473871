import styled from 'styled-components';
import { Input as InputAntd } from 'antd';

import { SearchGlass } from '../Icon';

export const Input = styled(InputAntd)`
  display: flex;
  height: 35px;
  color: ${({ theme: { colors } }) => colors.textGrey};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  border-radius: 5px;
`;

export const SearchGlassIcon = styled(SearchGlass)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;
