const oneMinuteInSeconds = 60;

const normalizeTime = (num: number) => (num < 10 ? `0${num}` : num);

const minutesToTime = (minutes: number) =>
  `${normalizeTime(Math.floor(minutes / oneMinuteInSeconds))}:${normalizeTime(
    minutes % oneMinuteInSeconds,
  )}`;

export const renderTime = (text: unknown) =>
  typeof text === 'number' ? <>{minutesToTime(text)}</> : <>{text}</>;

export const monthColumns = (months: string[]) =>
  months.map(month => ({ title: month, dataIndex: month, align: 'center', render: renderTime }));
