import { gql } from '@apollo/client';

export const TEXT_ORDER_GUIDELINE_FRAGMENT = gql`
  fragment textOrderGuideline on TextOrderGuideline {
    id
    guidelines
    text_type
    text_type_name
  }
`;
