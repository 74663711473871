import { defineMessages } from 'react-intl';

import { theme } from '@/theme';

/**
 * i18n Messages
 */
export const i18nMessages = defineMessages({
  shortText: {
    id: 'Copywriting.shortText',
    defaultMessage: 'Krótki tekst',
  },
  longText: {
    id: 'Copywriting.longText',
    defaultMessage: 'Długi tekst',
  },
  metaDescription: {
    id: 'Copywriting.metaDescription',
    defaultMessage: 'Meta desc',
  },
  boldKeywords: {
    id: 'Copywriting.boldKeywords',
    defaultMessage: 'Pogrubione słowa kluczowe',
  },
  baseOnUrl: {
    id: 'Copywriting.baseOnUrl',
    defaultMessage: 'Bazuj na URL',
  },
  headers: {
    id: 'Copywriting.headers',
    defaultMessage: 'Nagłówki',
  },
});

export const statusColors = {
  NEW: { backgroundColor: '#aa5499', textColor: theme.colors.lightGrey },
  READY_TO_WRITE: { backgroundColor: '#2ea4ed', textColor: theme.colors.lightGrey },
  TO_BE_VERIFIED: { backgroundColor: '#ffbb3f', textColor: theme.colors.darkBlue },
  READY_TO_SEND: { backgroundColor: '#4caf50', textColor: theme.colors.lightGrey },
  SENT_TO_CUSTOMER: { backgroundColor: '#404160', textColor: theme.colors.lightGrey },
  ACCEPTED_BY_CLIENT: { backgroundColor: '#e5f5fc', textColor: theme.colors.darkBlue },
  TO_BE_CORRECTED: { backgroundColor: '#ff3f3f', textColor: theme.colors.lightGrey },
  IMPLEMENTED: { backgroundColor: '#3f3f3f', textColor: theme.colors.lightGrey },
  INCORRECT_ORDER: { backgroundColor: '#feff3f', textColor: theme.colors.darkBlue },
  CANCELLED: { backgroundColor: '#969696', textColor: theme.colors.lightGrey },
} as const;
