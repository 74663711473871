import { gql } from '@apollo/client';

import { DOMAIN_ADS_DEPOSIT_FRAGMENT } from '../fragments';

export const DOMAIN_ADS_DEPOSITS = gql`
  query domainAdsDeposits($domainId: ID!) {
    domainAdsDeposits(domainId: $domainId) {
      ...domainAdsDeposit
    }
  }
  ${DOMAIN_ADS_DEPOSIT_FRAGMENT}
`;
