import { useIntl } from 'react-intl';

import { ListInfo, Pagination, PaginationWrapper } from './styled';

interface IListPagination {
  onPageSizeChange: (page: number, pageSize: number | undefined) => void;
  onPageChange: (currentPage: number, pageSize: number | undefined) => void;
  totalItems: number;
  pageSize: number;
  currentPage: number;
  listInfoText?: string;
}

/* Must be stateless */
export const ListPagination = ({
  onPageChange,
  onPageSizeChange,
  totalItems,
  pageSize,
  currentPage,
  listInfoText,
}: IListPagination) => {
  const intl = useIntl();

  const getLowerRange = () => (!!totalItems ? currentPage * pageSize - pageSize + 1 : 0);

  const getUpperRange = () =>
    pageSize * currentPage > totalItems ? totalItems : pageSize * currentPage;

  const pageSizeOptions = ['10', '20', '50', '100', '200', '500'];

  return (
    <PaginationWrapper withInfo={!!listInfoText}>
      {!!listInfoText && (
        <ListInfo>
          {listInfoText}{' '}
          {!!totalItems ? (
            <>
              {getLowerRange()} - {getUpperRange()}{' '}
              {intl.formatMessage({
                id: 'components.ListPagination.footer.outOf',
                defaultMessage: 'z',
              })}{' '}
              {totalItems}
            </>
          ) : (
            <>
              -{' '}
              {intl.formatMessage({
                id: 'components.ListPagination.footer.noResults',
                defaultMessage: 'Brak wyników',
              })}
            </>
          )}
        </ListInfo>
      )}
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItems || 1}
        onChange={onPageChange}
        onShowSizeChange={onPageSizeChange}
        pageSizeOptions={pageSizeOptions}
        showSizeChanger
        showLessItems
      />
    </PaginationWrapper>
  );
};
