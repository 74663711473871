import { ChangeEvent, DragEvent, useRef, useState } from 'react';

import { DnDCancelButton, DnDFileInfo, DnDInput, DnDLabel, DnDTooltip, DnDWrapper } from './styled';

export const DnDUpload = ({
  file,
  setFile,
}: {
  file: File;
  setFile: (file: File | null) => void;
}) => {
  const handleClearLeadFile = () => {
    setFile(null);
  };
  const handleChangeFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const _file = evt.target?.files?.[0];

    if (!_file) return;

    setFile(_file);
  };

  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = function (evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.type === 'dragenter' || evt.type === 'dragover') {
      setDragActive(true);
    } else if (evt.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    setDragActive(false);
    if (evt.dataTransfer.files && evt.dataTransfer.files[0]) {
      const _file = evt.dataTransfer?.files?.[0];

      if (!_file) return;

      setFile(_file);
    }
  };

  return (
    <DnDWrapper>
      <DnDInput
        ref={inputRef}
        id="csvFileUpload"
        type="file"
        accept=".csv"
        onChange={handleChangeFile}
      />
      {file && <DnDCancelButton onClick={handleClearLeadFile}>❌</DnDCancelButton>}
      <DnDLabel
        htmlFor="csvFileUpload"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        dragActive={dragActive}
      >
        {file && (
          <DnDFileInfo>
            Uploaded file: {file.name}, {file.size} kb
          </DnDFileInfo>
        )}
        <DnDTooltip>Click here or drag and drop your csv</DnDTooltip>
      </DnDLabel>
    </DnDWrapper>
  );
};
