import { gql } from '@apollo/client';

import { DOMAIN_ADS_BUDGET_FRAGMENT } from '../fragments';

export const DOMAIN_ADS_BUDGETS = gql`
  query domainAdsBudgets($domainId: ID!) {
    domainAdsBudgets(domainId: $domainId) {
      ...domainAdsBudget
    }
  }
  ${DOMAIN_ADS_BUDGET_FRAGMENT}
`;
