export enum Permissions {
  MANAGE_ADMINS = 'manage admins',
  CREATE_USERS = 'create users',
  UPDATE_USERS = 'update users',
  BLOCK_USERS = 'block users',
  IMPERSONATE_USERS = 'impersonate users',
  ASSIGN_ROLES = 'assign roles',
  CREATE_TICKETS = 'create tickets',
  LIST_TICKETS = 'list tickets',
  LIST_TICKETS_WITH_DOMAIN = 'list tickets with domain',
  UPDATE_TICKETS = 'update tickets',
  DELETE_TICKETS = 'delete tickets',
  CREATE_COMPANIES = 'create companies',
  LIST_COMPANIES = 'list companies',
  UPDATE_COMPANIES = 'update companies',
  DELETE_COMPANIES = 'delete companies',
  EDIT_TICKET_COMMENTS = 'edit ticket comments',
  DELETE_TICKET_COMMENTS = 'delete ticket comments',
  EDIT_TICKET_TIME = 'edit ticket time',
  DELETE_TICKET_TIME = 'delete ticket time',
  MANAGE_META_TICKETS = 'manage meta tickets',
  ARCHIVE_PROJECTS = 'archive projects',
  MANAGE_PROJECTS = 'manage projects',
  LIST_PARTNERSHIPS = 'list partnerships',
  SHOW_PARTNERSHIPS = 'show partnerships',
  DELETE_PARTNERSHIPS = 'delete partnerships',
  UPDATE_PARTNERSHIPS = 'update partnerships',
  CREATE_PARTNERSHIPS = 'create partnerships',
  MANAGE_ROLES = 'manage roles',
  RETRIEVE_CREDENTIALS = 'retrieve credentials',
  CREATE_CREDENTIALS = 'create credentials',
  DELETE_CREDENTIALS = 'delete credentials',
  UPDATE_CREDENTIALS = 'update credentials',
  CREATE_CONTACTS = 'create contacts',
  UPDATE_CONTACTS = 'update contacts',
  DELETE_CONTACTS = 'delete contacts',
  LIST_CONTACTS = 'list contacts',
  CREATE_DEALS = 'create deals',
  LIST_DEALS = 'list deals',
  EDIT_DEALS = 'edit deals',
  CREATE_PHRASES = 'create phrases',
  DELETE_PHRASES = 'delete phrases',
  LIST_PHRASES = 'list phrases',
  UPDATE_PHRASES = 'update phrases',
  GROUP_PHRASES = 'group phrases',
  LIST_SERVICES = 'list services',
  CREATE_SERVICES = 'create services',
  UPDATE_SERVICES = 'update services',
  DELETE_SERVICES = 'delete services',
  LIST_USER_ACTIVITIES = 'list user activities',
  CREATE_TEAMS = 'create teams',
  DELETE_TEAMS = 'delete teams',
  UPDATE_TEAMS = 'update teams',
  SHOW_TEAM_STATS = 'show team statistics',
  LIST_TEAMS = 'list teams',
  MANAGE_DOMAINS = 'manage domains',
  RESET_PASSWORD = 'reset password',
  LIST_DOMAINS = 'list domains',
  EDIT_DOMAINS = 'edit domains',
  MANAGE_DOMAIN_USERS = 'manage domain users',
  LIST_BLOCKED_USERS = 'list blocked users',
  LIST_MONITORS = 'list monitors',
  UPDATE_MONITORS = 'update monitors',
  DELETE_MONITORS = 'delete monitors',
  CREATE_MONITORS = 'create monitors',
  LIST_S4S_INSTANCES = 'list s4s instances',
  UPDATE_S4S_INSTANCES = 'update s4s instances',
  DELETE_S4S_INSTANCES = 'delete s4s instances',
  CREATE_S4S_INSTANCES = 'create s4s instances',
  LIST_LINKS = 'list links',
  UPDATE_LINKS = 'update links',
  DELETE_LINKS = 'delete links',
  CREATE_LINKS = 'create links',
  MANAGE_TRANSLATIONS = 'manage translations',
  CREATE_GA_INTERNAL_ACCOUNTS = 'create GA internal accounts',
  UPDATE_GA_INTERNAL_ACCOUNTS = 'update GA internal accounts',
  DELETE_GA_INTERNAL_ACCOUNTS = 'delete GA internal accounts',
  LIST_GA_INTERNAL_ACCOUNTS = 'list GA internal accounts',
  REFRESH_GA_INTERNAL_ACCOUNTS_TOKEN = 'refresh GA internal accounts token',
  FEATURE_PREVIEW = 'feature preview',
  CREATE_CMS = 'create CMS',
  UPDATE_CMS = 'update CMS',
  DELETE_CMS = 'delete CMS',
  LIST_TEXT_ORDERS = 'list text orders',
  UPDATE_TEXT_ORDERS = 'update text orders',
  DELETE_TEXT_ORDERS = 'delete text orders',
  CREATE_TEXT_ORDERS = 'create text orders',
  CREATE_ENUMS = 'create enums',
  UPDATE_ENUMS = 'update enums',
  DELETE_ENUMS = 'delete enums',
  UPDATE_ADS_BUDGETS = 'update ads budgets',
}
