import { gql } from '@apollo/client';

import { DOMAIN_VIEW_FRAGMENT } from '../fragments';

export const DOMAIN_VIEW = gql`
  query domainView($id: ID!) {
    domain(id: $id) {
      ...domainView
    }
  }
  ${DOMAIN_VIEW_FRAGMENT}
`;
