import { gql } from '@apollo/client';

import { CREDENTIAL_LOGIN_FRAGMENT } from '../fragments';

export const CREDENTIAL_LOGIN = gql`
  query credentialLogin($id: ID!) {
    credential(id: $id) {
      ...credentialLogin
    }
  }
  ${CREDENTIAL_LOGIN_FRAGMENT}
`;
