import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const DOMAIN_REPORT_FRAGMENT = gql`
  fragment domainReport on DomainReport {
    id
    user {
      ...userBasic
    }
    report_path
    report_type
    report_type_name
    created_at
  }
  ${USER_BASIC_FRAGMENT}
`;
