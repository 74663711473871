import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const PROJECTS = gql`
  query projects {
    projects {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
