import { ReactNode } from 'react';

import { SentryErrorBoundary } from '../SentryErrorBoundary';

interface IErrorBoundary {
  tagKey: string;
  tagValue: string;
  onError?: () => void;
  children: ReactNode;
}

/**
 * Error boundary wrapper
 */
export const ErrorBoundary = ({ children, ...props }: IErrorBoundary) => (
  <SentryErrorBoundary {...props}>{children}</SentryErrorBoundary>
);
