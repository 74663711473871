import { useIntl } from 'react-intl';
import axios from 'axios';

import { useNotify } from '@/hooks';

interface IFetchAvatar {
  setImage: (file: File) => void;
  userId: string;
  avatarHash: string;
}

type IUseFetchAvatar = [(props: IFetchAvatar) => Promise<void>];

export const useFetchAvatar = (): IUseFetchAvatar => {
  const intl = useIntl();
  const { notifyError } = useNotify();

  const fetchAvatar = async ({ setImage, userId, avatarHash }: IFetchAvatar) => {
    const hash = () => (!!avatarHash ? `?${avatarHash}` : '');
    const currentAvatar = `${API_URL}/user/${userId}/avatar${hash()}`;

    try {
      const res = await axios.get(currentAvatar, {
        responseType: 'blob',
      });

      const blobFile = res.data;
      const imageFile = new File([blobFile], avatarHash, { type: blobFile.type });

      setImage(imageFile);
    } catch (err: unknown) {
      notifyError(
        intl.formatMessage({
          defaultMessage: 'Nastapił błąd przy ładowaniu avatara',
        }),
      );
    }
  };

  return [fetchAvatar];
};
