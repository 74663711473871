import { gql } from '@apollo/client';

import { USER_STATISTICS_DETAILS_FRAGMENT, TEAM_STATISTICS_TOTALS_FRAGMENT } from '../fragments';

export const TEAM_STATISTICS = gql`
  query teamStatistics($id: ID!) {
    teamStatistics(id: $id) {
      id
      totals {
        ...teamStatisticsTotals
      }
      details {
        ...userStatisticsDetails
      }
    }
  }
  ${USER_STATISTICS_DETAILS_FRAGMENT}
  ${TEAM_STATISTICS_TOTALS_FRAGMENT}
`;
