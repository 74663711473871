import { gql } from '@apollo/client';

export const LINK_GROUPS = gql`
  query linkGroups {
    linkGroups {
      key
      id
      value
    }
  }
`;
