import { gql } from '@apollo/client';

export const DOMAIN_CMS = gql`
  query domainCms {
    domainCms {
      id
      value
    }
  }
`;
