import { createContext, ReactNode, useContext, useState } from 'react';

import { ExportDataModal } from '@/components';

/**
 * Context
 */
const ExportDataContext = createContext({
  isModalVisible: false,
  openModal: () => null,
  closeModal: () => null,
});

interface IExportDataProvider {
  children: ReactNode;
}

/**
 * Provider
 */

export type ModalFilters = 'quantity' | 'daterange';
interface IModalOptions {
  isVisible: boolean;
  defaultGrid: any;
  action: 'download' | 'copy' | null;
  smallSizedDataOptions?: {
    apiRoute: string;
    outputFilename: string;
  } | null;
  largeSizedDataOptions?: {
    copyRoute: string;
    downloadRoute: string;
  } | null;
  filters: ModalFilters[] | null;
}

const ExportDataProvider = ({ children }: IExportDataProvider) => {
  const [modalOptions, setModalOptions] = useState<IModalOptions>({
    isVisible: false,
    defaultGrid: {},
    action: null,
    filters: null,
    smallSizedDataOptions: null,
    largeSizedDataOptions: null,
  });
  const openModal = ({ grid, action, filters, smallSizedDataOptions, largeSizedDataOptions }) =>
    setModalOptions({
      defaultGrid: grid,
      isVisible: true,
      action,
      filters,
      smallSizedDataOptions,
      largeSizedDataOptions,
    });
  const closeModal = () =>
    setModalOptions({
      isVisible: false,
      defaultGrid: {},
      action: null,
      filters: null,
      smallSizedDataOptions: null,
      largeSizedDataOptions: null,
    });

  return (
    <ExportDataContext.Provider value={{ openModal, closeModal }}>
      {modalOptions.isVisible && (
        <ExportDataModal
          visible={modalOptions.isVisible}
          closeModal={closeModal}
          defaultGrid={modalOptions.defaultGrid}
          action={modalOptions.action}
          filters={modalOptions.filters}
          smallSizedDataOptions={modalOptions.smallSizedDataOptions}
          largeSizedDataOptions={modalOptions.largeSizedDataOptions}
        />
      )}
      {children}
    </ExportDataContext.Provider>
  );
};

const useExportData = () => {
  const context = useContext(ExportDataContext);

  if (context === undefined) {
    throw new Error('useExportData must be used within a ExportDataProvider');
  }

  return context;
};

export { ExportDataProvider, useExportData };
