import {
  CustomQuickFilters,
  TicketsQuickFilters,
  DomainTicketsQuickFilters,
  CopywritingQuickFilters,
} from './components';
import { FilterType } from './consts';

import { Container, FiltersWrapper } from './styled';

interface IQuickFilter {
  filtersType?: FilterType;
}

export const QuickFilters = (props: IQuickFilter) => {
  const { filtersType = FilterType.TICKETS } = props;

  return (
    <Container>
      <FiltersWrapper data-testid="quckfilters-wrapper">
        {filtersType === FilterType.TICKETS && <TicketsQuickFilters />}
        {filtersType === FilterType.DOMAIN_TICKETS && <DomainTicketsQuickFilters />}
        {filtersType === FilterType.COPYWRITING && <CopywritingQuickFilters />}

        <CustomQuickFilters filtersType={filtersType} />
      </FiltersWrapper>
    </Container>
  );
};
