import { useIntl } from 'react-intl';

import { axiosPost, downloadBlobAsFile } from '@/utils';

import { useNotify } from './notification';

interface IFetchAndExportData {
  payload: any;
  mode: 'download' | 'copy';
  smallSizedDataOptions?: {
    apiRoute: string;
    outputFilename: string;
  } | null;
  largeSizedDataOptions?: {
    copyRoute: string;
    downloadRoute: string;
  } | null;
}

export const useFetchAndExportData = () => {
  const intl = useIntl();
  const { notifySuccess, notifyWarning, notifyError } = useNotify();

  const fetchAndExportData = async ({
    payload,
    mode,
    smallSizedDataOptions,
    largeSizedDataOptions,
  }: IFetchAndExportData) => {
    try {
      const route = (() => {
        if (smallSizedDataOptions) return smallSizedDataOptions.apiRoute;
        else if (largeSizedDataOptions && mode === 'copy') return largeSizedDataOptions.copyRoute;
        return largeSizedDataOptions.downloadRoute;
      })();

      const result = await axiosPost(route, payload);

      if (mode === 'download') {
        if (largeSizedDataOptions) {
          notifyWarning(
            intl.formatMessage({
              defaultMessage: 'Za chwile otrzymasz powiadomienie z linkiem do pobrania danych',
            }),
          );
        } else {
          downloadBlobAsFile(new Blob([result.data]), smallSizedDataOptions?.outputFilename);
          notifySuccess(intl.formatMessage({ defaultMessage: 'Dane zostały pobrane' }));
        }
      } else if (mode === 'copy') {
        const normalizedForSpreadsheet = result.data.replace(/;/g, '\t');
        await navigator.clipboard.writeText(normalizedForSpreadsheet);
        notifySuccess(intl.formatMessage({ defaultMessage: 'Skopiowano do schowka' }));
      }
    } catch (e: unknown) {
      notifyError(
        intl.formatMessage({ defaultMessage: 'Nastąpił błąd podczas pobierania danych.' }),
      );
    }
  };

  return { fetchAndExportData };
};
