export enum Locale {
  PL_PL = 'pl-PL',
  EN_GB = 'en-GB',
}

export enum Languages {
  PL = 'pl',
  EN = 'en',
}

export enum UnderscoreLocales {
  pl_PL = 'pl_PL',
  en_GB = 'en_GB',
}
