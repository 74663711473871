import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { GridSearchParamsTypes } from '@/consts';
import { useUpdateGridSearchParams } from '@/hooks';
import { OperatorEnum, TextOrderStatusEnum, TicketGridFilterInput } from '@/__generated__';

import { Pill } from '../../../Pill';

export const CopywritingQuickFilters = () => {
  const intl = useIntl();
  const location = useLocation();
  const [updateGridSearchParams] = useUpdateGridSearchParams();

  const onClick = (filter: TicketGridFilterInput) => {
    const updatedFilter = { ...filter };

    updateGridSearchParams(
      { type: GridSearchParamsTypes.UPDATE_FILTER, payload: updatedFilter },
      location.pathname,
    );
  };

  const cards = [
    {
      label: intl.formatMessage({ defaultMessage: 'Nowe & Gotowe do pisania' }),
      filter: {
        status: {
          value: [TextOrderStatusEnum.NEW, TextOrderStatusEnum.READY_TO_WRITE],
          operator: OperatorEnum.IN,
        },
      },
      category: 'new_readyToWrite',
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Zakończone' }),
      filter: {
        status: {
          value: [TextOrderStatusEnum.ACCEPTED_BY_CLIENT],
          operator: OperatorEnum.IN,
        },
      },
      category: 'accepted_by_client',
    },
  ];

  return (
    <>
      {cards.map(({ label, filter, category }, index) => (
        <Pill
          key={label + index}
          onClick={() => onClick(filter)}
          data-testid={`quickfilter-${category}`}
        >
          {label}
        </Pill>
      ))}
    </>
  );
};
