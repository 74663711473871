import { gql } from '@apollo/client';

export const TICKET_PRIORITY_FRAGMENT = gql`
  fragment priority on Priority {
    id
    value
    color
    color_value
    default
    priority
    archived
  }
`;
