import {
  CardFilter,
  CardFilterKeys,
  CardFilterOptions,
  FilterOperators,
  ValueOperators,
} from '@/consts';

import { next7Days, todaysDate } from './formatDateTime';

export const getCardFilters = (cardKey: keyof CardFilter) => {
  const { OPEN, OVERDUE, TODAY, NEXT_7_DAYS, CLOSED } = CardFilterOptions;
  const { FILTER_DEADLINE, FITLER_CLOSED } = CardFilterKeys;
  const { TRUE, FALSE } = ValueOperators;
  const { RANGE, LT, EQ } = FilterOperators;

  const filterGrid = {
    [TODAY]: { operator: EQ, value: todaysDate() },
    [NEXT_7_DAYS]: { value: next7Days(), operator: RANGE },
    [OVERDUE]: { operator: LT, value: todaysDate() },
    [OPEN]: { operator: EQ, value: FALSE },
    [CLOSED]: { operator: EQ, value: TRUE },
  };

  const filters = {
    [TODAY]: { [FILTER_DEADLINE]: filterGrid[TODAY], [FITLER_CLOSED]: filterGrid[OPEN] },
    [NEXT_7_DAYS]: {
      [FILTER_DEADLINE]: filterGrid[NEXT_7_DAYS],
      [FITLER_CLOSED]: filterGrid[OPEN],
    },
    [OVERDUE]: { [FILTER_DEADLINE]: filterGrid[OVERDUE], [FITLER_CLOSED]: filterGrid[OPEN] },
    [OPEN]: { [FITLER_CLOSED]: filterGrid[OPEN] },
    [CLOSED]: { [FITLER_CLOSED]: filterGrid[CLOSED] },
  };

  // @ts-ignore
  return filters[cardKey];
};
