import { CSSProperties, ComponentProps, FC, PropsWithChildren } from 'react';

import { Paths, RouteActions, UserOverviewPaths } from '@/consts';
import { UserBasicFragment } from '@/__generated__';

import { UserAvatar } from '../UserAvatar';

import { Details, Name, NameLink, UserWrapper, StarFilledIcon } from './styled';

interface IUserItem {
  avatarOnly?: boolean;
  nameColor?: string;
  isLink?: boolean;
  noAvatar?: boolean;
  fontSize?: string;
  withStar?: boolean;
  user: UserBasicFragment;
  isNarrow?: boolean;
  style?: CSSProperties;
  wrapperProps?: ComponentProps<'div'>;
}

export const UserItem: FC<PropsWithChildren<IUserItem>> = props => {
  const {
    children,
    avatarOnly = false,
    nameColor = null,
    isLink = false,
    noAvatar = false,
    fontSize = null,
    withStar = false,
    isNarrow = false,
    user,
    style,
    wrapperProps = {},
  } = props;

  const username = `${user?.first_name} ${user?.last_name}`;

  const getName = () => (
    <>
      {!!username ? username : children} {withStar && <StarFilledIcon />}
    </>
  );

  const showAvatar = !noAvatar && !isNarrow;

  return (
    <UserWrapper {...wrapperProps} style={style}>
      {showAvatar && <UserAvatar user={user} />}
      {!avatarOnly && (
        <Details $noMargin={!showAvatar}>
          {isLink ? (
            <NameLink
              to={`${Paths.USERS}/${user.id}/${RouteActions.OVERVIEW}/${UserOverviewPaths.INFO}`}
              $fontSize={fontSize}
              data-testid="user-name-link"
            >
              {getName()}
            </NameLink>
          ) : (
            <Name $fontSize={fontSize} data-testid="user-name" $nameColor={nameColor}>
              {getName()}
            </Name>
          )}
        </Details>
      )}
    </UserWrapper>
  );
};
