import { useContext } from 'react';

import { MonitoringContext } from '../contexts';

export const useMonitoringContext = () => {
  const context = useContext(MonitoringContext);

  if (!context) {
    throw new Error('useMonitoringContext must be used within a MonitoringContextProvider');
  }

  return context;
};
