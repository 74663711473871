import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  padding: 0 10px;
  border-radius: 14px;
  min-width: 60px;
  background-color: ${({ theme: { colors } }) => colors.darkBlue};
  color: ${({ theme: { colors } }) => colors.white};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
  font-size: ${({ theme: { font } }) => font.size.medium};
`;
