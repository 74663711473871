import { Tooltip } from 'antd';
import { noop } from 'lodash';

import { theme } from '@/theme';
import { UserBasicFragment } from '@/__generated__';

import { UserAvatar } from '../UserAvatar';

import { AvatarWrapper, DeleteIcon } from './styled';

interface IUserItemAsAvatar {
  deleteCallback?: () => void;
  user: UserBasicFragment;
  size?: number;
  showDelete?: boolean;
}

export const UserItemAsAvatar = ({
  deleteCallback = noop,
  showDelete = true,
  user,
  size = 40,
}: IUserItemAsAvatar) => {
  const { font, colors, border } = theme;

  const tooltipTitle = `${user.first_name} ${user.last_name}`;

  return (
    <Tooltip
      title={tooltipTitle}
      placement="bottomLeft"
      overlayStyle={{
        fontWeight: font.weight.bold,
        fontSize: font.size.semiMedium,
        borderRadius: border.radius.big,
      }}
      key={user.id}
    >
      <AvatarWrapper key={user.id} data-testid={`avatar`} title={tooltipTitle}>
        <UserAvatar size={size} user={user} />
        {showDelete && <DeleteIcon onClick={deleteCallback} data-testid="delete-user" />}
      </AvatarWrapper>
    </Tooltip>
  );
};
