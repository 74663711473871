import { useState, KeyboardEvent, useEffect } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { SearchState, CursorState } from './consts';
import { SearchForm, SearchResults, SearchButton } from './components';

export const GlobalSearch = () => {
  const history = useHistory();

  const [cursor, setCursor] = useState<CursorState>({ key: 0, isArrowed: false });

  const [state, setState] = useState<SearchState>({ type: 'closed' });

  const title = () => <SearchForm setState={setState} />;

  const onCancel = () => {
    setState({ type: 'closed' });
  };

  useEffect(() => {
    if (state.type === 'loading') {
      setCursor({ key: 0, isArrowed: false });
    }
  }, [state.type]);

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (state.type === 'found') {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }

      if (e.key === 'ArrowUp' && cursor.key > 0) {
        setCursor(prev => ({ key: prev.key - 1, isArrowed: true }));

        return;
      }

      const resultsLength = state.results.reduce((prev, group) => {
        const groupLength = group?.hits?.length ?? 0;

        return prev + groupLength;
      }, 0);

      if (e.key === 'ArrowDown' && cursor.key < resultsLength - 1) {
        setCursor(prev => ({ key: prev.key + 1, isArrowed: true }));

        return;
      }

      if (e.key === 'Enter') {
        const found = state.results
          .flatMap(group => group.hits)
          .find(hit => hit.itemKey === cursor.key);

        if (!found) return;

        setState({ type: 'closed' });

        history.push(found.link);

        return;
      }
    }
  };

  return (
    <div>
      <SearchButton setState={setState} />
      <div onKeyDown={onKeyDown} role="none">
        <Modal
          open={state.type !== 'closed'}
          title={title()}
          onCancel={onCancel}
          footer={null}
          closable={false}
          width="47rem"
          destroyOnClose
        >
          <SearchResults setCursor={setCursor} setState={setState} state={state} cursor={cursor} />
        </Modal>
      </div>
    </div>
  );
};
