import { gql } from '@apollo/client';

export const TEAM_STATISTICS_TOTALS_FRAGMENT = gql`
  fragment teamStatisticsTotals on Totals {
    thisWeek
    overdue
    today
    open
    closed
    domains
  }
`;
