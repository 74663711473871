import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useCurrentUser, useImpersonateUser, useIsAuthorised, useUser } from '@/hooks';
import { Permissions } from '@/consts';

import { Button } from '../Buttons';
import { UserAvatar } from '../UserAvatar';
import { Card } from '../Card';
import { Skeleton } from '../Skeleton';
import { ErrorMessage } from '../ErrorMessage';
import { AvatarOverlay } from '../AvatarOverlay';

import { AvatarWrapper, Container, Name, NameWrapper } from './styled';

export const UserAvatarCard = () => {
  const intl = useIntl();
  const { id: userIdFromParams }: { id: string | undefined } = useParams();
  const { isAuthorised } = useIsAuthorised();
  const userData = useUser(userIdFromParams);
  const activeUserData = useCurrentUser();
  const [impersonateUser] = useImpersonateUser();

  const userDataSource = userIdFromParams ? userData.data : activeUserData;

  const nameAndLinks = () => {
    if (!!userData?.error) return <ErrorMessage error={userData?.error} />;

    const { first_name: firstName, last_name: lastName } = userDataSource;

    return (
      <>
        <NameWrapper>
          <Name>
            {firstName} {lastName}
          </Name>
        </NameWrapper>
      </>
    );
  };

  return (
    <Card>
      <Container>
        <AvatarWrapper data-testid="avatar-card-wrapper">
          {userData?.loading ? null : <UserAvatar size={112} user={userDataSource} />}
          {(userIdFromParams === activeUserData?.id || isAuthorised(Permissions.UPDATE_USERS)) && (
            <AvatarOverlay />
          )}
        </AvatarWrapper>

        {userData?.loading ? <Skeleton rows={2} hideTitle /> : nameAndLinks()}
        {activeUserData.allowedPermissions.includes('impersonate users') &&
          userIdFromParams !== activeUserData?.id && (
            <Button
              style={{ marginTop: '10px' }}
              onClick={() => impersonateUser({ userId: userData.data.id })}
              disabled={userData.data.is_impersonated}
            >
              {userData.data.is_impersonated
                ? intl.formatMessage({ defaultMessage: 'Najpierw opuść tryb “Widok jako”' })
                : intl.formatMessage({ defaultMessage: 'Widok jako' })}
            </Button>
          )}
      </Container>
    </Card>
  );
};
