import { gql } from '@apollo/client';

export const DOMAIN_ADS_BUDGET_FRAGMENT = gql`
  fragment domainAdsBudget on DomainAdsBudget {
    id
    domain {
      id
      name
    }
    planned_budget
    spent_budget
    date
    created_at
    updated_at
  }
`;
