import { ChangeEvent, KeyboardEvent, useState, useRef, useEffect, SetStateAction } from 'react';
import { Button, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PlusOutlined } from '@ant-design/icons';

import { log } from '@/utils';
import { useNotify } from '@/hooks';

import { TextInputField } from '../../../../../../../Form';
import { FilterType, IPill } from '../../../../../../consts';
import { Pill } from '../../../../../../../Pill';

import {
  PillContainer,
  PillBox,
  Title,
  Wrapper,
  ButtonWrapper,
  Checkboxes,
  OptionsRow,
  PillWrapper,
} from './styled';

interface IModalContent {
  onSubmit: () => void;
  setPills: (prop: SetStateAction<IPill[]>) => void;
  pills: IPill[];
  filtersType: FilterType;
}

interface IEditPill extends IPill {
  type: 'EDIT';
}

interface INewPill {
  url: string;
  name: string;
  showInAllTickets?: boolean;
  showInDomainTickets?: boolean;
  type: 'ADD';
}

type PillEditorState = INewPill | IEditPill;

export const ModalContent = (props: IModalContent) => {
  const intl = useIntl();
  const { notifyError } = useNotify();
  const { onSubmit, pills, setPills, filtersType } = props;
  const nameInputRef = useRef<HTMLInputElement>(null);

  const isTicketRelated = [FilterType.TICKETS, FilterType.DOMAIN_TICKETS].includes(filtersType);

  const initialState = {
    url: window.location.href,
    name: '',
    type: 'ADD' as const,

    ...(isTicketRelated
      ? {}
      : {
          showInAllTickets: true,
          showInDomainTickets: true,
        }),
  };

  const [state, setState] = useState<PillEditorState>(initialState);
  const [error, setError] = useState('');

  const urlLabel = intl.formatMessage({ defaultMessage: 'URL (tylko LSP)' });
  const nameLabel = intl.formatMessage({ defaultMessage: 'Nazwa' });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error && e.target.name === 'url') setError('');

    setState(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (nameInputRef.current) nameInputRef.current.focus();
  }, []);

  const onUpdateClick = () => {
    if (!state.url || !state.name) return;

    const queryParams = state.url.substring(state.url.indexOf('?'));

    if (!state.url.includes('?')) {
      setError(intl.formatMessage({ defaultMessage: 'Brak parametrów wyszukiwania' }));

      return;
    }

    if (state.type === 'ADD') {
      setPills(prev => [...prev, { ...state, url: queryParams, id: `${Date.now()}`, color: '' }]);
    }

    if (state.type === 'EDIT') {
      const newPills = pills.map(pill => {
        if (pill.id === state.id) return { ...state, url: queryParams };

        return pill;
      });

      setPills(newPills);
    }

    setState(initialState);
  };

  const onDeleteClick = () => {
    if (state.type !== 'EDIT') {
      log('Tried to delete a filter pill in add mode!');

      return;
    }

    const filtered = pills.filter(pill => pill.id !== state.id);

    setPills(filtered);
    setState(initialState);
  };

  const onUrlEnter = () => {
    if (nameInputRef.current) nameInputRef.current.focus();
  };

  const onNameEnter = () => {
    if (!state.url || !state.name) return;

    onUpdateClick();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      onSubmit();

      e.stopPropagation();
    }
  };

  const onPillClick = (pillId: string) => {
    const foundPill = pills.find(pill => pill.id === pillId);

    if (!foundPill) {
      notifyError(intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy wczytywaniu filtra' }));

      return;
    }

    setState({ ...foundPill, type: 'EDIT' });
  };

  const onAddNew = () => {
    setState(initialState);
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setState(prev => ({ ...prev, [e.target.name!]: e.target.checked }));
  };

  const filterKey =
    filtersType === FilterType.DOMAIN_TICKETS ? 'showInDomainTickets' : 'showInAllTickets';

  const isGreyedOut = (pill: IPill) => !pill[filterKey];

  return (
    <div onKeyDown={onKeyDown} role="none">
      <div>
        <Title>
          {state.type === 'ADD'
            ? intl.formatMessage({ defaultMessage: 'Dodaj nowy filtr' })
            : intl.formatMessage({ defaultMessage: 'Aktualizuj filtr' })}
        </Title>
        <Wrapper>
          <TextInputField
            onChange={onChange}
            onPressEnter={onUrlEnter}
            label={urlLabel}
            value={state.url}
            name="url"
            error={error}
          />
        </Wrapper>
        <Wrapper>
          <TextInputField
            onChange={onChange}
            onPressEnter={onNameEnter}
            ref={nameInputRef}
            label={nameLabel}
            value={state.name}
            name="name"
            maxLength={30}
            style={{ height: 'auto' }}
            showCount
          />
        </Wrapper>
        <OptionsRow>
          {isTicketRelated && (
            <Checkboxes>
              <Checkbox
                checked={state.showInAllTickets}
                name="showInAllTickets"
                onChange={onCheckboxChange}
              >
                {intl.formatMessage({ defaultMessage: 'Wszystkie tickety' })}
              </Checkbox>
              <Checkbox
                checked={state.showInDomainTickets}
                name="showInDomainTickets"
                onChange={onCheckboxChange}
              >
                {intl.formatMessage({ defaultMessage: 'Tickety domeny' })}
              </Checkbox>
            </Checkboxes>
          )}
          <ButtonWrapper>
            {state.type === 'EDIT' && (
              <Button type="primary" danger onClick={onDeleteClick} style={{ marginRight: '20px' }}>
                {intl.formatMessage({ defaultMessage: 'Usuń' })}
              </Button>
            )}
            <Button type="primary" onClick={onUpdateClick} disabled={!state.url || !state.name}>
              {state.type === 'EDIT'
                ? intl.formatMessage({ defaultMessage: 'Zmień' })
                : intl.formatMessage({ defaultMessage: 'Dodaj' })}
            </Button>
          </ButtonWrapper>
        </OptionsRow>
      </div>
      <PillContainer>
        <Title>{intl.formatMessage({ defaultMessage: 'Twoje filtry' })}</Title>
        <PillBox>
          {pills.map(pill => (
            <PillWrapper key={pill.id} $highlight={state.type === 'EDIT' && pill.id === state.id}>
              <Pill
                key={pill.id}
                onClick={() => onPillClick(pill.id)}
                {...(isGreyedOut(pill) ? { ghost: true } : {})}
                style={{ margin: 0 }}
              >
                {pill.name}
              </Pill>
            </PillWrapper>
          ))}
          <PillWrapper $highlight={state.type === 'ADD'}>
            <Button
              onClick={onAddNew}
              title={intl.formatMessage({ defaultMessage: 'Nowy' })}
              icon={<PlusOutlined />}
              type="primary"
              ghost
            />
          </PillWrapper>
        </PillBox>
      </PillContainer>
    </div>
  );
};
