import { gql } from '@apollo/client';

export const LOCATIONS = gql`
  query locations {
    locations {
      id
      key
    }
  }
`;
