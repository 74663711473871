import { isArray } from 'lodash';
import { useEffect, useState, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { getAllFromDomainsPillsIDB, getAllFromTicketsPillsIDB } from '@/utils';
import { useNotify } from '@/hooks';
import { getAllFromCopywritingPillsIDB } from '@/utils/idbHelpers';

import { FilterType, IPill } from './consts';

const hasRightProps = (obj: Record<string, unknown>) =>
  'url' in obj && 'name' in obj && 'id' in obj;

const isPillArr = (prop: unknown): prop is IPill[] => {
  if (!isArray(prop)) return false;

  if (prop.length === 0) return true;

  if (typeof prop[0] !== 'object') return false;

  return prop.every(hasRightProps);
};

interface IUsePillLoader {
  onLoad: () => void;
  onError: () => void;
  deps?: unknown[];
  type: FilterType;
}

export const usePillLoader = ({
  onLoad,
  onError,
  deps = [],
  type = FilterType.TICKETS,
}: IUsePillLoader): [IPill[], (prop: SetStateAction<IPill[]>) => void] => {
  const intl = useIntl();
  const { notifyError } = useNotify();

  const [pills, setPills] = useState<IPill[]>([]);

  const getIDB =
    type === FilterType.DOMAINS
      ? getAllFromDomainsPillsIDB
      : type === FilterType.COPYWRITING
      ? getAllFromCopywritingPillsIDB
      : getAllFromTicketsPillsIDB;

  useEffect(() => {
    getIDB()
      .then((res: unknown) => {
        if (!res || !isPillArr(res)) throw new Error('There was an error reading from a database');

        onLoad();
        setPills(res);
      })
      .catch((e: unknown) => {
        onError();

        notifyError(
          intl.formatMessage({ defaultMessage: 'Nastąpił błąd przy wczytywaniu filtrów' }),
          e,
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [pills, setPills];
};
