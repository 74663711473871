import styled from 'styled-components';

export const PillsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .ant-tag {
    /* min-width: 75px; */
    background: #e8e8e8;
    margin-right: 0;
    cursor: pointer;
  }
`;
