import { useState, useEffect, useRef, ChangeEvent, useMemo } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import { GlobalSearchQuery } from '@/__generated__';

import { variables } from '../../helpers';
import { useLazyGlobalSearch } from '../../useLazyGlobalSearch';
import { SearchState } from '../../consts';

import { useNormalizeSearchData } from './hooks';

import { SearchBar, EscButton, Label, Input, Form, SearchOutlinedIcon } from './styled';

interface ISearchForm {
  setState: (props: SearchState) => void;
}

interface ISearchValues {
  value: string;
  trimmedValue: string;
}

export const SearchForm = (props: ISearchForm) => {
  const { setState } = props;
  const intl = useIntl();

  const lastSearchedValue = useRef<string>();

  const [
    globalSearch,
    { loading: loadingGlobalSearch, data: globalSearchData, error: globalSearchError },
  ] = useLazyGlobalSearch();

  const [search, setSearch] = useState<ISearchValues>({ value: '', trimmedValue: '' });

  useEffect(() => {
    if (loadingGlobalSearch) {
      setState({ type: 'loading' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingGlobalSearch]);

  useEffect(() => {
    if (globalSearchError) {
      setState({ type: 'error', error: globalSearchError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearchError]);

  const normalizeSearchData = useNormalizeSearchData();

  const updateResults = (data: GlobalSearchQuery) => {
    const results = normalizeSearchData(data);

    if (!results?.length) {
      setState({ type: 'not-found' });

      return;
    }

    setState({ type: 'found', results, lastSearchedValue: lastSearchedValue.current ?? '' });
  };

  const inputRef = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    if (globalSearchData) {
      updateResults(globalSearchData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearchData]);

  const triggerSearch = (values: ISearchValues) => {
    if (!values.trimmedValue) return;

    globalSearch({ variables: variables(values.trimmedValue) });
    lastSearchedValue.current = values.trimmedValue;
  };

  const debouncedTriggerSearch = useMemo(
    () => debounce(searchVal => triggerSearch(searchVal), 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      debouncedTriggerSearch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    debouncedTriggerSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const trimmedValue = value.trim();

    setSearch({ value, trimmedValue });
  };

  const onEscClick = () => {
    setState({ type: 'closed' });
  };

  const label = intl.formatMessage({ defaultMessage: 'Wyszukiwarka globalna' });

  return (
    <SearchBar>
      <Form role="search">
        <Label htmlFor="global-search-input" id="global-search-label" aria-label={label}>
          <SearchOutlinedIcon />
        </Label>
        <Input
          aria-autocomplete="list"
          aria-labelledby="global-search-label"
          id="global-search-input"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={intl.formatMessage({ defaultMessage: 'Szukaj' })}
          type="search"
          bordered={false}
          value={search.value}
          onChange={onInputChange}
          ref={inputRef}
        />
      </Form>
      <EscButton onClick={onEscClick} tabIndex={-1}>
        Cancel
      </EscButton>
    </SearchBar>
  );
};
