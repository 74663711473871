import { useMemo } from 'react';

import { Permissions } from '@/consts';

import { useCurrentUser } from './useCurrentUser';

export const useIsAuthorised = () => {
  const { allowedPermissions = [] } = useCurrentUser();

  const isAuthorised = (requiredPermission: Permissions) => {
    return allowedPermissions.includes(requiredPermission);
  };

  const isAuthorisedToAll = (requiredPermissions: Permissions[]) => {
    return requiredPermissions.every(perm => allowedPermissions.includes(perm));
  };

  const isAuthorisedToAny = (requiredPermissions: Permissions[]) => {
    return requiredPermissions.some(perm => allowedPermissions.includes(perm));
  };

  const isAuthorisedToManageAdmins = useMemo(
    () => allowedPermissions.includes(Permissions.MANAGE_ADMINS),
    [],
  );

  return { isAuthorised, isAuthorisedToAll, isAuthorisedToAny, isAuthorisedToManageAdmins };
};
