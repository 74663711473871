import { gql } from '@apollo/client';

import { LINK_IN_THE_LIST_FRAGMENT } from './linkInTheList';
import { USER_BASIC_FRAGMENT } from './userBasic';

export const LINK_FRAGMENT = gql`
  fragment link on Link {
    ...linkInTheList
    order_date
    text_delivery_date
    text_url
    owner {
      ...userBasic
    }
    copywriter {
      ...userBasic
    }
    created_at
    updated_at
    article_url
    character_count
  }
  ${LINK_IN_THE_LIST_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;
