import { useMutation } from '@apollo/client';
import { RadioChangeEvent } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { mutations } from '@/services';
import { useNotify } from '@/hooks';
import { log } from '@/utils';
import { TodoStatusEnum, UpdateTodoMutation, UpdateTodoMutationVariables } from '@/__generated__';

import { TaskStatusSwitch } from './components';

interface IThreeStateSwitch {
  criterionId: string;
  status?: TodoStatusEnum;
  disabled?: boolean;
}

export const ThreeStateSwitch: FC<IThreeStateSwitch> = ({
  criterionId,
  status = TodoStatusEnum.INIT,
  disabled = false,
}) => {
  const { notifySuccess } = useNotify();
  const intl = useIntl();
  const [statusValue, setStatusValue] = useState<TodoStatusEnum>(status);

  const [updateTodo, { data: updatedTodoData }] = useMutation<
    UpdateTodoMutation,
    UpdateTodoMutationVariables
  >(mutations.UPDATE_TODO);

  useEffect(() => {
    const newStatus = updatedTodoData?.updateTodo?.status;

    if (newStatus) {
      setStatusValue(newStatus);
    }
  }, [updatedTodoData]);

  const statusText = (resState: TodoStatusEnum) => {
    switch (resState) {
      case TodoStatusEnum.DONE:
        return intl.formatMessage({ defaultMessage: 'Zrobione' });
      case TodoStatusEnum.NOT_DONE:
        return intl.formatMessage({ defaultMessage: 'Nie zrobione' });
      case TodoStatusEnum.INIT:
        return intl.formatMessage({ defaultMessage: 'Do zrobienia' });
    }
  };

  const onChange = (e: RadioChangeEvent): void => {
    const oldStatus = statusValue;

    setStatusValue(e.target.value);

    updateTodo({
      variables: {
        id: criterionId,
        status: e.target.value,
      },
    })
      .then(res => {
        const resStatus = res.data?.updateTodo.status;

        if (!resStatus) throw new Error('Criterion status not recognized');

        const resStatusText = statusText(resStatus);
        const text = intl.formatMessage(
          { defaultMessage: 'Zmieniono kryterium na: {resStatusText}' },
          { resStatusText },
        );

        notifySuccess(text);
      })
      .catch((err: unknown) => {
        setStatusValue(oldStatus);
        log(err);
      });
  };

  return <TaskStatusSwitch statusValue={statusValue} onChange={onChange} disabled={disabled} />;
};
