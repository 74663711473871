import { gql } from '@apollo/client';

import { PROJECT_FRAGMENT } from '../fragments';

export const PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;
