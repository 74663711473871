import { useIntl } from 'react-intl';

import { CollapseCard } from '../CollapseCard';

import { UserContactDetails } from './components';

import { Container } from './styled';

export const UserContactDetailsCard = () => {
  const intl = useIntl();

  return (
    <CollapseCard
      items={[
        {
          key: '1',
          label: intl.formatMessage({ defaultMessage: 'Dane pracownika' }),
          children: (
            <Container>
              <UserContactDetails />
            </Container>
          ),
        },
      ]}
      header={intl.formatMessage({ defaultMessage: 'Dane pracownika' })}
    />
  );
};
