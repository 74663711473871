import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { theme } from '@/theme';

import { Pill } from '../../../Pill';
import { FilterType } from '../../consts';

import { LocalQuickFilters, QuickFiltersModal } from './components';
import { QuickFiltersModalState, StateKeys } from './consts';

interface ICustomQuickFilters {
  filtersType: FilterType;
}

export const CustomQuickFilters = (props: ICustomQuickFilters) => {
  const { filtersType } = props;
  const intl = useIntl();

  const [modalState, setModalState] = useState<QuickFiltersModalState>({
    type: StateKeys.DISMOUNTED,
  });

  const onButtonClick = () => {
    setModalState({ type: StateKeys.OPEN, loading: true, isSaving: false });
  };

  return (
    <>
      <LocalQuickFilters modalState={modalState} filtersType={filtersType} />
      <Pill
        key={'edit'}
        onClick={onButtonClick}
        icon={<EditOutlined style={{ color: theme.colors.textGrey }} />}
        data-testid={`btn-edit-quickfilters`}
        type="dashed"
        title={intl.formatMessage({ defaultMessage: 'Edytuj szybkie filtry' })}
      />
      {modalState.type !== StateKeys.DISMOUNTED && (
        <QuickFiltersModal
          modalState={modalState}
          setModalState={setModalState}
          filtersType={filtersType}
        />
      )}
    </>
  );
};
