import { gql } from '@apollo/client';

import { DOMAIN_VIEW_FRAGMENT } from '../fragments';

export const UPDATE_DOMAIN_INFO = gql`
  mutation updateDomainInfo(
    $id: ID!
    $name: String
    $adminUserIds: [ID!]
    $fulfillmentUserIds: [ID!]
    $salesUserIds: [ID!]
    $seoContactUserIds: [ID!]
    $semContactUserIds: [ID!]
    $semFulfillmentUserIds: [ID!]
    $uxUserIds: [ID!]
  ) {
    updateDomain(
      id: $id
      name: $name
      adminUserIds: $adminUserIds
      fulfillmentUserIds: $fulfillmentUserIds
      salesUserIds: $salesUserIds
      seoContactUserIds: $seoContactUserIds
      semContactUserIds: $semContactUserIds
      semFulfillmentUserIds: $semFulfillmentUserIds
      uxUserIds: $uxUserIds
    ) {
      ...domainView
    }
  }
  ${DOMAIN_VIEW_FRAGMENT}
`;
