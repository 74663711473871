export enum StateKeys {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DISMOUNTED = 'DISMOUNTED',
  LOADING = 'LOADING',
}

export type QuickFiltersModalState =
  | { type: StateKeys.OPEN; loading: boolean; isSaving: boolean }
  | { type: StateKeys.CLOSED }
  | { type: StateKeys.DISMOUNTED };
