import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/lib/select';

import { log } from '@/utils';
import { useNotify } from '@/hooks';
import { UserBasicFragment } from '@/__generated__';

import { UserItem } from '../../UserItem';
import { dropdownStyle } from '../styled';

import { DeleteIcon, Select } from './styled';

interface IUserSelect extends SelectProps<string, UserBasicFragment> {
  users: UserBasicFragment[];
  onChange: (value: string) => void;
  value: any;
  placeholder?: string;
  dataTestId?: string;
  bordered?: boolean;
  fallbackUser?: UserBasicFragment | null;
}

export const UserSelect: FC<IUserSelect> = ({
  users,
  onChange,
  placeholder = 'Wybierz pracownika',
  value,
  dataTestId = 'user-select',
  bordered = false,
  fallbackUser = null,
  ...props
}) => {
  const intl = useIntl();
  const { notifyError } = useNotify();
  const [itemDisplay, setItemDisplay] = useState<'flex' | 'none'>('flex');

  const fallbackMessage = (notFoundId: string) => {
    const message = intl.formatMessage(
      { defaultMessage: 'Nie znaleziono pracownika o id: {notFoundId}' },
      { notFoundId },
    );

    log({ type: 'error', message, info: 'UserSelect' });

    notifyError(message);
  };

  const initialUsers = () => {
    const filtered = users?.filter(user => !user.blocked);

    if (!value) return filtered;

    /**
     * We need to look them up in users array passed from the parent
     */
    const found = users.find(user => user.id === value);

    if (!found) {
      if (!fallbackUser) {
        fallbackMessage(value);
      } else {
        filtered.push(fallbackUser);
      }
    }

    if (found?.blocked) {
      filtered.push(found);
    }

    return filtered;
  };

  const [userOptions, setUserOptions] = useState(() => initialUsers());

  const userItem = (user: UserBasicFragment) => <UserItem user={user} />;

  const selectOptions = useMemo(
    () =>
      userOptions.map(user => (
        <Select.Option
          key={user.id}
          value={user.id}
          username={`${user.first_name} ${user.last_name}`}
          data-testid={`${dataTestId}-option`}
        >
          {userItem(user)}
        </Select.Option>
      )),
    [userOptions, dataTestId],
  );

  const filterDropdownOptions = (input: string, option: any) =>
    option?.username?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;

  const onFocus = () => {
    setItemDisplay('none');
  };

  const onBlur = () => {
    setItemDisplay('flex');
  };

  const selectRef = useRef<HTMLInputElement>();

  const handleChange = (val: string) => {
    setUserOptions(initialUsers());
    setItemDisplay('flex');
    onChange(val);
    selectRef?.current?.blur();
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div data-testid={`${dataTestId}-dropdown`}>{originNode}</div>
  );

  return (
    <Select
      optionFilterProp="children"
      placeholder={placeholder}
      dropdownStyle={dropdownStyle}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      filterOption={filterDropdownOptions}
      value={value}
      itemdisplay={itemDisplay}
      allowClear={{ clearIcon: <DeleteIcon /> }}
      data-testid={dataTestId}
      ref={selectRef}
      dropdownRender={dropdownRender}
      $bordered={bordered}
      showSearch
      {...props}
    >
      {selectOptions}
    </Select>
  );
};
