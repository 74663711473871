import PL from 'antd/locale/pl_PL';
import GB from 'antd/locale/en_GB';

import { Locale, Languages } from '@/consts';

export const defaultLocale = Locale.PL_PL;
export const defaultLanguage = Languages.PL;

export const supportedLocales = [Locale.PL_PL, Locale.EN_GB];
export const supportedLanguages = [Languages.PL, Languages.EN];

export const antdLocales = { PL, GB };
