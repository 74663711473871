import { FC } from 'react';

import { UserInTheListFragment } from '@/__generated__';
import { theme } from '@/theme';

import { UserAvatar } from '../UserAvatar';
import { UserItem } from '../UserItem';
import { Hyperlink } from '../Hyperlink';

import {
  IconWrapper,
  Profile,
  AvatarWrapper,
  UserDetails,
  TextWrapper,
  MailIcon,
  PhoneIcon,
} from './styled';

interface IUserProfile {
  user: UserInTheListFragment;
  addStar?: boolean;
}

export const UserProfile: FC<IUserProfile> = ({ user, addStar = false }) => {
  const { first_name: firstName, last_name: lastName, phone, email } = user;

  const hyperlinkStyle = {
    marginLeft: '8px',
  };

  return (
    <Profile>
      <AvatarWrapper>
        <UserAvatar user={user} size={112} />
      </AvatarWrapper>
      <UserDetails>
        <UserItem
          user={user}
          fontSize={theme.font.size.mediumPlus}
          style={{ marginBottom: '20px' }}
          withStar={addStar}
          noAvatar
          isLink
        >
          {firstName} {lastName}
        </UserItem>

        {!!phone && (
          <TextWrapper>
            <IconWrapper>
              <PhoneIcon />
            </IconWrapper>
            <Hyperlink phone={phone} style={hyperlinkStyle} />
          </TextWrapper>
        )}
        <TextWrapper>
          <IconWrapper>
            <MailIcon />
          </IconWrapper>
          <Hyperlink email={email} style={hyperlinkStyle} />
        </TextWrapper>
      </UserDetails>
    </Profile>
  );
};
