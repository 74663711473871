import { createContext } from 'react';

import { FullDomainViewFragment, QuartersQuery } from '@/__generated__';

type FullDomainWithQuarters = FullDomainViewFragment & QuartersQuery['domain'];

interface IDomainContext {
  domain: FullDomainWithQuarters;
}

export const DomainContext = createContext<IDomainContext | undefined>(undefined);
