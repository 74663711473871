import { ComponentProps, FC, ReactNode, useEffect, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib/select';
import { Tooltip } from 'antd';

import { theme } from '@/theme';

import { SelectWrapper } from '../SelectWrapper';
import { ArrowIcon, dropdownStyle } from '../styled';

import { Color, LockIcon, Option, OptionText, SelectInput } from './styled';

interface IOption {
  id: string | boolean;
  value: string;
  color?: string | null;
  color_value?: string | null;
  textColor?: string;
  backgroundColor?: string;
  key?: string;
  disabled?: boolean;
  description?: string;
  treat_as_closed?: boolean;
  icon?: any;
}

interface ISelect extends SelectProps<string, IOption> {
  label?: string | ReactNode;
  options?: IOption[];
  placeholder?: string;
  addColor?: boolean;
  allowClear?: boolean;
  darkMode?: boolean;
  value?: string;
  fieldTestId?: string;
  fallbackValue?: string;
  fallbackColor?: string;
  height?: string;
  disabled?: boolean;
  loading?: boolean;
  acceptEmpty?: boolean;
  autoFocus?: boolean;
}

interface IGetOptionProps {
  icon: React.FunctionComponent<ComponentProps<'svg'>>;
  hasLockIcon: boolean;
  optDisabled: boolean;
  val: string;
  textColor?: string;
  color_value?: string;
  backgroundColor?: string;
}

export const Select: FC<ISelect> = ({
  label = null,
  options = [],
  placeholder = '',
  addColor = false,
  allowClear = false,
  fieldTestId = 'select',
  fallbackValue = null,
  fallbackColor = null,
  value = '',
  loading = false,
  acceptEmpty = false,
  autoFocus = false,

  darkMode = false,
  disabled = false,
  height = '35px',
  ...props
}) => {
  const selectRef = useRef<HTMLInputElement>(null);

  options = acceptEmpty ? [{ id: '', value: '' }, ...options] : options;

  const isInOptions = options.find(({ id }: IOption) => id === value);
  const isIncluded = !value || Array.isArray(value) || !!isInOptions;
  const selectValue = isIncluded ? value : fallbackValue;
  const currentValue = selectValue ?? value;

  const selectedOptionColor = isInOptions?.textColor
    ? isInOptions.textColor
    : addColor
    ? isInOptions?.color_value ?? fallbackColor ?? theme.colors.grey5
    : theme.colors.grey5;

  const selectedTextColor = isInOptions?.textColor
    ? isInOptions.textColor
    : addColor
    ? isInOptions?.color_value ?? fallbackColor
    : theme.colors.darkGrey4;

  const selectTextColor = darkMode
    ? !disabled
      ? theme.colors.white
      : theme.colors.grey7
    : selectedTextColor;

  const selectedOptionBgColor = addColor ? isInOptions?.backgroundColor : null;

  const bgColor = selectedOptionBgColor
    ? selectedOptionBgColor
    : darkMode
    ? theme.colors.shadyBlue
    : theme.colors.white;
  const searchTextColor = !darkMode ? theme.colors.shadyBlue : theme.colors.white;
  const borderColor = darkMode ? theme.colors.shadyBlue : selectedOptionColor;
  const disabledBgColor = darkMode ? theme.colors.shadyBlue : theme.colors.grey;
  const arrow = loading ? <LoadingOutlined /> : <ArrowIcon color={selectTextColor} />;
  const cursor = disabled ? 'not-allowed' : 'pointer';

  const getOption = ({
    hasLockIcon,
    optDisabled,
    val,
    textColor,
    color_value,
    icon: Icon,
  }: IGetOptionProps) => {
    return (
      <Option color={textColor}>
        {color_value && (
          <Color $color={color_value} disabled={optDisabled}>
            {hasLockIcon && <LockIcon />}
          </Color>
        )}
        {Icon && <Icon style={{ height: 20, marginRight: 4 }} />}
        <OptionText>{val}</OptionText>
      </Option>
    );
  };

  const mapOptions = () =>
    !!options?.length &&
    options.map(
      ({
        id,
        value: val,
        key = '',
        icon,
        color_value = null,
        backgroundColor,
        textColor,
        disabled: optDisabled = false,
        description,
        treat_as_closed: hasLockIcon = false,
      }: IOption) => (
        <SelectInput.Option
          key={key || id}
          value={id}
          disabled={optDisabled}
          data-testid={`${fieldTestId}-option`}
          style={
            backgroundColor && {
              color: textColor,
              backgroundColor: backgroundColor,
              borderRadius: 'unset',
            }
          }
        >
          {description || val ? (
            <Tooltip title={description || val}>
              {getOption({
                hasLockIcon,
                textColor,
                color_value,
                val,
                optDisabled,
                icon,
              })}
            </Tooltip>
          ) : (
            <>
              {getOption({
                hasLockIcon,
                textColor,
                color_value,
                val,
                optDisabled,
                icon,
              })}
            </>
          )}
        </SelectInput.Option>
      ),
    );

  const filterDropdownOptions = (input: string, option: any) => {
    const optionValue = option?.children?.props?.title ?? '';

    return optionValue.toLowerCase().includes(input.toLowerCase());
  };

  const notFound = () => {
    if (loading) return <LoadingOutlined />;

    return null;
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div data-testid={`${fieldTestId}-dropdown`}>{originNode}</div>
  );

  useEffect(() => {
    if (autoFocus) selectRef.current?.focus();
  }, []);

  return (
    <SelectWrapper label={label}>
      <SelectInput
        placeholder={placeholder}
        suffixIcon={arrow}
        allowClear={allowClear}
        dropdownStyle={dropdownStyle}
        filterOption={filterDropdownOptions}
        data-testid={fieldTestId}
        value={currentValue}
        $color={selectedOptionColor}
        $bgcolor={bgColor}
        $bordercolor={borderColor}
        $textcolor={selectTextColor}
        $searchtextcolor={searchTextColor}
        $disabledBgColor={disabledBgColor}
        disabled={disabled}
        $cursor={cursor}
        height={height}
        dropdownRender={dropdownRender}
        loading={loading}
        notFoundContent={notFound()}
        showSearch
        ref={selectRef}
        {...props}
      >
        {mapOptions()}
      </SelectInput>
    </SelectWrapper>
  );
};
