import { gql } from '@apollo/client';

import { GROUPED_PHRASE_MONTHS_VIEW_FRAGMENT } from './groupedPhraseMonthsView';

export const GROUPED_PHRASES_MONTHS_VIEW_FRAGMENT = gql`
  fragment groupedPhrasesMonthsView on GroupedPhrases {
    id
    name
    phrases {
      ...groupedPhraseMonthsView
    }
  }
  ${GROUPED_PHRASE_MONTHS_VIEW_FRAGMENT}
`;
