import { gql } from '@apollo/client';

export const MONITOR_LIMITS = gql`
  query monitorLimits {
    monitorLimits {
      monitors
      monitorsLimit
      upMonitors
      downMonitors
    }
  }
`;
