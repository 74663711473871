import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Name = styled(Link)`
  color: ${({ theme: { colors } }) => colors.darkBlue};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  transition: all 0.1s ease-in-out;

  :hover {
    color: ${({ theme: { colors } }) => colors.lightBlueHover};
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.lightGrey};
`;

export const NoTeams = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
