import { forwardRef } from 'react';
import { ErrorMessage, FieldProps } from 'formik';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { ErrorWrapper, FieldWrapper, Label, PasswordInput, TextInput } from '../styled';

interface ITextInputField extends FieldProps {
  label?: string;
  large?: boolean;
  type?: 'text' | 'password';
  id?: string;
}

export const TextInputField = forwardRef<HTMLInputElement, ITextInputField>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ field, form, label = null, large = false, type = 'text', id, ...props }, ref) => {
    const regularFontSize = 13;
    const largeFontSize = 17;

    const testId = `${field.name ?? 'text'}-input`;
    const inputId = id ?? testId;

    const getFontSize = () => (large ? largeFontSize : regularFontSize);

    return (
      <FieldWrapper>
        {label && <Label htmlFor={inputId}>{label}</Label>}
        {type === 'password' ? (
          <PasswordInput
            type={type}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            data-testid={testId}
            id={inputId}
            ref={ref}
            {...field}
            {...props}
          />
        ) : (
          <TextInput
            type={type}
            $fontSize={getFontSize()}
            data-testid={testId}
            id={inputId}
            ref={ref}
            {...field}
            {...props}
          />
        )}
        <ErrorMessage
          {...field}
          data-testid={`${field.name ?? 'text'}-error`}
          component={ErrorWrapper}
        />
      </FieldWrapper>
    );
  },
);

TextInputField.displayName = 'TextInputField';
