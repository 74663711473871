import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import {
  $createRangeSelection,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  LexicalCommand,
  LexicalEditor,
  $createParagraphNode,
  PASTE_COMMAND,
} from 'lexical';
import { useEffect, useRef, useState } from 'react';

import { CAN_USE_DOM } from '../../utils/canUseDOM';
import {
  $createImageNode,
  $isImageNode,
  ImageNode,
  ImagePayload,
  ImageSourcePayload,
} from '../../nodes/ImageNode';
import Button from '../../ui/Button';
import { DialogActions, DialogButtonsList } from '../../ui/Dialog';
import FileInput from '../../ui/FileInput';
import TextInput from '../../ui/TextInput';
import addFile from '../../utils/addFile';

export type InsertImagePayload = Readonly<ImagePayload>;
export type ReplaceImageSourcePayload = Readonly<ImageSourcePayload>;

const getDOMSelection = (targetWindow: Window | null): Selection | null =>
  CAN_USE_DOM ? (targetWindow || window).getSelection() : null;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_IMAGE_COMMAND');

export const REPLACE_IMAGE_SOURCE_COMMAND: LexicalCommand<ReplaceImageSourcePayload> =
  createCommand('REPLACE_IMAGE_SOURCE_COMMAND');

export function InsertImageUriDialogBody({
  onClick,
}: {
  onClick: (payload: InsertImagePayload) => void;
}) {
  const [src, setSrc] = useState('');

  const isDisabled = src === '';

  return (
    <>
      <TextInput
        label="Image URL"
        placeholder=""
        onChange={setSrc}
        value={src}
        data-test-id="image-modal-url-input"
      />
      <DialogActions>
        <Button
          data-test-id="image-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText: '', src })}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}

export function InsertImageUploadedDialogBody({
  onClick,
}: {
  onClick: (payload: InsertImagePayload) => void;
}) {
  const [src, setSrc] = useState('');

  const isDisabled = src === '';

  const loadImage = (files: FileList | null) => {
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };
    if (files !== null) {
      reader.readAsDataURL(files[0] as Blob);
    }
  };

  return (
    <>
      <FileInput
        label="Image Upload"
        onChange={loadImage}
        accept="image/*"
        data-test-id="image-modal-file-upload"
      />
      <DialogActions>
        <Button
          data-test-id="image-modal-file-upload-btn"
          disabled={isDisabled}
          onClick={() => onClick({ altText: '', src })}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}

export function InsertImageDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  const [mode, setMode] = useState<null | 'url' | 'file'>(null);
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e: KeyboardEvent) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [activeEditor]);

  const onClick = (payload: InsertImagePayload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <>
      {!mode && (
        <DialogButtonsList>
          <Button data-test-id="image-modal-option-url" onClick={() => setMode('url')}>
            URL
          </Button>
          <Button data-test-id="image-modal-option-file" onClick={() => setMode('file')}>
            File
          </Button>
        </DialogButtonsList>
      )}
      {mode === 'url' && <InsertImageUriDialogBody onClick={onClick} />}
      {mode === 'file' && <InsertImageUploadedDialogBody onClick={onClick} />}
    </>
  );
}

interface ClipboardEventImagesPlugin extends ClipboardEvent {
  _uploadImagesHandled: boolean;
}

export default function ImagesPlugin({
  uploadImages,
}: {
  uploadImages: boolean;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        payload => {
          const imageNode = $createImageNode(payload);

          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            const test = $wrapNodeInElement(imageNode, $createParagraphNode);
            test.selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand<ReplaceImageSourcePayload>(
        REPLACE_IMAGE_SOURCE_COMMAND,
        payload => {
          const { imageNode, src } = payload;
          imageNode.replaceSrc(src);

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand<DragEvent>(
        DRAGSTART_COMMAND,
        event => {
          return onDragStart(event);
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand<DragEvent>(
        DRAGOVER_COMMAND,
        event => {
          return onDragover(event);
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<DragEvent>(
        DROP_COMMAND,
        event => {
          return onDrop(event, editor);
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand<ClipboardEventImagesPlugin>(
        PASTE_COMMAND,
        event => {
          if (!event.clipboardData || !uploadImages || event._uploadImagesHandled) return false;

          if (event.clipboardData.files.length > 0) {
            return onImagesPaste(event.clipboardData, editor);
          }

          return onHtmlPaste(event, editor);
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerNodeTransform(ImageNode, async (node: ImageNode) => {
        if (!uploadImages || !node.getIsPlaceholder()) return;

        try {
          if (!node.getSrc()) return;
          const response = await fetchExternalImage(node.getSrc());
          const blob = await response.blob();
          if (response.ok === false) return;
          return replaceImage(blob as File, node, editor);
        } finally {
          editor.dispatchCommand(REPLACE_IMAGE_SOURCE_COMMAND, {
            imageNode: node,
            src: node.getSrc(),
          });
        }
      }),
    );
  }, [editor]);

  return null;
}

const TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

function fetchExternalImage(url: string) {
  return fetch(url, {
    headers: {
      'Sec-Fetch-Dest': 'image',
      'Sec-Fetch-Mode': 'no-cors',
      'Sec-Fetch-Site': 'cross-site',
      Accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
    },
  });
}

/**
 * Adds placeholder attribute to every pasted in img
 * Dispatches PASTE_COMMAND with modified event so editor can properly handle it
 */
function onHtmlPaste(event: ClipboardEvent, editor: LexicalEditor): boolean {
  const htmlString = event.clipboardData?.getData('text/html') ?? '';
  if (!htmlString) {
    return false;
  }
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  dom
    .querySelectorAll('img')
    .forEach((htmlImage: HTMLImageElement) => htmlImage.setAttribute('placeholder', ''));

  const modifiedClipboard = new DataTransfer();
  modifiedClipboard.setData('text/html', dom.documentElement.innerHTML);
  const newEvent = cloneClipboardEvent(event, modifiedClipboard);
  newEvent._uploadImagesHandled = true;

  editor.dispatchCommand(PASTE_COMMAND, newEvent);

  return true;
}

function cloneClipboardEvent(
  event: ClipboardEvent,
  newClipboardData: DataTransfer,
): ClipboardEventImagesPlugin {
  return new ClipboardEvent(event.type, {
    ...event,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    composed: event.composed,
    clipboardData: newClipboardData,
  }) as ClipboardEventImagesPlugin;
}

function onImagesPaste(dataTransfer: DataTransfer, editor: LexicalEditor): boolean {
  const reader = new FileReader();
  for (const image of dataTransfer.files) {
    reader.readAsDataURL(image);
    reader.onloadend = function () {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        file: image,
        altText: image.name,
        src: reader.result as string,
        isPlaceholder: true,
      });
    };
  }
  return true;
}

function onDragStart(event: DragEvent): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const dataTransfer = event.dataTransfer;
  if (!dataTransfer) {
    return false;
  }
  dataTransfer.setData('text/plain', '_');
  dataTransfer.setDragImage(img, 0, 0);
  dataTransfer.setData(
    'application/x-lexical-drag',
    JSON.stringify({
      data: {
        altText: node.__altText,
        height: node.__height,
        key: node.getKey(),
        maxWidth: node.__maxWidth,
        src: node.__src,
        width: node.__width,
      },
      type: 'image',
    }),
  );

  return true;
}

function onDragover(event: DragEvent): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  if (!canDropImage(event)) {
    event.preventDefault();
  }
  return true;
}

function onDrop(event: DragEvent, editor: LexicalEditor): boolean {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const data = getDragImageData(event);
  if (!data) {
    return false;
  }
  event.preventDefault();
  if (canDropImage(event)) {
    const range = getDragSelection(event);
    node.remove();
    const rangeSelection = $createRangeSelection();
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range);
    }
    $setSelection(rangeSelection);
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
  }
  return true;
}

async function replaceImage(file: File, imageNode: ImageNode, editor: LexicalEditor) {
  const res = await addFile(file, () => undefined);
  editor.dispatchCommand(REPLACE_IMAGE_SOURCE_COMMAND, {
    imageNode,
    src: res?.src ?? imageNode.getSrc(),
  });
}

function getImageNodeInSelection(): ImageNode | null {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isImageNode(node) ? node : null;
}

function getDragImageData(event: DragEvent): null | InsertImagePayload {
  const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
  if (!dragData) {
    return null;
  }
  const { type, data } = JSON.parse(dragData);
  if (type !== 'image') {
    return null;
  }

  return data;
}

declare global {
  interface DragEvent {
    rangeOffset?: number;
    rangeParent?: Node;
  }
}

function canDropImage(event: DragEvent): boolean {
  const target = event.target;
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest('code, span.editor-image') &&
    target.parentElement &&
    target.parentElement.closest('div.ContentEditable__root')
  );
}

function getDragSelection(event: DragEvent): Range | null | undefined {
  let range;
  const target = event.target as null | Element | Document;
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
      ? (target as Document).defaultView
      : (target as Element).ownerDocument.defaultView;
  const domSelection = getDOMSelection(targetWindow);
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error(`Cannot get the selection when dragging`);
  }

  return range;
}
