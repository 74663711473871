import { useLocation } from 'react-router-dom';

import { isStage } from '@/utils';
import { useNotify, useUpdateGridSearchParams } from '@/hooks';
import { GridSearchParamsTypes } from '@/consts';
import { TicketGridFilterInput } from '@/__generated__';

import { Pill } from '../../../Pill';

import { useQuickFilterCards } from './hooks';

/**
 * This feature works only on prod and dev.
 * Although it is displayed on other envs, it
 * does trigger noop action on them.
 */
export const DomainTicketsQuickFilters = () => {
  const { notifyWarning } = useNotify();
  const location = useLocation();
  const [updateGridSearchParams] = useUpdateGridSearchParams();

  const onClick = (filter: TicketGridFilterInput) => {
    if (isStage()) {
      notifyWarning('Ten ficzer nie działa na stage. Testowo ustawiono filtr "tylko otwarte".');
    }

    updateGridSearchParams(
      { type: GridSearchParamsTypes.UPDATE_FILTER, payload: filter },
      location.pathname,
    );
  };

  const cards = useQuickFilterCards();

  return (
    <>
      {cards.map(({ label, ids, category }) => (
        <Pill key={label} onClick={() => onClick(ids)} data-testid={`quickfilter-${category}`}>
          {label}
        </Pill>
      ))}
    </>
  );
};
