import { gql } from '@apollo/client';

import { LSP_TYPE_FRAGMENT } from '../fragments';

export const UPDATE_LSP_TYPE = gql`
  mutation updateLspType($id: ID!, $value: String!) {
    updateLspType(id: $id, value: $value) {
      ...lspType
    }
  }
  ${LSP_TYPE_FRAGMENT}
`;
