import { gql } from '@apollo/client';

export const EXTERNAL_REPORTS = gql`
  query externalReports($domainId: ID) {
    externalReports(domainId: $domainId) {
      id
      emailSent
      language
      link
      reportDate
      note
      type
    }
  }
`;
