import { gql } from '@apollo/client';

export const REPORT_LANGUAGES_EXTERNAL_REPORT = gql`
  query reportLanguagesExternalReport {
    reportLanguagesExternalReport {
      id
      key
    }
  }
`;
