import { gql } from '@apollo/client';

export const COMPANIES_EXTERNAL_REPORT = gql`
  query companiesExternalReport {
    companiesExternalReport {
      id
      key
    }
  }
`;
