import { FC } from 'react';
import { ErrorMessage, FieldProps } from 'formik';

import { ErrorWrapper, FieldWrapper, Label, TextAreaInput } from '../styled';

interface ITextAreaInputField extends FieldProps {
  label?: string;
  type?: string;
  rows?: number;
}

export const TextAreaInputField: FC<ITextAreaInputField> = ({
  field,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form,
  label = null,
  rows = 4,
  ...props
}) => (
  <FieldWrapper>
    {label && <Label>{label}</Label>}
    <TextAreaInput rows={rows} {...field} {...props} />
    <ErrorMessage {...field} component={ErrorWrapper} />
  </FieldWrapper>
);
