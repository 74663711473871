import styled from 'styled-components';

import { TextAreaInputStyled } from '../Form/styled';

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const TextAreaField = styled(TextAreaInputStyled)`
  min-height: 100px !important;
  max-height: 400px;
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: end;
`;

export const SchemaOptions = styled.div`
  display: grid;
  margin-top: 1rem;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const MetaDescOption = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-right: 10px;
  }
`;
