import { gql } from '@apollo/client';

import { TICKET_TODO_FRAGMENT } from '../fragments';

export const UPDATE_TODO = gql`
  mutation updateTodo($id: ID!, $text: String, $priority: Float, $status: TodoStatusEnum) {
    updateTodo(id: $id, text: $text, priority: $priority, status: $status) {
      ...ticketTodo
    }
  }
  ${TICKET_TODO_FRAGMENT}
`;
