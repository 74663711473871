import { FC, ReactNode } from 'react';
import { ErrorMessage, FieldProps } from 'formik';

import { UserBasicFragment } from '@/__generated__';

import { UserSelect } from '../../Selects';
import { ErrorWrapper, FieldWrapper, Label } from '../styled';

interface ISelectUserInputField extends FieldProps {
  label?: string | ReactNode;
  users: UserBasicFragment[];
}

export const SelectUserInputField: FC<ISelectUserInputField> = ({
  field,
  form,
  label,
  users,
  ...props
}) => (
  <FieldWrapper>
    {!!label && <Label>{label}</Label>}
    <UserSelect
      onChange={(value: string | undefined) => {
        form.setFieldValue(field.name, value ?? '');
      }}
      value={field.value}
      users={users}
      dataTestId={`${field.name}-select`}
      {...props}
    />

    <ErrorMessage {...field} component={ErrorWrapper} />
  </FieldWrapper>
);
