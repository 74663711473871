import { gql } from '@apollo/client';

export const ADD_RELATED_TICKET = gql`
  mutation addRelatedTicket(
    $ticketId: ID!
    $relatedTicketId: String!
    $relationType: RelationTypeEnum!
  ) {
    addRelatedTicket(
      ticketId: $ticketId
      relatedTicketId: $relatedTicketId
      relationType: $relationType
    ) {
      id
    }
  }
`;
