import { gql } from '@apollo/client';

import { DOMAIN_CONTACTS_FRAGMENT } from './domainContacts';
import { USER_BASIC_FRAGMENT } from './userBasic';
import { GAMGA_SERVICE_FRAGMENT } from './gamgaService';
import { GAM_SEARCH_CONSOLE_SITE_FRAGMENT } from './gamSearchConsoleSite';

export const FULL_DOMAIN_VIEW_FRAGMENT = gql`
  fragment fullDomainView on Domain {
    id
    name
    max_phrases
    positioned_domain

    contacts {
      ...domainContacts
    }

    fulfillmentUsers {
      ...userBasic
    }
    salesUsers {
      ...userBasic
    }
    adminUsers {
      ...userBasic
    }
    semFulfillmentUsers {
      ...userBasic
    }
    seoContactUsers {
      ...userBasic
    }
    semContactUsers {
      ...userBasic
    }
    uxUsers {
      ...userBasic
    }

    GAMGAServices {
      ...gamgaService
    }

    GAMSearchConsoleSites {
      ...gamSearchConsoleSite
    }

    textOrderGuidelines {
      id
      guidelines
      text_type
      text_type_name
    }
    newestDeals {
      key
      name
    }
  }

  ${USER_BASIC_FRAGMENT}
  ${DOMAIN_CONTACTS_FRAGMENT}
  ${GAMGA_SERVICE_FRAGMENT}
  ${GAM_SEARCH_CONSOLE_SITE_FRAGMENT}
`;
