import { gql } from '@apollo/client';

export const DOMAIN_REPORT_FIELDS = gql`
  query domainReportFields($reportType: String!) {
    domainReportFields(reportType: $reportType) {
      id
      key
    }
  }
`;
