import { gql } from '@apollo/client';

export const DOMAIN_CONTACTS_FRAGMENT = gql`
  fragment domainContacts on DomainContacts {
    id
    contact_person
    contact_info {
      contact_value
      contact_type
      contact_type_name
    }
  }
`;
