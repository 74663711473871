import { gql } from '@apollo/client';

import { MONITOR_FRAGMENT } from '../fragments';

export const MONITORS = gql`
  query monitors($grid: MonitorGridInput) {
    monitors(grid: $grid) {
      total
      rows {
        ...monitor
      }
    }
  }
  ${MONITOR_FRAGMENT}
`;
