import { FC } from 'react';

import { UserBasicFragment } from '@/__generated__';

import { PersonItemWithAction } from '../../PersonItemWithAction';
import { IconButton } from '../../Buttons';
import { DeleteIcon } from '../styled';

interface IUserItemField {
  users: UserBasicFragment[];
  userId: string;
  onClick: () => void;
}

/* This component is created for Formik FieldArray */
export const UserItemField: FC<IUserItemField> = ({ users, userId, onClick }) => {
  const user = users.find(item => item.id === userId) ?? null;

  if (!user) return null;

  return (
    <PersonItemWithAction user={user} isLink>
      <IconButton onClick={onClick} icon={<DeleteIcon />} />
    </PersonItemWithAction>
  );
};
