import { gql } from '@apollo/client';

import { GROUPED_PHRASES_MONTHS_VIEW_FRAGMENT } from '../fragments';

export const GROUPED_PHRASES_MONTHS_VIEW = gql`
  query groupedPhrasesMonthsView($grid: GroupedPhraseGridInput) {
    groupedPhrases(grid: $grid) {
      ...groupedPhrasesMonthsView
    }
  }
  ${GROUPED_PHRASES_MONTHS_VIEW_FRAGMENT}
`;
