import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import { mutations, queries } from '@/services';
import { useNotify } from '@/hooks';
import {
  UpdateQuarterOverrideMutation,
  UpdateQuarterOverrideMutationVariables,
} from '@/__generated__';
import { useDomainContext } from '@/scenes/Domains/scenes/DomainView/hooks';

type IUseUpdateQuarterOverride = [(props: any) => Promise<void>];

export const useUpdateQuarterOverride = (): IUseUpdateQuarterOverride => {
  const intl = useIntl();
  const { notifyError, notifySuccess } = useNotify();

  const { domain } = useDomainContext();

  const [updateQuarterOverride] = useMutation<
    UpdateQuarterOverrideMutation,
    UpdateQuarterOverrideMutationVariables
  >(mutations.UPDATE_QUARTER_OVERRIDE);

  const handleUpdateQuarterOverride = ({ variables }: any) => {
    return updateQuarterOverride({
      variables,
      refetchQueries: [{ query: queries.QUARTERS, variables: { id: domain.id } }],
    })
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: 'scenes.QuarterOverrideForm.notification.success',
            defaultMessage: `Nadpisano kwartał`,
          }),
        );
      })

      .catch(() => {
        notifyError(
          intl.formatMessage({
            id: 'scenes.QuarterOverrideForm.notification.error',
            defaultMessage: 'Nastąpił błąd podczas nadpisywaniu kwartału',
          }),
        );
      });
  };

  return [handleUpdateQuarterOverride];
};
