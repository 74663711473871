import { gql } from '@apollo/client';

export const DOMAIN_REPORT_TYPE = gql`
  query domainReportType {
    domainReportType {
      id
      key
      value
    }
  }
`;
