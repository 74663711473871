import { gql } from '@apollo/client';

export const NOTIFICATION_SETTINGS = gql`
  query notificationSettings {
    notificationSettings {
      group
      groupName
      settings {
        id
        key
        name
        channel
        value
        group
      }
    }
  }
`;
