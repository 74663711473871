import { gql } from '@apollo/client';

import { USER_FRAGMENT } from '../fragments';

export const ACTIVE_USER = gql`
  query activeUser {
    activeUser {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
