import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Paths } from '@/consts';

import { Button, ButtonLink } from '../../../Buttons';

import { Footer } from './styled';

interface INotificationsFooter {
  handleReadAllClick: () => void;
  handleShowAllClick: () => void;
  loadingMarkAllNotificationRead: boolean;
}

export const NotificationsFooter = (props: INotificationsFooter) => {
  const intl = useIntl();
  const { handleReadAllClick, handleShowAllClick, loadingMarkAllNotificationRead } = props;

  return (
    <Footer>
      <ButtonLink
        onClick={handleReadAllClick}
        loading={loadingMarkAllNotificationRead}
        data-testid="mark-all-notifications-read"
      >
        {intl.formatMessage({
          id: `components.Notifications.footer`,
          defaultMessage: 'Oznacz wszystkie jako przeczytane',
        })}
      </ButtonLink>
      <Link to={Paths.NOTIFICATIONS} onClick={e => e.preventDefault()}>
        <Button onClick={handleShowAllClick} data-testid="show-all-notifications">
          {intl.formatMessage({
            id: `components.Notifications.footerButton`,
            defaultMessage: 'Pokaż wszystkie',
          })}
        </Button>
      </Link>
    </Footer>
  );
};
