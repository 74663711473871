import { ApolloClient, from, NormalizedCacheObject } from '@apollo/client';

import typeDefs from './typeDefs';
// eslint-disable-next-line import/namespace
import { cache } from './cache';
import { httpLink, setTokenLink, errorLink, appUpdater } from './links';

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([setTokenLink, errorLink, appUpdater.concat(httpLink)]),
  cache,
  typeDefs,
});
