import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import { ErrorBoundary } from '@/components';
import { AppProviders } from '@/context';
import { initLogServices /* initMockServices  */ } from '@/utils';

import App from './App'; /*
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'; */

initLogServices();
/* initMockServices(); */

/**
|--------------------------------------------------
| App start
|--------------------------------------------------
*/

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <StrictMode>
    <AppProviders>
      <ErrorBoundary tagKey={'app-providers-level'} tagValue={'app providers level'}>
        <App />
      </ErrorBoundary>
    </AppProviders>
  </StrictMode>,
);

/* serviceWorkerRegistration.unregister();
reportWebVitals(console.log); */
