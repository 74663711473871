import { gql } from '@apollo/client';

export const TICKETS_TITLES_LIST = gql`
  query ticketsTitlesList($grid: TicketGridInput) {
    tickets(grid: $grid) {
      total
      rows {
        id
        title
      }
    }
  }
`;
