import { gql } from '@apollo/client';

import { TICKET_PRIORITY_FRAGMENT } from '../fragments';

export const TICKET_PRIORITIES = gql`
  query ticketPriorities($projectId: ID, $includeArchived: Boolean) {
    ticketPriorities(projectId: $projectId, includeArchived: $includeArchived) {
      ...priority
    }
  }
  ${TICKET_PRIORITY_FRAGMENT}
`;
