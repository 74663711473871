import { gql } from '@apollo/client';

import { DOMAIN_ADS_BUDGET_FRAGMENT } from '../fragments';

export const UPDATE_DOMAIN_ADS_BUDGET = gql`
  mutation updateAdsBudget($input: DomainAdsBudgetInput!, $id: String!) {
    updateDomainAdsBudget(input: $input, id: $id) {
      ...domainAdsBudget
    }
  }

  ${DOMAIN_ADS_BUDGET_FRAGMENT}
`;
