import { gql } from '@apollo/client';

export const DEAL_PRODUCTS = gql`
  query dealProducts {
    dealProducts {
      id
      key
      value
    }
  }
`;
