import { gql } from '@apollo/client';

import { DEAL_FRAGMENT } from '../fragments';

export const UPDATE_DEAL = gql`
  mutation updateDeal(
    $id: ID!
    $ipressMonthly: Int
    $linkBuildingBudget: Float
    $diversificationLinksCount: Int
    $sponsoredArticlesCount: Int
    $lspTypeId: String
    $communicationLanguage: DomainLanguageEnum
    $status: DealStatusEnum
    $terminationShouldBeSaved: Boolean
    $suspensionDate: String
  ) {
    updateDeal(
      id: $id
      ipressMonthly: $ipressMonthly
      linkBuildingBudget: $linkBuildingBudget
      diversificationLinksCount: $diversificationLinksCount
      sponsoredArticlesCount: $sponsoredArticlesCount
      lspTypeId: $lspTypeId
      communicationLanguage: $communicationLanguage
      status: $status
      terminationShouldBeSaved: $terminationShouldBeSaved
      suspensionDate: $suspensionDate
    ) {
      ...deal
    }
  }
  ${DEAL_FRAGMENT}
`;
