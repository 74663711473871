import styled from 'styled-components';
import { Select as SelectAntd } from 'antd';

import { Lock } from '@/components/Icon';

export const Option = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color, theme }) => color ?? theme.colors.darkBlue};
`;

export const SelectInput = styled<
  | {
      $color: string;
      $bgcolor: string;
      $bordercolor: string;
      $textcolor: string;
      $searchtextcolor: string;
      $disabledBgColor: string;
      height: string;
      $cursor: string;
    }
  | any
>(SelectAntd)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: ${({ height }) => height};
    border: 0.7px solid ${({ $bordercolor }) => $bordercolor};
    border-radius: ${({ theme: { border } }) => border.radius.semiMedium};
    background-color: ${({ $bgcolor }) => $bgcolor};
    cursor: ${({ $cursor }) => $cursor};
    color: ${({ $searchtextcolor }) => $searchtextcolor};

    .ant-select-selection-overflow {
      cursor: ${({ $cursor }) => $cursor};
    }
  }

  .ant-select-selection-search > .ant-select-selection-search-input {
    color: ${({ $searchtextcolor }) => $searchtextcolor};
    cursor: pointer !important;
  }

  .ant-select-item-option-content,
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-item-option-content {
    color: ${({ theme: { colors } }) => colors.textGrey};
  }

  .ant-select-selection-item > ${Option} {
    color: ${({ $textcolor }) => $textcolor};
    overflow: hidden;
  }

  .ant-select-clear > .anticon.anticon-close-circle {
    background-color: ${({ $bgcolor }) => $bgcolor};
    color: ${({ theme: { colors } }) => colors.textGrey};
  }

  &.ant-select.ant-select-disabled .ant-select-selector {
    background-color: ${({ $disabledBgColor }) => $disabledBgColor};
    color: ${({ $textcolor }) => $textcolor};
  }
`;

export const Color = styled.div<{ $color: string; disabled: boolean }>`
  height: 14px;
  min-width: 14px;
  margin-right: 10px;
  border-radius: ${({ theme: { border } }) => border.radius.small};
  background-color: ${({ $color, disabled, theme: { colors } }) =>
    disabled ? colors.disabledGrey : $color};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LockIcon = styled(Lock)`
  fill: ${({ theme: { colors } }) => colors.white};
  height: 10px;
  min-width: 10px;
`;
