import { useMutation } from '@apollo/client';

import { mutations, queries } from '@/services';
import { useNotify } from '@/hooks';
import {
  DeleteQuarterOverrideMutation,
  DeleteQuarterOverrideMutationVariables,
} from '@/__generated__';
import { useDomainContext } from '@/scenes/Domains/scenes/DomainView/hooks';

interface IDeleteTodo {
  variables: DeleteQuarterOverrideMutationVariables;
}

type IUseDeleteQuarterOverride = [({ variables }: IDeleteTodo) => Promise<void>];

export const useDeleteQuarterOverride = (): IUseDeleteQuarterOverride => {
  const { notifyError, notifySuccess } = useNotify();

  const { domain } = useDomainContext();

  const [deleteQuarterOverride] = useMutation<
    DeleteQuarterOverrideMutation,
    DeleteQuarterOverrideMutationVariables
  >(mutations.DELETE_QUARTER_OVERRIDE);

  const handleDeleteQuarterOverride = ({ variables }: IDeleteTodo) => {
    return deleteQuarterOverride({
      variables,
      refetchQueries: [{ query: queries.QUARTERS, variables: { id: domain.id } }],
      optimisticResponse: {
        __typename: 'Mutation',
        deleteQuarterOverride: {
          __typename: 'QuarterOverride',
          id: variables.id,
        },
      },
    })
      .then(() => {
        notifySuccess();
      })
      .catch(() => {
        notifyError();
      });
  };

  return [handleDeleteQuarterOverride];
};
