import { FC, PropsWithChildren, ReactNode } from 'react';

import { Label, Wrapper } from '../styled';

interface SelectWrapper {
  label?: ReactNode;
}

export const SelectWrapper: FC<PropsWithChildren<SelectWrapper>> = props => {
  const { label, children } = props;

  return (
    <Wrapper>
      {!!label && <Label>{label}</Label>}
      {children}
    </Wrapper>
  );
};
