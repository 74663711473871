import { gql } from '@apollo/client';

export const CREDENTIAL_FILES_FRAGMENT = gql`
  fragment credentialFiles on Credential {
    files {
      id
      sizeInKb
      title
      url
    }
  }
`;
