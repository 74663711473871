import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const TICKET_IN_THE_LIST_FRAGMENT = gql`
  fragment ticketInTheList on Ticket {
    sequence
    id
    title
    start
    deadline
    updated_at
    created_at
    priority_value
    total_time_logs
    project {
      id
      name
    }
    type {
      id
      value
      color
      color_value
    }
    priority {
      id
      value
      color
    }
    status {
      id
      value
      color
    }
    owner {
      ...userBasic
    }
    assigned {
      ...userBasic
    }
    updatedBy {
      ...userBasic
    }
    domain {
      id
      name
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
