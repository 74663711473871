import { gql } from '@apollo/client';

export const USER_STATISTICS = gql`
  query userStatistics($id: ID!) {
    userStatistics(id: $id) {
      thisWeek
      overdue
      today
      open
      closed
    }
  }
`;
