import { gql } from '@apollo/client';

import { PROJECT_USERS_FRAGMENT } from '../fragments';

export const DELETE_PROJECT_USER = gql`
  mutation deleteProjectUser($projectId: ID!, $userId: String!) {
    deleteProjectUser(projectId: $projectId, userId: $userId) {
      ...projectUsers
    }
  }
  ${PROJECT_USERS_FRAGMENT}
`;
