import { useMutation, FetchResult } from '@apollo/client';

import { mutations, queries } from '@/services';
import {
  CreateTextOrdersMutation,
  CreateTextOrdersMutationVariables,
  TextOrdersQueryVariables,
  TextOrdersQuery,
} from '@/__generated__';

interface IHandleCreateTextOrders {
  variables: CreateTextOrdersMutationVariables;
  textOrdersVariables: TextOrdersQueryVariables;
}

type UseCreateTextOrders<T> = [
  (props: IHandleCreateTextOrders) => Promise<T>,
  { loading: boolean },
];

export const useCreateTextOrders = (): UseCreateTextOrders<
  FetchResult<CreateTextOrdersMutation>
> => {
  const [createTextOrders, { loading }] = useMutation<
    CreateTextOrdersMutation,
    CreateTextOrdersMutationVariables
  >(mutations.CREATE_TEXT_ORDERS);

  const handleCreateTextOrders = ({ variables, textOrdersVariables }: IHandleCreateTextOrders) => {
    return createTextOrders({
      variables,
      update: (proxy, { data }) => {
        const cacheData: TextOrdersQuery | null = proxy.readQuery({
          query: queries.TEXT_ORDERS,
          variables: textOrdersVariables,
        });

        if (!cacheData || !data) return;

        const newTextOrders = [...cacheData.textOrders.rows, ...data.createTextOrders];

        proxy.writeQuery({
          query: queries.TEXT_ORDERS,
          variables: textOrdersVariables,
          data: {
            ...data,
            textOrders: { ...cacheData.textOrders, rows: newTextOrders },
          },
        });
      },
    });
  };

  return [handleCreateTextOrders, { loading }];
};
