import { gql } from '@apollo/client';

export const PERMISSIONS = gql`
  query permissions {
    permissions {
      id
      key
      value
    }
  }
`;
