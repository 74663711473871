import { gql } from '@apollo/client';

import { ROLE_FRAGMENT } from '../fragments';

export const CREATE_ROLE = gql`
  mutation createRole($title: String!, $permissions: [PermissionEnum!]) {
    createRole(title: $title, permissions: $permissions) {
      ...role
    }
  }
  ${ROLE_FRAGMENT}
`;
