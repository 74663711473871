import styled from 'styled-components';

import { Key } from '../../../Icon';

export const Wrapper = styled.div<{ disabled: boolean; $isDragging: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 10px 20px;
  border: 1px solid
    ${({ theme: { colors }, $isDragging }) => ($isDragging ? colors.lightBlue : colors.borderGrey)};
  background-color: ${({ theme: { colors }, disabled, $isDragging }) =>
    disabled ? colors.lightGrey : $isDragging ? colors.azure : colors.white};
  transition: all 0.2s ease;
`;

export const ItemName = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme: { colors }, disabled }) => (disabled ? colors.darkGrey : colors.lightBlue)};
  }
`;

export const Color = styled.div<{ $color: string; disabled: boolean }>`
  height: 15px;
  width: 15px;
  min-width: 15px;
  margin-left: ${({ disabled }) => (disabled ? '39px' : '20px')};
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;

export const Text = styled.div`
  margin: 0 10px;
  color: ${({ theme: { colors } }) => colors.darkBlue};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
`;

export const KeyIcon = styled(Key)`
  height: 16px;
`;
