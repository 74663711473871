import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 32px;
  border: 2px solid ${({ theme: { colors } }) => colors.textGrey};
  border-radius: ${({ theme: { border } }) => border.radius.big};
  color: ${({ theme: { colors } }) => colors.darkBlue};
  font-size: ${({ theme: { font } }) => font.size.small};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
`;
