import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const DOMAIN_VIEW_FRAGMENT = gql`
  fragment domainView on Domain {
    id
    name
    max_phrases
    positioned_domain

    fulfillmentUsers {
      ...userBasic
    }
    salesUsers {
      ...userBasic
    }
    adminUsers {
      ...userBasic
    }
    semFulfillmentUsers {
      ...userBasic
    }
    seoContactUsers {
      ...userBasic
    }
    semContactUsers {
      ...userBasic
    }
    uxUsers {
      ...userBasic
    }
  }

  ${USER_BASIC_FRAGMENT}
`;
