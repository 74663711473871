import { gql } from '@apollo/client';

import { USER_BASIC_FRAGMENT } from './userBasic';
import { TICKET_FILE_FRAGMENT } from './ticketFile';
import { USER_WITH_PERM_INFO_FRAGMENT } from './userWithPermInfo';

export const TICKET_FRAGMENT = gql`
  fragment ticket on Ticket {
    sequence
    id
    title
    content_html
    created_at
    updated_at
    is_children
    deadline
    start
    total_time_logs
    priority_value
    priority {
      id
      value
      color
      color_value
    }
    type {
      id
      value
      color
      color_value
    }
    status {
      id
      value
      color
      color_value
    }
    project {
      id
      name
    }
    owner {
      ...userWithPermInfo
    }
    assigned {
      ...userBasic
    }
    updatedBy {
      ...userBasic
    }
    watchers {
      ...userBasic
    }

    file {
      ...ticketFile
    }
    domain {
      id
      name
      cms {
        value
      }
      is_whitelabel
    }
    sendingToClientTextOrders {
      id
    }
  }
  ${TICKET_FILE_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
  ${USER_WITH_PERM_INFO_FRAGMENT}
`;
